<template>
    <div class="sm:max-w-[400px]">
        <Slider @set="emitChange" :step="20" :min="0" :max="max" :format="{suffix: ' kr.', decimals: 0}"
                v-model="value" :merge="merge" tooltipPosition="bottom" />
        <input type="hidden" name="price_range" :value="value" />
    </div>
</template>

<script>
    import Slider from "@vueform/slider";

    export default {
        components: {
            Slider
        },
        props: {
            max: {
                type: Number,
                required: false,
                default: 1500
            },
            predefinedValue: {
                type: Array,
                required: false
            }
        },
        data() {
            return {
                value: [0, this.max],
                merge: 200
            };
        },
        mounted() {
            if (this.predefinedValue) {
                this.value = this.predefinedValue;
            }
        },
        methods: {
            emitChange(value) {
                this.$emit("changed-price", value);
            }
        }
    };
</script>
<style> :root {
    --slider-connect-bg: #CBA6FF;
    --slider-bg: #cacad0;
    --slider-height: 4px;
    --slider-handle-width: 20px;
    --slider-handle-height: 20px;
    --slider-handle-bg: #3E4377;
    --slider-tooltip-bg: #BEA6DC;

    --slider-handle-ring-width: 3px;
    --slider-handle-ring-color: #a594fe30;
}
</style>
<style src="@vueform/slider/themes/default.css"></style>
