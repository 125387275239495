<template>
    <div class="space-y-1 w-full mt-4">
        <!--        <div v-if="someIsSold" class="p-4 bg-red-100 text-red-600 text-xs">Et eller flere af dine produkter er desværre blevet solgt.</div>-->
        <div v-for="product in $store.state.cart.items" :key="product.id">
            <div class="space-y-2" :class="{ 'p-1' : inCart}">
                <div class="flex">
                    <div class="basis-1/12" :class="{ 'basis-2/12' : inCart }">
                        <div v-if="product.image">
                            <img :src="product.image" :alt="product.alt">
                        </div>
                        <div v-else>
                            <div v-if="product.options.category.parent_id === 351">
                                <div class="bg-topolis-gray-100 rounded-md p-2">
                                    <img :src="getClothesImage(product)"
                                         :alt="getClothesAlt(product)"
                                         class="bg-no-repeat">
                                </div>
                            </div>
                            <div v-else-if="product.options.category.parent_id === 213"
                                 class="bg-topolis-gray-100 rounded-md p-2">
                                <img src="/assets/categories/toys.svg"
                                     alt="minitopolis-no-image-toy-placeholder"
                                     class="bg-no-repeat">
                            </div>
                            <div v-else-if="product.options.category.parent_id === 144"
                                 class="bg-topolis-gray-100 rounded-md p-2">
                                <img :src="getEquipmentImage(product)"
                                     :alt="getEquipmentAlt(product)"
                                     class="bg-no-repeat">
                            </div>
                            <div v-else-if="product.options.category.parent_id === 221"
                                 class="bg-topolis-gray-100 rounded-md p-2">
                                <img src="/assets/categories/jacket.svg"
                                     alt="minitopolis-no-image-jacket-placeholder"
                                     class="bg-no-repeat">
                            </div>
                            <div v-else-if="product.options.category.parent_id === 620"
                                 class="bg-topolis-gray-100 rounded-md p-2">
                                <img :src="getInteriorImage(product)"
                                     :alt="getInteriorAlt(product)"
                                     class="bg-no-repeat">
                            </div>
                            <div v-else-if="product.options.category.parent_id === 376"
                                 class="bg-topolis-gray-100 rounded-md p-2">
                                <img :src="getAccessoriesImage(product)"
                                     :alt="getAccessoriesAlt(product)"
                                     class="bg-no-repeat">
                            </div>
                            <div v-else-if="product.options.category.parent_id === 621"
                                 class="bg-topolis-gray-100 rounded-md p-2">
                                <img src="/assets/categories/footwear.svg"
                                     alt="minitopolis-no-image-footwear-placeholder"
                                     class="bg-no-repeat">
                            </div>
                            <div v-else-if="product.options.category.parent_id === 377"
                                 class="bg-topolis-gray-100 rounded-md p-2">
                                <img src="/assets/categories/books-games.svg"
                                     alt="minitopolis-no-image-books-games-placeholder"
                                     class="bg-no-repeat">
                            </div>
                        </div>
                    </div>
                    <div class="flex justify-between text-left w-full ml-3 my-1">
                        <div class="flex flex-col justify-between gap-1 leading-3 mr-4">
                            <p class="inline-flex font-medium">{{ product.title }}
                                <span v-if="product.options.size">, str. {{ product.options.size.name
                                    }}</span>
                            </p>
                            <p class="inline-flex font-medium">{{ product.quantity }} stk.</p>
                        </div>
                        <div class="flex flex-col items-end justify-between gap-1">
                            <div v-if="product.extra_info.discount" class="flex">
                                <price class="font-bold text-sm line-through"
                                       :price="product.extra_info.original_price"></price>
                                <price :price="product.price"></price>
                            </div>
                            <div v-if="!product.extra_info.discount" class="flex">
                                <price class="font-bold text-sm" :price="product.price"></price>
                            </div>
                            <button @click="removeFromCart(product)"
                                    class="remove-item-cart inline-flex text-xxs font-medium underline hover:text-red-700">
                                Fjern
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-4 bg-red-200 text-red-900 text-xs" v-if="product.extra_info.is_sold">
                Produktet er desværre blevet solgt :(. Kontakt os for at høre om vi har noget lignende.
            </div>
        </div>
    </div>
</template>
<script>
    import Price from "./Price.vue";

    export default {
        name: "product-list",
        components: { Price },
        props: {
            inCart: {
                required: false,
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                timer: null
            };
        },
        mounted() {
            this.timer = setInterval(() => {
                this.checkProductAvailability();
            }, 10000);
        },
        beforeUnmount() {
            clearInterval(this.timer);
        },
        computed: {
            someIsSold() {
                this.$nextTick(() => {
                    return this.$store.state.cart.items.some(item => item.extra_info.is_sold);
                });
            }
        },
        methods: {
            checkProductAvailability() {
                axios.get("/check-product-availability").then((response) => {
                    this.$nextTick(() => {
                        this.$store.commit("setCart", response.data.result);
                    });
                });
            },
            removeFromCart(product) {
                this.$store.dispatch("removeFromCart", product.hash);
            },
            getClothesImage(product) {
                if (product.extra_info.target_segment) {
                    return product.extra_info.target_segment === "mama"
                        ? "/assets/categories/mama-clothes.svg"
                        : "/assets/categories/clothes.svg"; // Default to 'children' or fallback
                }
                return "/assets/categories/clothes.svg"; // Default fallback
            },
            getClothesAlt(product) {
                if (product.extra_info.target_segment) {
                    return product.extra_info.target_segment === "mama"
                        ? "MAMA tøj"
                        : "Børnetøj"; // Default to 'children' or fallback
                }
                return "MAMA tøj"; // Default fallback
            },
            getEquipmentImage(product) {
                if (product.extra_info.target_segment) {
                    return product.extra_info.target_segment === "mama"
                        ? "/assets/categories/belt.svg"
                        : "/assets/categories/equipment.svg"; // Default to 'children' or fallback
                }
                return "/assets/categories/equipment.svg"; // Default fallback
            },
            getEquipmentAlt(product) {
                if (product.extra_info.target_segment) {
                    return product.extra_info.target_segment === "mama"
                        ? "Udstyr til MAMA"
                        : "Udstyr til børn"; // Default to 'children' or fallback
                }
                return "Udstyr til børn"; // Default fallback
            },
            getAccessoriesImage(product) {
                if (product.extra_info.target_segment) {
                    return product.extra_info.target_segment === "mama"
                        ? "/assets/categories/mama-accessories.svg"
                        : "/assets/categories/accessories.svg"; // Default to 'children' or fallback
                }
                return "/assets/categories/accessories.svg"; // Default fallback
            },
            getAccessoriesAlt(product) {
                if (product.extra_info.target_segment) {
                    return product.extra_info.target_segment === "mama"
                        ? "Accessories til MAMA"
                        : "Accessories til børn"; // Default to 'children' or fallback
                }
                return "Accessories til børn"; // Default fallback
            },
            getInteriorImage(product) {
                if (product.extra_info.target_segment) {
                    return product.extra_info.target_segment === "mama"
                        ? "/assets/categories/mama-interior.svg"
                        : "/assets/categories/interior.svg"; // Default to 'children' or fallback
                }
                return "/assets/categories/interior.svg"; // Default fallback
            },
            getInteriorAlt(product) {
                if (product.extra_info.target_segment) {
                    return product.extra_info.target_segment === "mama"
                        ? "Interiør til MAMA"
                        : "Interiør til børn"; // Default to 'children' or fallback
                }
                return "Interiør til børn"; // Default fallback
            }
        }
    };
</script>
