<template>
    <div class="leading-none w-fit flex items-center "
         :class="{ 'px-1 shadow rounded border border-gray-200' : !onlyNumber }">
        <div v-if="!onlyNumber" class="text-xxs text-gray-800">{{ $translate("no.:") }}</div>
        <div class="text-sm font-medium">
            <div v-if="isBookingStatusSpecial">{{ booking.old_store_id }}</div>
            <div v-else>{{ booking.store_id }}</div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            booking: { type: Object, required: true },
            onlyNumber: { type: Boolean, default: false }
        },
        computed: {
            isBookingStatusSpecial() {
                const specialStatuses = [
                    "PAID",
                    "CANCELLED",
                    "REFUNDED",
                    "DECLINED",
                    "DISCONTINUED",
                    "FINISHED"
                ];
                return specialStatuses.includes(this.booking.status);
            }
        }
    };
</script>

<!-- Default styles -->
<style scoped>
</style>