<template>
    <div class="flex flex-col w-full mx-auto space-y-8">
        <div class="flex flex-col">
            <div class="flex flex-col space-y-8">
                <span class="text-xl xs:text-2xl sm:text-3xl font-black">Min information</span>
                <div class="flex flex-col items-center justify-between gap-6">
                    <h5 class="app-h5">
                        For at kunne gennemføre bookingen skal du logge ind som ny eller eksisterende bruger. På den
                        måde får du adgang til “Mit Minitopolis”, som blandt andet giver dig overblik over alle de
                        brugte børnesager, du har til salg.
                    </h5>
                    <div class="flex items-center justify-between w-full">
                        <router-link to="/kalender" class="flex items-center hover:no-underline">
                            <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 min-w-0 w-4"
                                 viewBox="0 0 24 24">
                                <g transform="translate(-684 -188)">
                                    <path d="M6.07,0,0,6.07l6.07,6.07" transform="translate(687.5 193.93)" fill="none"
                                          stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                          stroke-width="1.5" />
                                    <path d="M16.83,0H0" transform="translate(687.67 200)" fill="none"
                                          stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                          stroke-width="1.5" />
                                </g>
                            </svg>
                            <span class="ml-1 text-xs xxs:text-base">Tilbage</span>
                        </router-link>
                        <bouncing-button data-cy="btn-booking-continue" type="button" :bouncing="formType !== null"
                                         :disabled="formType === null" @click.native="gotoNext">Fortsæt
                        </bouncing-button>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex justify-center items-center space-x-4">
            <label for="radio-card-1" class="radio-card login-card" :class="{ checked: formType === 'register' }">
                <input id="radio-card-1" v-model="formType" type="radio" name="radio-card" class="hidden"
                       value="register" />
                <div class="radio-card-wrapper">
                    <div class="absolute top-3 right-3">
                        <span class="radio-card-check-icon"></span>
                    </div>
                    <span class="font-bold text-lg xs:text-xl sm:text-2xl my-4 xs:my-0 text-center"
                    >Ny<span><br /></span> bruger</span
                    >
                </div>
            </label>
            <label for="radio-card-2" class="radio-card login-card" :class="{ checked: formType === 'login' }">
                <input id="radio-card-2" v-model="formType" type="radio" name="radio-card" class="hidden"
                       value="login" />
                <div class="radio-card-wrapper">
                    <div class="absolute top-3 right-3">
                        <span class="radio-card-check-icon"></span>
                    </div>
                    <span class="font-bold text-lg xs:text-xl sm:text-2xl my-4 xs:my-0 text-center"
                    >Nuværende<span><br /></span> bruger
                    </span>
                </div>
            </label>
        </div>

        <div class="text-center">
            <p>
                Læs venligst
                <a href="https://minitopolis.dk/persondatapolitik#/" class="a-link" target="_blank">persondata
                    politik</a>
                for yderligere information om beskyttelse af personlige oplysninger
            </p>
        </div>

        <div class="flex flex-col space-y-8">
            <hr />
            <!--breadcrumbs-->
            <div
                class="flex flex-col sm:flex-row justify-center items-center space-x-4 sm:space-x-8 relative mt-12 mb-8 space-y-4 sm:space-y-0">
                <div class="flex justify-center items-center space-x-4 sm:space-x-8">
                    <!--concept-->
                    <router-link to="/booking" class="no-underline">
                        <div v-if="chosenConcept === 'easy'"
                             class="flex flex-row items-center justify-center space-x-2">
                            <img class="w-10" src="/assets/images/hanger-yellow.svg" alt="" />
                            <div class="flex flex-col items-start">
                                <p class="font-bold">Nemt</p>
                                <p v-if="chosenPeriod.concept === 'easy25'" class="text-xxs font-medium">25 varer</p>
                                <p v-else-if="chosenPeriod.concept === 'easy50'" class="text-xxs font-medium">50
                                    varer</p>
                                <p v-else-if="chosenPeriod.concept === 'easy75'" class="text-xxs font-medium">75
                                    varer</p>
                                <p v-else class="text-xxs font-medium">100 varer</p>
                            </div>
                        </div>
                        <div v-if="chosenConcept === 'easier'"
                             class="flex flex-row items-center justify-center space-x-2">
                            <img class="w-10" src="/assets/images/hanger-purple.svg" alt="" />
                            <div class="flex flex-col items-start">
                                <p class="font-bold">Nemmere</p>
                                <p v-if="chosenPeriod.concept === 'easier50'" class="text-xxs font-medium">50
                                    varer</p>
                                <p v-else class="text-xxs font-medium">100 varer</p>
                            </div>

                        </div>
                        <div v-if="chosenConcept === 'easiest'"
                             class="flex flex-row items-center justify-center space-x-2">
                            <img class="w-10" src="/assets/images/hanger-blue.svg" alt="" />
                            <div class="flex flex-col items-start">
                                <p class="font-bold">Nemmest</p>
                                <p class="text-xxs font-medium">100 varer</p>
                            </div>
                        </div>
                    </router-link>
                    <!--period-->
                    <router-link to="/booking" class="no-underline hover:underline decoration-blue-900">
                        <div class="flex flex-row items-center justify-center space-x-1">
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 class="flex-shrink-0 min-w-0 w-8 "
                                 :class="{ 'text-topolis-yellow-700': chosenConcept === 'easy', 'text-topolis-purple-600': chosenConcept === 'easier', 'text-topolis-blue-700': chosenConcept === 'easiest' }"
                                 viewBox="0 0 24 24">
                                <g transform="translate(-108 -188)">
                                    <path
                                        d="M12.949,7.68A3.631,3.631,0,0,1,9.549,10h-5.9A3.635,3.635,0,0,1,1.2,3.67L5.249,0h2.71L12,3.67A3.635,3.635,0,0,1,12.949,7.68Z"
                                        transform="translate(113.401 200)" fill="currentColor" opacity="0.4" />
                                    <path
                                        d="M4.32,1.36H.68A.68.68,0,1,1,.68,0H4.32A.681.681,0,0,1,5,.68.687.687,0,0,1,4.32,1.36Z"
                                        transform="translate(117.5 204.78)" fill="currentColor" />
                                    <path
                                        d="M12.944,2.32A3.631,3.631,0,0,0,9.544,0h-5.9A3.63,3.63,0,0,0,1.2,6.33L5.244,10h2.71l4.04-3.67A3.635,3.635,0,0,0,12.944,2.32ZM8.414,5.23H4.774a.68.68,0,1,1,0-1.36h3.64a.68.68,0,1,1,0,1.36Z"
                                        transform="translate(113.406 190)"
                                        fill="currentColor"
                                    />
                                </g>
                            </svg>
                            <div class="flex items-center justify-center">
                                <p class="font-black">{{ chosenPeriod.period_length }}</p>
                                <p class="font-light ml-1">uger</p>
                            </div>
                        </div>
                    </router-link>
                </div>
                <!--calendar-->
                <router-link to="/kalender" class="no-underline hover:underline decoration-blue-900">
                    <div class="flex items-center justify-center space-x-2">
                        <div class="flex items-center justify-center space-x-2">
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 class="flex-shrink-0 min-w-0 w-8"
                                 :class="{ 'text-topolis-yellow-700': chosenConcept === 'easy', 'text-topolis-purple-600': chosenConcept === 'easier', 'text-topolis-blue-700': chosenConcept === 'easiest' }"
                                 viewBox="0 0 24 24">
                                <g transform="translate(-556 -188)">
                                    <path
                                        d="M13.711,2.31V.75a.75.75,0,0,0-1.5,0v1.5h-6.5V.75a.75.75,0,0,0-1.5,0V2.31A4.248,4.248,0,0,0,0,6.56a.5.5,0,0,0,.5.53h16.92a.5.5,0,0,0,.5-.53A4.248,4.248,0,0,0,13.711,2.31Z"
                                        transform="translate(559.039 189.25)"
                                        fill="currentColor"
                                    />
                                    <path
                                        d="M18,1.01V2.75a.985.985,0,0,1-1.14.98A6.108,6.108,0,0,0,16,3.66a5.51,5.51,0,0,0-5.5,5.5,6.176,6.176,0,0,0,.37,1.68,1,1,0,0,1-.95,1.32H5c-3.5,0-5-2-5-5V1A1,1,0,0,1,1,0H17A1.018,1.018,0,0,1,18,1.01Z"
                                        transform="translate(559 197.83)"
                                        fill="currentColor"
                                        opacity="0.4"
                                    />
                                    <path
                                        d="M4,0A4,4,0,0,0,0,4,3.921,3.921,0,0,0,.58,6.06a3.985,3.985,0,0,0,6.84,0A3.921,3.921,0,0,0,8,4,4,4,0,0,0,4,0ZM6.07,3.57,3.94,5.54a.763.763,0,0,1-.51.2.742.742,0,0,1-.53-.22l-.99-.99A.75.75,0,0,1,2.97,3.47l.48.48,1.6-1.48a.75.75,0,0,1,1.02,1.1Z"
                                        transform="translate(571 203)"
                                        fill="currentColor"
                                    />
                                    <path
                                        d="M1,2a1.052,1.052,0,0,1-.71-.29A1.052,1.052,0,0,1,0,1,1.052,1.052,0,0,1,.29.291,1.009,1.009,0,0,1,1.2.021a.636.636,0,0,1,.18.06.757.757,0,0,1,.18.09l.15.12A1.052,1.052,0,0,1,2,1a1.052,1.052,0,0,1-.29.71l-.15.12a.757.757,0,0,1-.18.09.636.636,0,0,1-.18.06A1.5,1.5,0,0,1,1,2Z"
                                        transform="translate(563.5 200.999)"
                                        fill="#fff"
                                    />
                                    <path
                                        d="M1,1.987A1.052,1.052,0,0,1,.29,1.7,1.052,1.052,0,0,1,0,.987,1.052,1.052,0,0,1,.29.278a1.047,1.047,0,0,1,1.42,0A1.052,1.052,0,0,1,2,.987a1.052,1.052,0,0,1-.29.71A1.052,1.052,0,0,1,1,1.987Z"
                                        transform="translate(567 201.013)"
                                        fill="#fff"
                                    />
                                    <path
                                        d="M1,2a1.052,1.052,0,0,1-.71-.29A1.052,1.052,0,0,1,0,1,1.052,1.052,0,0,1,.29.289,1.032,1.032,0,0,1,.62.079a1,1,0,0,1,1.09.21A1.052,1.052,0,0,1,2,1a1.052,1.052,0,0,1-.29.71A1.052,1.052,0,0,1,1,2Z"
                                        transform="translate(563.5 204.501)"
                                        fill="#fff"
                                    />
                                </g>
                            </svg>
                        </div>
                        <p class="font-black text-left"><span class="font-light">uge</span> {{ startWeek.week_number }}
                            - {{ endWeek.week_number }}</p>
                    </div>
                </router-link>
            </div>
            <div class="flex items-center justify-between pb-48 sm:pb-28">
                <router-link to="/kalender" class="flex items-center hover:no-underline">
                    <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 min-w-0 w-4" viewBox="0 0 24 24">
                        <g transform="translate(-684 -188)">
                            <path d="M6.07,0,0,6.07l6.07,6.07" transform="translate(687.5 193.93)" fill="none"
                                  stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                  stroke-width="1.5" />
                            <path d="M16.83,0H0" transform="translate(687.67 200)" fill="none" stroke="currentColor"
                                  stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                        </g>
                    </svg>
                    <span class="ml-1 text-xs xxs:text-base">Tilbage</span>
                </router-link>
                <bouncing-button data-cy="btn-booking-continue" type="button" :bouncing="formType !== null"
                                 :disabled="formType === null" @click.native="gotoNext">Fortsæt
                </bouncing-button>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapGetters } from "vuex";
    import BouncingButton from "../Common/BouncingButton.vue";

    export default {
        name: "FormStep",
        components: { BouncingButton },
        data() {
            return {
                formType: null
            };
        },
        computed: {
            ...mapGetters(["chosenConcept", "periods", "chosenPeriod", "formattedStartDate", "formattedEndDate", "startWeek", "endWeek"])
        },
        methods: {
            gotoNext() {
                if (this.formType === "register") {
                    this.$store.commit("setParameters", {
                        showPassword: true,
                        registrationStep: true,
                        fullRegistration: true,
                        isEditMode: false
                    });
                    this.$router.push({ name: "register-step" });
                }
                if (this.formType === "login") {
                    this.$router.push({ name: "login-step" });
                }
            }
        }
    };
</script>
