<template>
    <GoogleMap
        ref="mapRef"
        :api-key="GOOGLE_MAPS_API_KEY"
        style="width: 100%; height: 700px"
        :center="center"
        :zoom="10"
        :max-zoom="13"
    >
        <MarkerCluster v-for="(location, i) in locations" :key="i">
            <Marker
                v-for="(user, j) in usersInLocation(location)"
                :key="j"
                :options="{ position: location }">
            </Marker>
        </MarkerCluster>

    </GoogleMap>
    <div>
        <table class="table table-auto w-full divide-y divide-gray-400">
            <thead class="bg-transparent">
            <tr>
                <th scope="col"
                    class="px-4 py-2 text-left text-xxs font-medium text-gray-800 uppercase tracking-wider">By / Postnr.
                </th>
                <th scope="col"
                    class="px-4 py-2 text-left text-xxs font-medium text-gray-800 uppercase tracking-wider">Antal
                    brugere
                </th>
                <th scope="col"
                    class="px-4 py-2 text-left text-xxs font-medium text-gray-800 uppercase tracking-wider">Antal
                    bookinger
                </th>
                <th scope="col"
                    class="px-4 py-2 text-left text-xxs font-medium text-gray-800 uppercase tracking-wider">
                    Gns. booking per bruger
                </th>
                <th scope="col"
                    class="px-4 py-2 text-left text-xxs font-medium text-gray-800 uppercase tracking-wider">
                    {{ $translate("easy") }}
                </th>
                <th scope="col"
                    class="px-4 py-2 text-left text-xxs font-medium text-gray-800 uppercase tracking-wider">
                    {{ $translate("easier") }}
                </th>
                <th scope="col"
                    class="px-4 py-2 text-left text-xxs font-medium text-gray-800 uppercase tracking-wider">
                    {{ $translate("easiest") }}
                </th>
                <th scope="col"
                    class="px-4 py-2 text-left text-xxs font-medium text-gray-800 uppercase tracking-wider">
                    {{ $translate("business") }}
                </th>
                <th scope="col"
                    class="px-4 py-2 text-left text-xxs font-medium text-gray-800 uppercase tracking-wider">
                    {{ $translate("VIP") }}
                </th>
            </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
            <tr v-for="(item, index) in tableData" :key="index" :class="{ 'bg-red-200': !item.foundInLocations }">
                <td class="px-4 py-2 whitespace-nowrap">
                    <div v-if="!item.foundInLocations">
                        <tooltip>
                            <template v-slot:triggerText>
                                {{ item.city }}, {{ item.zip }}
                            </template>
                            <span class="font-light">
                                <span class="flex items-center gap-1">
                                    By og postnr. er ikke på kortet - endnu! Fortæl det til Mathias
                                </span>
                            </span>
                        </tooltip>
                    </div>
                    <div v-else>
                        {{ item.city }}, {{ item.zip }}
                    </div>

                </td>
                <td class="px-4 py-2 whitespace-nowrap">{{ item.usersCount }}</td>
                <td class="px-4 py-2 whitespace-nowrap">{{ item.bookingsCount }}</td>
                <td class="px-4 py-2 whitespace-nowrap">{{ item.avgBookingsPerUser }}</td>
                <td class="px-4 py-2 whitespace-nowrap">{{ item.easy }}</td>
                <td class="px-4 py-2 whitespace-nowrap">{{ item.easier }}</td>
                <td class="px-4 py-2 whitespace-nowrap">{{ item.easiest }}</td>
                <td class="px-4 py-2 whitespace-nowrap">{{ item.business }}</td>
                <td class="px-4 py-2 whitespace-nowrap">{{ item.vip }}</td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import { GoogleMap, Marker, MarkerCluster } from "vue3-google-map";
    import UserStoreId from "./UserStoreId.vue";
    import Tooltip from "./Tooltip.vue";

    const GOOGLE_MAPS_API_KEY = "AIzaSyA3VV7ze7d6qGWNxY5tcfg0IvTypafh9VI";

    export default {
        name: "SimpleMap",
        components: { Tooltip, UserStoreId, GoogleMap, Marker, MarkerCluster },
        props: {
            users: {}
        },
        data() {
            return {
                GOOGLE_MAPS_API_KEY,
                center: { lat: 56.1629, lng: 10.2039 },
                locations: [
                    { zip: 2100, city: "København Ø", lat: 55.70786655302687, lng: 12.575084643506631 },
                    { zip: 2635, city: "Ishøj", lat: 55.61820108468121, lng: 12.346015891901818 },
                    { zip: 4000, city: "Roskilde", lat: 55.6416584666198, lng: 12.0834584215665 },
                    { zip: 6760, city: "Ribe", lat: 55.32510384654383, lng: 8.768781508185281 },
                    { zip: 7100, city: "Vejle", lat: 55.70798874936172, lng: 9.533001959251907 },
                    { zip: 7140, city: "Stouby", lat: 55.70379651438306, lng: 9.7929741743252 },
                    { zip: 7160, city: "Tørring", lat: 55.85454729057581, lng: 9.48885923219427 },
                    { zip: 7400, city: "Herning", lat: 56.13710682218323, lng: 8.973091895724936 },
                    { zip: 7430, city: "Ikast", lat: 56.135600047693536, lng: 9.156593982571684 },
                    { zip: 7500, city: "Holstebro", lat: 56.361613172806685, lng: 8.626323828337872 },
                    { zip: 7850, city: "Stoholm Jyll", lat: 56.48438402671041, lng: 9.144620178946564 },
                    { zip: 8000, city: "Aarhus", lat: 56.14731712600378, lng: 10.204912380516156 },
                    { zip: 8200, city: "Aarhus N", lat: 56.183029041389204, lng: 10.19252777258303 },
                    { zip: 8210, city: "Aarhus V", lat: 56.17121812304498, lng: 10.162300605936311 },
                    { zip: 8220, city: "Brabrand", lat: 56.15857734287951, lng: 10.101579212382136 },
                    { zip: 8230, city: "Åbyhøj", lat: 56.152272084778545, lng: 10.160270503805824 },
                    { zip: 8240, city: "Risskov", lat: 56.19913408514844, lng: 10.235074933094445 },
                    { zip: 8250, city: "Egå", lat: 56.22224433157851, lng: 10.292286124233907 },
                    { zip: 8260, city: "Viby", lat: 56.124593321187895, lng: 10.137512095698352 },
                    { zip: 8270, city: "Højbjerg", lat: 56.11268876399154, lng: 10.20498032095712 },
                    { zip: 8300, city: "Odder", lat: 55.973325966252, lng: 10.170286074767919 },
                    { zip: 8310, city: "Tranbjerg J", lat: 56.09016265900113, lng: 10.134574393611867 },
                    { zip: 8330, city: "Beder", lat: 56.061172003203225, lng: 10.212131475768466 },
                    { zip: 8355, city: "Solbjerg", lat: 56.04010608512768, lng: 10.084721074814343 },
                    { zip: 8362, city: "Hørning", lat: 56.087873291671045, lng: 10.035132439063624 },
                    { zip: 8370, city: "Hadsten", lat: 56.33103584459912, lng: 10.05004864003177 },
                    { zip: 8380, city: "Trige", lat: 56.25577828874675, lng: 10.149487515448364 },
                    { zip: 8381, city: "Tilst", lat: 56.18505091601152, lng: 10.106362367304484 },
                    { zip: 8382, city: "Hinnerup", lat: 56.264671402334656, lng: 10.060881828508888 },
                    { zip: 8400, city: "Ebeltoft", lat: 56.20356580004241, lng: 10.692220854530438 },
                    { zip: 8410, city: "Rønde", lat: 56.30129493643103, lng: 10.478738556725343 },
                    { zip: 8420, city: "Knebel", lat: 56.212620689816404, lng: 10.485650209729917 },
                    { zip: 8541, city: "Skødstrup", lat: 56.26642072447041, lng: 10.309532358731486 },
                    { zip: 8450, city: "Hammel", lat: 56.256865521279025, lng: 9.867480946690293 },
                    { zip: 8464, city: "Galten", lat: 56.15396763000937, lng: 9.911648369036325 },
                    { zip: 8471, city: "Sabro", lat: 56.213049660079584, lng: 10.028392408298643 },
                    { zip: 8520, city: "Lystrup", lat: 56.23760489874929, lng: 10.227734125795056 },
                    { zip: 8530, city: "Hjortshøj", lat: 56.26027308539718, lng: 10.2566093703907 },
                    { zip: 8543, city: "Hornslet", lat: 56.32059257364992, lng: 10.311167753604357 },
                    { zip: 8544, city: "Mørke", lat: 56.340972611781275, lng: 10.380135554929844 },
                    { zip: 8560, city: "Kolind", lat: 56.36092440179127, lng: 10.594115344244145 },
                    { zip: 8600, city: "Silkeborg", lat: 56.177175566630716, lng: 9.558212662520784 },
                    { zip: 8660, city: "Skanderborg", lat: 56.042641572725366, lng: 9.925432378668186 },
                    { zip: 8700, city: "Horsens", lat: 55.858779759299814, lng: 9.836524045990757 },
                    { zip: 8722, city: "Hedensted", lat: 55.77198302538013, lng: 9.702530879015315 },
                    { zip: 8870, city: "Langå", lat: 56.38928128021116, lng: 9.900769568867563 },
                    { zip: 8920, city: "Randers NV", lat: 56.47060570662796, lng: 9.986992508791623 },
                    { zip: 8960, city: "Randers SØ", lat: 56.441406387485614, lng: 10.116019274805431 },
                    { zip: 8963, city: "Auning", lat: 56.43152227702258, lng: 10.388288056999626 }
                ]
            };
        },
        computed: {
            tableData() {
                // Define the concept names
                const concepts = ["easy", "easier", "easiest", "business", "vip"];

                // Get all unique zip codes from the users data
                const userZips = [...new Set(this.users.map(user => user.zip))];

                // Create a mapping of user ZIP codes to their cities
                const userZipToCity = this.users.reduce((map, user) => ({ ...map, [user.zip]: user.city }), {});

                // Combine zips from this.locations and userZips, ensuring each zip is unique
                const allZips = [...new Set([...this.locations.map(location => location.zip), ...userZips])];

                // Create data for each unique zip
                const data = allZips.map(zip => {
                    const locationFromLocations = this.locations.find(location => location.zip === zip);
                    // Find location in this.locations for additional details, or use details from user data
                    const location = this.locations.find(location => location.zip === zip) || {
                        city: userZipToCity[zip],
                        zip: zip
                    };

                    // Get all the users in this zip
                    const users = this.users.filter(user => user.zip === zip);

                    // Object to hold the count for each concept
                    const conceptCounts = concepts.reduce((carry, concept) => ({ ...carry, [concept]: 0 }), {});

                    // Increment counters for each concept found in the user's bookings
                    users.forEach(user => {
                        user.bookings.forEach(booking => {
                            if (concepts.includes(booking.concept))
                                conceptCounts[booking.concept]++;
                        });
                    });

                    // Calculate total bookings count
                    const bookingsCount = Object.values(conceptCounts).reduce((a, b) => a + b, 0);

                    // Calculate booking per users per given location
                    const avgBookingsPerUser = users.length > 0 ? bookingsCount / users.length : 0;

                    return {
                        city: location.city || "",
                        zip: location.zip,
                        usersCount: users.length,
                        bookingsCount: bookingsCount,
                        avgBookingsPerUser: avgBookingsPerUser.toFixed(2),
                        foundInLocations: !!locationFromLocations, // added
                        ...conceptCounts  // Spread the concept count object into this data object
                    };
                });

                return data.sort((a, b) => b.usersCount - a.usersCount);
            }
        },
        methods: {
            usersInLocation(location) {
                const zipNames = [location.zip];
                return this.users.filter(user => zipNames.includes(user.zip));
            }
        }
    };
</script>