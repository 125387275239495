<template>
    <div v-if="user.avatar">
        <img :class="[avatarClassSizes[size], { 'cursor-pointer hover:brightness-110' : interaction }]"
             :src="user.avatar"
             width="auto"
             height="auto"
             alt="" />
    </div>

    <div v-else
         :class="[avatarFallbackClassSizes[size], { 'cursor-pointer hover:border-gray-500' : interaction }]"
         :style="{ lineHeight: 'normal' }">
        {{ user.initials }}
    </div>
</template>

<script>
    export default {
        props: {
            user: { type: Object, required: true },
            size: { type: String, default: "medium" },
            interaction: { type: Boolean, default: false }
        },
        data() {
            return {
                avatarClassSizes: {
                    "xxsmall": "block w-5 h-5 rounded-full border border-office-gray-300",
                    "xsmall": "block w-6 h-6 rounded-full border border-office-gray-300",
                    "small": "block w-8 h-8 rounded-full border border-office-gray-300",
                    "medium": "block w-10 h-10 rounded-full border border-office-gray-300",
                    "large": "block w-12 h-12 rounded-full border border-office-gray-300"
                },
                avatarFallbackClassSizes: {
                    "xxsmall": "flex items-center justify-center w-5 h-5 text-[8px] tracking-tighter rounded-full border border-office-gray-300 font-medium text-office-black",
                    "xsmall": "flex items-center justify-center w-6 h-6 text-xs tracking-normal rounded-full border border-office-gray-300 font-medium text-office-black",
                    "small": "flex items-center justify-center w-8 h-8 text-xs tracking-normal rounded-full border border-office-gray-300 font-medium text-office-black",
                    "medium": "flex items-center justify-center w-10 h-10 text-xs tracking-normal rounded-full border border-office-gray-300 font-medium text-office-black",
                    "large": "flex items-center justify-center w-12 h-12 text-xs tracking-normal rounded-full border border-office-gray-300 font-medium text-office-black"
                }
            };
        }
    };
</script>

<!-- Default styles -->
<style scoped>
</style>