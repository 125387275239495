<template>
    <div class="h-full">
        <div v-if="loading" class="flex h-1/2">
            <loading-spinner :loading="loading" :size="tableText ? 'mini' : 'small'"></loading-spinner>
        </div>
        <div v-if="!loading" class="w-full relative">
            <p class="font-bold text-gray-900"
               :class="{ 'text-sm font-semibold' : tableText, 'text-xl sm:text-2xl' : !tableText }">
                {{ total }}
            </p>
            <!--            <div v-for="bucket in [sortedAllBookings]" :key="bucket">-->
            <!--                <table>-->
            <!--                    <thead>-->
            <!--                    <tr>-->
            <!--                        <th>Store ID</th>-->
            <!--                        <th>User Name</th>-->
            <!--                        <th>End date</th>-->
            <!--                        <th>User payout</th>-->
            <!--                        &lt;!&ndash; Add other fields you want to display &ndash;&gt;-->
            <!--                    </tr>-->
            <!--                    </thead>-->
            <!--                    <tbody>-->
            <!--                    <tr v-for="booking in bucket" :key="booking.id">-->
            <!--                        <td>{{ booking.store_id ? booking.store_id : booking.old_store_id }}</td>-->
            <!--                        <td>{{ booking.customer.name }}</td>-->
            <!--                        <td>{{ new Date(booking.end_date).toLocaleDateString("da-DK", {-->
            <!--                            day: "numeric",-->
            <!--                            month: "short",-->
            <!--                            year: "numeric"-->
            <!--                        }) }}-->
            <!--                        </td>-->
            <!--                        <td>{{ booking.stats.totalOwed.value.amount }}</td>-->
            <!--                        &lt;!&ndash; Add other fields you want to display &ndash;&gt;-->
            <!--                    </tr>-->
            <!--                    </tbody>-->
            <!--                </table>-->
            <!--            </div>-->
        </div>
    </div>
</template>

<script>
    import axios from "axios";
    import LoadingSpinner from "../Pages/Common/LoadingSpinner.vue";

    export default {
        name: "InfoBox",
        components: { LoadingSpinner },
        props: {
            locale: String,
            endpoint: String,
            tableText: Boolean
        },
        data() {
            return {
                total: null,
                loading: false,
                allBookings: []
            };
        },
        beforeUnmount() { // Use this hook to prevent potential memory leaks
            this.$root.emitter.off("dateChanged", this.fetchData);
        },
        methods: {
            fetchData() {
                this.loading = true;
                axios.get(this.endpoint)
                    .then(response => {
                        // console.log("que?", response.data);
                        if (response.data.totalPayout) {
                            // console.log("totalPauut?", response.data);
                            this.allBookings = response.data.allBookings;
                            let amount = parseFloat(response.data.totalPayout.value.amount) / 100;
                            amount = amount.toLocaleString("da-DK", { minimumFractionDigits: 2 });
                            let currency = response.data.totalPayout.value.currency;
                            currency = currency === "DKK" ? "kr." : currency;
                            this.total = `${amount} ${currency}`;
                        }
                        // Add handling of other types of responses here
                        else if (response.data.totalEarnings) {
                            let amount = parseFloat(response.data.totalEarnings.value.amount) / 100;
                            amount = amount.toLocaleString("da-DK", { minimumFractionDigits: 2 });
                            let currency = response.data.totalEarnings.value.currency;
                            currency = currency === "DKK" ? "kr." : currency;
                            this.total = `${amount} ${currency}`;

                        } else if (response.data.projectedPayout) {
                            this.allBookings = response.data.allBookings;
                            // console.log("hello?", response.data);
                            let amount = parseFloat(response.data.projectedPayout.value.amount) / 100;
                            amount = amount.toLocaleString(this.locale, { minimumFractionDigits: 2 });
                            let currency = response.data.projectedPayout.value.currency;
                            currency = currency === "DKK" ? "kr." : currency;
                            this.total = `${amount} ${currency}`;
                        }
                        // generic else case if none of the above formats match
                        else {
                            this.total = response.data.total;
                        }
                        this.loading = false;
                    })
                    .catch(error => {
                        console.error(error);
                        // if (error.response) {
                        //     // The request was made and the server responded with a status code
                        //     // that falls out of the range of 2xx
                        //     console.error("Response data:", error.response.data);
                        //     console.error("Response status:", error.response.status);
                        //     console.error("Response headers:", error.response.headers);
                        // } else if (error.request) {
                        //     // The request was made but no response was received
                        //     console.error("Request:", error.request);
                        // } else {
                        //     // Something happened in setting up the request that triggered an Error
                        //     console.error("Error message:", error.message);
                        // }
                        // console.error("Config:", error.config);
                        this.loading = false;
                    });
            }
        },
        mounted() {
            this.fetchData();
            this.$root.emitter.on("dateChanged", this.fetchData);
        },
        computed: {
            sortedAllBookings() {
                return this.allBookings.sort((a, b) => {
                    const storeIdA = a.store_id ? a.store_id : a.old_store_id;
                    const storeIdB = b.store_id ? b.store_id : b.old_store_id;

                    if (storeIdA < storeIdB) return -1;
                    if (storeIdA > storeIdB) return 1;
                    return 0;
                });
            }
        }
    };
</script>