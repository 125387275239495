<template>
    <div class="relative">
        <div class="grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-4">
            <div v-for="booking in bookings" :key="booking.id">
                <label>
                    <div class="bg-office-gray-200 rounded-xl shadow hover:bg-slate-200">
                        <div class="p-4 flex items-center justify-between relative space-x-2">
                            <div class="flex flex-col w-full space-y-2">
                                <div class="flex items-center justify-between relative">
                                    <div class="border border-black px-1 leading-none w-fit flex items-center">
                                        <span class="text-xxs text-gray-800">nr.:</span>
                                        <span class="text-sm font-medium text-black">
                                            <span
                                                v-if="booking.status === 'paid' || booking.status === 'cancelled' || booking.status === 'declined' || booking.status === 'refunded' || booking.status === 'finished'">{{ booking.old_store_id
                                                }}</span>
                                            <span v-else>{{ booking.store_id }}</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="flex items-center space-x-1">
                                    <div v-if="booking.concept === 'easy'" class="concept-object concept-easy"></div>
                                    <div v-if="booking.concept === 'easier'"
                                         class="concept-object concept-easier"></div>
                                    <div v-if="booking.concept === 'easiest'"
                                         class="concept-object concept-easiest"></div>
                                    <span
                                        class="font-bold text-sm lg:text-base max-w-[8rem] truncate capitalize">{{ booking.customer.name
                                        }}</span>
                                </div>
                                <div class="flex items-center justify-between">
                                    <div class="flex flex-col" v-if="isCollectingItems">
                                        <span class="text-sm">Indsamlet:</span>
                                        <div>
                                            <span class="font-black text-topolis-green-300">
                                                {{ booking.collected_products.length }}
                                            </span>
                                            <span class="text-sm">
                                                <span>/</span>
                                                {{ booking.not_collected_products.length + booking.collected_products.length
                                                }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="flex flex-col" v-if="!isCollectingItems">
                                        <span class="text-sm">Produkter med discount</span>
                                        <div>
                                            <span class="font-black text-topolis-green-300">
                                                {{ booking.all_prepared_available_user_products_after_discount_extension.length
                                                }}
                                            </span>
                                            <span class="text-sm">
                                                <span>/</span>
                                                {{ booking.all_not_prepared_available_user_products_after_discount_extension.length + booking.all_prepared_available_user_products_after_discount_extension.length
                                                }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="flex items-center space-x-2">
                                        <div
                                            class="topolis-badge topolis-border-badge topolis-small-badge topolis-pearl-badge">
                                            {{ booking.start_week_number }}
                                        </div>
                                        <week-color :end-date="booking.end_date" />
                                    </div>
                                </div>
                            </div>
                            <div class="flex items-center justify-center">
                                <input v-model="selectedBookings" type="checkbox" :value="booking" />
                            </div>
                        </div>
                    </div>
                </label>
            </div>
        </div>

        <div class="flex flex-col items-center justify-center my-8">
            <div v-if="withScanningOption" class="flex flex-col items-center justify-center">
                <div class="flex items-center space-x-2">
                    <button class="admin-gray-button" @click="openModal">Start
                        scanning
                    </button>
                    <button v-if="selectedBookings.length" class="admin-gray-button admin-red-button"
                            @click="clearSelected">Ryd
                    </button>
                </div>
                <span v-if="selectedBookings.length === 0"
                      class="pt-2">Vælg en eller flere bookinger for at scanne</span>
            </div>
            <div v-if="selectedBookings.length" class="text-center pt-2">
                <span>Følgende butiksnumre er markeret:</span>
                <div v-if="selectedBookings" class="flex items-center justify-center flex-wrap -m-0.5">
                    <div v-for="selectedBooking in selectedBookings" :key="selectedBooking.id">
                        <div class="flex items-center justify-between m-1">
                            <div class="border border-black px-1 leading-none w-fit flex items-center">
                                <span class="text-xxs text-gray-800">nr.:</span>
                                <span class="text-sm font-medium text-black">
                                    {{ selectedBooking.store_id }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="selectedBookings && selectedBookings.length">
            <div class="w-full space-y-8">
                <div class="flex flex-col w-fit">
                    <label for="parent_category_id" class="form-label">Vælg overkategori</label>
                    <select v-model="parent_category_id" class="text-xs form-select" @change="fetchProducts">
                        <option selected value="null">-- Ingen valgt --</option>
                        <option :value="351">Tøj</option>
                        <option :value="221">Overtøj</option>
                        <option :value="213">Legetøj</option>
                        <option :value="144">Udstyr</option>
                        <option :value="620">Interiør</option>
                        <option :value="621">Fodtøj</option>
                        <option :value="376">Accessories</option>
                        <option :value="377">Bøger og spil</option>
                    </select>
                </div>

                <!--Not Collected table-->
                <div v-if="isCollectingItems">
                    <div class="text-lg font-bold pl-5 pb-2">Ikke indsamlet</div>
                    <div class="bg-white shadow rounded-lg border border-gray-200 mb-8">
                        <!-- Table -->
                        <div class="overflow-x-auto rounded-lg">
                            <!--Desktop-->
                            <table class="hidden xl:table table-auto w-full divide-y divide-gray-200">
                                <!-- Table header -->
                                <thead class="text-xs uppercase text-gray-500 bg-gray-50 border-gray-200">
                                <tr>
                                    <th class="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                        <div class="font-semibold text-left">Produkt navn</div>
                                    </th>
                                    <th class="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                        <div class="font-semibold text-left">Kategori</div>
                                    </th>
                                    <th class="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                        <button
                                            class="text-xs uppercase font-semibold text-left flex items-center text-office-sky-900 w-fit"
                                            @click="setOrderBy('size', sortDirection === 'ASC' ? 'DESC' : 'ASC')">
                                            <span class="mr-1 font-semibold text-left">Str.</span>
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4"
                                                 viewBox="0 0 12 19.5" stroke="currentColor">
                                                <g transform="translate(0 0)">
                                                    <g id="arrow-down" transform="translate(0 7.5)">
                                                        <path d="M7.92,0,4.66,3.26a.993.993,0,0,1-1.4,0L0,0"
                                                              transform="translate(2.04 4.475)" fill="none"
                                                              stroke="currentColor" stroke-linecap="round"
                                                              stroke-linejoin="round" stroke-width="1.5" />
                                                    </g>
                                                    <g id="arrow-up">
                                                        <path
                                                            d="M7.92,3.549,4.66.289a.993.993,0,0,0-1.4,0L0,3.549"
                                                            transform="translate(2.04 3.976)"
                                                            fill="none"
                                                            stroke="currentColor"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                            stroke-width="1.5"
                                                        />
                                                    </g>
                                                </g>
                                            </svg>
                                        </button>
                                    </th>
                                    <th class="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                        <div class="font-semibold text-left">Mærke</div>
                                    </th>
                                    <th class="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                        <div class="font-semibold text-left">Farve</div>
                                    </th>
                                    <th class="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                        <button
                                            class="text-xs uppercase font-semibold text-left flex items-center text-office-sky-900 w-fit"
                                            @click="setOrderBy('status', sortDirection === 'ASC' ? 'DESC' : 'ASC')">
                                            <span class="mr-1 font-semibold text-left">Status</span>
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4"
                                                 viewBox="0 0 12 19.5" stroke="currentColor">
                                                <g transform="translate(0 0)">
                                                    <g id="arrow-down" transform="translate(0 7.5)">
                                                        <path d="M7.92,0,4.66,3.26a.993.993,0,0,1-1.4,0L0,0"
                                                              transform="translate(2.04 4.475)" fill="none"
                                                              stroke="currentColor" stroke-linecap="round"
                                                              stroke-linejoin="round" stroke-width="1.5" />
                                                    </g>
                                                    <g id="arrow-up">
                                                        <path
                                                            d="M7.92,3.549,4.66.289a.993.993,0,0,0-1.4,0L0,3.549"
                                                            transform="translate(2.04 3.976)"
                                                            fill="none"
                                                            stroke="currentColor"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                            stroke-width="1.5"
                                                        />
                                                    </g>
                                                </g>
                                            </svg>
                                        </button>
                                    </th>
                                    <th class="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                        <div class="font-semibold text-left">Pris</div>
                                    </th>
                                    <th class="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                        <span class="font-semibold text-left">Status</span>
                                    </th>
                                </tr>
                                </thead>
                                <!-- Table body -->
                                <tbody class="text-sm">
                                <!-- Row -->
                                <scan-product v-for="product in allNotCollectedProducts" :key="product.id"
                                              :prop-product="product" class="topolis-table-row"
                                              :is-collecting-items="isCollectingItems" />
                                </tbody>
                            </table>
                            <!--Mobile-->
                            <ul class="xl:hidden w-full divide-y divide-gray-200">
                                <scan-product-mobile v-for="product in allNotCollectedProducts" :key="product.id"
                                                     :prop-product="product"
                                                     :is-collecting-items="isCollectingItems"></scan-product-mobile>
                            </ul>
                        </div>
                    </div>
                </div>

                <!--Collected table-->
                <div v-if="isCollectingItems">
                    <div class="text-lg font-bold pl-5 pb-2">Indsamlet</div>
                    <div class="bg-white shadow rounded-lg border border-gray-200 mb-8">
                        <!-- Table -->
                        <div class="overflow-x-auto rounded-lg">
                            <table class="hidden xl:table table-auto w-full divide-y divide-gray-200">
                                <!-- Table header -->
                                <thead class="text-xs uppercase text-gray-500 bg-gray-50 border-gray-200">
                                <tr>
                                    <th class="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                        <div class="font-semibold text-left">Produkt navn</div>
                                    </th>
                                    <th class="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                        <div class="font-semibold text-left">Kategori</div>
                                    </th>
                                    <th class="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                        <div class="font-semibold text-left">Str.</div>
                                    </th>
                                    <th class="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                        <div class="font-semibold text-left">Mærke</div>
                                    </th>
                                    <th class="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                        <div class="font-semibold text-left">Farve</div>
                                    </th>
                                    <th class="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                        <div class="font-semibold text-left">Status</div>
                                    </th>
                                    <th class="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                        <div class="font-semibold text-left">Pris</div>
                                    </th>
                                    <th class="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                        <span class="font-semibold text-left">Status</span>
                                    </th>
                                </tr>
                                </thead>
                                <!-- Table body -->
                                <tbody class="text-sm">
                                <!-- Row -->
                                <scan-product v-for="product in allCollectedProducts" :key="product.id"
                                              :prop-product="product" class="topolis-table-row bg-gray-300"
                                              :is-collecting-items="isCollectingItems" />
                                </tbody>
                            </table>
                            <!--Mobile-->
                            <ul class="xl:hidden w-full divide-y divide-gray-200 bg-gray-300">
                                <scan-product-mobile v-for="product in allCollectedProducts" :key="product.id"
                                                     :prop-product="product"
                                                     :is-collecting-items="isCollectingItems"></scan-product-mobile>
                            </ul>
                        </div>
                    </div>
                </div>

                <!--Products not prepared with Discount table-->
                <div v-if="!isCollectingItems">
                    <div class="text-lg font-bold pl-5 pb-2">Mangler discount label</div>
                    <div class="bg-white shadow rounded-lg border border-gray-200 mb-8">
                        <!-- Table -->
                        <div class="overflow-x-auto rounded-lg">
                            <table class="hidden xl:table table-auto w-full divide-y divide-gray-200">
                                <!-- Table header -->
                                <thead class="text-xs uppercase text-gray-500 bg-gray-50 border-gray-200">
                                <tr>
                                    <th class="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                        <div class="font-semibold text-left">Produkt navn</div>
                                    </th>
                                    <th class="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                        <div class="font-semibold text-left">Kategori</div>
                                    </th>
                                    <th class="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                        <div class="font-semibold text-left">Str.</div>
                                    </th>
                                    <th class="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                        <div class="font-semibold text-left">Mærke</div>
                                    </th>
                                    <th class="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                        <div class="font-semibold text-left">Farve</div>
                                    </th>
                                    <th class="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                        <div class="font-semibold text-left">Status</div>
                                    </th>
                                    <th class="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                        <div class="font-semibold text-left">Pris</div>
                                    </th>
                                    <th class="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                        <span class="font-semibold text-left">Status</span>
                                    </th>
                                </tr>
                                </thead>
                                <!-- Table body -->
                                <tbody class="text-sm">
                                <!-- Row -->
                                <scan-product
                                    v-for="product in allNotPreparedAvailableUserProductsAfterDiscountExtension"
                                    :key="product.id"
                                    :prop-product="product" class="topolis-table-row"
                                    :is-collecting-items="isCollectingItems" />
                                </tbody>
                            </table>
                            <!--Mobile-->
                            <ul class="xl:hidden w-full divide-y divide-gray-200">
                                <scan-product-mobile
                                    v-for="product in allNotPreparedAvailableUserProductsAfterDiscountExtension"
                                    :key="product.id"
                                    :prop-product="product"
                                    :is-collecting-items="isCollectingItems"></scan-product-mobile>
                            </ul>
                        </div>
                    </div>
                </div>

                <!--Products prepared with Discount table-->
                <div v-if="!isCollectingItems">
                    <div class="text-lg font-bold pl-5 pb-2">Produkter med discount label</div>
                    <div class="bg-white shadow rounded-lg border border-gray-200 mb-8">
                        <!-- Table -->
                        <div class="overflow-x-auto rounded-lg">
                            <table class="hidden xl:table table-auto w-full divide-y divide-gray-200">
                                <!-- Table header -->
                                <thead class="text-xs uppercase text-gray-500 bg-gray-50 border-gray-200">
                                <tr>
                                    <th class="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                        <div class="font-semibold text-left">Produkt navn</div>
                                    </th>
                                    <th class="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                        <div class="font-semibold text-left">Kategori</div>
                                    </th>
                                    <th class="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                        <div class="font-semibold text-left">Str.</div>
                                    </th>
                                    <th class="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                        <div class="font-semibold text-left">Mærke</div>
                                    </th>
                                    <th class="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                        <div class="font-semibold text-left">Farve</div>
                                    </th>
                                    <th class="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                        <div class="font-semibold text-left">Status</div>
                                    </th>
                                    <th class="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                        <div class="font-semibold text-left">Pris</div>
                                    </th>
                                    <th class="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                                        <span class="font-semibold text-left">Status</span>
                                    </th>
                                </tr>
                                </thead>
                                <!-- Table body -->
                                <tbody class="text-sm">
                                <!-- Row -->
                                <scan-product v-for="product in allPreparedAvailableUserProductsAfterDiscountExtension"
                                              :key="product.id"
                                              :prop-product="product" class="topolis-table-row bg-green-50/[0.8]"
                                              :is-collecting-items="isCollectingItems" />
                                </tbody>
                            </table>
                            <!--Mobile-->
                            <ul class="xl:hidden w-full divide-y divide-gray-200 bg-green-50/[0.8]">
                                <scan-product-mobile
                                    v-for="product in allPreparedAvailableUserProductsAfterDiscountExtension"
                                    :key="product.id"
                                    :prop-product="product"
                                    :is-collecting-items="isCollectingItems"></scan-product-mobile>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="modalOpen"
             class="bg-gray-100 shadow-lg p-2 top-0 left-0 right-0 z-[999999] h-full fixed">
            <div class="flex flex-col w-full">
                <div class="flex flex-col w-full">
                    <div class="flex items-center justify-between w-full mb-1">
                        <div class="flex items-center gap-2" v-if="loaded">
                            <div class="scanner-switch-input" :class="{ disabled: videoDevices?.devices?.length < 1 }"
                                 @click="switchInputDevice">
                                <CameraIcon class="flex-shrink-0 min-w-0 w-6" aria-hidden="true" />
                            </div>
                            <div class="scanner-switch-input"
                                 :class="{ disabled: !isAndroidChrome, activated: landscape }"
                                 @click="landscape = !landscape">
                                <PhotoIcon class="flex-shrink-0 min-w-0 w-6" aria-hidden="true" />
                            </div>
                            <div class="scanner-switch-input"
                                 :class="{ disabled: !hasAutofocus, activated: !autofocus && hasFocusDistance }"
                                 @click="autofocus = !autofocus">
                                <ViewfinderCircleIcon class="flex-shrink-0 min-w-0 w-6" aria-hidden="true" />
                            </div>
                            <div class="scanner-switch-input"
                                 :class="{ disabled: !hasTorch, activated: hasTorch && torch }" @click="torch = !torch">
                                <LightBulbIcon class="flex-shrink-0 min-w-0 w-6" aria-hidden="true" />
                            </div>
                        </div>
                        <button class="admin-border-icon-button" @click="closeModal">
                            <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 min-w-0 w-6"
                                 viewBox="0 0 32 32">
                                <g transform="translate(-236 -252)">
                                    <path d="M0,14.935,14.935,0" transform="translate(244.532 260.532)" fill="none"
                                          stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                          stroke-width="2" />
                                    <path d="M14.935,14.935,0,0" transform="translate(244.532 260.532)" fill="none"
                                          stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                          stroke-width="2" />
                                </g>
                            </svg>
                        </button>
                    </div>
                    <div class="flex flex-col gap-0.5">
                        <span class="text-sm">Følgende bookinger bliver der scannet for:</span>
                        <div class="flex flex-wrap -m-0.5 pb-1">
                            <div v-for="selectedBooking in selectedBookings" :key="selectedBooking.id">
                                <div class="bg-office-gray-200 shadow m-1">
                                    <div class="p-1 flex items-center relative space-x-2">
                                        <div class="flex flex-col">
                                            <div class="flex items-center justify-between">
                                                <div
                                                    class="border border-black px-1 leading-none w-fit flex items-center">
                                                    <span class="text-xxs text-gray-800">nr.:</span>
                                                    <span class="text-sm font-medium text-black">
                                                    {{ selectedBooking.store_id }}
                                                </span>
                                                </div>
                                                <div class="flex items-center space-x-2">
                                                    <week-color :end-date="selectedBooking.end_date" />
                                                </div>
                                            </div>
                                            <div class="flex items-center space-x-1">
                                            <span class="font-bold text-sm truncate">
                                                {{ selectedBooking.customer.name }}
                                            </span>
                                            </div>
                                            <div>
                                                <span class="text-sm">Indsamlet:</span>
                                                <span class="font-black text-topolis-green-300">
                                                {{ selectedBooking.collected_products.length }}
                                            </span>
                                                <span class="text-sm">
                                                <span>/</span>
                                                {{ selectedBooking.collected_products.length + selectedBooking.not_collected_products.length
                                                    }}
                                            </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="relative">
                    <div
                        v-if="message"
                        :class="messageType"
                        class="scanner-message absolute top-0 left-0 right-0 text-left flex items-center p-2 w-full h-fit min-h-[50px] bg-office-black text-office-white font-bold text-base sm:text-lg z-[999999999]"
                    >
                        {{ message }}
                    </div>
                    <div class="barcode-container">
                        <StreamBarcodeReader
                            ref="scanner"
                            v-model:videoDevices="videoDevices"
                            v-model:hasFocusDistance="hasFocusDistance"
                            v-model:hasAutofocus="hasAutofocus"
                            v-model:hasTorch="hasTorch"
                            v-model:hasZoom="hasZoom"
                            v-model:cameraDetails="cameraDetails"
                            :landscape="landscape"
                            :torch="torch"
                            no-front-cameras
                            :zoom="Number(zoom)"
                            :autofocus="autofocus"
                            :focus-distance="Number(focusDistance)"
                            :device-index="deviceIndex"
                            :ms-between-decoding="500"
                            @decode="scanSuccessful"
                            @loaded="onLoaded"
                        />
                        <div
                            v-if="!autofocus && hasFocusDistance && loaded"
                            class="focus-container"
                        >
                            <div>Focus</div>
                            <input
                                v-model="focusDistance"
                                type="range"
                                :min="hasFocusDistance.min || 0"
                                :max="Math.min(hasFocusDistance.max, 1) || 1"
                                :step="hasFocusDistance.step || 0.1"
                            />
                        </div>

                        <div
                            v-if="hasZoom && loaded"
                            class="zoom-container"
                        >
                            <div>Zoom</div>
                            <input
                                v-model="zoom"
                                type="range"
                                :min="hasZoom.min || 1"
                                :max="hasZoom.max || 2"
                                :step="hasZoom.step || 0.1"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import StreamBarcodeReader from "../../Components/StreamBarcodeReader.vue";
    import ScanProduct from "./ScanProduct.vue";
    import ScanProductMobile from "./ScanProductMobile.vue";
    import WeekColor from "./WeekColor.vue";
    import { CameraIcon, ViewfinderCircleIcon, LightBulbIcon, PhotoIcon } from "@heroicons/vue/20/solid";

    const initialState = {
        loaded: false,
        modalState: false,
        torch: false,
        zoom: 1,
        autofocus: true,
        focusDistance: 0,
        landscape: false,
        hasTorch: false,
        hasZoom: false,
        hasAutofocus: false,
        hasFocusDistance: false,
        videoDevices: {},
        deviceIndex: null,
        debounce: false,
        debounceTimeout: null,
        cameraDetails: {},
        isMobile: navigator?.userAgentData?.mobile || navigator?.platform === "iPad" || navigator?.platform === "iPhone",
        isAndroid: navigator?.userAgentData?.platform === "Android",
        isChrome: navigator?.userAgentData?.brands.findIndex(brand => brand.brand === "Google Chrome" || brand.brand === "Chromium") !== -1
    };

    export default {
        name: "StockBookingScanner",
        components: {
            StreamBarcodeReader,
            CameraIcon,
            ViewfinderCircleIcon,
            LightBulbIcon,
            PhotoIcon,
            WeekColor,
            ScanProductMobile,
            ScanProduct
        },
        props: {
            bookings: Array,
            withScanningOption: {
                type: Boolean,
                required: true,
                default: true
            },
            isCollectingItems: {
                type: Boolean,
                required: false
            }
        },
        data() {
            return {
                selectedBookings: [],
                isScanning: false,
                modalOpen: false,
                orderBy: "size",
                sortDirection: "DESC",
                parent_category_id: null,
                message: null,
                messageType: null,
                detailsShown: false,
                selectProductStatus: "Vælg status",
                allCollectedProducts: [],
                allNotCollectedProducts: [],
                allNotPreparedAvailableUserProductsAfterDiscountExtension: [],
                allPreparedAvailableUserProductsAfterDiscountExtension: [],
                ...initialState
            };
        },
        beforeUnmount() {
            this.closeModal();
        },
        computed: {
            isAndroidChrome() {
                return this.isMobile && this.isAndroid && this.isChrome;
            }
        },
        watch: {
            selectedBookings(bookings) {
                this.fetchProducts();
            },
            hasAutofocus() {
                this.autofocus = this.hasAutofocus;
            },
            focusDistance() {
                this.sliderMovement();
            },
            zoom() {
                this.sliderMovement();
            },
            "videoDevices.selectedIndex": {
                handler() {
                    if (this.loaded) {
                        this.deviceIndex = this.videoDevices?.selectedIndex;
                    }
                }
            }
        },
        mounted() {
            this.fetchProducts();

            setInterval(this.fetchProducts, 5000);

            emitter.on("product-changed", this.fetchProducts);  // listen for event
        },
        methods: {
            switchInputDevice() {
                const length = this.videoDevices?.devices?.length;
                console.log("length", length);
                console.log(this.videoDevices);
                if (this.deviceIndex >= 0 && length > 1) {
                    this.loaded = false;
                    this.deviceIndex = this.deviceIndex + 1 >= length ? 0 : this.deviceIndex + 1;
                }
            },
            sliderMovement() {
                if (!this.debounce) {
                    this.debounce = true;
                    window.navigator?.vibrate?.(10);
                    clearTimeout(this.debounceTimeout);
                    this.debounceTimeout = setTimeout(() => {
                        this.debounce = false;
                    }, 10);
                }
            },
            onLoaded() {
                this.loaded = true;
                if (!this.hasAutofocus) {
                    this.autofocus = false;
                }
            },
            submitForm() {
            },
            setOrderBy(value, direction) {
                this.orderBy = value;

                this.sortDirection = direction;

                this.fetchProducts();
            },
            fetchProducts() {
                const allCollected = [];
                const allNotCollected = [];
                const allPreparedAvailableAfterDiscountExtension = [];
                const allNotPreparedAvailableAfterDiscountExtension = [];

                if (this.selectedBookings.length === 0) return;

                axios.post("/admin/get-booking-products", {
                    orderBy: this.orderBy,
                    sortDirection: this.sortDirection,
                    parent_category_id: this.parent_category_id,
                    booking_ids: this.selectedBookings.map((booking) => booking.id)
                }).then((response) => {
                    console.log(response);
                    response.data.products.forEach((product) => {

                        if (this.isCollectingItems) {
                            if (product.collected_at) {
                                allCollected.push(product);
                            } else {
                                allNotCollected.push(product);
                            }

                            this.allNotCollectedProducts = allNotCollected;
                            this.allCollectedProducts = allCollected;

                        } else {
                            if (product.discount_prepared_at) {
                                allPreparedAvailableAfterDiscountExtension.push(product);
                            } else {
                                allNotPreparedAvailableAfterDiscountExtension.push(product);
                            }

                            this.allNotPreparedAvailableUserProductsAfterDiscountExtension = allNotPreparedAvailableAfterDiscountExtension;
                            this.allPreparedAvailableUserProductsAfterDiscountExtension = allPreparedAvailableAfterDiscountExtension;
                        }

                    });


                    // this.sumProducts(response.data.products);
                });
            },
            // sumProducts(products) {
            //     const totalCollected = [];
            //     const totalNotCollected = [];
            //
            //     const closetCount = 0;
            //
            //     products.forEach((product) => {
            //         let collected = [];
            //         let notCollected = [];
            //
            //         // console.log(booking.collected_products);
            //         if(product.is_collected) {
            //
            //         }
            //         collected = booking.collected_products;
            //         notCollected = booking.not_collected_products;
            //
            //         totalCollected.push(collected);
            //         totalNotCollected.push(notCollected);
            //     });
            //
            //     this.allUserProducts = totalCollected.concat(totalNotCollected);
            //     this.allUserProducts = this.allUserProducts.flat();
            //     this.allNotCollectedProducts = totalNotCollected.flat();
            // },
            setScannerMode(mode) {
                this.currentMode = mode;
            },
            scanSuccessful(barcode) {
                const vm = this;
                axios
                    .post("/admin/nedtagning/scan", {
                        barcode: barcode,
                        selectedBookings: this.selectedBookings.map((booking) => booking.id)
                    })
                    .then((response) => {
                        if (response.data.message) {
                            this.message = response.data.message;
                            this.messageType = "success";

                            const audio = new Audio("/assets/sounds/ding.wav");
                            audio.play();
                            // audio.play();
                            setTimeout(() => {
                                vm.message = null;
                                vm.messageType = null;
                            }, 8000);
                        }
                    })
                    .catch((error) => {
                        this.message = error.response.data.message;
                        this.messageType = "error";

                        // console.log(error.response);

                        const audio = new Audio("/assets/sounds/negative-ding.wav");
                        audio.play();
                        // audio.play();

                        setTimeout(() => {
                            vm.message = null;
                            vm.messageType = null;
                        }, 8000);
                    });
            },
            playAudio() {
                const audio = new Audio("/assets/sounds/ding.wav");

                audio.play();
            },
            setStatus(product) {
                console.log(product);
                axios.post("/admin/set-product-status", {
                    product: product,
                    status: this.selectProductStatus
                }).then((response) => {
                    console.log(response);
                });
            },
            // stopScanner() {
            //     const tracks = this.$refs.scanner.srcObject.getTracks();
            //     tracks.forEach((track) => {
            //         track.stop();
            //     });
            // },
            openModal() {
                this.modalOpen = true;
            },
            closeModal() {
                Object.assign(this.$data, initialState);
                this.modalOpen = false;

                // this.stopScanner();
            },
            openDetails() {
                this.detailsShown = true;
            },
            closeDetails() {
                this.detailsShown = false;
            },
            clearSelected() {
                this.selectedBookings = [];
            }
        }
    };
</script>

<style>
    .scanner-message.error {
        background-color: rgb(227, 206, 206) !important;
    }

    .scanner-message.success {
        background-color: rgb(180, 199, 11);
    }

    .barcode-container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    video {
        max-width: 1000px !important;
    }

    .zoom-container {
        position: absolute;
        z-index: 3;
        top: 50px;
        bottom: 0;
        right: 0;
        width: 55px;
        color: white;
    }

    .zoom-container > div {
        position: absolute;
        top: 10px;
        left: 0;
        right: 0;
        text-align: center;
    }

    .zoom-container input[type="range"] {
        transform: rotate(270deg);
        touch-action: none;
        width: 200px;
        height: 24px;
        position: absolute;
        right: -75px;
        top: 120px;
    }

    .focus-container {
        position: absolute;
        z-index: 3;
        top: 50px;
        bottom: 0;
        left: 0;
        width: 55px;
        color: white;
    }

    .focus-container > div {
        position: absolute;
        top: 10px;
        left: 0;
        right: 0;
        text-align: center;
    }

    .focus-container input[type="range"] {
        transform: rotate(270deg);
        touch-action: none;
        width: 200px;
        height: 24px;
        position: absolute;
        left: -75px;
        top: 120px;
    }
</style>
