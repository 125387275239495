<template>
    <div>
        <apexchart type="radialBar" :options="chartOptions" :series="series" />
        <div v-if="productsCountWithExtensions >= booking.max_products"
             class="flex items-center justify-center sm:justify-start gap-2 pt-4 mx-auto w-2/3">
            <span v-if="productsCountWithExtensions === booking.max_products"
                  class="text-center text-sm font-medium break-words w-fit">Din booking har nået produkt oprettelsesgrænsen.</span>
            <span v-if="productsCountWithExtensions > booking.max_products"
                  class="text-center text-sm font-medium text-red-800 break-words w-fit">Din booking har overskredet produkt oprettelsesgrænsen.</span>
        </div>
    </div>
</template>

<script>
    import VueApexCharts from "vue3-apexcharts";
    import axios from "axios"; // Import axios if not already imported

    export default {
        components: {
            VueApexCharts
        },
        props: {
            booking: {
                type: Object,
                required: true
            }
        },
        mounted() {
            this.$nextTick(() => {
                setTimeout(() => {
                    window.dispatchEvent(new Event("componentLoaded"));
                }, 300);
            });
            // Create promises for both API calls
            const fetchProductsCountPromise = this.fetchProductsCountWithExtensions();
            const fetchInWebshopCountPromise = this.fetchInWebshopProductsCount();

            // Wait for both promises to resolve
            Promise.all([fetchProductsCountPromise, fetchInWebshopCountPromise])
                .then(([productsCount, webshopCount]) => {
                    // Update your data properties with the fetched counts
                    this.productsCountWithExtensions = productsCount;
                    this.inWebshopProductsCount = webshopCount;

                    // Calculate the series data and push it into this.series
                    this.series.push(...this.chartSeries);

                    // Trigger the event to signal the component is loaded after data is set
                    window.dispatchEvent(new Event("componentLoaded"));
                })
                .catch((error) => {
                    // Handle errors
                    console.error(error);
                    // If there's an error, ensure the event is still triggered to prevent indefinite loading
                    window.dispatchEvent(new Event("componentLoaded"));
                });
        },
        computed: {
            chartSeries() {
                const maxProducts = this.booking.max_products;
                const countWithExtensions = this.productsCountWithExtensions;
                // Calculate the percentage based on productsCountWithExtensions and maxProducts
                const percentageProducts = (countWithExtensions / maxProducts) * 100;
                // Check if the max limit is reached (percentage === 100)
                this.maxLimitReached = percentageProducts >= 100;

                const maxWebshopProducts = this.booking.max_online_products;
                const countInWebshop = this.inWebshopProductsCount;
                // Calculate the percentage based on inWebshopProductsCount and maxWebshopProducts
                const percentageWebshopProducts = (countInWebshop / maxWebshopProducts) * 100;
                // Check if the max limit is reached (percentage === 100)
                this.maxOnlineLimitReached = percentageWebshopProducts >= 100;

                // Return the percentage as an array
                return [Math.floor(percentageProducts)];
            }
        },
        data() {
            return {
                series: [],
                productsCountWithExtensions: 0,
                inWebshopProductsCount: 0,
                maxLimitReached: false,
                maxOnlineLimitReached: false,
                chartOptions: {
                    plotOptions: {
                        radialBar: {
                            startAngle: -135,
                            endAngle: 135,
                            hollow: {
                                size: "70%"
                            },
                            dataLabels: {
                                name: {
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                    color: undefined
                                },
                                value: {
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    color: undefined,
                                    offsetY: -20,
                                    formatter: (val) => `${this.productsCountWithExtensions} / ${this.booking.max_products}`
                                },
                                total: {
                                    show: true,
                                    label: "",
                                    formatter: (val) => {
                                        return [
                                            `${this.productsCountWithExtensions} / ${this.booking.max_products}`,
                                            "produkter" // Add "produkter" below the values
                                        ];
                                    }
                                }
                            }
                        }
                    },
                    fill: {
                        colors: [function({ value, seriesIndex, w }) {
                            if (value < 55) {
                                return "#49b5d7";
                            } else if (value >= 55 && value < 100) {
                                return "#0d728c";
                            } else {
                                return "#D9534F";
                            }
                        }],
                        type: "gradient",
                        gradient: {
                            shade: "light",
                            shadeIntensity: 0.4,
                            inverseColors: false,
                            opacityFrom: 1,
                            opacityTo: 1,
                            stops: [0, 50, 65, 91]
                        }
                    }
                }
            };
        },
        methods: {
            async fetchProductsCountWithExtensions() {
                try {
                    const response = await axios.get(`/booking/${this.booking.id}/products-with-extensions-count`);
                    // Handle the response data for productsCountWithExtensions
                    const count = response.data.count;

                    this.productsCountWithExtensions = count;

                    return count; // Return the count value
                } catch (error) {
                    // Handle errors
                    console.error(error);
                    throw error; // Re-throw the error to propagate it
                }
            },
            async fetchInWebshopProductsCount() {
                try {
                    const response = await axios.get(`/booking/${this.booking.id}/products-in-webshop-count`);
                    // Handle the response data for productsCountWithExtensions
                    const count = response.data.count;

                    this.inWebshopProductsCount = count;

                    return count; // Return the count value
                } catch (error) {
                    // Handle errors
                    console.error(error);
                    throw error; // Re-throw the error to propagate it
                }
            }
        }
    };
</script>