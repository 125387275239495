<template>
    <form method="POST" @submit.prevent="register">
        <div class="flex flex-col md:max-w-lg">
            <div class="w-full mx-auto text-left space-y-8">
                <div v-if="generalError" class="text-red-500">
                    {{ generalError }}
                </div>
                <div class="space-y-4">
                    <!--Phone-->
                    <div v-if="!isEventPage">
                        <label for="phone" class="block text-sm font-bold text-topolis-blue-700">Telefonnummer</label>
                        <div class="mt-1 relative flex">
                        <span class="form-input-defined-phone">
                            <img src="https://flagcdn.com/24x18/dk.png"
                                 srcset="https://flagcdn.com/48x36/dk.png 2x, https://flagcdn.com/72x54/dk.png 3x"
                                 width="24" height="18" alt="Denmark" />
                        </span>
                            <div class="relative w-full">
                                <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                    <span class="text-sm text-topolis-blue-300">+45</span>
                                </div>
                                <input
                                    id="phone"
                                    v-model="form.phone"
                                    required
                                    :class="{ valid: validatePhoneNumber, invalid: !validatePhoneNumber }"
                                    name="phone"
                                    type="tel"
                                    placeholder="30292827"
                                    autocomplete="tel"
                                    data-cy="login-form-phone-input"
                                    class="pl-10 block w-full rounded-r-lg border border-gray-300 placeholder-gray-300 focus:outline-none focus:ring-topolis-purple-400 focus:border-topolis-purple-400 text-sm min-w-[140px]"
                                    @input="isValidPhone"
                                />
                            </div>
                        </div>

                        <div v-if="!validatePhoneNumber" class="mt-1 text-xs text-red-500">Ugyldigt telefonnr.</div>

                        <div v-if="errors && errors.phone" class="mt-1 text-xs text-red-500">
                            <p>{{ errors.phone[0] }}</p>
                        </div>
                    </div>

                    <div class="flex flex-col gap-4">
                        <!--Zip code and city-->
                        <div class="flex flex-col xs:flex-row w-full gap-4">
                            <div class="w-full">
                                <label for="zip"
                                       class="block text-sm font-bold text-topolis-blue-700">Postnummer</label>
                                <div class="mt-1">
                                    <input id="zip" v-model="form.zip" name="zip" type="text" placeholder="8000"
                                           autocomplete="postal-code" data-cy="login-form-zip-input" required
                                           class="form-input" />
                                </div>
                                <div v-if="errors && errors.zip" class="mt-1 text-xs text-red-500">
                                    <p>{{ errors.zip[0] }}</p>
                                </div>
                            </div>
                            <div class="w-full">
                                <label for="city" class="block text-sm font-bold text-topolis-blue-700">By</label>
                                <div class="mt-1">
                                    <input id="city" v-model="form.city" name="city" type="text" placeholder="Aarhus"
                                           autocomplete="address-level2" data-cy="login-form-city-input" required
                                           class="form-input" />
                                </div>
                                <div v-if="errors && errors.city" class="mt-1 text-xs text-red-500">
                                    <p>{{ errors.city[0] }}</p>
                                </div>
                            </div>
                        </div>
                        <!--Password-->
                        <div>
                            <div class="flex flex-col w-full gap-4">
                                <div class="w-full">
                                    <label for="password-register"
                                           class="block text-sm font-bold text-topolis-blue-700">Password <span
                                        class="font-medium text-xs">(minimum 6
                                    bogstaver/tegn)<span
                                            v-if="passwordLengthRequirementMet"> &#10004;</span></span></label>
                                    <div class="mt-1">
                                        <input id="password-register" v-model="form.password"
                                               data-cy="login-form-password-input" minlength="4"
                                               name="password" type="password" placeholder="************"
                                               autocomplete="current-password" required class="form-input" />
                                    </div>
                                    <div v-if="errors && errors.password" class="mt-1 text-xs text-red-500">
                                        <p>{{ errors.password[0] }}</p>
                                    </div>
                                </div>
                                <div class="w-full">
                                    <label for="new-password" class="block text-sm font-bold text-topolis-blue-700">Gentag
                                        Password</label>
                                    <div class="mt-1">
                                        <input id="new-password" v-model="confirmPassword" type="password"
                                               placeholder="************" autocomplete="new-password"
                                               required class="form-input" />
                                    </div>
                                    <div v-if="passwordMismatch" class="mt-1 text-xs text-red-500">Passwords matcher
                                        ikke.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--Terms-->
                <div class="space-y-4">
                    <!--Newsletter-->
                    <div class="flex">
                        <div class="flex items-center h-5">
                            <input id="accepts_marketing_emails" v-model="form.accepts_marketing_emails"
                                   name="accepts_marketing_emails" type="checkbox" class="form-input-checkbox"
                                   value="" />
                        </div>
                        <div class="ml-3 text-sm">
                            <label for="accepts_marketing_emails"
                                   class="text-xs xs:text-sm font-medium text-gray-700">Jeg vil gerne
                                modtage Minitopolis' nyhedsbrev pr. email </label>
                        </div>
                    </div>
                    <!--Data policies-->
                    <p>
                        Læs venligst
                        <a href="https://minitopolis.dk/persondatapolitik#/" class="a-link" target="_blank">persondata
                            politik</a>
                        for yderligere information om beskyttelse af personlige oplysninger
                    </p>
                </div>
                <loading-button type="submit" :loading="loading"
                                :disabled="!validateRegex(this.form.phone) || !form.zip || !form.city || !confirmPassword"
                                class="button button-purple small-button">
                    {{ $translate("Create user") }}
                </loading-button>
            </div>
        </div>
    </form>
</template>

<script>

    import LoadingButton from "../../../Pages/Common/LoadingButton.vue";

    export default {
        components: { LoadingButton },
        watch: {
            "form.phone"() {
                const isValid = this.validateRegex(this.form.phone);
                this.$emit("validation-changed", isValid);
            },
            "form.password"() {
                this.checkPasswordsMatch();
                this.checkPasswordLength();
            },
            confirmPassword() {
                this.checkPasswordsMatch();
            },
            "form.zip"(newVal) {
                // Only try to find and set the city if zip code is 4 characters long.
                if (newVal.length === 4) {
                    const city = Object.keys(this.allPostalCodes).find(cityName => this.allPostalCodes[cityName] === newVal);
                    if (city) {
                        this.form.city = city;
                        this.zipChanged = false;
                    }
                }

                if (newVal.length > 4) {
                    this.zipChanged = true;
                }

                if (newVal.length === 4 && this.zipChanged) {
                    this.form.city = "";
                }
            }
        },
        created() {
            axios.get("/postal-codes/danish")
                .then(response => {
                    this.allPostalCodes = response.data;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        mounted() {
            this.$nextTick(() => {
                setTimeout(() => {
                    window.dispatchEvent(new Event("componentLoaded"));
                }, 300);
            });
        },
        computed: {
            validatePhoneNumber() {
                if (!this.form.phone) return true;

                return this.form.phone.match(this.phoneRegex);
            }
        },
        props: {
            errors: Object,
            generalError: Object,
            user: Object,
            isShopAgentPage: Boolean,
            isShopOrderPage: Boolean,
            isEventPage: Boolean
        },
        data() {
            return {
                form: {
                    zip: "",
                    city: "",
                    phone: this.isEventPage ? this.user.phone : null,
                    password: null,
                    accepts_marketing_emails: false
                },
                allPostalCodes: {},
                zipChanged: false,
                confirmPassword: "",
                passwordMismatch: false,
                passwordLengthRequirementMet: false,
                // Regex only for 8 numbers, with no symbols or spaces allowed
                phoneRegex: /^[1-9][0-9]{7}$/,
                loading: false
            };
        },
        methods: {
            checkPasswordsMatch() {
                if (this.form.password.length >= 2 && this.confirmPassword.length >= 2) {
                    this.passwordMismatch = this.form.password !== this.confirmPassword;
                } else {
                    this.passwordMismatch = false;
                }
            },
            checkPasswordLength() {
                this.passwordLengthRequirementMet = this.form.password.length >= 6;
            },
            isValidPhone() {
                if (!this.form.phone) return;

                return this.phoneRegex.test(this.form.phone);
            },
            validateRegex(phone) {
                return this.isValidPhone(phone);
            },
            register() {
                this.loading = true;

                axios.post(`/events/${this.event.slug}/tilmeld`, this.form)
                    .then((response) => {
                        window.location.href = response.data.url;
                    })
                    .catch((error) => {
                        if (error.response.status === 400) {
                            // show error notification
                            this.generalError = error.response.data.error;
                        }

                        this.errors = error.response.data.errors;
                    });
            }
        }
    };
</script>
