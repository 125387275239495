<template>
    <div>
        This is the overview page, it just serves as a practical example implementation of our authentication flow :)
<br><br>
        <button @click="logout" class="button">Log ud</button>
        <br><br>
        Here is a list of dummy orders fetched from the egaa.minitopolis backend (can only be fetched with a valid token):
        <div v-if="loading">
            Loading...
        </div>
        <div v-for="order in orders" v-if="!loading">
            {{ order.name }}
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            orders: [],
            loading: false
        }
    },
    created() {
        if(!window.localStorage.getItem('access_token')) {
            window.location.href = '/log-ind';
        } else {
            this.getOrders();
        }
    },
    methods: {
        logout() {
            axios.post('/api/logout', null, {
                headers: {
                    'Authorization': 'Bearer ' + window.localStorage.getItem('access_token'),
                    'X-Tenant': 'egaa'
                }
            }).then((response) => {
                window.localStorage.removeItem('access_token');
                window.location.href = '/log-ind';
            })
        },
        getOrders() {
            this.loading = true;
            axios.get('/api/ordrer', {
                headers: {
                    'Authorization': 'Bearer ' + window.localStorage.getItem('access_token'),
                    'X-Tenant': 'egaa'
                }
            }).then((response) => {
                this.loading = false;
                console.log(response.data);
                this.orders = response.data.result.orders;
            }).catch(() => {
                this.loading = false;
            });
        }
    }
}
</script>
