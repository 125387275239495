<template>
    <transition name="bounce" appear>
        <div v-if="visitors" class="currently-browsing">
            <span class="font-semibold">{{ visitors }}</span> {{ visitors === 1 ? "anden" : "andre" }} kigger i
            øjeblikket på denne vare.
        </div>
    </transition>
</template>
<script>
    import * as FingerprintJS from "@fingerprintjs/fingerprintjs";

    export default {
        data() {
            return {
                visitors: null,
                interval: null,
                clientId: null
            };
        },
        props: {
            productUuid: {},
            variantUuid: {}
        },
        created() {
            return;
            // Not part of the 1st version of the product
            if (document.visibilityState === "visible") {
                const fpPromise = FingerprintJS.load({
                    monitoring: false
                });

                fpPromise
                    .then(fp => fp.get())
                    .then(result => {
                        // This is the visitor identifier:
                        this.clientId = result.visitorId;
                        this.registerBrowsing();
                    });
            }


            document.addEventListener("visibilitychange", this.visibilityChange, false);
            window.addEventListener("beforeunload", this.visibilityChange);
        },
        methods: {
            registerBrowsing() {
                axios.post("/register-browsing-product", {
                    productUuid: this.productUuid,
                    variantUuid: this.variantUuid,
                    clientId: this.clientId
                }).then(() => {
                    this.interval = setInterval(() => {
                        axios.get("/stream/" + this.productUuid + "/" + this.variantUuid + "?clientId=" + this.clientId).then((response) => {
                            this.visitors = response.data.visitors;
                        });
                    }, 6000);
                });
            },
            visibilityChange() {
                const vm = this;

                const data = new FormData();
                data.append("productUuid", vm.productUuid);
                data.append("variantUuid", vm.variantUuid);
                data.append("clientId", this.clientId);

                if (document.visibilityState === "hidden") {
                    window.navigator.sendBeacon("/remove-browsing-product", data);
                    clearInterval(this.interval);
                } else {
                    this.registerBrowsing();
                }
            }
        }
    };
</script>

<style>
    .currently-browsing {
        background: #b05738;
        color: #ffffff;
        padding: 8px 12px;
        font-size: 14px;
        z-index: 99999999;
        @apply shadow-lg rounded-md mb-2;
    }

    .bounce-enter-active {
        animation: bounce-in .3s;
    }

    .bounce-leave-active {
        animation: bounce-in .3s reverse;
    }

    @keyframes bounce-in {
        0% {
            transform: scale(0);
        }
        50% {
            transform: scale(1.2);
        }
        100% {
            transform: scale(1);
        }
    }
</style>
