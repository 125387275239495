<template>
    <div class="site-wide-search w-full">
        <label for="simple-search" class="sr-only">Search</label>
        <div class="relative w-full">
            <div class="flex absolute inset-y-0 left-0 items-center pl-4 pointer-events-none">
                <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 stroke-gray-900" viewBox="0 0 24 24">
                    <g transform="translate(-428 -188)">
                        <path d="M19,9.5A9.5,9.5,0,1,1,9.5,0,9.5,9.5,0,0,1,19,9.5Z" transform="translate(430 190)"
                              fill="none" stroke="fillCurrent" stroke-linecap="round" stroke-linejoin="round"
                              stroke-width="1.5" />
                        <path d="M2,2,0,0" transform="translate(448 208)" fill="none" stroke="fillCurrent"
                              stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                        <path d="M0,0H24V24H0Z" transform="translate(428 188)" fill="none" opacity="0" />
                    </g>
                </svg>
            </div>
            <input type="text" @keydown.esc="hideDropdown" @keydown.enter.prevent="submit" @keydown.down="down"
                   @keydown.up="up" id="simple-search" v-model.lazy="query" v-debounce="250"
                   class="simple-search bg-gray-50 rounded-full border-0 text-gray-900 leading-3 text-sm block w-full pl-12 pr-4 py-3.5"
                   autocomplete="off" placeholder="Søg efter produkter" required>
            <div class="absolute w-full h-full z-[1]" v-if="showDropdown">
                <ul v-if="query && searchResults.length === 0"
                    class="mt-1 bg-white rounded-xl shadow-lg py-2 pl-6 pr-4">
                    <li class="text-sm text-gray-600">Vi har ingen produkter der matcher din søgning</li>
                </ul>
                <ul v-if="query && searchResults.length"
                    class="mt-1 bg-white rounded-xl shadow-lg divide-y divide-gray-100">
                    <li @click="submit(result)" class="relative py-2 pl-10 pr-4 hover:bg-gray-50 cursor-pointer"
                        v-for="(result, index) in searchResults" :key="result.uuid"
                        :class="{ 'is-active': index === arrowCounter }">{{ result.pretty_name }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
    import { directive } from "v-debounce";

    export default {
        name: "SiteWideSearch",
        data() {
            return {
                searchResults: [],
                query: "",
                arrowCounter: 0,
                showDropdown: false
            };
        },
        directives: {
            debounce: directive
        },
        watch: {
            query() {
                if (this.query.length === 0) {
                    this.showDropdown = false;
                }
                if (this.query.length > 1) {
                    this.search();
                }
            }
        },
        methods: {
            down(e) {
                e.preventDefault();
                if (this.arrowCounter + 1 < this.searchResults.length) {
                    this.arrowCounter = this.arrowCounter + 1;
                }
            },
            up(e) {
                e.preventDefault();
                if (this.arrowCounter > 0) {
                    this.arrowCounter = this.arrowCounter - 1;
                }
            },
            hideDropdown() {
                this.showDropdown = false;
            },
            submit(result = null) {
                let foundItem = null;
                if (result) {
                    foundItem = result;
                }

                foundItem = this.searchResults[this.arrowCounter];

                window.location.href = "/produkter/" + foundItem.slug;
            },
            search() {
                axios.get("/search", {
                    params: {
                        query: this.query
                    }
                })
                    .then(response => {
                        console.log(response);
                        this.searchResults = response.data.results;
                        if (this.searchResults.length) {
                            console.log(this.searchResults);
                            this.showDropdown = true;
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    };
</script>
