<template>
    <div>
        <div class="space-y-2">
            <h4 class="app-h4 pl-2 pb-2">Kommende events</h4>
            <div class="w-full">
                <div class="flex flex-wrap items-stretch justify-between gap-8 h-full">
                    <div v-for="event in events"
                         class="dashboard-card-container basis-full sm:basis-[32%] xl:basis-[40%] flex-1 h-auto">
                        <div class="flex flex-col space-y-4">
                            <div class="flex flex-col w-full">
                                <div class="flex items-center space-x-2 relative w-full">
                                    <div
                                        class="bg-[#EDCB8D] text-black shadow-md w-9 xs:w-12 sm:w-16 h-9 xs:h-12 sm:h-16 rounded-full flex-shrink-0 min-w-0 relative">
                                        <div
                                            class="flex items-center justify-center h-full w-full xs:py-2 xs:px-6 relative">
                                                <span
                                                    class="text-sm xs:text-xxs sm:text-xs font-black text-center xs:absolute xs:left-2 sm:left-3 xs:top-2 sm:top-4 xs:w-[13px] sm:w-[15px]">
                                                        {{ getFormattedStartDate(event.start_date) }}
                                                    <span class="-ml-1 xs:hidden">.</span>
                                                </span>
                                            <svg
                                                class="hidden xs:block absolute w-4 sm:w-6 h-12 left-3.5 sm:left-4 xs:-top-0.5 sm:top-2"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.701 21.701">
                                                <line y1="21" x2="16" transform="translate(0.35 0.35)"
                                                      fill="none" stroke="#523F07" stroke-linecap="round"
                                                      stroke-width="0.5" />
                                            </svg>
                                            <span
                                                class="hidden xs:block text-[10px] sm:text-xs font-black absolute xs:right-1.5 sm:right-3 xs:bottom-3 sm:bottom-4">
                                                {{ formatStartDate(event.start_date) }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="flex flex-col justify-center space-y-1">
                                        <span class="text-left w-full font-bold leading-5 line-clamp-2">{{ event.name
                                            }}</span>
                                        <span class="text-left w-full text-xs line-clamp-2">{{ event.teaser }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-col gap-4">
                                <div class="flex items-center space-x-4">
                                    <div class="space-x-2">
                                        <span class="text-xs">kl.
                                            <span class="font-bold">
                                                {{ formatDateHour(event.start_date) }}
                                                <span class="font-normal">-</span>
                                                {{ formatDateHour(event.end_date) }}
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div class="flex flex-col border-t border-gray-300 pt-4">
                                    <div class="flex items-center space-x-4">
                                        <p
                                            ref="description"
                                            class="text-center sm:text-left"
                                            :style="{ maxHeight: isLongText ? (showMore ? maxHeight + 'px' : 'none') : 'none', overflow: 'hidden' }"
                                        >
                                            {{ event.description }}
                                        </p>
                                    </div>
                                    <button v-if="isLongText" @click="toggleDescription">
                                        {{ showMore ? "Read more" : "Read less" }}
                                    </button>
                                </div>
                                <div>
                                    <div v-if="event.open_event">
                                        <div class="text-xs">
                                            <span>Tilmelding ikke påkrævet</span>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div v-if="event.max_attendees === event.attendees_count"
                                             class="text-xs font-medium">
                                            <span class="text-amber-800">Fuldt booket</span>
                                        </div>
                                        <div
                                            v-if="(event.max_attendees - (event.max_attendees * 0.25) > event.attendees_count)"
                                            class="text-xs font-medium">
                                            <div class="text-xs">
                                                <span>Ledige pladser: </span>
                                                <span class="font-bold">Ja</span>
                                            </div>
                                        </div>
                                        <div
                                            v-if="(event.max_attendees - (event.max_attendees * 0.25) <= event.attendees_count)"
                                            class="text-xs font-medium">
                                            <div class="text-xs">
                                                <span class="text-red-700">Få pladser tilbage</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div class="space-y-2 mt-8">
                                        <div class="flex items-center justify-between">
                                            <h4 class="app-h4">Samlet pris inkl. moms</h4>
                                            <!--                                    <span class="app-h2">{{ extend.price }}</span>-->
                                        </div>
                                    </div>
                                </div>
                                <button class="button button-purple" @click="openDrawer('purchase')">Gå til betaling
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <!--        <drawer deactivate-outside-click drawer-header="Kurv" to-purchase name="purchase"></drawer>-->
    </div>
</template>

<script>
    import Drawer from "./Drawer.vue";

    export default {
        name: "BookingAddonPage",
        components: { Drawer },
        props: {
            booking: {},
            user: {},
            productCount: {},
            events: {
                type: Object,
                required: true
            },
            locale: String
        },
        data() {
            return {
                showMore: true, // Initial state
                maxHeight: 10 // Set the initial max height (in pixels)
            };
        },
        computed: {
            isLongText() {
                return this.$refs.description ? this.$refs.description.scrollHeight > this.maxHeight : false;
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.isLongText;
            });
            this.$nextTick(() => {
                window.dispatchEvent(new Event("componentLoaded"));
            });
        },
        methods: {
            getFormattedStartDate(eventDate) {
                const date = new Date(eventDate);
                return date.getDate();
            },
            formatStartDate(eventDate) {
                return new Date(eventDate).toLocaleString(this.locale, { month: "short" });
            },
            formatDateHour(eventDate) {
                const date = new Date(eventDate);
                return date.toLocaleTimeString(this.locale, {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: false
                });
            },
            toggleDescription() {
                this.showMore = !this.showMore;
            },
            // formatPrice(price) {
            //     return new Intl.NumberFormat("da-DK", {
            //         style: "currency",
            //         currency: "DKK",
            //         maximumSignificantDigits: 3
            //     }).format(price / 100);
            // },

            openDrawer(name) {
                this.emitter.emit("open-drawer", name);
            }
        }
    };
</script>

<style scoped></style>
