<template>
    <span class="leading-4">{{ formattedPrice }}</span>
</template>

<script>
    export default {
        props: {
            price: {},
            locale: {
                default: "da-DA"
            },
            currency: {
                default: "DKK"
            },
            formatPrice: {
                type: Boolean,
                default: true,
                required: false
            }
        },
        computed: {
            formattedPrice() {
                if (!this.price) {
                    return new Intl.NumberFormat(this.locale, {
                        style: "currency",
                        currency: this.currency
                    }).format(0);
                }

                if (typeof this.price === "object") {
                    if (this.formatPrice) {
                        return new Intl.NumberFormat(this.locale, {
                            style: "currency",
                            currency: this.currency
                        }).format(this.price.value.amount / 100);
                    } else {
                        return this.price;
                    }
                }
                if (this.formatPrice) {
                    return new Intl.NumberFormat(this.locale, {
                        style: "currency",
                        currency: this.currency
                    }).format(this.price / 100);
                } else {
                    return this.price;
                }

            }
        }
    };
</script>
