<template>
    <div class="flex flex-col w-full mx-auto space-y-8" data-cy="registration-step">
        <profile v-if="authenticatedUser" class="mt-2 mx-auto max-w-lg" />

        <div class="flex flex-col text-center space-y-4">
            <span class="app-h1">{{ headerText }}</span>
            <h5 class="app-h5">{{ headlineText }}</h5>
        </div>

        <form class="w-full max-w-lg mx-auto text-left space-y-8" method="POST" @submit.prevent="register">
            <profile-form-fields :general-error="generalError" :errors="errors" :outside-flow="false"
                                 :registration-step="registrationStep" show-terms
                                 :full-registration="fullRegistration"
                                 :show-password="showPassword"
                                 :is-edit-mode="isEditMode"
                                 @validation-changed="handleValidationChange"
                                 @form-updated="updateForm"></profile-form-fields>

            <div slot="footer" class="flex flex-col space-y-8 pb-48 sm:pb-28">
                <hr />
                <div class="flex items-center justify-between">
                    <button type="button" v-if="!outsideFlow" class="transparent-button flex items-center"
                            formnovalidate
                            @click="gotoPrev">
                        <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 min-w-0 w-4" viewBox="0 0 24 24">
                            <g transform="translate(-684 -188)">
                                <path d="M6.07,0,0,6.07l6.07,6.07" transform="translate(687.5 193.93)" fill="none"
                                      stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                      stroke-width="1.5" />
                                <path d="M16.83,0H0" transform="translate(687.67 200)" fill="none" stroke="currentColor"
                                      stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                            </g>
                        </svg>
                        <span class="ml-1 text-xs xxs:text-base">Tilbage</span>
                    </button>
                    <bouncing-button data-cy="login-form-submit-button" type="submit"
                                     :bouncing="isFormValid"
                                     :disabled="!isFormValid">{{ submitButtonText }}
                    </bouncing-button>
                </div>
                <div v-if="registrationStep" class="relative flex justify-center text-sm">
                    <span class="px-1 text-gray-500">Har du allerede en bruger?</span>
                    <router-link to="/login" class="a-link"> Login</router-link>
                </div>
            </div>
        </form>

    </div>
</template>

<script>
    import ProfileFormFields from "./Common/ProfileFormFields.vue";
    import BouncingButton from "../Common/BouncingButton.vue";
    import { mapGetters } from "vuex";


    export default {
        name: "RegistrationStep",
        components: { ProfileFormFields, BouncingButton },
        props: {
            outsideFlow: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                errors: null,
                generalError: null,
                isFormValid: false,
                // isRegistrationStep: false,
                form: {}
                // form: {
                //     name: null,
                //     address: null,
                //     zip: null,
                //     city: null,
                //     email: null,
                //     phone: null,
                //     password: null,
                //     accepts_marketing_emails: false,
                //     accepts_trade_terms: false,
                //     accepts_quality_terms: false,
                //     fullUser: this.fullRegistration
                // }
            };
        },
        // mounted() {
        //     console.log(this.$route);
        //     const url = new URLSearchParams(window.location.search);
        //
        //     if (url.has("registrationStep")) {
        //         this.isRegistrationStep = url.get("registrationStep");
        //     }
        //
        //     console.log(this.isRegistrationStep);
        // },
        // created() {
        //     this.showPassword = this.$route.state.showPassword;
        //     this.registrationStep = this.$route.state.registrationStep;
        //     this.fullRegistration = this.$route.state.fullRegistration;
        // },
        computed: {
            ...mapGetters(["authenticatedUser"]),
            submitButtonText() {
                if (this.registrationStep) {
                    return "Opret bruger";
                }

                return "Gem";
            },
            headerText() {
                if (this.registrationStep) {
                    return "Ny bruger";
                }

                return "Rediger bruger";
            },
            headlineText() {
                if (this.registrationStep) {
                    return "Opret en bruger hos os ved brug af din email og telefon";
                }

                return "Rediger din eksisterende bruger";
            },
            showPassword() {
                return this.$store.state.showPassword;
            },
            registrationStep() {
                return this.$store.state.registrationStep;
            },
            fullRegistration() {
                return this.$store.state.fullRegistration;
            },
            isEditMode() {
                return this.$store.state.isEditMode;
            }
        },
        methods: {
            gotoPrev() {
                this.$router.back();
            },
            handleValidationChange(isValid) {
                // The `isValid` value receives the current validation status from the child component
                this.isFormValid = isValid;
            },
            updateForm(newFormData) {
                this.form = newFormData;
            },
            register() {
                if (this.isEditMode) {
                    axios
                        .patch("update-user", this.form)
                        .then((response) => {
                            this.$store.commit("setAuthenticatedUser", response.data.user);
                            this.$router.push({ name: "review-step" });
                        })
                        .catch((error) => {
                            this.errors = error.response.data.errors;
                        });
                } else {
                    axios
                        .post("register-user", this.form)
                        .then((response) => {
                            this.$store.commit("setAuthenticatedUser", response.data.user);
                            this.$router.push({ name: "review-step" });
                        })
                        .catch((error) => {
                            this.errors = error.response.data.errors;
                        });
                }

            }
        }
    };
</script>

<style scoped></style>
