<template>
    <div>
        <div>total: {{ totalRecords }}</div>
        <div>per page: {{ perPage }}</div>
        <div>page: {{ page }}</div>
    </div>
    <div class="w-full">
        <DataTable
            v-model:selection="selectedVariants"
            :value="variants"
            lazy
            paginator
            :rows="perPage"
            :total-records="totalRecords"
            dataKey="id"
            :first="(page-1) * perPage"
            :sortField="sortField"
            :sortOrder="sortOrder"
            :filters="filters"
            @page="onPageChange"
            @sort="onSortChange"
            @filter="onFilterChange"
            :loading="isLoading"
            :rowHover="true"
            filterDisplay="menu"
            removableSort
            scrollable
            size="small"
            :scrollHeight="height + 'px'"
            :globalFilterFields="['product.name', 'product.category.name', 'product.brand.name', 'patterns.name', 'colors.name']"
            class="hidden xl:inline-block w-full">
            <template #header>
                <div class="flex items-center justify-between gap-4 mb-4">
                    <div>
                        <p class="text-gray-800"><span class="font-bold">{{ totalRecords }}</span> produkter</p>
                    </div>
                    <div class="flex gap-2 rounded-2xl">
                        <Button v-if="filters['global'].value" class="!text-sm" type="button" icon="pi pi-filter-slash"
                                label="Ryd"
                                outlined @click="clearFilter()" />
                        <IconField>
                            <InputIcon class="!top-[45%]">
                                <i class="pi pi-search" />
                            </InputIcon>
                            <InputText v-model="filters['global'].value" class="!text-sm w-[400px]"
                                       placeholder="Søg produkt navn, kategori, mærke" />
                        </IconField>
                    </div>
                </div>
            </template>
            <template #empty>Ingen produkter fundet.</template>
            <Column v-if="adminPage" selectionMode="multiple" headerStyle="width: 2rem"></Column>
            <!--            <template #paginatorstart>-->
            <!--                <Button type="button" icon="pi pi-refresh" text />-->
            <!--            </template>-->
            <!--            <template #paginatorend>-->
            <!--                <Button type="button" icon="pi pi-download" text />-->
            <!--            </template>-->
            <template #loading>
                <div class="flex items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
                    <Skeleton width="60%" height="1rem" />
                </div>
            </template>
            <!--            <Column expander style="width: 5rem" />-->
            <Column header="Billede" class="!text-sm" headerStyle="width: 3rem">
                <template #body="slotProps">
                    <Image v-if="slotProps.data.product.category.parent_id === 351" alt="Image" preview>
                        <template #previewicon>
                            <i class="pi pi-eye"></i>
                        </template>
                        <template #image>
                            <img src="/assets/categories/clothes.svg"
                                 alt="minitopolis-clothing-placeholder"
                                 width="auto"
                                 height="auto"
                                 class="flex items-center justify-center flex-shrink-0 min-w-0 w-10 h-10 rounded-lg bg-gray-100 object-contain object-center p-1.5" />
                        </template>
                        <template #preview="slotProps">
                            <img src="/assets/categories/clothes.svg" alt="preview"
                                 :style="slotProps.style" @click="slotProps.onClick"
                                 class="flex items-center justify-center w-[500px] h-full rounded-lg bg-gray-100 p-10" />
                        </template>
                    </Image>
                    <Image v-else-if="slotProps.data.product.category.parent_id === 213" alt="Image" preview>
                        <template #previewicon>
                            <i class="pi pi-eye"></i>
                        </template>
                        <template #image>
                            <img src="/assets/categories/toys.svg"
                                 alt="minitopolis-toys-placeholder"
                                 width="auto"
                                 height="auto"
                                 class="flex items-center justify-center flex-shrink-0 min-w-0 w-10 h-10 rounded-lg bg-gray-100 object-contain object-center p-1.5" />
                        </template>
                        <template #preview="slotProps">
                            <img src="/assets/categories/toys.svg" alt="preview"
                                 :style="slotProps.style" @click="slotProps.onClick"
                                 class="flex items-center justify-center w-[500px] h-full rounded-lg bg-gray-100 p-10" />
                        </template>
                    </Image>
                    <Image v-else-if="slotProps.data.product.category.parent_id === 221" alt="Image" preview>
                        <template #previewicon>
                            <i class="pi pi-eye"></i>
                        </template>
                        <template #image>
                            <img src="/assets/categories/jacket.svg"
                                 alt="minitopolis-jacket-placeholder"
                                 width="auto"
                                 height="auto"
                                 class="flex items-center justify-center flex-shrink-0 min-w-0 w-10 h-10 rounded-lg bg-gray-100 object-contain object-center p-1.5" />
                        </template>
                        <template #preview="slotProps">
                            <img src="/assets/categories/jacket.svg" alt="preview"
                                 :style="slotProps.style" @click="slotProps.onClick"
                                 class="flex items-center justify-center w-[500px] h-full rounded-lg bg-gray-100 p-10" />
                        </template>
                    </Image>
                    <Image v-else-if="slotProps.data.product.category.parent_id === 621" alt="Image" preview>
                        <template #previewicon>
                            <i class="pi pi-eye"></i>
                        </template>
                        <template #image>
                            <img src="/assets/categories/footwear.svg"
                                 alt="minitopolis-footwear-placeholder"
                                 width="auto"
                                 height="auto"
                                 class="flex items-center justify-center flex-shrink-0 min-w-0 w-10 h-10 rounded-lg bg-gray-100 object-contain object-center p-1.5" />
                        </template>
                        <template #preview="slotProps">
                            <img src="/assets/categories/footwear.svg" alt="preview"
                                 :style="slotProps.style" @click="slotProps.onClick"
                                 class="flex items-center justify-center w-[500px] h-full rounded-lg bg-gray-100 p-10" />
                        </template>
                    </Image>
                    <Image v-else-if="slotProps.data.product.category.parent_id === 376" alt="Image" preview>
                        <template #previewicon>
                            <i class="pi pi-eye"></i>
                        </template>
                        <template #image>
                            <img src="/assets/categories/accessories.svg"
                                 alt="minitopolis-accessories-placeholder"
                                 width="auto"
                                 height="auto"
                                 class="flex items-center justify-center flex-shrink-0 min-w-0 w-10 h-10 rounded-lg bg-gray-100 object-contain object-center p-1.5" />
                        </template>
                        <template #preview="slotProps">
                            <img src="/assets/categories/accessories.svg" alt="preview"
                                 :style="slotProps.style" @click="slotProps.onClick"
                                 class="flex items-center justify-center w-[500px] h-full rounded-lg bg-gray-100 p-10" />
                        </template>
                    </Image>
                    <Image v-else-if="slotProps.data.product.category.parent_id === 377" alt="Image" preview>
                        <template #previewicon>
                            <i class="pi pi-eye"></i>
                        </template>
                        <template #image>
                            <img src="/assets/categories/books-games.svg"
                                 alt="minitopolis-books-games-placeholder"
                                 width="auto"
                                 height="auto"
                                 class="flex items-center justify-center flex-shrink-0 min-w-0 w-10 h-10 rounded-lg bg-gray-100 object-contain object-center p-1.5" />
                        </template>
                        <template #preview="slotProps">
                            <img src="/assets/categories/books-games.svg" alt="preview"
                                 :style="slotProps.style" @click="slotProps.onClick"
                                 class="flex items-center justify-center w-[500px] h-full rounded-lg bg-gray-100 p-10" />
                        </template>
                    </Image>
                    <Image v-else-if="slotProps.data.product.category.parent_id === 144" alt="Image" preview>
                        <template #previewicon>
                            <i class="pi pi-eye"></i>
                        </template>
                        <template #image>
                            <img src="/assets/categories/equipment.svg"
                                 alt="minitopolis-equipment-placeholder"
                                 width="auto"
                                 height="auto"
                                 class="flex items-center justify-center flex-shrink-0 min-w-0 w-10 h-10 rounded-lg bg-gray-100 object-contain object-center p-1.5" />
                        </template>
                        <template #preview="slotProps">
                            <img src="/assets/categories/equipment.svg" alt="preview"
                                 :style="slotProps.style" @click="slotProps.onClick"
                                 class="flex items-center justify-center w-[500px] h-full rounded-lg bg-gray-100 p-10" />
                        </template>
                    </Image>
                    <Image v-else-if="slotProps.data.product.category.parent_id === 620" alt="Image" preview>
                        <template #previewicon>
                            <i class="pi pi-eye"></i>
                        </template>
                        <template #image>
                            <img src="/assets/categories/interior.svg"
                                 alt="minitopolis-interior-placeholder"
                                 width="auto"
                                 height="auto"
                                 class="flex items-center justify-center flex-shrink-0 min-w-0 w-10 h-10 rounded-lg bg-gray-100 object-contain object-center p-1.5" />
                        </template>
                        <template #preview="slotProps">
                            <img src="/assets/categories/interior.svg" alt="preview"
                                 :style="slotProps.style" @click="slotProps.onClick"
                                 class="flex items-center justify-center w-[500px] h-full rounded-lg bg-gray-100 p-10" />
                        </template>
                    </Image>
                </template>
            </Column>
            <Column field="created_at" header="Produkt" sortable sortField="created_at" filterField="created_at"
                    class="!text-sm">
                <template #loading>
                    <div class="flex items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
                        <Skeleton width="60%" height="1rem" />
                    </div>
                </template>
                <template #body="item">
                    <div class="flex flex-col gap-0.5">
                        <tooltip>
                            <template v-slot:triggerText>
                                <div class="max-w-[150px] truncate">
                                    {{ cleanProductName(item.data.product.name) }}
                                </div>
                            </template>
                            <span class="font-light">Produkt navn er: <span
                                class="font-bold">{{ item.data.product.name }}</span>
                            </span>
                        </tooltip>
                        <tooltip>
                            <template v-slot:triggerText>
                                <div class="created-at-badge flex items-center !text-xxs">
                                    {{ formatDate(item.data.created_at) }}
                                </div>
                            </template>
                            <span class="font-light">Produktet er oprettet d.: <span
                                class="font-bold">{{ formatDate(item.data.created_at)
                                }}</span></span>
                        </tooltip>
                    </div>
                </template>
            </Column>
            <Column header="Univers" class="!text-sm" headerStyle="width: 3rem" filterField="representative"
                    :showFilterMatchModes="false">
                <template #loading>
                    <div class="flex items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
                        <Skeleton width="60%" height="1rem" />
                    </div>
                </template>
                <template #body="item">
                    <div class="flex items-center">
                        <tooltip>
                            <template v-slot:triggerText>
                                <img v-if="item.data.product.target_segment.name === 'children'"
                                     class="flex-shrink-0 min-w-6 w-5 h-5 fill-purple-300"
                                     src="/assets/categories/child-clothes.svg" alt="" />
                                <img v-else class="flex-shrink-0 min-w-6 w-5 h-5"
                                     src="/assets/categories/mama.svg" alt="" />
                            </template>
                            <span class="font-light">Produktet er i:
                                <span
                                    class="font-bold px-1 py-0.5"
                                    :class="{ 'bg-blue-100 text-topolis-purple-900' : item.data.product.target_segment.name === 'children', 'bg-yellow-100 text-topolis-yellow-900' : item.data.product.target_segment.name === 'mama' }">{{ item.data.product.target_segment.name === "children" ? "Børneuniverset" : "MAMA universet"
                                    }}</span>
                            </span>
                        </tooltip>
                    </div>
                </template>
                <template #filter="{ filterModel }">
                    <PrimeMultiSelect v-model="filterModel.value" :options="universes" optionLabel="name"
                                      placeholder="Any">
                        <template #option="slotProps">
                            <div class="flex items-center gap-2">
                                <img :alt="slotProps.option.name"
                                     :src="`https://primefaces.org/cdn/primevue/images/avatar/${slotProps.option.image}`"
                                     style="width: 32px" />
                                <span>{{ slotProps.option.name }}</span>
                            </div>
                        </template>
                    </PrimeMultiSelect>
                </template>
            </Column>
            <Column v-if="!onlySoldProducts" field="product.category.name" header="Kategori"
                    :sortable="!onlySoldProducts"
                    sortField="product.category.name"
                    filterField="product.category.name"
                    class="!text-sm">
                <template #loading>
                    <div class="flex items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
                        <Skeleton width="60%" height="1rem" />
                    </div>
                </template>
                <template #body="item">
                    <div class="flex items-center">
                        <tooltip>
                            <template v-slot:triggerText>
                                <div>
                                    {{ $translate(item.data.product.category.name) }}
                                </div>
                            </template>
                            <span class="font-light">Kategorien er: <span
                                class="font-bold">{{ $translate(item.data.product.category.name)
                                }}</span></span>
                        </tooltip>
                    </div>
                </template>
            </Column>
            <Column v-if="!onlySoldProducts" field="product.brand.name" header="Mærke" :sortable="!onlySoldProducts"
                    sortField="product.brand.name"
                    filterField="product.brand.name"
                    class="!text-sm">
                <template #loading>
                    <div class="flex items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
                        <Skeleton width="60%" height="1rem" />
                    </div>
                </template>
                <template #body="item">
                    <div v-if="item.data.product.brand" class="flex items-center">
                        <tooltip>
                            <template v-slot:triggerText>
                                <div>
                                    {{ $translate(item.data.product.brand.name) }}
                                </div>
                            </template>
                            <span class="font-light">Brandet er: <span
                                class="font-bold">{{ $translate(item.data.product.brand.name)
                                }}</span></span>
                        </tooltip>
                    </div>
                </template>
            </Column>
            <Column v-if="!onlySoldProducts" field="size" header="Str." :sortable="!onlySoldProducts" sortField="size"
                    filterField="size"
                    class="!text-sm">
                <template #loading>
                    <div class="flex items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
                        <Skeleton width="60%" height="1rem" />
                    </div>
                </template>
                <template #body="item">
                    <div v-if="item.data.size" class="flex items-center">
                        <tooltip>
                            <template v-slot:triggerText>
                                <div>
                                    {{ $translate(item.data.size.name) }}
                                </div>
                            </template>
                            <span class="font-light">Størrelsen er: <span
                                class="font-bold">{{ $translate(item.data.size.name)
                                }}</span></span>
                        </tooltip>
                    </div>
                </template>
            </Column>
            <Column field="colors" header="Farve(r)" filterField="color"
                    class="!text-sm">
                <template #loading>
                    <div class="flex items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
                        <Skeleton width="60%" height="1rem" />
                    </div>
                </template>
                <template #body="item">
                    <div v-if="item.data.colors && item.data.colors.length" class="flex items-center">
                        <div v-for="color in item.data.colors">
                            <tooltip>
                                <template v-slot:triggerText>
                                    <color-badge model="color" :show-name="false" :name="color.name"
                                                 :hex="color.hex_code"
                                                 class="mr-1" />
                                </template>
                                <span class="font-light">Farve: <span
                                    class="font-bold">{{ $translate(color.name)
                                    }}</span></span>
                            </tooltip>
                        </div>
                    </div>
                    <div v-if="item.data.patterns && item.data.patterns.length" class="flex items-center">
                        <span v-for="pattern in item.data.patterns">
                            <tooltip>
                                <template v-slot:triggerText>
                                    {{ pattern.name }}
                                </template>
                                <span class="font-light">Mønster: <span
                                    class="font-bold">{{ $translate(pattern.name)
                                    }}</span></span>
                            </tooltip>
                        </span>
                    </div>
                </template>
            </Column>
            <Column field="product.title" header="Titel" :sortable="!onlySoldProducts" sortField="product.title"
                    filterField="product.title"
                    class="!text-sm">
                <template #loading>
                    <div class="flex items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
                        <Skeleton width="60%" height="1rem" />
                    </div>
                </template>
                <template #body="item">
                    <div v-if="item.data.product.title" class="flex items-center">
                        <tooltip>
                            <template v-slot:triggerText>
                                <div class="max-w-[50px] truncate">
                                    {{ $translate(item.data.product.title) }}
                                </div>
                            </template>
                            <span class="font-light">Titlen er: <span
                                class="font-bold">{{ $translate(item.data.product.title)
                                }}</span></span>
                        </tooltip>
                    </div>
                </template>
            </Column>
            <Column v-if="!onlySoldProducts" field="product.description" header="Beskrivelse"
                    :sortable="!onlySoldProducts"
                    sortField="product.description"
                    filterField="product.description"
                    class="!text-sm">
                <template #loading>
                    <div class="flex items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
                        <Skeleton width="60%" height="1rem" />
                    </div>
                </template>
                <template #body="item">
                    <div v-if="item.data.product.description" class="flex items-center">
                        <tooltip>
                            <template v-slot:triggerText>
                                <div class="max-w-[75px] truncate">
                                    {{ $translate(item.data.product.description) }}
                                </div>
                            </template>
                            <span class="font-light">Produkt beskrivelse: <span
                                class="font-bold">{{ $translate(item.data.product.description)
                                }}</span></span>
                        </tooltip>
                    </div>
                </template>
            </Column>
            <Column field="price" header="Pris" sortable sortField="price"
                    filterField="price"
                    class="!text-sm">
                <template #loading>
                    <div class="flex items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
                        <Skeleton width="60%" height="1rem" />
                    </div>
                </template>
                <template #body="item">
                    <div class="flex items-center">
                        <tooltip>
                            <template v-slot:triggerText>
                                <div v-if="item.data.discount" class="flex flex-col">
                                    <div class="flex">
                                        <p class="text-sm font-medium text-red-500">
                                            <price :price="item.data.discounted_price"></price>
                                        </p>
                                    </div>
                                    <div class="flex items-center -mt-0.5">
                                        <p class="text-gray-500 line-through text-xs mr-1">
                                            <price :price="item.data.price"></price>
                                        </p>
                                        <p class="text-xxs xs:text-xs text-red-500 font-medium">-{{ item.data.discount
                                            }}%</p>
                                    </div>
                                </div>
                                <div v-else
                                     class="flex text-sm font-medium text-topolis-blue-700 mr-2 xs:mr-0">
                                    <price :price="item.data.price"></price>
                                </div>
                            </template>
                            <span class="font-light">Prisen: <span
                                class="font-bold">
                                <price :price="item.data.price"></price>
                            </span></span>
                        </tooltip>
                    </div>
                </template>
            </Column>
            <Column v-if="onlySoldProducts" field="sold_at" header="Solgt" sortable sortField="sold_at"
                    filterField="sold_at"
                    class="!text-sm">
                <template #loading>
                    <div class="flex items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
                        <Skeleton width="60%" height="1rem" />
                    </div>
                </template>
                <template #body="item">
                    <div class="flex items-center">
                        <tooltip>
                            <template v-slot:triggerText>
                                <div class="topolis-tag lime-badge">
                                    {{ formatDate(item.data.sold_at) }}
                                </div>
                            </template>
                            <span class="font-light">Produktet blev solgt d. <span
                                class="font-bold">{{ formatDate(item.data.sold_at)
                                }}</span></span>
                        </tooltip>
                    </div>
                </template>
            </Column>
            <Column v-if="adminPage" class="!text-sm" headerStyle="width: 3rem" header="Slut">
                <template #loading>
                    <div class="flex items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
                        <Skeleton width="60%" height="1rem" />
                    </div>
                </template>
                <template #body="item">
                    <div class="flex items-center gap-1">
                        <div>
                            <div v-if="item.data.product.booking.concept !== 'business'">
                                <tooltip>
                                    <template v-slot:triggerText>
                                        <week-color :end-date="item.data.product.booking.end_date" />
                                    </template>
                                    <div>Booking slutter i uge
                                        {{ getIsoWeek(item.data.product.booking.end_date) }}
                                        (d. {{ formatDateLL(item.data.product.booking.end_date) }})
                                    </div>
                                </tooltip>
                            </div>
                            <div v-else>
                                <tooltip>
                                    <template v-slot:triggerText>
                                        <div
                                            class="topolis-badge topolis-border-badge topolis-small-badge topolis-pearl-badge"
                                            title="Virksomhedsbooking">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 min-w-0 w-4"
                                                 viewBox="0 0 24 24">
                                                <g transform="translate(-108 -188)">
                                                    <path
                                                        d="M5.751,16h8c4.02,0,4.74-1.61,4.95-3.57l.75-8c.27-2.44-.43-4.43-4.7-4.43h-10C.481,0-.219,1.99.051,4.43l.75,8C1.011,14.39,1.731,16,5.751,16Z"
                                                        transform="translate(110.249 194)" fill="none"
                                                        stroke="currentColor"
                                                        stroke-linecap="round" stroke-linejoin="round"
                                                        stroke-width="1.5" />
                                                    <path d="M0,4V3.2C0,1.43,0,0,3.2,0H4.8C8,0,8,1.43,8,3.2V4"
                                                          transform="translate(116 190)" fill="none"
                                                          stroke="currentColor"
                                                          stroke-linecap="round" stroke-linejoin="round"
                                                          stroke-width="1.5" />
                                                    <path
                                                        d="M4,1V2.02C4,3.11,3.99,4,2,4S0,3.12,0,2.03V1C0,0,0,0,1,0H3C4,0,4,0,4,1Z"
                                                        transform="translate(118 200)" fill="none" stroke="currentColor"
                                                        stroke-linecap="round" stroke-linejoin="round"
                                                        stroke-width="1.5" />
                                                    <path d="M7.65,0A16.484,16.484,0,0,1,0,3.02"
                                                          transform="translate(122 199)"
                                                          fill="none" stroke="currentColor" stroke-linecap="round"
                                                          stroke-linejoin="round" stroke-width="1.5" />
                                                    <path d="M0,0A16.283,16.283,0,0,0,7.38,2.76"
                                                          transform="translate(110.62 199.27)"
                                                          fill="none" stroke="currentColor" stroke-linecap="round"
                                                          stroke-linejoin="round" stroke-width="1.5" />
                                                </g>
                                            </svg>
                                        </div>
                                    </template>
                                    <div>Virksomhedsbooking er aktiv
                                    </div>
                                </tooltip>
                            </div>
                        </div>
                    </div>
                </template>
            </Column>
            <Column v-if="!onlySoldProducts" field="status" header="Status" sortable="" sortField="status"
                    filterField="status" class="!text-sm">
                <template #loading>
                    <div class="flex items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
                        <Skeleton width="60%" height="1rem" />
                    </div>
                </template>
                <template #body="item">
                    <div class="flex items-center">
                        <tooltip>
                            <template v-slot:triggerText>
                                <div class="status-badge topolis-small-badge !text-xxs"
                                     :class="tagClass(item.data.status)">
                                    {{ $translate(item.data.status) }}
                                </div>
                            </template>
                            <span class="font-light">Booking status er: <span
                                class="font-bold">{{ $translate(item.data.status)
                                }}</span></span>
                        </tooltip>
                    </div>
                </template>
                <template #filter="{ filterModel }">
                    <Select v-model="filterModel.value" :options="statuses" placeholder="Vælg en" showClear>
                        <template #option="slotProps">
                            <div class="status-badge topolis-small-badge !text-xxs"
                                 :class="tagClass(slotProps.option)" :value="slotProps.option">
                                {{ $translate(slotProps.option) }}
                            </div>
                            <div />
                            <!--                            <Tag :value="slotProps.option" :severity="getSeverity(slotProps.option)" />-->
                        </template>
                    </Select>
                </template>
            </Column>
            <Column class="!text-sm">
                <template #loading>
                    <div class="flex items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
                        <Skeleton width="60%" height="1rem" />
                    </div>
                </template>
                <template #body="item">
                    <div class="flex items-center justify-end">
                        <Button @click="openUploadImageDialogBox(item)" icon="pi pi-images" text rounded
                                size="normal"
                                severity="secondary" title="Upload billeder dialog"
                                aria-label="Upload billeder dialog" />
                        <Button v-if="adminPage" @click="syncProductToPOS(item)" icon="pi pi-sync" text rounded
                                size="small"
                                severity="secondary" title="Sync produkt til POS" aria-label="Sync Product to POS"
                                style="width: 2rem; height: 2rem"></Button>
                        <Button @click="goToEditProductPage(item)" icon="pi pi-pencil" text rounded size="small"
                                severity="secondary" title="Rediger produkt" aria-label="Edit Product"
                                style="width: 2rem; height: 2rem"></Button>
                        <Button @click="goToProductPage(item)" icon="pi pi-angle-right" text rounded
                                severity="secondary" title="Gå til produkt" aria-label="Go To Product"
                                style="width: 2rem; height: 2rem"></Button>
                    </div>
                </template>
            </Column>
            <!--            <template #expansion="slotProps">-->
            <!--                <div class="p-4">-->
            <!--                    <h5>Orders for {{ slotProps.data.name }}</h5>-->
            <!--                    <DataTable :value="slotProps.data.orders">-->
            <!--                        <Column field="id" header="Id" sortable></Column>-->
            <!--                        <Column field="customer" header="Customer" sortable></Column>-->
            <!--                        <Column field="date" header="Date" sortable></Column>-->
            <!--                        <Column field="amount" header="Amount" sortable>-->
            <!--                            <template #body="slotProps">-->
            <!--                                {{ formatCurrency(slotProps.data.amount) }}-->
            <!--                            </template>-->
            <!--                        </Column>-->
            <!--                        <Column field="status" header="Status" sortable>-->
            <!--                            <template #body="slotProps">-->
            <!--                                <Tag :value="slotProps.data.status.toLowerCase()"-->
            <!--                                     :severity="getOrderSeverity(slotProps.data)" />-->
            <!--                            </template>-->
            <!--                        </Column>-->
            <!--                        <Column headerStyle="width:4rem">-->
            <!--                            <template #body>-->
            <!--                                <Button icon="pi pi-search" />-->
            <!--                            </template>-->
            <!--                        </Column>-->
            <!--                    </DataTable>-->
            <!--                </div>-->
            <!--            </template>-->
        </DataTable>
        <DataView v-if="!isLoading" :value="variants" :sortOrder="sortOrder" :sortField="sortField" :layout="layout"
                  paginator
                  :rows="perPage"
                  :total-records="totalRecords"
                  class="xl:hidden">
            <!--            <template #header>-->
            <!--                <div class="flex items-center justify-between gap-4">-->
            <!--                    <Select v-model="sortKey" :options="sortOptions" optionLabel="label" placeholder="Sort By Price"-->
            <!--                            @change="onSortChange($event)" class="form-select-admin" />-->
            <!--                    <SelectButton v-model="layout" :options="options" :allowEmpty="false">-->
            <!--                        <template #option="{ option }">-->
            <!--                            <i :class="[option === 'list' ? 'pi pi-bars' : 'pi pi-table']" />-->
            <!--                        </template>-->
            <!--                    </SelectButton>-->
            <!--                </div>-->
            <!--            </template>-->
            <template #list="slotProps">
                <div class="flex flex-col">
                    <div v-for="(item, index) in slotProps.items" :key="index">
                        <div class="flex flex-col sm:flex-row sm:items-center p-2 gap-4"
                             :class="{ 'border-t border-surface-200 dark:border-surface-700': index !== 0 }">
                            <product-list-card :variant="item" :admin-page="true" />
                        </div>
                    </div>
                </div>
            </template>
            <!--            TODO: after webshop!-->
            <!--            <template #grid="slotProps">-->
            <!--                <div-->
            <!--                    class="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-x-2 gap-y-4 xs:gap-4 sm:gap-y-6 lg:gap-y-8">-->
            <!--                    <div v-for="(item, index) in slotProps.items" :key="index" class="p-2">-->
            <!--                        <product-card :variant="item" :admin-page="true" />-->
            <!--                        &lt;!&ndash;                        <div&ndash;&gt;-->
            <!--                        &lt;!&ndash;                            class="p-6 border border-surface-200 dark:border-surface-700 bg-surface-0 dark:bg-surface-900 rounded flex flex-col">&ndash;&gt;-->
            <!--                        &lt;!&ndash;                            <div class="bg-surface-50 flex justify-center rounded p-4">&ndash;&gt;-->
            <!--                        &lt;!&ndash;                                <div class="relative mx-auto">&ndash;&gt;-->
            <!--                        &lt;!&ndash;                                    <img class="rounded w-full"&ndash;&gt;-->
            <!--                        &lt;!&ndash;                                         src="/assets/shop/minitopolis-accessories-placeholder.jpg"&ndash;&gt;-->
            <!--                        &lt;!&ndash;                                         alt="overtøj" style="max-width: 300px" />&ndash;&gt;-->
            <!--                        &lt;!&ndash;                                    &lt;!&ndash;                                    <Tag :value="item.inventoryStatus" :severity="getSeverity(item)"&ndash;&gt;&ndash;&gt;-->
            <!--                        &lt;!&ndash;                                    &lt;!&ndash;                                         class="absolute dark:!bg-surface-900" style="left: 4px; top: 4px"></Tag>&ndash;&gt;&ndash;&gt;-->
            <!--                        &lt;!&ndash;                                </div>&ndash;&gt;-->
            <!--                        &lt;!&ndash;                            </div>&ndash;&gt;-->
            <!--                        &lt;!&ndash;                            <div class="pt-6">&ndash;&gt;-->
            <!--                        &lt;!&ndash;                                <div class="flex flex-row justify-between items-start gap-2">&ndash;&gt;-->
            <!--                        &lt;!&ndash;                                    <div>&ndash;&gt;-->
            <!--                        &lt;!&ndash;                                        <span&ndash;&gt;-->
            <!--                        &lt;!&ndash;                                            class="font-medium text-surface-500 dark:text-surface-400 text-sm">{{ item.category&ndash;&gt;-->
            <!--                        &lt;!&ndash;                                            }}</span>&ndash;&gt;-->
            <!--                        &lt;!&ndash;                                        <div class="text-lg font-medium mt-1">{{ item.name }}</div>&ndash;&gt;-->
            <!--                        &lt;!&ndash;                                    </div>&ndash;&gt;-->
            <!--                        &lt;!&ndash;                                    <div class="bg-surface-100 p-1" style="border-radius: 30px">&ndash;&gt;-->
            <!--                        &lt;!&ndash;                                        <div class="bg-surface-0 flex items-center gap-2 justify-center py-1 px-2"&ndash;&gt;-->
            <!--                        &lt;!&ndash;                                             style="border-radius: 30px; box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.04), 0px 1px 2px 0px rgba(0, 0, 0, 0.06)">&ndash;&gt;-->
            <!--                        &lt;!&ndash;                                            <span class="text-surface-900 font-medium text-sm">{{ item.rating }}</span>&ndash;&gt;-->
            <!--                        &lt;!&ndash;                                            <i class="pi pi-star-fill text-yellow-500"></i>&ndash;&gt;-->
            <!--                        &lt;!&ndash;                                        </div>&ndash;&gt;-->
            <!--                        &lt;!&ndash;                                    </div>&ndash;&gt;-->
            <!--                        &lt;!&ndash;                                </div>&ndash;&gt;-->
            <!--                        &lt;!&ndash;                                <div class="flex flex-col gap-6 mt-6">&ndash;&gt;-->
            <!--                        &lt;!&ndash;                                    <div v-if="item.discount" class="flex flex-col">&ndash;&gt;-->
            <!--                        &lt;!&ndash;                                        <div class="flex">&ndash;&gt;-->
            <!--                        &lt;!&ndash;                                            <p class="text-sm xs:text-base font-medium text-red-500">&ndash;&gt;-->
            <!--                        &lt;!&ndash;                                                <price :price="item.discounted_price"></price>&ndash;&gt;-->
            <!--                        &lt;!&ndash;                                            </p>&ndash;&gt;-->
            <!--                        &lt;!&ndash;                                        </div>&ndash;&gt;-->
            <!--                        &lt;!&ndash;                                        <div class="flex items-center -mt-0.5">&ndash;&gt;-->
            <!--                        &lt;!&ndash;                                            <p class="text-gray-500 line-through text-xs mr-1">&ndash;&gt;-->
            <!--                        &lt;!&ndash;                                                <price :price="item.price"></price>&ndash;&gt;-->
            <!--                        &lt;!&ndash;                                            </p>&ndash;&gt;-->
            <!--                        &lt;!&ndash;                                            <p class="text-xxs xs:text-xs text-red-500 font-medium">-{{ item.discount&ndash;&gt;-->
            <!--                        &lt;!&ndash;                                                }}%</p>&ndash;&gt;-->
            <!--                        &lt;!&ndash;                                        </div>&ndash;&gt;-->
            <!--                        &lt;!&ndash;                                    </div>&ndash;&gt;-->
            <!--                        &lt;!&ndash;                                    <div v-else&ndash;&gt;-->
            <!--                        &lt;!&ndash;                                         class="flex text-sm xs:text-base font-medium text-topolis-blue-700 mr-2 xs:mr-0">&ndash;&gt;-->
            <!--                        &lt;!&ndash;                                        <price :price="item.price"></price>&ndash;&gt;-->
            <!--                        &lt;!&ndash;                                    </div>&ndash;&gt;-->
            <!--                        &lt;!&ndash;                                    <div class="flex gap-2">&ndash;&gt;-->
            <!--                        &lt;!&ndash;                                        <Button icon="pi pi-shopping-cart" label="Buy Now"&ndash;&gt;-->
            <!--                        &lt;!&ndash;                                                :disabled="item.inventoryStatus === 'OUTOFSTOCK'"&ndash;&gt;-->
            <!--                        &lt;!&ndash;                                                class="flex-auto whitespace-nowrap"></Button>&ndash;&gt;-->
            <!--                        &lt;!&ndash;                                        <Button icon="pi pi-heart" outlined></Button>&ndash;&gt;-->
            <!--                        &lt;!&ndash;                                    </div>&ndash;&gt;-->
            <!--                        &lt;!&ndash;                                </div>&ndash;&gt;-->
            <!--                        &lt;!&ndash;                            </div>&ndash;&gt;-->
            <!--                        &lt;!&ndash;                        </div>&ndash;&gt;-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--            </template>-->
        </DataView>
        <Dialog v-model:visible="showUploadDialog" maximizable modal header="Upload billeder"
                :style="{ width: '50rem' }"
                :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
            <FileUpload name="demo[]" url="/api/upload" @upload="onUpload($event)" :multiple="true"
                        accept="image/*" :maxFileSize="1000000" :auto="true">
                <template #empty>
                    <span>Drag and drop files to here to upload.</span>
                </template>
            </FileUpload>
        </Dialog>
    </div>
</template>

<script>
    import ColorBadge from "./ColorBadge.vue";
    import RateUser from "../RateUser.vue";
    import WeekColor from "./WeekColor.vue";
    import UserConcept from "../UserConcept.vue";
    import UserTask from "../UserTask.vue";
    import UserStoreId from "../UserStoreId.vue";
    import UserAvatar from "../UserAvatar.vue";
    import Tooltip from "../Tooltip.vue";
    import AssignUser from "../AssignUser.vue";
    import LoadingSpinner from "./LoadingSpinner.vue";
    import DataView from "primevue/dataview";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import ColumnGroup from "primevue/columngroup";
    import Row from "primevue/row";
    import IconField from "primevue/iconfield";
    import InputIcon from "primevue/inputicon";
    import InputText from "primevue/inputtext";
    import Button from "primevue/button";
    import { FilterMatchMode, FilterOperator } from "@primevue/core/api";
    import DatePicker from "primevue/datepicker";
    import MultiSelect from "@vueform/multiselect";
    import Select from "primevue/select";
    import Tag from "primevue/tag";
    import Skeleton from "primevue/skeleton";
    import axios from "axios";
    import Price from "../../Shop/Price.vue";
    import SelectButton from "primevue/selectbutton";
    import ProductListCard from "../../Shop/ProductListCard.vue";
    import Image from "primevue/image";
    import FileUpload from "primevue/fileupload";
    import Dialog from "primevue/dialog";

    export default {
        name: "ProductTable",
        components: {
            ProductListCard,
            AssignUser,
            UserTask,
            WeekColor,
            LoadingSpinner,
            UserAvatar,
            RateUser,
            Tooltip,
            UserConcept,
            UserStoreId,
            DataTable,
            Column,
            ColumnGroup,
            Row,
            IconField,
            InputIcon,
            InputText,
            Button,
            FilterMatchMode,
            FilterOperator,
            DatePicker,
            PrimeMultiSelect: MultiSelect,
            Select,
            Tag,
            Skeleton,
            Price,
            ColorBadge,
            DataView,
            SelectButton,
            Image,
            FileUpload,
            Dialog
        },
        props: {
            booking: {},
            locale: {},
            height: {},
            adminPage: {
                type: Boolean,
                required: false,
                default: false
            },
            onlySoldProducts: {
                type: Boolean,
                required: false,
                default: false
            },
            endpoint: {
                type: String,
                required: true
            },
            productPerPage: {
                type: Number,
                required: false
            }
        },
        data() {
            return {
                visibleGroups: {},
                admins: [],
                variants: [],
                isLoading: false,
                perPage: this.productPerPage,
                page: 1,
                totalRecords: 0,
                selectedVariants: null,
                selectedStatuses: null,
                selectedAdmins: null,
                filters: null,
                expandedRows: {},
                statuses: ["approved", "completed", "pending", "active", "paid", "declined", "refunded", "stalled", "discontinued", "cancelled", "finished"],
                universes: [
                    { name: "Børneunivers" },
                    { name: "MAMA univers" }
                ],
                sortKey: null,
                sortOrder: null,
                sortField: null,
                sortOptions: [
                    { label: "Price High to Low", value: "!price" },
                    { label: "Price Low to High", value: "price" }
                ],
                size: { label: "Small", value: "null" },
                layout: "list",
                options: ["list", "grid"],
                showUploadDialog: false
            };
        },
        created() {
            this.fetchProducts();
            this.initFilters();
        },
        mounted() {
            window.addEventListener("keyup", this.handleKeyUp);
        },
        beforeUnmount() {
            window.removeEventListener("keyup", this.handleKeyUp);
        },
        methods: {
            openUploadImageDialogBox(item) {
                console.log("hej", item);
                this.showUploadDialog = !this.showUploadDialog;
            },
            syncProductToPOS(item) {
                let url;
                url = `/admin/produkter/${item.data.product.booking.uuid}/${item.data.product.uuid}/item.data/${item.data.uuid}/sync-to-zettle`;
                // Or redirect to the URL
                window.location.href = url;
            },
            goToEditProductPage(item) {
                let url;
                if (this.adminPage) {
                    url = `/admin/produkter/${item.data.product.booking.uuid}/${item.data.product.uuid}/variant/${item.data.uuid}/rediger`;
                } else {
                    url = `/mit-minitopolis/${item.data.product.booking.uuid}/dine-produkter/${item.data.product.uuid}/${item.data.uuid}/rediger`;
                }
                // Or redirect to the URL
                window.location.href = url;
            },
            goToProductPage(item) {
                let url;
                if (this.adminPage) {
                    url = `/admin/produkter/${item.data.product.booking.uuid}/${item.data.product.uuid}/variant/${item.data.uuid}`;
                } else {
                    url = `/mit-minitopolis/${item.data.product.booking.uuid}/dine-produkter/${item.data.product.uuid}/${item.data.uuid}`;
                }
                // Or redirect to the URL
                window.location.href = url;
            },
            cleanProductName(productName) {
                return productName.replace(/\[\d+% RABAT\]/, "").trim();
            },
            handleKeyUp(event) {
                if (event.key === "Escape" && this.filters["global"].value) {
                    this.clearFilter();
                }
            },
            generateURL(product, variant) {
                return `/mit-minitopolis/${this.booking.uuid}/dine-produkter/${product.uuid}/${variant.uuid}`;
            },
            formatDate(dateString) {
                const date = new Date(dateString);
                return `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${("0" + date.getDate()).slice(-2)} ${("0" + date.getHours()).slice(-2)}:${("0" + date.getMinutes()).slice(-2)}`;
            },
            async fetchProducts() {
                this.isLoading = true;
                try {
                    const response = await axios.get(this.endpoint, {
                        params: {
                            perPage: this.perPage,
                            page: this.page // Ensure 'page' is included in the request
                        }
                    });

                    console.log("productttt", this.variants);

                    this.variants = response.data.data;
                    this.totalRecords = response.data.total; // update total records
                    this.page = response.data.current_page;


                    // if (payload) {
                    //     this.emitter.emit("show-toast", {
                    //         message: payload.message,
                    //         type: payload.success ? "success" : "error"
                    //     });
                    // }

                    this.isLoading = false;

                } catch (error) {
                    console.error(error);

                } finally {
                    this.isLoading = false;
                }
            },
            clearFilter() {
                this.initFilters();
            },
            initFilters() {
                this.filters = {
                    ...this.filters,
                    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                    "product.name": {
                        operator: FilterOperator.AND,
                        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
                    },
                    "product.category.name": {
                        operator: FilterOperator.AND,
                        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
                    },
                    "product.brand.name": {
                        operator: FilterOperator.AND,
                        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
                    },
                    universes:
                        { value: null, matchMode: FilterMatchMode.IN }
                    // and other fields....
                };
            },
            tagClass(status) {
                switch (status) {
                    case "store":
                        return "purple-badge";
                    case "storage":
                        return "yellow-badge";
                    case "collected":
                        return "neon-blue-badge";
                    case "sold":
                        return "lime-badge";
                    case "review":
                        return "amber-badge";
                    case "rejected":
                        return "orange-badge";
                    case "unregistered":
                        return "red-badge";
                    case "none":
                        return "pink-badge";
                    case "unknown":
                        return "gray-badge";
                    case "released":
                        return "sky-badge";
                    case "donated":
                        return "red-cr-badge";
                    default:
                        return "gray-badge";
                }
            },
            getIsoWeek(date) {
                const targetDate = new Date(date);
                const dayNumber = (targetDate.getUTCDay() + 6) % 7;
                targetDate.setDate(targetDate.getDate() - dayNumber + 3);
                const firstThursday = targetDate.valueOf();
                targetDate.setMonth(0, 1);
                if (targetDate.getDay() !== 4) {
                    targetDate.setMonth(0, 1 + ((4 - targetDate.getDay()) + 7) % 7);
                }
                return 1 + Math.ceil((firstThursday - targetDate) / 604800000);
            },
            formatDateLL(date) {
                const options = { year: "numeric", month: "long", day: "numeric" };
                return new Date(date).toLocaleDateString(this.locale, options);
            },
            getGradientColor(count) {
                if (count <= 1) {
                    return "fill-green-200"; // light green
                } else if (count <= 3) {
                    return "fill-teal-400"; // medium-light green
                } else if (count > 3) {
                    return "fill-blue-400"; // dark green
                } else {
                    return "fill-sky-200";
                }
            },
            getGradientExtendColor(count) {
                if (count <= 1) {
                    return "fill-rose-300"; // light green
                } else if (count <= 2) {
                    return "fill-red-600"; // medium-light green
                } else if (count > 3) {
                    return "fill-red-800"; // dark green
                }
            },
            onUpload() {
            },
            onSortChange(event) {
                const value = event.value.value;
                const sortValue = event.value;

                if (value.indexOf("!") === 0) {
                    this.sortOrder = -1;
                    this.sortField = value.substring(1, value.length);
                    this.sortKey = sortValue;
                } else {
                    this.sortOrder = 1;
                    this.sortField = value;
                    this.sortKey = sortValue;
                }
            },
            onPageChange(event) {
                const newPage = event.page + 1; // Adjust for zero-based index
                this.page = newPage; // Update current page state
                console.log("Page change event:", event);
                this.fetchProducts({ page: this.page });
                // Scroll to the top when pagination occurs
                window.scrollTo({
                    top: 0,
                    behavior: "smooth" // Optional: for smooth scrolling
                });
            }
        }
    };
</script>

<style>
    .p-datatable-header {
        border-top-left-radius: 0.5rem; /* 8px */
        border-top-right-radius: 0.5rem; /* 8px */
    }

    .p-icon.p-datatable-sort-icon {
        width: 0.75rem;
        height: 0.75rem;
        margin-bottom: 0.25rem;
    }

    .p-icon.p-button-icon {
        width: 0.75rem;
        height: 0.75rem;
    }

    .product-table-upload-button.p-button {
        border-radius: 50%;
        width: 2rem;
        height: 2rem;
        padding: 0.5rem;
        background: transparent;
        border: 1px solid transparent;
        color: #000;
    }

    .product-table-upload-button.p-button {
        border-radius: 50%;
        width: 2rem;
        height: 2rem;
        padding: 0.5rem;
        background: transparent;
        border: 1px solid transparent;
        color: #64748b;
    }

    .product-table-upload-button.p-button:not(:disabled):hover {
        background: #f8fafc;
        border: transparent;
        color: #64748b;
    }

    .product-table-upload-button .p-button-label {
        display: none;
    }
</style>