<template>
    <div class="flex items-center gap-1 relative" v-click-outside="closeDropdown">
        <div v-if="item.status !== 'pending' && item.status !== 'stalled'" class="flex items-center gap-1 relative">
            <div class="flex items-center gap-2 rounded p-1 border"
                 :class="[colorClass]">
                <div v-if="remainingDays != null" class="text-xs font-medium">
                    <tooltip>
                        <template v-slot:triggerText>
                            <div class="text-xs font-bold text-center leading-4 flex xl:flex-col"
                                 :class="[textColorClass]">
                                <div>{{ remainingDays }}</div>
                                <div class="xl:hidden text-[8px] font-normal">/d</div>
                                <div class="hidden xl:flex text-[8px]">dag(e)</div>
                            </div>
                        </template>
                        <span class="font-light">{{ remainingDays }} dag(e) tilbage indtil opgaven skal være
                            fulført</span>
                    </tooltip>
                </div>
            </div>
            <div class="flex flex-col relative p-1 cursor-pointer hover:bg-gray-100"
                 @click="dropdownOpen = !dropdownOpen">
                <div class="flex items-center justify-between gap-2">
                    <div class="text-xxs">{{ $translate("Task") }}:</div>
                    <div
                        class="rounded-md border border-green-600 h-4 p-1 flex items-center justify-center cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3"
                             stroke="currentColor" class="flex-shrink-0 min-w-0 w-3 h-3 text-green-600">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                        </svg>
                        <span class="border-l border-black/[0.5] h-full mx-1"></span>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                             stroke="currentColor"
                             class="flex-shrink-0 min-w-0 w-3 h-3 text-black transition-transform duration-300"
                             :class="dropdownOpen ? 'transform rotate-180' : ''">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                        </svg>
                    </div>
                </div>
                <div class="text-xs font-medium">{{ $translate(task.name) }}</div>
            </div>
            <div v-show="dropdownOpen"
                 class="origin-top-right absolute top-0 right-3 mt-12 z-[99999] rounded-md shadow-lg bg-white border-2 border-office-sky-700 ring-2 ring-black ring-opacity-5">
                <div class="p-2 m-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                    <task-item :item="item" :task="task" type="booking" @close-task-dropdown="closeDropdown"
                               :locale="locale"
                               @mark-task-as-done="payload => $emit('mark-task-as-done', payload)" />
                </div>
            </div>
        </div>
        <div v-else class="flex items-center gap-1 relative">
            <tooltip>
                <template v-slot:triggerText>
                    <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 min-w-0 w-6 h-6 fill-amber-400"
                         viewBox="0 0 24 24">
                        <g id="info-circle" transform="translate(-364 -252)">
                            <path id="Vector" d="M10,20A10,10,0,1,0,0,10,10.029,10.029,0,0,0,10,20Z"
                                  transform="translate(366 254)" fill="none" stroke="#292d32" stroke-linecap="round"
                                  stroke-linejoin="round" stroke-width="1.5" />
                            <path id="Vector-2" data-name="Vector" d="M0,0V5" transform="translate(376 260)" fill="none"
                                  stroke="#292d32" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                                  opacity="0.34" />
                            <path id="Vector-3" data-name="Vector" d="M0,0H.009" transform="translate(375.995 268)"
                                  fill="none" stroke="#292d32" stroke-linecap="round" stroke-linejoin="round"
                                  stroke-width="2" opacity="0.34" />
                            <path id="Vector-4" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(364 252)"
                                  fill="none" opacity="0" />
                        </g>
                    </svg>
                </template>
                <span class="font-light">{{ remainingDays }} dag(e) tilbage indtil opgaven skal være
                    fulført</span>
            </tooltip>
            <div class="flex flex-col relative p-1 cursor-pointer hover:bg-gray-100"
                 @click="dropdownOpen = !dropdownOpen">
                <div class="flex items-center justify-between gap-2">
                    <div class="text-xxs">{{ $translate("Task") }}:</div>
                    <div
                        class="rounded-md border border-green-600 h-4 p-1 flex items-center justify-center cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3"
                             stroke="currentColor" class="flex-shrink-0 min-w-0 w-3 h-3 text-green-600">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                        </svg>
                        <span class="border-l border-black/[0.5] h-full mx-1"></span>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                             stroke="currentColor"
                             class="flex-shrink-0 min-w-0 w-3 h-3 text-black transition-transform duration-300"
                             :class="dropdownOpen ? 'transform rotate-180' : ''">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                        </svg>
                    </div>
                </div>
                <div class="text-xs font-medium">{{ $translate(task.name) }}</div>
            </div>
            <div v-show="dropdownOpen"
                 class="origin-top-right absolute top-0 right-3 mt-12 z-[99999] rounded-md shadow-lg bg-white border-2 border-office-sky-700 ring-2 ring-black ring-opacity-5">
                <div class="p-2 m-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                    <booking-status-item type="booking" :item="item" @close-task-dropdown="closeDropdown"
                                         :locale="locale"
                                         @mark-task-as-done="payload => $emit('mark-task-as-done', payload)"></booking-status-item>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import LoadingSpinner from "./Common/LoadingSpinner.vue";
    import Tooltip from "./Tooltip.vue";
    import TaskItem from "./TaskItem.vue";
    import BookingStatusItem from "./BookingStatusItem.vue";

    export default {
        name: "UserTask",
        components: { BookingStatusItem, Tooltip, LoadingSpinner, TaskItem },
        emits: ["mark-task-as-done", "close-task-dropdown"], // declare the event
        props: {
            item: { type: Object, required: true },
            task: { type: Object, required: true },
            type: { type: String, required: true },
            locale: {}
        },
        data() {
            return {
                dropdownOpen: false
            };
        },
        methods: {
            closeDropdown() {
                this.dropdownOpen = false;
            },
            keydownListener(e) {
                if (e.key === "Escape") {
                    this.closeDropdown();
                }
            }
        },
        computed: {
            remainingDays() {
                let deadlineDays = 0;

                const deadlines = {
                    "easy": {
                        "Print labels": 7,
                        "Receive products": 7,
                        "Quality check": 0,
                        "Booking active": 0,
                        "Collect products": 3,
                        "Manage collected products": 7,
                        "Payout profit": 14
                    },
                    "easier": {
                        "Receive products": 21,
                        "Sort products": 14,
                        "Pricing products": 14,
                        "Print labels": 7,
                        "Booking active": 0,
                        "Collect products": 3,
                        "Manage collected products": 7,
                        "Payout profit": 14
                    },
                    "easiest": {
                        "Arrange pickup date": 35,
                        "Pickup products": 21,
                        "Sort products": 14,
                        "Pricing products": 14,
                        "Print labels": 7,
                        "Booking active": 0,
                        "Collect products": 3,
                        "Manage collected products": 7,
                        "Payout profit": 14
                    }
                };

                // now inside your function, get the deadline days like this:
                if (this.item.concept in deadlines && this.task.name in deadlines[this.item.concept]) {
                    deadlineDays = deadlines[this.item.concept][this.task.name];
                } else {
                    return null;
                }

                const startDate = new Date(this.item.start_date);
                const deadline = new Date(startDate);
                deadline.setDate(startDate.getDate() - deadlineDays);
                // Get the current date
                const now = new Date();

                // Return the difference in days
                return Math.ceil((deadline - now) / (1000 * 60 * 60 * 24));
            },
            // Computed property for background color
            colorClass() {
                if (this.remainingDays > 7) return "bg-gray-100 border-gray-600 !text-office-black";
                else if (this.remainingDays > 3) return "bg-yellow-400 border-gray-600 !text-office-black";
                else if (this.remainingDays > 1) return "bg-orange-400 border-gray-600 !text-office-black";
                else return "bg-red-700 border-red-900 !text-white";
            },
            textColorClass() {
                if (this.remainingDays > 7) return "text-office-black";
                else if (this.remainingDays > 3) return "bg-yellow-400";
                else if (this.remainingDays > 1) return "bg-orange-400";
                else return "!text-white";
            }
        },
        mounted() {
            window.addEventListener("keydown", this.keydownListener);
        },
        beforeUnmount() {
            window.removeEventListener("keydown", this.keydownListener);
        }
    };
</script>
