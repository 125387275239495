<template>
    <div class="flex flex-col text-sm gap-2 w-full">
        <div class="flex items-center justify-between gap-2">
            <div>Opgaven: <span class="font-bold">{{ $translate(task.name) }}</span></div>
            <button type="button" class="admin-border-icon-button admin-gray-icon-small-button"
                    @click="closeTaskDropdown">
                <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 min-w-0 w-4 h-4"
                     viewBox="0 0 32 32">
                    <g transform="translate(-236 -252)">
                        <path d="M0,14.935,14.935,0" transform="translate(244.532 260.532)" fill="none"
                              stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                              stroke-width="2" />
                        <path d="M14.935,14.935,0,0" transform="translate(244.532 260.532)" fill="none"
                              stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                              stroke-width="2" />
                    </g>
                </svg>
            </button>
        </div>
        <div class="flex flex-col">
            <div class="text-xs break-words">
                {{ $translate(task.description) }}
            </div>
            <div v-if="task.name === 'Arrange pickup date'">
                <form @submit.prevent="arrangePickupDate" class="flex flex-col gap-2 my-2">
                    <div>
                        <label for="suggested_date" class="form-label">{{ $translate("Afhentnings dato") }}</label>
                        <input
                            class="form-input"
                            v-model.lazy="pickupDate"
                            id="suggested_date"
                            type="datetime-local"
                            name="suggested_date">
                    </div>
                    <div v-if="pickupDate">Det valgte tidspunkt for afhentning er:
                        <div class="font-bold">{{ formatDate(pickupDate) }}</div>
                    </div>

                    <div class="flex items-center gap-2 mt-2">
                        <loading-button :disabled="!pickupDate" type="submit" :loading="isLoading[task] || false"
                                        button-type="admin-border-button">
                            <span v-if="pickupDate">Gem tidspunkt</span>
                            <span v-else>Angiv tidspunkt</span>
                        </loading-button>
                    </div>
                </form>
            </div>
            <div v-else-if="task.name === 'Pickup products'" class="mt-4">
                <div class="flex items-center justify-between gap-2 w-full">
                    <div v-if="pickupDate">Tidspunkt for afhentning:
                        <div class="font-bold">{{ formatDate(pickupDate) }}</div>
                    </div>
                    <button>
                        <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 min-w-0 w-6 h-6"
                             viewBox="0 0 24 24">
                            <g transform="translate(-620 -252)">
                                <path d="M9,0H7C2,0,0,2,0,7v6c0,5,2,7,7,7h6c5,0,7-2,7-7V11"
                                      transform="translate(622 254)" fill="none" stroke="currentColor"
                                      stroke-linecap="round"
                                      stroke-linejoin="round" stroke-width="1.5" />
                                <path
                                    d="M8.991,1.271l-7.88,7.88a2.712,2.712,0,0,0-.66,1.32l-.43,3.01a1.424,1.424,0,0,0,1.7,1.7l3.01-.43a2.8,2.8,0,0,0,1.32-.66l7.88-7.88c1.36-1.36,2-2.94,0-4.94S10.351-.089,8.991,1.271Z"
                                    transform="translate(627.049 253.749)" fill="none" stroke="currentColor"
                                    stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                <path d="M0,0A7.144,7.144,0,0,0,4.94,4.94"
                                      transform="translate(634.91 256.15)" fill="none" stroke="currentColor"
                                      stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                            </g>
                        </svg>
                    </button>
                </div>

                <form @submit.prevent="arrangePickupDate" class="flex flex-col gap-2 my-2">
                    <div>
                        <label for="suggested_date" class="form-label">{{ $translate("Afhentnings dato") }}</label>
                        <input
                            class="form-input"
                            v-model.lazy="pickupDate"
                            id="suggested_date"
                            type="datetime-local"
                            name="suggested_date">
                    </div>


                    <div class="flex items-center gap-2 mt-2">
                        <loading-button :disabled="!pickupDate" type="submit" :loading="isLoading[task] || false"
                                        button-type="admin-border-button">
                            <span v-if="pickupDate">Gem tidspunkt</span>
                            <span v-else>Angiv tidspunkt</span>
                        </loading-button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import LoadingButton from "../Shop/LoadingButton.vue";
    import { reactive } from "vue";

    export default {
        name: "TaskItem",
        components: { LoadingButton },
        emits: ["mark-task-as-done", "close-task-dropdown"], // declare the event
        props: {
            item: { type: Object, required: true },
            task: { type: Object, required: true },
            type: { type: String, required: true },
            locale: {}
        },
        data() {
            return {
                isLoading: reactive({}),
                pickupDate: null,
                formErrors: {}
            };
        },
        mounted() {
            this.pickupDate = this.item.suggested_date || null;
        },
        methods: {
            closeTaskDropdown() {
                this.$emit("close-task-dropdown");
            },
            arrangePickupDate() {
                if (!this.pickupDate) {
                    this.formErrors.suggested_date = "The pickup date field is required.";
                } else {
                    this.isLoading[this.task] = true;  // Set loading status for this task
                    axios.patch(`/admin/booking/${this.item.uuid}/${this.task.id}/set-delivery-date`, {
                        suggested_date: this.pickupDate
                    })
                        .then(response => {
                            this.isLoading[this.task] = false; // Unset loading status for this task
                            this.item.suggested_date = this.pickupDate; // Update suggested_date in item.
                            this.$emit("mark-task-as-done", response.data);
                            console.log(response.data); // Or handle the response as needed.
                        })
                        .catch(error => {
                            this.isLoading[this.task] = false; // Always unset loading.
                            if (error.response && error.response.data) {
                                // Laravel validation errors
                                if (error.response.status === 422) {
                                    this.formErrors = error.response.data.errors;
                                }
                                // Other errors
                                else {
                                    // Handle other errors here. Possibly use a user-visible notification system/toast/flash message etc.
                                    console.error(error.response.data);
                                }
                            } else {
                                console.error(error);
                            }
                        });
                }
            },
            formatDate(dateString) {
                if (!dateString) return "";

                const date = new Date(dateString);

                const options = { year: "numeric", month: "short", day: "numeric", hour: "2-digit", minute: "2-digit" };

                // using the passed in locale prop
                return new Intl.DateTimeFormat(this.locale, options).format(date);
            },
            getTaskApiEndpoint: function(task) {
                const endpoints = {
                    "Receive products": "/admin/bookings/" + this.item.uuid + "/" + task.id + "/set-items-as-handed-in"
                };
                return endpoints[this.task.name] || null;
            },
            onSubmit: function(task) {
                this.isLoading[task] = true;

                let taskEndpoint = this.getTaskApiEndpoint(task);
                if (taskEndpoint) {
                    axios.post(taskEndpoint)
                        .then(response => {
                            this.isLoading[task] = false;
                            this.$emit("mark-task-as-done", response.data);
                        })
                        .catch(error => {
                            console.log(error);
                            this.isLoading[task] = false;
                        });
                } else {
                    // route not found, handle error
                }
            }
        }
    };
</script>