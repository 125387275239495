<template>
    <div>
        <apexchart type="area" :height="300" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<script>
    export default {
        name: "GroupedColumnChart",
        props: {
            chartData: Object
        },
        data() {
            return {
                series: [],
                chartOptions: {
                    chart: {
                        stacked: true
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: "50%"
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    xaxis: {
                        categories: this.chartData.categories
                    }
                }
            };
        },
        mounted() {
            // Format the series data in the mounted hook
            this.formatSeriesData();
        },
        methods: {
            formatSeriesData() {
                this.series = this.chartData.series[0].map(item => ({
                    name: item.name,
                    data: [item.data[0], item.data[1]]
                }));
            }
        }
    };
</script>