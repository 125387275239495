<template>
    <div class="flex flex-col">
        <div class="flex items-center space-x-2">
            <div class="relative inline-block text-left" v-click-outside="closeDropdown">
                <div @click="dropdownOpen = !dropdownOpen">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg"
                             class="flex-shrink-0 min-w-0 w-5 h-5 cursor-pointer hover:brightness-150"
                             :class="{ 'text-[#D96C71]' : user.rating === 1, 'text-[#F47A1E]' : user.rating === 2, 'text-[#F3BD30]' : user.rating === 3, 'text-[#8BC954]' : user.rating === 4, 'text-[#2CBAE4]' : user.rating === 5, 'text-gray-300 hover:text-gray-500' : user.rating === 0 }"
                             viewBox="0 0 24 24">
                            <g transform="translate(-108 -188)">
                                <path
                                    d="M11.729,1.433l1.76,3.52A2.173,2.173,0,0,0,14.909,6l3.19.53c2.04.34,2.52,1.82,1.05,3.28l-2.48,2.48a2.171,2.171,0,0,0-.52,1.81l.71,3.07c.56,2.43-.73,3.37-2.88,2.1l-2.99-1.77a2.162,2.162,0,0,0-1.98,0l-2.99,1.77c-2.14,1.27-3.44.32-2.88-2.1l.71-3.07a2.171,2.171,0,0,0-.52-1.81L.849,9.813c-1.46-1.46-.99-2.94,1.05-3.28L5.089,6A2.178,2.178,0,0,0,6.5,4.953l1.76-3.52C9.219-.477,10.779-.477,11.729,1.433Z"
                                    transform="translate(110.001 190.077)" fill="none"
                                    stroke="currentColor"
                                    stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                <text transform="translate(117 204)" fill="currentColor" font-size="10"
                                      font-weight="700">
                                    <tspan v-if="user.rating > 0" x="0" y="0" class="text-black">
                                        {{ user.rating }}
                                    </tspan>
                                </text>
                            </g>
                        </svg>
                    </div>

                </div>
                <div
                    class="origin-top-right absolute left-0 mt-2 w-40 z-[99999] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
                    v-show="dropdownOpen">
                    <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                        <template v-for="rating in [0, 1, 2, 3, 4, 5]" :key="rating">
                            <div
                                @click="rateUser(rating)"
                                class="block px-2 py-1 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                                :class="{ 'bg-gray-200': rating === user.rating, 'text-red-700 hover:bg-red-100 hover:text-red-900' : rating === null}"
                                role="menuitem"

                            >
                                <div class="flex items-center justify-between w-full gap-2">
                                    <div>
                                        {{ rating === 0 ? $translate("No rating") : $translate("Rate") + " " + rating
                                        }}
                                    </div>
                                    <loading-spinner :loading="isLoading[rating] || false" size="mini" />
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios";
    import { reactive } from "vue";
    import UserAvatar from "./UserAvatar.vue";
    import LoadingSpinner from "./Common/LoadingSpinner.vue";

    export default {
        name: "RateUser",
        components: { LoadingSpinner, UserAvatar },
        emits: ["rate-user"],
        props: {
            user: { type: Object, required: true }
        },
        data() {
            return {
                dropdownOpen: false,
                isLoading: reactive({})
            };
        },
        methods: {
            rateUser(rating) {
                this.isLoading[rating] = true;

                if (rating === this.user.rating) {
                    this.isLoading[rating] = false;
                    this.dropdownOpen = false;
                    return;
                }

                const url = `/admin/brugere/${this.user.uuid}/rate`;

                axios.patch(url, { rating: rating, user_uuid: this.user.uuid })
                    .then(response => {
                        this.$emit("rate-user", response.data);
                        this.isLoading[rating] = false;
                        this.dropdownOpen = false;
                    })
                    .catch(error => {
                        console.error(error);
                        this.isLoading[rating] = false;
                    });
            },
            closeDropdown() {
                this.dropdownOpen = false;
            },
            keydownListener(e) {
                if (e.key === "Escape") {
                    this.closeDropdown();
                }
            }
        },
        mounted() {
            window.addEventListener("keydown", this.keydownListener);
        },
        beforeUnmount() {
            window.removeEventListener("keydown", this.keydownListener);
        }
    };
</script>