<template>
    <button @click="openCart" class="menu-item relative flex items-center gap-2"
            :class="{ 'menu-item-frontpage' : frontPage }">
        <span class="inline-flex items-center relative">
            <cart-counter :count="$store.state.cart.items_count"></cart-counter>
            <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 min-w-0"
                 :class="{ 'w-7 lg:w-6 h-7 lg:h-6': size === 'small', 'w-7 h-7': size === 'medium', 'mb-0.5': withTitle }"
                 viewBox="0 0 24 24"
                 fill="fillColor"
                 stroke="currentColor"
                 stroke-width="1">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
            </svg>
        </span>
        <span v-if="withTitle" class="hidden lg:inline-flex">Likes</span>
    </button>
</template>

<script>
    import CartCounter from "./CartCounter.vue";

    export default {
        components: {
            CartCounter
        },
        props: {
            frontPage: {
                required: false,
                type: Boolean,
                default: false
            },
            size: {
                type: String,
                required: false,
                default: "medium"
            },
            withTitle: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        // created() {
        //     this.getCartItems();
        // },
        methods: {
            // getCartItems() {
            //     this.$store.dispatch('getCartItems');
            // },
            openCart() {
                this.$store.commit("showCart");
                // this.lockBodyInApp();
                // this.activateOverlay();
            }
            // lockBodyInApp() {
            //     const body = document.body;
            // body.classList.add('body-overflow-hidden');
            // },
            // activateOverlay() {
            // TODO: not working as overlay is null atm
            // console.log(this.$parent.$refs.slideOverlay)
            // const overlay = document.querySelector('.drawer-shop-overlay')
            // console.log('overlay', overlay)
            // overlay.classList.toggle('active');
            // }
        }
    };
</script>
