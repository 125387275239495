<template>
    <div class="topolis-badge topolis-small-badge topolis-pearl-badge" :class="color">
        {{ week }}
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: "WeekColor",
        props: {
            endDate: {}
        },
        data() {
            return {
                color: null,
                week: null
            };
        },
        watch: {
            endDate: "getWeekColor"
        },
        methods: {
            getWeekColor() {
                axios.get("/pearl_color/" + this.endDate)
                    .then(response => {
                        this.week = response.data.week;
                        this.color = response.data.color;
                    })
                    .catch(error => {
                        console.log("color error:", error);
                    });
            }
        },
        created() {
            this.getWeekColor();
        }
    };
</script>