<template>
    <div class="w-full h-full">
        <div class="mx-4 mt-2 -mb-1">
            <h3 class="flex items-center justify-end gap-1">{{ totalSoldProducts }} <span class="text-xs">/</span>
                <span class="text-base">{{ totalProducts }}</span></h3>
        </div>
        <spline-area-chart :chart-data="chartData" :locale="locale"></spline-area-chart>
    </div>
</template>

<script>
    import SplineAreaChart from "./SplineAreaChart.vue";

    export default {
        name: "SparkBox",
        components: { SplineAreaChart },
        props: {
            chartData: Object,
            locale: {},
            seriesName: String

        },
        computed: {
            totalProducts() {
                return Object.values(this.chartData.products).reduce((a, b) => a + b, 0);
            },
            totalSoldProducts() {
                return Object.values(this.chartData.soldProducts).reduce((a, b) => a + b, 0);
            }
        }
    };
</script>