<template>
    <div>
        <button v-if="!alreadyInCart" disabled
                :class="{ 'shop-button p-1.5 xs:p-2.5' : buttonType === 'small', 'button shop-button-blue h-12 small-button' : buttonType === 'large' }"
                class="flex items-center justify-between" @click="addToCart" title="Læg i kurv"
                :disabled="variant.sold_at">
            <span v-if="variant.sold_at">
                <span class="uppercase text-xxs xxs:text-xs md:text-sm font-medium">Solgt</span>
            </span>
            <span v-else class="inline-flex items-center justify-between">
                <span class="uppercase mr-2 text-xxs xxs:text-xs md:text-sm font-medium leading-3">Læg i kurv</span>
                <svg xmlns="http://www.w3.org/2000/svg"
                     class="flex-shrink-0 min-w-0 w-4 xs:w-5 -mt-0.5"
                     :class="{ '-mt-1' : buttonType === 'large' }" viewBox="0 0 32 32">
                    <path
                        d="M6.559,0h9.6c4.533,0,4.987,2.12,5.293,4.707l1.2,10c.387,3.28-.627,5.96-5.293,5.96H5.373c-4.68,0-5.693-2.68-5.293-5.96l1.2-10C1.573,2.12,2.026,0,6.559,0Z"
                        transform="translate(4.641 8.667)" fill="none" stroke="currentColor" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="1.5" />
                    <path d="M0,8V3.333A3.149,3.149,0,0,1,3.333,0h4a3.149,3.149,0,0,1,3.333,3.333V8"
                          transform="translate(10.667 2.667)" fill="none" stroke="currentColor" stroke-linecap="round"
                          stroke-linejoin="round" stroke-width="1.5" />
                    <path d="M16.547,0H0" transform="translate(10.667 22.707)" fill="none" stroke="currentColor"
                          stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                </svg>
            </span>
        </button>
        <button v-if="alreadyInCart" disabled
                :class="{ 'already-in-cart': alreadyInCart, 'shop-button p-1.5 xs:p-2.5' : buttonType === 'small', 'button button-blue h-12 small-button' : buttonType === 'large' }"
                class="flex items-center justify-between" @click="removeFromCart" title="Fjern fra kurv">
            <span class="uppercase mr-2 text-xxs xxs:text-xs md:text-sm font-medium text-red-500 leading-3">Fjern fra
                kurv</span>
            <svg xmlns="http://www.w3.org/2000/svg"
                 class="flex-shrink-0 min-w-0 w-4 xs:w-5 -mt-0.5 text-red-500"
                 :class="{ '-mt-1' : buttonType === 'large' }" viewBox="0 0 32 32">
                <path
                    d="M6.559,0h9.6c4.533,0,4.987,2.12,5.293,4.707l1.2,10c.387,3.28-.627,5.96-5.293,5.96H5.373c-4.68,0-5.693-2.68-5.293-5.96l1.2-10C1.573,2.12,2.026,0,6.559,0Z"
                    transform="translate(4.641 8.667)" fill="none" stroke="currentColor" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="1.5" />
                <path d="M0,8V3.333A3.149,3.149,0,0,1,3.333,0h4a3.149,3.149,0,0,1,3.333,3.333V8"
                      transform="translate(10.667 2.667)" fill="none" stroke="currentColor" stroke-linecap="round"
                      stroke-linejoin="round" stroke-width="1.5" />
                <path d="M16.547,0H0" transform="translate(10.667 22.707)" fill="none" stroke="currentColor"
                      stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
            </svg>
        </button>
    </div>
</template>

<script>
    export default {
        props: {
            product: {
                required: true
            },
            variant: {
                required: true
            },
            buttonType: {
                required: true,
                type: String
            }
        },
        computed: {
            alreadyInCart() {
                return this.$store.getters.cartProductIds.includes(this.product);
            }
        },
        methods: {
            addToCart(e) {
                if (this.alreadyInCart) return;
                e.preventDefault();

                this.$store.dispatch("addToCart", {
                    product: this.product,
                    variant: this.variant
                });
            },
            removeFromCart(e) {
                if (!this.alreadyInCart) return;
                e.preventDefault();

                const foundProduct = this.$store.getters.cartProducts.find((prod) => {
                    return prod.id === this.product.id;
                });

                this.$store.dispatch("removeFromCart", foundProduct.hash);
            }
        }
    };
</script>
