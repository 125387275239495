<template>
    <button :data-cy="dataCy" :class="buttonType" :disabled="disabled" class="button button-blue flex items-center nav-button">
        <span v-if="bouncing" class="button-spinner flex gap-1">
            <span v-show="bouncing"><slot /></span>
            <svg v-show="bouncing" xmlns="http://www.w3.org/2000/svg" class="bouncing h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
            </svg>
        </span>
        <span v-if="!bouncing"><slot /></span>
    </button>
</template>

<script>
    export default {
        name: 'BouncingButton',
        components: {},
        props: {
            bouncing: {
                type: Boolean,
            },
            type: {
                type: String,
                default: 'button',
            },
            buttonType: {
                type: String,
                default: 'button',
            },
            dataCy: {
                type: String,
                required: false,
            },
            disabled: {
                type: Boolean,
            },
        },
    };
</script>

<style>
    .bouncing {
        animation: bouncing 1.2s infinite;
    }
    @keyframes bouncing {
        0%,
        100% {
            transform: translateX(60%);
            animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
        }
        50% {
            transform: translateX(0);
            animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
        }
    }
</style>
