<template>
    <div class="w-full relative divide-y divide-gray-200">
        <div v-for="item in items" :key="item.id">
            <a :href="itemLink(item)" class="flex hover:no-underline hover:bg-gray-50 hover:text-inherit p-2">
                <!--                <div class="basis-1/12">-->
                <!--                    <div v-if="item.image">-->
                <!--                        <img :src="item.image" :alt="item.alt">-->
                <!--                    </div>-->
                <!--                    <div v-else>-->
                <!--                        <div class="bg-topolis-gray-100 rounded-md p-2">-->
                <!--                            <img src="/assets/categories/books-games.svg"-->
                <!--                                 alt="minitopolis-no-image-books-games-placeholder"-->
                <!--                                 class="bg-no-repeat">-->
                <!--                        </div>-->
                <!--                    </div>-->
                <!--                </div>-->
                <div class="flex justify-between text-left w-full ml-3 my-0.5">
                    <div class="flex flex-col justify-between gap-1 leading-3 mr-4">
                        <p class="inline-flex font-medium">{{ item.name }}</p>
                    </div>
                </div>
            </a>
        </div>
    </div>
</template>
<script>

    export default {
        name: "item-list",
        props: {
            items: {},
            type: {}
        },
        methods: {
            itemLink(item) {
                if (this.type === "category") {
                    if (item.parent) {
                        console.log(item.parent);
                        return `/shop/kategori/${item.parent.slug}/${item.slug}`;
                    } else {
                        return `/shop/kategori/${item.slug}`;
                    }

                } else if (this.type === "brand") {
                    return `/shop/brands/${item.slug}`;
                }
            }
        }
    };
</script>
