<template>
    <div class="accordion flex flex-col gap-3">
        <div v-for="item in filteredItems" :key="item.id" class="accordion-item">
            <div class="bg-gray-50 py-2 w-full rounded cursor-pointer" @click="toggle(item.id)">
                <div
                    class="flex items-center justify-between gap-2 accordion-header select-none"
                >
                    <h5 class="font-medium text-black ml-2">
                        {{ item.header }}</h5>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                         stroke="currentColor" class="flex-shrink-0 min-w-0 h-4 w-4 text-black mr-2"
                         style="transition: transform 0.3s ease; transform:rotate(calc(var(--rotate, 0) * 1deg)); transform-origin: 50% 50%"
                         :style="{ '--rotate': openItem === item.id ? 90 : 0 }">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 5v14" />
                        <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14"
                              :class="{ 'opacity-0': openItem === item.id }" />
                    </svg>
                </div>
                <transition
                    @before-enter="beforeEnter"
                    @enter="enter"
                    @after-enter="afterEnter"
                    @before-leave="beforeLeave"
                    @leave="leave"
                >
                    <div v-if="openItem === item.id" class="accordion-content mt-1 overflow-hidden p-2 select-none"
                         ref="content">
                        <p class="text-gray-700 leading-5">{{ item.description }}</p>
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            type: { String, default: "" }
        },
        computed: {
            filteredItems() {
                return this.items.filter(item => item.type === this.type);
            }
        },
        data() {
            return {
                openItem: null, // this will store id of a opened accordion
                items: [
                    {
                        id: 1,
                        header: "Hvad betyder mit nr.?",
                        description: "Hver gang du booker hos os, så tildeles din booking periode altid et såkaldt butiksnummer. Dette nummer skal du angive til os nede i butikken, da vi hurtigt kan finde perioden frem i systemet, hvis du skulle have spørgsmål.",
                        type: "bookings"
                    },
                    {
                        id: 2,
                        header: "Forlængelse af booking",
                        description: "En forlængelse varer altid 10 uger. Det er meget specifikt ift. dit koncept, hvad du har af muligheder. Du kan enten forlænge med eller uden opfyldning af nye varer. Med opfyldning må du opfylde fra det antal varer, du har tilbage og op til dit samlede valgte max produkter.",
                        type: "bookings"
                    },
                    {
                        id: 3,
                        header: "Hvad består et \"Kit\" af?",
                        description: "Et \"kit\" består af labels, manillamærker, klistermærker og sikkerhedsnåle, som du har brug for ift. at klargøre dine varer. For at spare tid så skriv endelig til os, før du kommer ned i butikken, hvorfra vi kan klaregøre \"kittet\" til dig. Du påsætter dine mærker på dine ting derhjemme eller nede i butikken, hvor vi har en arbejdsstation, du kan benytte.",
                        type: "bookings"
                    },
                    {
                        id: 4,
                        header: "Hvad omfatter \"klargøring\" af varene i butikken?",
                        description: "Du kan gøre det meste hjemmefra. Nemlig prissætning. Herefter skal du ned forbi butikken og hente et \"Kit\". Du kan påføre mærker på dine ting hjemmefra eller nede i butikken. Det eneste du skal gøre nede i butikken i vores \"arbejdsstation\", er at hænge det op på et stativ efter størrelse.",
                        type: "bookings"
                    },
                    {
                        id: 5,
                        header: "Udbetaling af fortjeneste",
                        description: "Betalingen kommer altid i start hver måned, når perioden er afsluttet. Du skal have afhentet dine resterende varer for at få udbetaling (hvis ikke du har valgt at donere, eller har solgt alle dine varer). Hvis dine penge stadigvæk ikke er kommet ind, så skriv endelig til os, så vi kan få løst det.",
                        type: "bookings"
                    },
                    {
                        id: 6,
                        header: "Hvordan sletter jeg en vare?",
                        description: "Et produkt har altid en status, så hvis dit produkt har en status der hedder; \"Ikke sat\", \"Gennemse\", \"Ikke registreret\", eller \"Afleveret\", så kan produktet slettes.",
                        type: "products"
                    }
                    // Add more items here
                ]
            };
        },
        methods: {
            toggle(id) {
                if (this.openItem === id) {
                    this.openItem = null;
                } else if (this.openItem !== null) {
                    this.openItem = null;
                    this.openItem = id;
                } else {
                    this.openItem = id;
                }
            },
            beforeEnter(el) {
                el.style.maxHeight = "0";
            },
            enter(el, done) {
                this.$nextTick(() => {
                    el.style.maxHeight = el.scrollHeight + "px";
                    done();
                });
            },
            afterEnter(el) {
                el.style.maxHeight = "";
            },
            beforeLeave(el) {
                el.style.maxHeight = el.scrollHeight + "px";
            },
            leave(el) {
                el.style.maxHeight = "0";
            }
        }
    };
</script>