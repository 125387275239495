<template>
    <div class="flex flex-col">
        <div class="flex items-center space-x-2">
            <div class="relative inline-block text-left" v-click-outside="closeDropdown">
                <div @click="dropdownOpen = !dropdownOpen">
                    <div v-if="item.responsible_id">
                        <div v-for="admin in admins">
                            <div v-if="item.responsible_id === admin.id">
                                <user-avatar :interaction="true" :dashboard="false" :user="getResponsibleUser"
                                             :size="size">
                                </user-avatar>
                            </div>
                        </div>
                    </div>
                    <div v-else :class="[avatarClassSizes[size]]"
                         class="flex justify-center items-center cursor-pointer hover:border-gray-400 focus:outline-none focus:shadow-outline group">
                        <svg xmlns="http://www.w3.org/2000/svg" class="text-gray-300 group-hover:text-gray-400"
                             fill="none" :class="[avatarIconClassSizes[size]]"
                             viewBox="0 0 24 24"
                             stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                  d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                        </svg>
                    </div>

                </div>
                <div
                    class="origin-top-right absolute right-0 mt-2 w-72 z-[99999] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
                    v-show="dropdownOpen">
                    <div class="py-1" role="menu" aria-orientation="vertical"
                         aria-labelledby="options-menu">
                        <div @click="assignUser(null)"
                             class="block px-2 py-1 text-sm text-red-700 hover:bg-red-100 hover:text-red-900 cursor-pointer"
                             role="menuitem">
                            <div class="flex items-center gap-2">
                                <div
                                    class="flex justify-center items-center w-8 h-8 border border-dashed border-gray-300 rounded-full cursor-pointer">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="text-gray-300 h-4 w-4" fill="none"
                                         viewBox="0 0 24 24"
                                         stroke="currentColor" stroke-width="2">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                              d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                                    </svg>
                                </div>
                                <div class="w-3/4">
                                    <div class="text-xs truncate">
                                        {{ $translate("No assignee") }}
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div @click="assignUser(admin.id)" v-for="admin in admins"
                             class="block px-2 py-1 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                             role="menuitem" :class="{ 'bg-gray-200': item.responsible_id === admin.id }">
                            <div class="flex items-center justify-between w-full gap-2">
                                <div class="flex items-center gap-2 w-full">
                                    <user-avatar :dashboard="false" :user="admin"
                                                 size="small">
                                    </user-avatar>
                                    <div class="w-3/4">
                                        <div class="text-xs truncate">
                                            {{ admin.name }}
                                        </div>
                                    </div>
                                </div>
                                <loading-spinner :loading="isLoading[admin.id] || false" size="mini" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios";
    import UserAvatar from "./UserAvatar.vue";
    import { reactive } from "vue";
    import LoadingSpinner from "./Common/LoadingSpinner.vue";

    export default {
        name: "AssignUser",
        components: { LoadingSpinner, UserAvatar },
        emits: ["assign-user"],
        props: {
            item: { type: Object, required: true },
            advanced: { type: Boolean, default: false },
            type: { type: String, required: true },
            admins: { type: Object, default: true },
            size: { type: String, required: false, default: "medium" }
        },
        data() {
            return {
                dropdownOpen: false,
                isLoading: reactive({}),
                avatarClassSizes: {
                    "xxsmall": "w-5 h-5 border border-dashed border-gray-300 rounded-full",
                    "xsmall": "w-6 h-6 border border-dashed border-gray-300 rounded-full",
                    "small": "w-8 h-8 border border-dashed border-gray-300 rounded-full",
                    "medium": "w-8 h-8 2xl:w-10 2xl:h-10 border border-dashed border-gray-300 rounded-full",
                    "large": "w-12 h-12 border border-dashed border-gray-300 rounded-full"
                },
                avatarIconClassSizes: {
                    "xxsmall": "h-2 w-2",
                    "xsmall": "h-3 w-3",
                    "small": "h-4 w-4",
                    "medium": "h-4 w-4 2xl:h-6 2xl:w-6",
                    "large": "h-8 w-8"
                }
            };
        },
        computed: {
            getResponsibleUser() {
                return this.admins.find(admin => admin.id === this.item.responsible_id);
            }
        },
        methods: {
            assignUser(adminId) {
                this.isLoading[adminId] = true;

                if (adminId === this.item.responsible_id) {
                    this.isLoading[adminId] = false;
                    this.dropdownOpen = false;
                    return;
                }

                const url = this.type === "event" ? `/admin/events/${this.item.slug}/assignUserToEvent`
                    : this.type === "booking" ? `/admin/bookings/${this.item.uuid}/assignUser`
                        : `/admin/webshop/orders/${this.item.uuid}/assignUserToOrder`;

                axios.patch(url, { responsible_id: adminId, booking_uuid: this.item.uuid })
                    .then(response => {
                        console.log(response.data);
                        this.$emit("assign-user", response.data);
                        this.isLoading[adminId] = false;
                        this.dropdownOpen = false;
                    })
                    .catch(error => {
                        console.error(error);
                        this.isLoading[adminId] = false;
                    });
            },
            closeDropdown() {
                this.dropdownOpen = false;
            },
            keydownListener(e) {

                if (e.key === "Escape") {
                    this.closeDropdown();
                }
            }
        },
        mounted() {
            window.addEventListener("keydown", this.keydownListener);
        },
        beforeUnmount() {
            window.removeEventListener("keydown", this.keydownListener);
        }
    };
</script>