<template>
    <div>
        {{ product.name }}
    </div>
</template>

<script>
    export default {
        props: {
            product: {},
            variant: {},
            adminPage: {
                type: Boolean,
                required: false,
                default: false
            }
        }
    };
</script>

