<template>
    <div class="relative">
        <button @click="openDrawer('search')"
                class="menu-item relative flex items-center gap-1.5"
                :class="{ 'menu-item-frontpage' : frontPage }">
            <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 min-w-0"
                 :class="{ 'w-6 lg:w-5 h-6 lg:h-5': size === 'small', 'w-6 h-6': size === 'medium', 'mb-0.5': withTitle }"
                 viewBox="0 0 24 24">
                <g transform="translate(-108 -188)">
                    <path d="M18,9A9,9,0,1,1,9,0,9,9,0,0,1,18,9Z" transform="translate(110 190)" fill="fillColor"
                          stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                    <path d="M.1,1.97c.53,1.6,1.74,1.76,2.67.36C3.62,1.05,3.06,0,1.52,0A1.406,1.406,0,0,0,.1,1.97Z"
                          transform="translate(126.83 206.72)" fill="fillColor" stroke="currentColor"
                          stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                </g>
            </svg>
            <span v-if="withTitle" class="hidden lg:inline-flex">Søg</span>
        </button>
    </div>
</template>

<script>
    import Drawer from "../Pages/Common/Drawer.vue";

    export default {
        components: { Drawer },
        props: {
            frontPage: {
                type: Boolean,
                required: false,
                default: false
            },
            size: {
                type: String,
                required: false,
                default: "medium"
            },
            withTitle: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                isOpen: false,
                searchQuery: ""
            };
        },
        methods: {
            openDrawer(name) {
                this.emitter.emit("open-drawer", name);
            }
        }
    };
</script>

<style>

</style>