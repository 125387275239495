<template>
    <div class="w-full h-full">
        <apexchart type="area" :height="200" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<script>
    export default {
        name: "SplineAreaChart",
        props: {
            chartData: Object
        },
        data() {
            return {
                series: [],
                chartOptions: {
                    chart: {
                        stacked: true
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: "50%"
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    xaxis: {
                        categories: Object.keys(this.chartData.products).sort((a, b) => Date.parse(a) - Date.parse(b)),
                        labels: {
                            show: false
                        }
                    },
                    yaxis: {
                        labels: {
                            show: false,
                            formatter: function(val) {
                                return val.toFixed(0); // No decimals
                            }
                        }
                    },
                    grid: {
                        show: false // Hide grid
                    },
                    stroke: {
                        curve: "smooth"
                    },
                    legend: {
                        position: "top",
                        horizontalAlign: "right"
                    },
                    tooltip: {
                        y: {
                            formatter: function(value) {
                                if (isNaN(value)) {
                                    return 0;
                                } else {
                                    return value;
                                }
                            }
                        }
                    }
                }
            };
        },
        mounted() {
            this.$nextTick(() => {
                setTimeout(() => {
                    window.dispatchEvent(new Event("componentLoaded"));
                }, 300);
            });
            // Format the series data in the mounted hook
            this.formatSeriesData();
        },
        methods: {
            formatSeriesData() {
                const sortedKeys = Object.keys(this.chartData.products).sort((a, b) => Date.parse(a) - Date.parse(b));

                const productsData = sortedKeys.map(key => this.chartData.products[key]);
                const soldProductsData = sortedKeys.map(key => this.chartData.soldProducts[key]);

                this.series = [
                    {
                        name: this.$translate("Products"),
                        data: productsData
                    },
                    {
                        name: this.$translate("Sold products"),
                        data: soldProductsData
                    }
                ];
            }
        }
    };
</script>