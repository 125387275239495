<template>
    <div class="flex flex-col mx-auto space-y-8">
        <profile v-if="authenticatedUser" class="mt-2" />
        <div class="flex flex-col space-y-4">
            <span class="app-h1">Din booking er hermed bekræftet og er under behandling</span>
            <h5>
                En bekræftelse er hermed sendt til:
                <span class="font-black text-topolis-blue-700">{{ authenticatedUser.email }}</span>
            </h5>
        </div>
        <div class="flex flex-col w-full max-w-2xl mx-auto pb-48 sm:pb-28">
            <div class="space-y-4">
                <h5>Du har netop booket en periode hos Minitopolis</h5>
                <h5>Når vi har godkendt din booking vil du modtage en velkomstmail med informationer, gode råd mm.</h5>
                <h5>Da du har oprettet dig som kunde hos Minitopolis, så kan du fra nu af logge ind på “Mit
                    Minitopolis”. Derinde kan du følge med i dine salg, oprette produkter, og redigere de nødvendige
                    informationer.</h5>
            </div>
            <div class="space-y-4 mt-8">
                <div>
                    <span>Tag mig til </span>
                    <a href="/mit-minitopolis" class="a-link">Mit Minitopolis</a>
                </div>
                <div>
                    <span>Tag mig til </span>
                    <a href="https://www.minitopolis.dk/" class="a-link">Minitopolis' forside</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import Profile from "./Common/Profile.vue";

    export default {
        components: {
            Profile
        },
        data() {
            return {
                success: false,
                chosenConceptNew: null
            };
        },
        computed: {
            ...mapGetters(["chosenConcept", "periods", "chosenPeriod", "authenticatedUser", "bookingUuid", "startDate", "endDate", "startWeek", "endWeek", "formattedStartDate", "formattedEndDate", "note"])
        },
        mounted() {
            this.chosenConceptNew = Object.freeze(this.chosenConcept);
            this.$store.commit("setConcept", null);
            this.$store.commit("setSubConcept", null);
            this.$store.commit("setPeriod", null);
            this.$store.commit("setSelectedWeeks", []);
            this.$store.commit("setStartDates", {
                startDate: null,
                startDateFormatted: null
            });
            this.$store.commit("setEndDates", {
                endDate: null,
                endDateFormatted: null
            });
            this.$store.commit("setStartWeek", null);
            this.$store.commit("setEndWeek", null);

            this.$store.commit("setBookingUuid", null);
        }
    };
</script>
