<template>
    <tr>
        <td class="p-2 first:pl-5 last:pr-5 whitespace-nowrap">
            <div class="flex items-center text-gray-800 font-medium space-x-2">
                <span class="main-product-scanner-name">
                    {{ product.name }}
                </span>
                <span class="topolis-badge topolis-table-badge" :class="product.pearl_color">
                    {{ product.end_date }}
                </span>
                <div class="border border-black px-1 leading-none w-fit flex items-center">
                    <span class="text-xxs text-gray-800">nr.:</span>
                    <span class="text-sm font-medium text-black">
                        <span>{{ product.store_id }}</span>
                    </span>
                </div>
            </div>
        </td>
        <td class="p-2 first:pl-5 last:pr-5 whitespace-nowrap">
            <div class="text-gray-800 font-medium">
                <span v-if="product.category">{{ product.category.name }}</span>
            </div>
        </td>
        <td class="p-2 first:pl-5 last:pr-5 whitespace-nowrap">
            <div class="text-gray-800 font-medium max-w-[70px] 2xl:max-w-[90px] truncate">
                <span v-if="product.title && product.category.parent_id === 377">
                    <span class="product-scanner-name">{{ product.title }}</span>
                </span>
                <span v-else-if="product.size && product.category.parent_id !== 377"
                      class="status-badge turquoise-badge">
                    <span class="product-scanner-name"
                    >{{ product.size.name }}</span>
                </span>
            </div>
        </td>
        <td class="p-2 first:pl-5 last:pr-5 whitespace-nowrap">
            <div class="text-gray-800 font-medium max-w-[6rem] truncate">
                <span v-if="product.brand" class="product-scanner-name">{{ product.brand.name }}</span>
            </div>
        </td>
        <td class="p-2 first:pl-5 last:pr-5 whitespace-nowrap">
            <div class="flex flex-col w-full text-gray-800 font-medium capitalize truncate">
                <div class="product-scanner-name">
                    <div class="flex w-full">
                        <span v-if="product.colors">
                            <span v-for="color in product.colors">
                                <color-badge model="color" :show-name="true" :name="color.name" :hex="color.hex_code"
                                             class="mr-1" />
                            </span>
                        </span>
                    </div>
                    <div class="flex w-full">
                        <span v-if="product.patterns">
                            <span class="inline-flex relative mr-1" v-for="pattern in product.patterns">
                                {{ pattern.name }}
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        </td>
        <td class="p-2 first:pl-5 last:pr-5 whitespace-nowrap">
            <div class="text-gray-800 font-medium">
                <span v-if="product.status === 'store'"
                      class="status-badge topolis-small-badge purple-badge">I butikken</span>
                <span v-if="product.status === 'storage'"
                      class="status-badge topolis-small-badge yellow-badge">På lager</span>
                <span v-if="product.status === 'review'"
                      class="status-badge topolis-small-badge amber-badge">Gennemse</span>
                <span v-if="product.status === 'collected'" class="status-badge topolis-small-badge neon-blue-badge">Indsamlet</span>
                <span v-if="product.status === 'rejected'" class="status-badge topolis-small-badge orange-badge">Returvare</span>
                <span v-if="product.status === 'unknown'"
                      class="status-badge topolis-small-badge gray-badge">Ukendt</span>
                <span v-if="product.status === 'unregistered'" class="status-badge topolis-small-badge gray-badge">Ikke registreret</span>
                <span v-if="product.status === 'released'"
                      class="status-badge topolis-small-badge gray-badge">Afleveret</span>
                <span v-if="product.status === 'donated'"
                      class="status-badge topolis-small-badge gray-badge">Doneret</span>
                <span v-if="product.status === 'none' || !product.status"
                      class="status-badge topolis-small-badge pink-badge">Ikke sat</span>
            </div>
        </td>
        <td class="p-2 first:pl-5 last:pr-5 whitespace-nowrap">
            <div class="text-gray-800 font-medium max-w-[6rem] truncate">
                {{ product.price }}
            </div>
        </td>
        <td class="p-2 first:pl-5 last:pr-5 whitespace-nowrap">
            <select v-model="selectProductStatus" class="form-select-admin text-sm w-full" @change="setStatus(product)"
                    v-if="isCollectingItems">
                <option value="none" disabled>Ikke sat</option>
                <option value="store">I butikken</option>
                <option value="storage">På lager</option>
                <option value="review">Gennemse</option>
                <option value="rejected">Returvare</option>
                <option value="collected">Indsamlet</option>
                <option value="unknown">Ukendt</option>
                <option value="unregistered">Ikke registreret</option>
                <option value="released">Afleveret</option>
                <option value="donated">Doneret</option>
            </select>
            <div class="flex justify-center" v-else>
                <label class="flex items-center h-5" for="accepts_marketing_emails">
                    <input id="accepts_marketing_emails" v-model="markProductDiscountPreparation"
                           name="accepts_marketing_emails" type="checkbox" checked
                           class="form-input-checkbox hover:cursor-pointer"
                           value="" @change="setDiscountPreparationProduct(product)" />
                </label>
            </div>
        </td>
    </tr>
</template>
<script>
    import WeekColor from "./WeekColor.vue";
    import ColorBadge from "./ColorBadge.vue";

    export default {
        name: "ScanProduct",
        components: { ColorBadge, WeekColor },
        props: {
            propProduct: {},
            isCollectingItems: {
                type: Boolean,
                required: false
            }
        },
        data() {
            return {
                selectProductStatus: this.propProduct.status,
                product: this.propProduct,
                markProductDiscountPreparation: !!this.propProduct.discount_prepared_at

            };
        },
        methods: {
            setStatus(product) {
                axios.post("/admin/set-product-status/" + product.id, { status: this.selectProductStatus }).then((response) => {
                    console.log(response);
                    this.product = response.data.product;
                    emitter.emit("product-changed");  // emit event after change
                });
            },
            setDiscountPreparationProduct(product) {
                axios.post("/admin/set-product-discount-preparation/" + product.id, { discount_prepared_at: this.markProductDiscountPreparation }).then((response) => {
                    this.product = response.data.product;
                    // will evaluate to true if discount_prepared_at (timestamp) is not null
                    this.markProductDiscountPreparation = !!this.product.discount_prepared_at;
                    emitter.emit("product-changed");  // emit event after change
                });
            }
        }
    };
</script>
