<template>
    <span
        :class="{ 'bg-black text-white' : count >= 1, 'bg-gray-300 text-topolis-black' : count === 0 }"
        class="absolute text-xxs font-bold leading-3 p-0.5 h-4 w-4 flex items-center justify-center rounded-full -top-0.5 -right-1.5">
        {{ count }}
    </span>
</template>

<script>
    export default {
        props: {
            count: {
                type: Number,
                required: true
            }
        }
    };
</script>