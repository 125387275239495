<template>
    <div data-cy="login-step" class="flex flex-col space-y-8" :class="outsideFlow ? 'p-6 sm:p-12' : ''">
        <div class="flex items-center justify-center">
            <img v-if="outsideFlow && !partnerLogin" class="w-44" :src="'/assets/logos/minitopolis-lilla.svg'" alt=""
                 width="auto" height="auto" />
            <img v-if="outsideFlow && partnerLogin" class="w-44" :src="'/assets/logos/minitopolis-blaa-lilla.svg'"
                 alt="" width="auto" height="auto" />
        </div>
        <div class="flex flex-col text-center space-y-4">
            <span v-if="outsideFlow && !partnerLogin" class="app-h1 -mt-4">Mit Minitopolis login</span>
            <span v-if="outsideFlow && partnerLogin" class="app-h1 -mt-4">Partners login</span>
            <span v-if="!outsideFlow" class="app-h1">Nuværende bruger</span>
            <h5 class="app-h5">Velkommen tilbage! Login ved brug af din email</h5>
        </div>
        <div class="flex flex-col">
            <form class="w-full max-w-sm mx-auto text-left space-y-8" method="POST" @submit.prevent="login">
                <div v-if="generalError" class="text-red-500">
                    {{ generalError }}
                </div>
                <div class="space-y-4">
                    <div>
                        <label for="email" class="block text-sm font-bold text-topolis-blue-700">Email</label>
                        <div class="mt-1">
                            <input id="email" v-model="form.email" name="email" type="email"
                                   placeholder="fx. hejsa@minitopolis.dk" autocomplete="email"
                                   data-cy="login-form-email-input" required class="form-input" />
                        </div>
                        <div v-if="errors && errors.email" class="mt-1 text-xs text-red-500">
                            <p class="text-red-500">{{ errors.email[0] }}</p>
                        </div>
                    </div>
                    <div>
                        <label for="password" class="block text-sm font-bold text-topolis-blue-700"> Password </label>
                        <div class="mt-1">
                            <input id="password" v-model="form.password" data-cy="login-form-password-input"
                                   name="password" type="password" placeholder="******" autocomplete="current-password"
                                   required class="form-input" />
                        </div>
                        <div v-if="errors && errors.password" class="mt-1 text-xs text-red-500">
                            <p class="text-red-500">{{ errors.password[0] }}</p>
                        </div>
                    </div>
                    <div class="flex items-center justify-end">
                        <div class="text-xs -mt-1">
                            <a href="/forgot-password" class="a-link"> Glemt dit password? </a>
                        </div>
                    </div>
                </div>

                <div class="text-center">
                    <p>
                        Læs venligst
                        <a href="https://minitopolis.dk/persondatapolitik#/" class="a-link" target="_blank">persondata
                            politik</a>
                        for yderligere information om beskyttelse af personlige oplysninger
                    </p>
                </div>

                <div class="flex flex-col space-y-8 pb-48 sm:pb-28">
                    <hr />
                    <div class="flex items-center" :class="outsideFlow ? 'justify-center' : 'justify-between'">
                        <button type="button" v-if="!outsideFlow" class="transparent-button flex items-center"
                                formnovalidate
                                @click="gotoPrev">
                            <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 min-w-0 w-4"
                                 viewBox="0 0 24 24">
                                <g transform="translate(-684 -188)">
                                    <path d="M6.07,0,0,6.07l6.07,6.07" transform="translate(687.5 193.93)" fill="none"
                                          stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                          stroke-width="1.5" />
                                    <path d="M16.83,0H0" transform="translate(687.67 200)" fill="none"
                                          stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                          stroke-width="1.5" />
                                </g>
                            </svg>
                            <span class="ml-1 text-xs xxs:text-base">Tilbage</span>
                        </button>
                        <bouncing-button data-cy="login-form-submit-button" type="submit" :bouncing="bouncing"
                                         :disabled="bouncing || invalidInputs" @click.native="login">Login
                        </bouncing-button>
                    </div>
                    <div v-if="!outsideFlow" class="relative flex justify-center text-sm">
                        <span class="px-1 text-gray-500"> Har du ikke en bruger? </span>
                        <button type="button" @click="goToRegister" class="a-link">Opret bruger</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import BouncingButton from "../Common/BouncingButton.vue";
    import { mapGetters } from "vuex";

    export default {
        name: "LoginStep",
        components: { BouncingButton },
        props: {
            outsideFlow: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                errors: null,
                generalError: null,
                partnerLogin: false,
                form: {
                    email: null,
                    password: null
                },
                bouncing: false,
                url: "/login-user"
            };
        },
        computed: {
            ...mapGetters(["chosenConcept", "periods", "chosenPeriod"]),
            invalidInputs() {
                return false;
                // @TODO - make disabled work
                // return !this.form.email || this.form.password;
            }
        },
        created() {
            const searchParams = new URLSearchParams(window.location.search);
            if (searchParams.has("partner") && searchParams.get("partner") === "true") {
                this.partnerLogin = true;
            }
            if (this.outsideFlow) {
                this.url = "/login";
            }
        },
        methods: {
            isValidEmail() {
                const emailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

                return emailRegex.test(this.form.email);
            },
            gotoPrev() {
                this.$router.back();
            },
            goToRegister() {
                this.$store.commit("setParameters", {
                    showPassword: true,
                    registrationStep: true,
                    fullRegistration: true,
                    isEditMode: false
                });
                this.$router.push("/registrer");
            },
            login() {
                if (this.isValidEmail(this.form.email)) {
                    this.bouncing = true;
                    axios
                        .post(this.url, this.form)
                        .then((response) => {
                            this.bouncing = false;
                            // Redirect appropriately
                            if (this.outsideFlow) {
                                window.location.href = response.data;
                            } else {
                                window.axios.defaults.headers.common["X-CSRF-TOKEN"] = response.data.token;

                                this.$store.commit("setAuthenticatedUser", response.data.user);

                                if (!this.$store.state.chosenConcept || !this.$store.state.chosenPeriod || !this.$store.state.startDate) {
                                    this.$router.push({ name: "concept-step" });
                                } else {
                                    this.$router.push({ name: "review-step" });
                                }
                            }
                        })
                        .catch((error) => {
                            if (error.response.status === 423) {
                                this.generalError = error.response.data.message;
                            } else {
                                this.errors = error.response.data.errors;
                            }
                            this.bouncing = false;
                        });
                }
            }
        }
    };
</script>

<style scoped></style>
