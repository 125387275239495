<template>
    <div class="flex flex-col mx-auto space-y-8">
        <profile v-if="authenticatedUser" class="mt-2" />
        <div class="flex flex-col">
            <div class="flex flex-col space-y-8">
                <span v-if="chosenConcept === 'easy'"
                      class="text-xl xs:text-2xl sm:text-3xl font-black">Nemt konceptet</span>
                <span v-if="chosenConcept === 'easier'" class="text-xl xs:text-2xl sm:text-3xl font-black">Nemmere konceptet</span>
                <span v-if="chosenConcept === 'easiest'" class="text-xl xs:text-2xl sm:text-3xl font-black">Nemmest konceptet</span>

                <div class="flex flex-col items-center justify-between gap-6">
                    <h5 class="app-h5">Du skal klikke på det uge nummer, hvor du ønsker din periode skal starte.
                        Perioden er på <span class="font-black text-topolis-blue-700">“8 uger”</span>.</h5>
                    <div class="flex items-center justify-between w-full">
                        <router-link to="/booking" class="flex items-center hover:no-underline">
                            <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 min-w-0 w-4"
                                 viewBox="0 0 24 24">
                                <g transform="translate(-684 -188)">
                                    <path d="M6.07,0,0,6.07l6.07,6.07" transform="translate(687.5 193.93)" fill="none"
                                          stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                          stroke-width="1.5" />
                                    <path d="M16.83,0H0" transform="translate(687.67 200)" fill="none"
                                          stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                          stroke-width="1.5" />
                                </g>
                            </svg>
                            <span class="ml-1 text-xs xxs:text-base">Tilbage</span>
                        </router-link>
                        <bouncing-button data-cy="btn-booking-continue" type="button"
                                         :bouncing="selectedWeeks.length !== 0" :disabled="selectedWeeks.length === 0"
                                         @click.native="gotoNext">Fortsæt
                        </bouncing-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex flex-col items-center space-y-4">
            <div class="py-8 bg-white rounded-2xl w-full space-y-4">
                <div class="w-full flex flex-col items-center justify-center px-8 space-y-2">
                    <h3 class="font-bold">Vælg en uge</h3>
                    <p v-if="!startWeek">Klik på en ledig uge nedenfor</p>
                    <p class="font-bold text-xs"><span class="font-black text-sm">*</span> Lukket: <span
                        class="font-normal">Du er sikret 8 uger; så i tilfælde af din periode falder sammen med ferielukning, forlænger vi manuelt din booking. </span>
                    </p>

                    <p v-if="startWeek && selectedWeeks && selectedWeeks.length">
                        Uge <span
                        class="font-bold text-amber-700">{{ chosenConcept === "easy" ? selectedWeeks[1].week_number : selectedWeeks[3].week_number
                        }}</span> er valgt.
                        <span v-if="chosenConcept === 'easy'">Nemt konceptet kræver <span
                            class="font-bold">en uge</span> til indlevering. Din periode vil derfor starte i uge </span>
                        <span v-if="chosenConcept === 'easier'">Nemmere konceptet kræver <span class="font-bold">tre uger</span> til henholdsvis indlevering, prissætning og klargøring. </span>
                        <span v-if="chosenConcept === 'easiest'">Nemmest konceptet kræver <span class="font-bold">tre uger</span> til henholdsvis afhentning, prissætning og klargøring. </span>
                    </p>
                    <div v-if="startWeek && selectedWeeks && selectedWeeks.length"
                         class="flex flex-col text-center text-center space-y-2 w-full">
                        <div class="flex items-center justify-center">
                            <div class="flex flex-col">
                                <div class="flex items-center justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                         class="flex-shrink-0 min-w-0 w-6 text-topolis-blue-700 mr-2"
                                         viewBox="0 0 24 24">
                                        <g id="calendar" transform="translate(-492 -188)">
                                            <path d="M0,0V3" transform="translate(500 190)" fill="none"
                                                  stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                                  stroke-width="1.5" />
                                            <path d="M0,0V3" transform="translate(508 190)" fill="none"
                                                  stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                                  stroke-width="1.5" />
                                            <path d="M0,0H17" transform="translate(495.5 197.09)" fill="none"
                                                  stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                                  stroke-width="1.5" />
                                            <path
                                                d="M18,5v8.5c0,3-1.5,5-5,5H5c-3.5,0-5-2-5-5V5C0,2,1.5,0,5,0h8C16.5,0,18,2,18,5Z"
                                                transform="translate(495 191.5)"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="1.5"
                                            />
                                            <path d="M.495.5H.5" transform="translate(507.2 201.2)" fill="none"
                                                  stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                                  stroke-width="2" />
                                            <path d="M.495.5H.5" transform="translate(507.2 204.2)" fill="none"
                                                  stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                                  stroke-width="2" />
                                            <path d="M.495.5H.5" transform="translate(503.501 201.2)" fill="none"
                                                  stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                                  stroke-width="2" />
                                            <path d="M.495.5H.5" transform="translate(503.501 204.2)" fill="none"
                                                  stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                                  stroke-width="2" />
                                            <path d="M.495.5H.5" transform="translate(499.8 201.2)" fill="none"
                                                  stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                                  stroke-width="2" />
                                            <path d="M.495.5H.5" transform="translate(499.8 204.2)" fill="none"
                                                  stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                                  stroke-width="2" />
                                        </g>
                                    </svg>
                                    <div>
                                        <span class="text-xs xxs:text-sm">Start</span>
                                        <span class="text-xs xxs:text-sm"
                                        > uge: <span class="text-topolis-blue-700 font-bold">{{ startWeek.week_number
                                            }}</span></span
                                        >
                                        <span class="ml-1 space-x-1"
                                        >(
                                            <span class="text-xs">Slut</span>
                                            <span
                                                class="text-xs xxs:text-sm text-topolis-blue-700 font-bold">uge: {{ endWeek.week_number
                                                }}</span>
                                            )
                                        </span>
                                    </div>
                                </div>
                                <span class="text-xxs">{{ startWeek.start_date_pretty + " " + (startWeek.year)
                                    }} - {{ endWeek.end_date_pretty + " " + (endWeek.year) }}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="p-1 py-4">
                    <div class="flex justify-center flex-wrap w-full min-h-[764px]">
                        <loading-spinner :loading="loading"></loading-spinner>
                        <div
                            v-for="(week, index) in weekCalendar"
                            v-if="!loading"
                            class="week-calendar-container"
                            :class="[
                                { 'week-disabled-container': week.disabled || disableFirstWeeks(index) || isManuallyClosedWeek(week, index) },
                                { 'week-closed-container': isClosedWeek(week, index) },
                                { 'week-selected-container': weekIsSelected(week) },
                                { 'week-buffer-container': weekIsBuffer(week) || (selectedWeeks.length && pickupWeek.week_number === week.week_number && selectedWeeks[selectedWeeks.length - 1].year === week.year) },
                                { 'first-buffer-week': chosenConcept === 'easy' ? calculateBuffer(week, 1) : calculateBuffer(week, 3) },
                            ]"
                            @click="selectWeek(week, index)"
                        >
                            <div class="flex flex-col items-center p-2 xxs:space-y-1 relative">
                                <span v-if="calculateBuffer(week, 0) && chosenConcept !== 'easiest'"
                                      class="hidden xxs:inline-flex absolute top-0 mx-auto text-[9px] font-bold text-white">
                                    <span class="underline decoration-2 decoration-[#53D4A2]">Indlevering</span>
                                </span>
                                <span v-if="calculateBuffer(week, 0) && chosenConcept === 'easiest'"
                                      class="hidden xxs:inline-flex absolute top-0 mx-auto text-[9px] font-bold text-white">
                                    <span class="underline decoration-2 decoration-[#53D4A2]">Afhentning</span>
                                </span>
                                <span v-if="calculateBuffer(week, 1) && chosenConcept !== 'easy'"
                                      class="hidden xxs:inline-flex absolute top-0 mx-auto text-[9px] font-bold text-white">
                                    <span class="underline decoration-2 decoration-[#53D4A2]">Prissætning</span>
                                </span>
                                <span v-if="calculateBuffer(week, 2) && chosenConcept !== 'easy'"
                                      class="hidden xxs:inline-flex absolute top-0 mx-auto text-[9px] font-bold text-white">
                                    <span class="underline decoration-2 decoration-[#53D4A2]">Klargøring</span>
                                </span>

                                <span v-if="chosenConcept === 'easy' && calculateBuffer(week, 1)"
                                      class="hidden xxs:inline-flex absolute top-0 mx-auto text-[9px] font-bold text-white">
                                    <span class="underline decoration-2 decoration-[#53D4A2]">Periode start</span>
                                </span>
                                <span v-if="chosenConcept === 'easy' && calculateBuffer(week, 8)"
                                      class="hidden xxs:inline-flex absolute top-0 mx-auto text-[9px] font-bold text-white">
                                    <span class="underline decoration-2 decoration-[#53D4A2]">Periode slut</span>
                                </span>

                                <span v-if="chosenConcept !== 'easy' && calculateBuffer(week, 3)"
                                      class="hidden xxs:inline-flex absolute top-0 mx-auto text-[9px] font-bold text-white">
                                    <span class="underline decoration-2 decoration-[#53D4A2]">Periode start</span>
                                </span>
                                <span v-if="chosenConcept !== 'easy' && calculateBuffer(week, 10)"
                                      class="hidden xxs:inline-flex absolute top-0 mx-auto text-[9px] font-bold text-white">
                                    <span class="underline decoration-2 decoration-[#53D4A2]">Periode slut</span>
                                </span>

                                <span v-if="selectedWeeks.length"
                                      class="hidden xxs:inline-flex absolute top-0 mx-auto text-[9px] font-bold text-white">
                                    <span
                                        v-if="pickupWeek.week_number === week.week_number && selectedWeeks[selectedWeeks.length - 1].year === week.year">
                                        <span class="underline decoration-2 decoration-[#53D4A2]">Afhentning</span>
                                    </span>
                                </span>

                                <span
                                    v-if="isClosedWeek(week, index)"
                                    class="week-is-closed"
                                    :class="{
                                        'week-is-closed-handle-text-white': (chosenConcept !== 'easy' && selectedWeeks.length && calculateBuffer(week, 10)) || (chosenConcept === 'easy' && calculateBuffer(week, 8)),
                                        'week-is-closed-handle-text-dark': weekIsBuffer(week) || (selectedWeeks.length && pickupWeek.week_number === week.week_number && selectedWeeks[selectedWeeks.length - 1].year === week.year),
                                    }"
                                >
                                    Lukket
                                </span>

                                <div class="flex items-center xxs:space-x-1 xxs:mb-1 leading-none">
                                    <span class="hidden xxs:inline-flex text-sm">uge</span>
                                    <span class="font-black">{{ week.week_number }}</span>
                                </div>
                                <div class="hidden xxs:flex flex-col relative">
                                    <div class="flex items-center leading-none text-[8px] week-calendar-dates">
                                        <span>{{ week.start_date_pretty }}<span class="px-0.5">-</span></span>
                                        <span>{{ week.end_date_pretty }}</span>
                                    </div>
                                    <span
                                        class="absolute -bottom-2.5 left-0 right-0 mx-auto leading-none text-[8px] week-calendar-dates">{{ week.year
                                        }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex flex-col space-y-8 w-full mb-8">
            <div class="w-full">
                <label for="note" class="block text-left text-sm font-bold text-topolis-blue-700">Evt. en note</label>
                <div class="mt-1">
                    <textarea id="note" v-model="note" rows="3" class="form-input"
                              placeholder="En kommentar til din periode..."></textarea>
                </div>
            </div>
        </div>

        <div class="flex flex-col space-y-8">
            <hr />
            <!--breadcrumbs-->
            <div class="flex justify-center items-center space-x-4 sm:space-x-8 relative mt-12 mb-8">
                <!--concept-->
                <router-link to="/booking" class="no-underline hover:underline decoration-blue-900">
                    <div v-if="chosenConcept === 'easy'" class="flex flex-row items-center justify-center space-x-2">
                        <img class="w-10" src="/assets/images/hanger-yellow.svg" alt="" />
                        <div class="flex flex-col items-start">
                            <p class="font-bold">Nemt</p>
                            <p v-if="chosenPeriod.concept === 'easy25'" class="text-xxs font-medium">25 varer</p>
                            <p v-else-if="chosenPeriod.concept === 'easy50'" class="text-xxs font-medium">50 varer</p>
                            <p v-else-if="chosenPeriod.concept === 'easy75'" class="text-xxs font-medium">75 varer</p>
                            <p v-else class="text-xxs font-medium">150 varer</p>
                        </div>
                    </div>
                    <div v-if="chosenConcept === 'easier'" class="flex flex-row items-center justify-center space-x-2">
                        <img class="w-10" src="/assets/images/hanger-purple.svg" alt="" />
                        <div class="flex flex-col items-start">
                            <p class="font-bold">Nemmere</p>
                            <p v-if="chosenPeriod.concept === 'easier50'" class="text-xxs font-medium">50 varer</p>
                            <p v-else class="text-xxs font-medium">100 varer</p>
                        </div>
                    </div>
                    <div v-if="chosenConcept === 'easiest'" class="flex flex-row items-center justify-center space-x-2">
                        <img class="w-10" src="/assets/images/hanger-blue.svg" alt="" />
                        <div class="flex flex-col items-start">
                            <p class="font-bold">Nemmest</p>
                            <p class="text-xxs font-medium">100 varer</p>
                        </div>
                    </div>
                </router-link>
                <!--period-->
                <router-link to="/booking" class="no-underline hover:underline decoration-blue-900">
                    <div class="flex flex-row items-center justify-center space-x-1">
                        <svg xmlns="http://www.w3.org/2000/svg"
                             class="flex-shrink-0 min-w-0 w-8 "
                             :class="{ 'text-topolis-yellow-700': chosenConcept === 'easy', 'text-topolis-purple-600': chosenConcept === 'easier', 'text-topolis-blue-700': chosenConcept === 'easiest' }"
                             viewBox="0 0 24 24">
                            <g transform="translate(-108 -188)">
                                <path
                                    d="M12.949,7.68A3.631,3.631,0,0,1,9.549,10h-5.9A3.635,3.635,0,0,1,1.2,3.67L5.249,0h2.71L12,3.67A3.635,3.635,0,0,1,12.949,7.68Z"
                                    transform="translate(113.401 200)" fill="currentColor" opacity="0.4" />
                                <path
                                    d="M4.32,1.36H.68A.68.68,0,1,1,.68,0H4.32A.681.681,0,0,1,5,.68.687.687,0,0,1,4.32,1.36Z"
                                    transform="translate(117.5 204.78)" fill="currentColor" />
                                <path
                                    d="M12.944,2.32A3.631,3.631,0,0,0,9.544,0h-5.9A3.63,3.63,0,0,0,1.2,6.33L5.244,10h2.71l4.04-3.67A3.635,3.635,0,0,0,12.944,2.32ZM8.414,5.23H4.774a.68.68,0,1,1,0-1.36h3.64a.68.68,0,1,1,0,1.36Z"
                                    transform="translate(113.406 190)"
                                    fill="currentColor"
                                />
                            </g>
                        </svg>
                        <div class="flex items-center justify-center">
                            <p class="font-black">{{ chosenPeriod.period_length }}</p>
                            <p class="font-light ml-1">uger</p>
                        </div>
                    </div>
                </router-link>
            </div>
            <div class="flex items-center justify-between pb-48 sm:pb-28">
                <div>
                    <router-link to="/booking" class="flex items-center hover:no-underline">
                        <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 min-w-0 w-4" viewBox="0 0 24 24">
                            <g transform="translate(-684 -188)">
                                <path d="M6.07,0,0,6.07l6.07,6.07" transform="translate(687.5 193.93)" fill="none"
                                      stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                      stroke-width="1.5" />
                                <path d="M16.83,0H0" transform="translate(687.67 200)" fill="none" stroke="currentColor"
                                      stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                            </g>
                        </svg>
                        <span class="ml-1 text-xs xxs:text-base">Tilbage</span>
                    </router-link>
                </div>
                <bouncing-button data-cy="btn-booking-continue" type="button" :bouncing="selectedWeeks.length !== 0"
                                 :disabled="selectedWeeks.length === 0" @click.native="gotoNext">Fortsæt
                </bouncing-button>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapGetters } from "vuex";
    import BouncingButton from "../Common/BouncingButton.vue";
    import Profile from "./Common/Profile.vue";
    import LoadingSpinner from "../../Pages/Common/LoadingSpinner.vue";

    export default {
        name: "CalendarStep",
        components: {
            LoadingSpinner,
            BouncingButton,
            Profile
        },
        data() {
            return {
                weekCalendar: null,
                loading: null
            };
        },
        computed: {
            ...mapGetters(["chosenPeriod", "chosenConcept", "authenticatedUser", "pickupWeek", "selectedWeeks", "startWeek", "endWeek", "startDate", "endDate", "formattedStartDate", "formattedEndDate"]),
            startDate: {
                get() {
                    return this.$store.state.startDate;
                },
                set(date) {
                    this.$store.commit("setStartDates", {
                        startDate: date,
                        formattedStartDate: this.formattedStartDate
                    });
                }
            },
            endDate: {
                get() {
                    return this.$store.state.endDate;
                },
                set(date) {
                    this.$store.commit("setEndDates", {
                        endDate: date,
                        formattedEndDate: this.formattedEndDate
                    });
                }
            },
            note: {
                get() {
                    return this.$store.state.note;
                },
                set(note) {
                    this.$store.commit("setNote", note);
                }
            }
        },
        watch: {
            chosenConcept() {
                this.getCalendarForYear();
            }
        },
        created() {
            this.getCalendarForYear();

            if (this.getNote) {
                this.form.note = this.getNote;
            }
            axios
                .get("authenticated-user")
                .then((response) => {
                    this.$store.commit("setAuthenticatedUser", response.data.user);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        methods: {
            weekIsSelected(week) {
                return this.selectedWeeks.some((w) => {
                    return w.week_number === week.week_number && w.year === week.year;
                });
            },
            calculateBuffer(week, number) {
                if (this.selectedWeeks.length) {
                    return this.selectedWeeks[number].week_number === week.week_number && this.selectedWeeks[number].year === week.year;
                }

                return false;
            },
            weekIsBuffer(week) {
                if (this.selectedWeeks.length) {
                    if (this.chosenConcept === "easy") {
                        return this.calculateBuffer(week, 0);
                    } else {
                        return this.calculateBuffer(week, 0) || this.calculateBuffer(week, 1) || this.calculateBuffer(week, 2);
                    }
                }

                return false;
            },
            isClosedWeek(week, index) {
                // Check if there is a closed week that matches the week and year.
                return week.closed_weeks.some(closedWeek => {
                    return closedWeek.week_number === week.week_number && closedWeek.week_year === week.year;
                });
            },
            isManuallyClosedWeek(week, index) {
                // Check if there is a manually closed week that matches the week and year.
                return week.manually_closed_weeks.some(closedWeek => {
                    return closedWeek.week_number === week.week_number && closedWeek.week_year === week.year;
                });
            },
            selectWeek(week, index) {
                if (week.disabled || this.isClosedWeek(week, index) || this.isManuallyClosedWeek(week, index) || this.disableFirstWeeks(index)) return;

                axios.get("/week-calendar/select-week?start_date=" + week.start_date + "&concept=" + this.chosenConcept).then((response) => {
                    this.$store.commit("setSelectedWeeks", response.data.weeks);

                    const startWeek = this.selectedWeeks.filter((week) => {
                        return !this.weekIsBuffer(week);
                    })[0];

                    this.$store.commit("setStartDates", {
                        startDate: week.start_date,
                        formattedStartDate: week.start_date_pretty
                    });
                    this.$store.commit("setEndDates", {
                        endDate: week.end_date,
                        formattedEndDate: week.end_date_pretty
                    });
                    this.$store.commit("setStartWeek", startWeek);
                    this.$store.commit("setEndWeek", this.selectedWeeks[this.selectedWeeks.length - 2]);
                });
            },
            getCalendarForYear() {
                this.loading = true;
                axios.get("/week-calendar?concept=" + this.chosenConcept).then((response) => {
                    this.weekCalendar = response.data.weekCalendar;
                    this.loading = false;
                });
            },
            disableFirstWeeks(index) {
                if (this.chosenConcept === "easy") {
                    if (index === 0 || index === 1) {
                        return true;
                    }
                }
                if (this.chosenConcept === "easier" || this.chosenConcept === "easiest") {
                    if (index === 0 || index === 1 || index === 2 || index === 3) {
                        return true;
                    }
                }
                return false;
            },
            gotoNext() {
                if (this.authenticatedUser) {
                    this.$router.push({ name: "review-step" });
                } else {
                    this.$router.push({ name: "form-step" });
                }
            },
            gotoPrev() {
                this.$router.back();
            }
        }
    };
</script>
