<template>
    <!-- Lightbox Modal -->
    <div v-if="show"
         class="fixed flex items-center justify-center top-0 left-0 right-0 bottom-0 z-50"
         style="background:rgba(0, 0, 0, 0.5);">
        <button class="absolute left-4 top-1/2 transform -translate-y-1/2 text-white text-5xl"
                @click="$emit('move', -1)">
            &lt;
        </button>

        <img :src="currentImage.real_image_path" :alt="currentImage.alt"
             class="object-center object-cover w-2/3 h-auto my-16">

        <button class="absolute right-4 top-1/2 transform -translate-y-1/2 text-white text-5xl"
                @click="$emit('move', 1)">
            &gt;
        </button>

        <button class="absolute top-4 right-4 text-white bg-gray-800 rounded px-2 py-1"
                @click="$emit('close')">
            Close
        </button>
    </div>
</template>

<script>
    export default {
        name: "Lightbox",
        props: ["show", "images", "currentImage"],
        openLightbox(image) {
            this.currentImage = image;
            this.showLightbox = true;
        }
    };
</script>