<template>
    <div class="p-2">
        <!-- Tab navigation -->
        <div class="flex items-center gap-4 p-2">
            <button @click="handleClick('all')"
                    class="flex items-center gap-1 border-b-2 border-transparent hover:border-gray-200 pb-1"
                    :class="{ '!border-gray-400': currentTab === 'all' }">
                <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 min-w-0 w-4 h-4 text-sky-500"
                     viewBox="0 0 24 24">
                    <g transform="translate(-364 -316)">
                        <path d="M0,10A9.978,9.978,0,0,1,10,0,12.3,12.3,0,0,1,20,5.56m0,0v-5m0,5H15.56"
                              transform="translate(366 318)" fill="none" stroke="currentColor" stroke-linecap="round"
                              stroke-linejoin="round" stroke-width="3" />
                        <path d="M0,4.44v5" transform="translate(367 328)" fill="none"
                              stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"
                              opacity="0.4" />
                        <path d="M18.89,0a10,10,0,0,1-10,10C3.37,10,0,4.44,0,4.44H4.52"
                              transform="translate(367 328)" fill="none" stroke="currentColor" stroke-linecap="round"
                              stroke-linejoin="round" stroke-width="3" opacity="0.6" />
                    </g>
                </svg>
                <span>{{ $translate("All") }}</span>
            </button>
            <button @click="handleClick('in')"
                    class="flex items-center gap-1 border-b-2 border-transparent hover:border-gray-200 pb-1"
                    :class="{ '!border-gray-400': currentTab === 'in' }">
                <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 min-w-0 w-4 h-4 text-gray-500"
                     viewBox="0 0 24 24">
                    <g transform="translate(-364 -316)">
                        <path d="M0,10A9.978,9.978,0,0,1,10,0,12.3,12.3,0,0,1,20,5.56m0,0v-5m0,5H15.56"
                              transform="translate(366 318)" fill="none" stroke="#22c55e" stroke-linecap="round"
                              stroke-linejoin="round" stroke-width="3" />
                        <path d="M0,4.44v5" transform="translate(367 328)" fill="none"
                              stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"
                              opacity="0.4" />
                        <path d="M18.89,0a10,10,0,0,1-10,10C3.37,10,0,4.44,0,4.44H4.52"
                              transform="translate(367 328)" fill="none" stroke="currentColor" stroke-linecap="round"
                              stroke-linejoin="round" stroke-width="3" opacity="0.6" />
                    </g>
                </svg>
                <span>{{ $translate("På vej ind") }}</span>
            </button>
            <button @click="handleClick('out')"
                    class="flex items-center gap-1 border-b-2 border-transparent hover:border-gray-200 pb-1"
                    :class="{ '!border-gray-400': currentTab === 'out' }">
                <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 min-w-0 w-4 h-4 text-gray-500"
                     viewBox="0 0 24 24">
                    <g transform="translate(-364 -316)">
                        <path d="M0,10A9.978,9.978,0,0,1,10,0,12.3,12.3,0,0,1,20,5.56m0,0v-5m0,5H15.56"
                              transform="translate(366 318)" fill="none" stroke="currentColor" stroke-linecap="round"
                              stroke-linejoin="round" stroke-width="3" opacity="0.6" />
                        <path d="M0,4.44v5" transform="translate(367 328)" fill="none"
                              stroke="#ef4444" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"
                              opacity="0.4" />
                        <path d="M18.89,0a10,10,0,0,1-10,10C3.37,10,0,4.44,0,4.44H4.52"
                              transform="translate(367 328)" fill="none" stroke="#ef4444" stroke-linecap="round"
                              stroke-linejoin="round" stroke-width="3" />
                    </g>
                </svg>
                <span>{{ $translate("Udgående") }}</span>
            </button>
            <button @click="handleClick('action')"
                    class="flex items-center gap-1 border-b-2 border-transparent hover:border-gray-200 pb-1"
                    :class="{ '!border-gray-400': currentTab === 'action' }">
                <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 min-w-0 w-4 h-4 text-orange-300"
                     viewBox="0 0 24 24">
                    <g transform="translate(-364 -316)">
                        <path d="M0,10A9.978,9.978,0,0,1,10,0,12.3,12.3,0,0,1,20,5.56m0,0v-5m0,5H15.56"
                              transform="translate(366 318)" fill="none" stroke="currentColor" stroke-linecap="round"
                              stroke-linejoin="round" stroke-width="3" opacity="0.6" />
                        <path d="M0,4.44v5" transform="translate(367 328)" fill="none"
                              stroke="#fb923c" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"
                              opacity="0.4" />
                        <path d="M18.89,0a10,10,0,0,1-10,10C3.37,10,0,4.44,0,4.44H4.52"
                              transform="translate(367 328)" fill="none" stroke="#fb923c" stroke-linecap="round"
                              stroke-linejoin="round" stroke-width="3" />
                    </g>
                </svg>
                <span>{{ $translate("Needs action") }}</span>
            </button>
        </div>

        <DataTable v-model:filters="filters" v-model:selection="selectedTransfers" :value="filteredTransfers"
                   :rows="20" dataKey="id" :rowHover="true" filterDisplay="menu" removableSort scrollable
                   :scrollHeight="height + 'px'"
                   :globalFilterFields="['store_id', 'customer.name']">
            <template #header>
                <div class="flex items-center justify-end gap-2">
                    <Button v-if="filters['global'].value" class="!text-sm" type="button" icon="pi pi-filter-slash"
                            label="Ryd"
                            outlined @click="clearFilter()" />
                    <IconField>
                        <InputIcon class="!top-[45%]">
                            <i class="pi pi-search" />
                        </InputIcon>
                        <InputText v-model="filters['global'].value" class="!text-sm w-[400px]"
                                   placeholder="Søg efter butiksnr., eller kundenavn" />
                    </IconField>
                </div>
            </template>
            <template #empty>No bookings found.</template>
            <!--            <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>-->
            <!--            <template #paginatorstart>-->
            <!--                <Button type="button" icon="pi pi-refresh" text />-->
            <!--            </template>-->
            <template #loading>
                <div class="flex items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
                    <Skeleton width="60%" height="1rem" />
                </div>
            </template>
            <Column field="store_id" header="Butiksnr." sortable sortField="store_id" filterField="store_id"
                    class="!text-sm">
                <template #loading>
                    <div class="flex items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
                        <Skeleton width="60%" height="1rem" />
                    </div>
                </template>
                <template #body="transfer">
                    <div class="flex items-center">
                        <tooltip>
                            <template v-slot:triggerText>
                                <user-store-id :booking="transfer.data" />
                            </template>
                            <span class="font-light">
                                <span class="flex items-center gap-1">Butiksnr.:
                                    <user-store-id :booking="transfer.data" :only-number="true" />
                                    for bookingen.
                                </span>
                            </span>
                        </tooltip>
                    </div>
                </template>
            </Column>
            <Column field="customer" header="Name" sortable sortField="customer.name" filterField="customer.name"
                    style="min-width: 14rem" class="!text-sm">
                <template #loading>
                    <div class="flex items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
                        <Skeleton width="60%" height="1rem" />
                    </div>
                </template>
                <template #body="transfer">
                    <div class="flex items-center">
                        <div class="hidden 2xl:flex">
                            <user-avatar :user="transfer.data.customer" size="medium"></user-avatar>
                        </div>
                        <div class="2xl:ml-4">
                            <div class="flex items-center gap-1">
                                <tooltip>
                                    <template v-slot:triggerText>
                                        <user-concept :booking="transfer.data" :show-name="false" />
                                    </template>
                                    <span class="font-light">
                                        <span>Booking konceptet er <span
                                            class="font-bold">{{ $translate(transfer.data.concept)
                                            }}</span></span>
                                    </span>
                                </tooltip>
                                <div class="text-sm font-medium text-gray-900 capitalize">
                                    {{ transfer.data.customer.name }}
                                </div>
                                <rate-user v-if="userRole === 'admin'" :user="transfer.data.customer"
                                           @rate-user="payload => $emit('rate-user', payload)"></rate-user>
                            </div>
                            <div class="text-xs text-gray-500 mt-1">
                                <a :href="`mailto:${transfer.data.customer.email}`"
                                   class="underline">{{ transfer.data.customer.email
                                    }}</a>
                            </div>
                        </div>
                    </div>
                </template>
                <template #filter="{ filterModel }">
                    <InputText v-model="filterModel.value" type="text"
                               :placeholder="$translate('Søg efter kundenavn')" />
                </template>
            </Column>
            <Column field="start" header="Start" sortable sortField="start_date" filterField="start_date"
                    class="!text-sm">
                <template #loading>
                    <div class="flex items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
                        <Skeleton width="60%" height="1rem" />
                    </div>
                </template>
                <template #body="transfer">
                    <div class="flex items-center gap-1">
                        <div>
                            <div v-if="transfer.data.concept !== 'business'">
                                <tooltip>
                                    <template v-slot:triggerText>
                                        <div
                                            class="topolis-badge topolis-border-badge topolis-small-badge topolis-pearl-badge">
                                            {{ getIsoWeek(transfer.data.start_date) }}
                                        </div>
                                    </template>
                                    <div>Booking starter i uge {{ getIsoWeek(transfer.data.start_date) }}
                                        (d. {{ formatDateLL(transfer.data.start_date) }})
                                    </div>
                                </tooltip>
                            </div>
                            <div v-else>
                                <tooltip>
                                    <template v-slot:triggerText>
                                        <div
                                            class="topolis-badge topolis-border-badge topolis-small-badge topolis-pearl-badge"
                                            title="Virksomhedsbooking">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 min-w-0 w-4"
                                                 viewBox="0 0 24 24">
                                                <g transform="translate(-108 -188)">
                                                    <path
                                                        d="M5.751,16h8c4.02,0,4.74-1.61,4.95-3.57l.75-8c.27-2.44-.43-4.43-4.7-4.43h-10C.481,0-.219,1.99.051,4.43l.75,8C1.011,14.39,1.731,16,5.751,16Z"
                                                        transform="translate(110.249 194)" fill="none"
                                                        stroke="currentColor"
                                                        stroke-linecap="round" stroke-linejoin="round"
                                                        stroke-width="1.5" />
                                                    <path d="M0,4V3.2C0,1.43,0,0,3.2,0H4.8C8,0,8,1.43,8,3.2V4"
                                                          transform="translate(116 190)" fill="none"
                                                          stroke="currentColor"
                                                          stroke-linecap="round" stroke-linejoin="round"
                                                          stroke-width="1.5" />
                                                    <path
                                                        d="M4,1V2.02C4,3.11,3.99,4,2,4S0,3.12,0,2.03V1C0,0,0,0,1,0H3C4,0,4,0,4,1Z"
                                                        transform="translate(118 200)" fill="none" stroke="currentColor"
                                                        stroke-linecap="round" stroke-linejoin="round"
                                                        stroke-width="1.5" />
                                                    <path d="M7.65,0A16.484,16.484,0,0,1,0,3.02"
                                                          transform="translate(122 199)"
                                                          fill="none" stroke="currentColor" stroke-linecap="round"
                                                          stroke-linejoin="round" stroke-width="1.5" />
                                                    <path d="M0,0A16.283,16.283,0,0,0,7.38,2.76"
                                                          transform="translate(110.62 199.27)"
                                                          fill="none" stroke="currentColor" stroke-linecap="round"
                                                          stroke-linejoin="round" stroke-width="1.5" />
                                                </g>
                                            </svg>
                                        </div>
                                    </template>
                                    <div>Virksomhedsbooking er aktiv
                                    </div>
                                </tooltip>
                            </div>
                        </div>
                        <div class="text-sm text-gray-500">
                            <tooltip>
                                <template v-slot:triggerText>
                                    <div class="flex items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 min-w-0 w-5 h-5"
                                             :class="getGradientColor(transfer.data.customer.booking_count)"
                                             viewBox="0 0 24 24">
                                            <g transform="translate(-428 -316)">
                                                <path
                                                    d="M7.276,0V16.66l-.8.91c-1.11,1.26-2.02.92-2.02-.76V9.61H1.366c-1.4,0-1.79-.86-.86-1.91Z"
                                                    transform="translate(432.724 319.67)" fill="fillCurrent" />
                                                <path
                                                    d="M6.77,10.631,0,18.331V1.671L.8.761c1.11-1.26,2.02-.92,2.02.76v7.2H5.91C7.31,8.721,7.7,9.581,6.77,10.631Z"
                                                    transform="translate(440 317.999)" fill="fillCurrent"
                                                    opacity="0.6" />
                                            </g>
                                        </svg>
                                        <div>{{ transfer.data.customer.booking_count }}
                                        </div>
                                    </div>
                                </template>
                                <span class="font-light">
                                    <span v-if="transfer.data.customer.booking_count > 0">
                                        Brugeren har booket:
                                        <span class="font-bold">{{ transfer.data.customer.booking_count }}
                                            gang(e)</span>
                                    </span>
                                    <span v-else class="font-bold">
                                        Er brugeren første booking!
                                    </span>
                                </span>

                            </tooltip>
                        </div>
                    </div>
                </template>
                <!--                <template #filter="{ filterModel }">-->
                <!--                    <DatePicker v-model="filterModel.value" dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" />-->
                <!--                </template>-->
            </Column>
            <Column field="slut" header="Slut" sortable sortField="end_date" filterField="end_date" class="!text-sm">
                <template #loading>
                    <div class="flex items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
                        <Skeleton width="60%" height="1rem" />
                    </div>
                </template>
                <template #body="transfer">
                    <div class="flex items-center gap-1">
                        <div>
                            <div v-if="transfer.data.concept !== 'business'">
                                <tooltip>
                                    <template v-slot:triggerText>
                                        <week-color :end-date="transfer.data.end_date" />
                                    </template>
                                    <div>Booking slutter i uge {{ getIsoWeek(transfer.data.end_date) }}
                                        (d. {{ formatDateLL(transfer.data.end_date) }})
                                    </div>
                                </tooltip>
                            </div>
                            <div v-else>
                                <tooltip>
                                    <template v-slot:triggerText>
                                        <div
                                            class="topolis-badge topolis-border-badge topolis-small-badge topolis-pearl-badge"
                                            title="Virksomhedsbooking">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 min-w-0 w-4"
                                                 viewBox="0 0 24 24">
                                                <g transform="translate(-108 -188)">
                                                    <path
                                                        d="M5.751,16h8c4.02,0,4.74-1.61,4.95-3.57l.75-8c.27-2.44-.43-4.43-4.7-4.43h-10C.481,0-.219,1.99.051,4.43l.75,8C1.011,14.39,1.731,16,5.751,16Z"
                                                        transform="translate(110.249 194)" fill="none"
                                                        stroke="currentColor"
                                                        stroke-linecap="round" stroke-linejoin="round"
                                                        stroke-width="1.5" />
                                                    <path d="M0,4V3.2C0,1.43,0,0,3.2,0H4.8C8,0,8,1.43,8,3.2V4"
                                                          transform="translate(116 190)" fill="none"
                                                          stroke="currentColor"
                                                          stroke-linecap="round" stroke-linejoin="round"
                                                          stroke-width="1.5" />
                                                    <path
                                                        d="M4,1V2.02C4,3.11,3.99,4,2,4S0,3.12,0,2.03V1C0,0,0,0,1,0H3C4,0,4,0,4,1Z"
                                                        transform="translate(118 200)" fill="none" stroke="currentColor"
                                                        stroke-linecap="round" stroke-linejoin="round"
                                                        stroke-width="1.5" />
                                                    <path d="M7.65,0A16.484,16.484,0,0,1,0,3.02"
                                                          transform="translate(122 199)"
                                                          fill="none" stroke="currentColor" stroke-linecap="round"
                                                          stroke-linejoin="round" stroke-width="1.5" />
                                                    <path d="M0,0A16.283,16.283,0,0,0,7.38,2.76"
                                                          transform="translate(110.62 199.27)"
                                                          fill="none" stroke="currentColor" stroke-linecap="round"
                                                          stroke-linejoin="round" stroke-width="1.5" />
                                                </g>
                                            </svg>
                                        </div>
                                    </template>
                                    <div>Virksomhedsbooking er aktiv
                                    </div>
                                </tooltip>
                            </div>
                        </div>
                        <div class="text-sm text-gray-500"
                             v-if="transfer.data.sorting_storage_with_extension_count > 0">
                            <tooltip>
                                <template v-slot:triggerText>
                                    <div class="flex items-center gap-1">
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             :class="getGradientExtendColor(transfer.data.sorting_storage_with_extension_count)"
                                             class="flex-shrink-0 min-w-0 w-5 h-5" viewBox="0 0 24 24">
                                            <g transform="translate(-364 -508)">
                                                <path
                                                    d="M13.3,21.17a.753.753,0,0,1-.2-1.48,9.253,9.253,0,0,0-2.36-18.2,11.3,11.3,0,0,0-8.5,4.06H5.18a.755.755,0,0,1,.01,1.51H.76a.761.761,0,0,1-.21-.03.9.9,0,0,1-.24-.12A.659.659,0,0,1,.1,6.68.867.867,0,0,1,0,6.37V1.75A.755.755,0,0,1,.75,1a.755.755,0,0,1,.75.75V4.14A12.558,12.558,0,0,1,10.75,0a10.753,10.753,0,0,1,2.74,21.15A1.225,1.225,0,0,1,13.3,21.17Z"
                                                    transform="translate(365.25 509.25)" fill="fillCurrent"
                                                    opacity="0.4" />
                                                <path
                                                    d="M10.04,11.48a.17.17,0,0,1-.07-.01,10.466,10.466,0,0,1-3.12-.7.761.761,0,0,1-.48-.71.75.75,0,0,1,1.02-.69,9.221,9.221,0,0,0,2.69.61h.01a.749.749,0,0,1,.7.76v.04A.761.761,0,0,1,10.04,11.48ZM4.53,9.33a.743.743,0,0,1-.47-.16A10.822,10.822,0,0,1,1.88,6.82a.729.729,0,0,1-.14-.42.738.738,0,0,1,.33-.62.76.76,0,0,1,1.04.19A9.076,9.076,0,0,0,5,7.99a.755.755,0,0,1,.28.58.771.771,0,0,1-.16.48A.744.744,0,0,1,4.53,9.33ZM1.19,4.45a.732.732,0,0,1-.71-.52A10.705,10.705,0,0,1,0,.75.755.755,0,0,1,.75,0,.755.755,0,0,1,1.5.75a9.308,9.308,0,0,0,.41,2.73.852.852,0,0,1,.03.23.741.741,0,0,1-.52.71A.908.908,0,0,1,1.19,4.45Z"
                                                    transform="translate(365.25 519.25)" fill="fillCurrent" />
                                                <path d="M8,4A4,4,0,1,1,4,0,4,4,0,0,1,8,4Z"
                                                      transform="translate(372 516)"
                                                      fill="fillCurrent" opacity="0.4" />
                                            </g>
                                        </svg>
                                        <div>{{ transfer.data.sorting_storage_with_extension_count }}</div>
                                    </div>
                                </template>
                                <span class="font-light">Brugeren har forlænget bookingen: <span
                                    class="font-bold">{{ transfer.data.sorting_storage_with_extension_count
                                    }} gang(e)</span></span>
                            </tooltip>
                        </div>
                    </div>
                </template>
            </Column>
            <Column field="status" header="Status" sortable sortField="status" filterField="status" class="!text-sm">
                <template #loading>
                    <div class="flex items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
                        <Skeleton width="60%" height="1rem" />
                    </div>
                </template>
                <template #body="transfer">
                    <div class="flex items-center mb-1">
                        <tooltip>
                            <template v-slot:triggerText>
                                <div class="topolis-tag" :class="tagClass(transfer.data.status)">
                                    {{ $translate(transfer.data.status) }}
                                </div>
                            </template>
                            <span class="font-light">Booking status er: <span
                                class="font-bold">{{ $translate(transfer.data.status)
                                }}</span></span>
                        </tooltip>
                    </div>
                </template>
                <!--                <template #filter="{ filterModel }">-->
                <!--                    <PrimeMultiSelect v-model="selectedStatuses"-->
                <!--                                      :options="statuses" optionLabel="name" multiple-->
                <!--                                      placeholder="Select One" title="status" display="chip">-->
                <!--                        <template #chip="slotProps">-->
                <!--                            <div class="topolis-tag" :class="tagClass(slotProps.value)">-->
                <!--                                {{ $translate(slotProps.value) }}-->
                <!--                            </div>-->
                <!--                        </template>-->
                <!--                    </PrimeMultiSelect>-->
                <!--                </template>-->
            </Column>
            <Column field="products_sold_today" header="Antal" sortable sortField="products_sold_today"
                    filterField="products_sold_today"
                    class="!text-sm">
                <template #loading>
                    <div class="flex items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
                        <Skeleton width="60%" height="1rem" />
                    </div>
                </template>
                <template #body="transfer">
                    <div class="flex flex-col">
                        <tooltip>
                            <template v-slot:triggerText>
                                <div class="text-sm text-gray-600 font-medium"
                                     :class="{ 'text-green-600' : transfer.data.products_sold_today > 0 }">
                                    {{ transfer.data.products_sold_today ?? 0 }} <span class="text-xs">vare(r)</span>
                                </div>
                            </template>
                            <span class="font-light"><span class="font-bold">{{ transfer.data.products_sold_today
                                }}</span> vare(r) er solgt i dag på
                                bookingen</span>
                        </tooltip>
                        <tooltip>
                            <template v-slot:triggerText>
                                <div class="text-xs text-gray-500">
                                    <span class="font-bold text-sm">{{ transfer.data.products_sold ?? 0 }}</span> |
                                    {{ transfer.data.all_user_products ?? 0
                                    }} <span
                                    class="text-xs">vare(r)</span>
                                </div>
                            </template>
                            <span class="font-light"><span class="font-bold">{{ transfer.data.products_sold }}</span>
                                vare(r) ud af
                                {{ transfer.data.all_user_products }} er solgt i alt på
                                bookingen. <span v-if="transfer.data.products_sold > 0"><span
                                    class="italic font-bold">{{ (transfer.data.products_sold /
                                    transfer.data.all_user_products * 100).toFixed(2)
                                    }}%</span> solgt</span></span>
                        </tooltip>
                    </div>
                </template>
            </Column>
            <Column field="salesAdjustedForCommissionAndDiscount" header="Salg" sortable
                    sortField="salesAdjustedForCommissionAndDiscount"
                    filterField="salesAdjustedForCommissionAndDiscount"
                    class="!text-sm">
                <template #loading>
                    <div class="flex items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
                        <Skeleton width="60%" height="1rem" />
                    </div>
                </template>
                <template #body="transfer">
                    <div class="flex flex-col">
                        <tooltip>
                            <template v-slot:triggerText>
                                <div class="text-sm text-gray-600 font-medium"
                                     :class="{ 'text-green-600' : parseFloat(transfer.data.todayWithCommission) > 0 }">
                                    {{ transfer.data.todayWithCommission ?? "0,00 kr." }}
                                </div>
                            </template>
                            <span class="font-light"><span class="font-bold">{{ transfer.data.todayWithCommission ?? 0
                                }}</span> er solgt for i dag på
                                bookingen</span>
                        </tooltip>
                        <tooltip>
                            <template v-slot:triggerText>
                                <div class="text-xs text-gray-500">
                                    <span
                                        class="font-bold text-sm">{{ transfer.data.salesAdjustedForCommissionAndDiscount ?? "0,00 kr."
                                        }}</span>
                                </div>
                            </template>
                            <span class="font-light"><span
                                class="font-bold">{{ transfer.data.salesAdjustedForCommissionAndDiscount }}</span>
                                er blevet solgt i alt på
                                bookingen.</span>
                        </tooltip>
                    </div>
                </template>
            </Column>
            <!--            <Column field="task" header="Task" filterField="status" class="!text-sm">-->
            <!--                <template #loading>-->
            <!--                    <div class="flex items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">-->
            <!--                        <Skeleton width="60%" height="1rem" />-->
            <!--                    </div>-->
            <!--                </template>-->
            <!--                <template #body="transfer">-->
            <!--                    <user-task :item="transfer.data" :task="transfer.data.tasks[0]" type="booking" :locale="locale"-->
            <!--                               @mark-task-as-done="payload => $emit('mark-task-as-done', payload)"></user-task>-->
            <!--                </template>-->
            <!--                <template #filter="{ filterModel }">-->
            <!--                    <PrimeMultiSelect v-model="filterModel.value" :options="admins" optionLabel="name"-->
            <!--                                      placeholder="Any" title="admin">-->
            <!--                        <template #option="slotProps">-->
            <!--                            <div class="flex items-center gap-2">-->
            <!--                                <user-avatar :dashboard="false" :user="slotProps.option"-->
            <!--                                             size="small">-->
            <!--                                </user-avatar>-->
            <!--                                <div class="w-3/4">-->
            <!--                                    <div class="text-xs truncate">-->
            <!--                                        {{ slotProps.option.name }}-->
            <!--                                    </div>-->
            <!--                                </div>-->
            <!--                            </div>-->
            <!--                        </template>-->
            <!--                    </PrimeMultiSelect>-->
            <!--                </template>-->
            <!--            </Column>-->
            <Column field="admin" header="Assignee" filterField="admin.name" class="!text-sm">
                <template #loading>
                    <div class="flex items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
                        <Skeleton width="60%" height="1rem" />
                    </div>
                </template>
                <template #body="transfer">
                    <assign-user :item="transfer.data" type="booking" :advanced="false" :admins="admins"
                                 @assign-user="payload => $emit('assign-user', payload)" />
                </template>
                <!--                <template #filter="{ filterModel }">-->
                <!--                    <PrimeMultiSelect v-model="selectedAdmins" :options="admins" optionLabel="name"-->
                <!--                                      placeholder="Vælg admin" title="admin" filter display="chip">-->
                <!--                        <template #option="slotProps">-->
                <!--                            <div class="flex items-center gap-2">-->
                <!--                                <user-avatar :dashboard="false" :user="slotProps.option"-->
                <!--                                             size="small">-->
                <!--                                </user-avatar>-->
                <!--                                <div class="w-3/4">-->
                <!--                                    <div class="text-xs truncate">-->
                <!--                                        {{ slotProps.option.name }}-->
                <!--                                    </div>-->
                <!--                                </div>-->
                <!--                            </div>-->
                <!--                        </template>-->
                <!--                    </PrimeMultiSelect>-->
                <!--                </template>-->
            </Column>
            <Column class="!text-sm">
                <template #loading>
                    <div class="flex items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
                        <Skeleton width="60%" height="1rem" />
                    </div>
                </template>
                <template #body="transfer">
                    <div class="flex items-center justify-end gap-3">
                        <a :href="`/admin/produkter/${transfer.data.uuid}/opret`"
                           class="text-indigo-600 hover:text-indigo-900"
                           title="Opret booking produkt">
                            <span class="sr-only">Create booking product</span>
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 class="flex-shrink-0 min-w-0 w-6 h-6 text-office-black hover:text-office-black/[0.7]"
                                 viewBox="0 0 24 24">
                                <g transform="translate(-300 -188)">
                                    <path
                                        d="M8,4a3.921,3.921,0,0,1-.58,2.06A3.593,3.593,0,0,1,6.63,7,3.96,3.96,0,0,1,.97,6.59C.95,6.56.92,6.54.9,6.51a2.827,2.827,0,0,1-.32-.45A3.921,3.921,0,0,1,0,4,4,4,0,0,1,4,0,3.921,3.921,0,0,1,6.6.97a2.423,2.423,0,0,1,.33.31A4.011,4.011,0,0,1,8,4Z"
                                        transform="translate(315 202)" fill="none" stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-linejoin="round" stroke-width="1.5" />
                                    <path d="M2.98,0H0" transform="translate(317.51 205.98)" fill="none"
                                          stroke="currentColor"
                                          stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                    <path d="M0,0V2.99" transform="translate(319 204.52)" fill="none"
                                          stroke="currentColor"
                                          stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                    <g opacity="0.4">
                                        <path d="M0,0,8.83,5.11,17.6.03" transform="translate(303.17 195.44)"
                                              fill="none"
                                              stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                              stroke-width="1.5" />
                                        <path d="M0,9.07V0" transform="translate(312 200.54)" fill="none"
                                              stroke="currentColor"
                                              stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                        <path
                                            d="M19.22,7.17v5.66a.66.66,0,0,1-.01.14,3.921,3.921,0,0,0-2.6-.97,4,4,0,0,0-4,4,3.921,3.921,0,0,0,.58,2.06,2.827,2.827,0,0,0,.32.45l-1.83,1.01a4.7,4.7,0,0,1-4.14,0L2.2,16.56A4.719,4.719,0,0,1,0,12.83V7.17A4.719,4.719,0,0,1,2.2,3.44L7.54.48a4.7,4.7,0,0,1,4.14,0l5.34,2.96A4.719,4.719,0,0,1,19.22,7.17Z"
                                            transform="translate(302.39 190)" fill="none" stroke="currentColor"
                                            stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                    </g>
                                </g>
                            </svg>
                        </a>
                        <a :href="`/admin/bookinger/${transfer.data.uuid}/produkter`"
                           class="text-indigo-600 hover:text-indigo-900"
                           title="Se booking produkter">
                            <span class="sr-only">Go to Booking products</span>
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 class="flex-shrink-0 min-w-0 w-6 h-6 text-office-black hover:text-office-black/[0.7]"
                                 viewBox="0 0 24 24">
                                <g transform="translate(-108 -188)">
                                    <g opacity="0.4">
                                        <path d="M0,0,8.83,5.11,17.6.03" transform="translate(111.17 195.44)"
                                              fill="none"
                                              stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                              stroke-width="1.5" />
                                        <path d="M0,9.07V0" transform="translate(120 200.54)" fill="none"
                                              stroke="currentColor"
                                              stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                    </g>
                                    <path
                                        d="M7.54.481,2.2,3.441A4.719,4.719,0,0,0,0,7.171v5.65a4.719,4.719,0,0,0,2.2,3.73l5.34,2.97a4.792,4.792,0,0,0,4.15,0l5.34-2.97a4.719,4.719,0,0,0,2.2-3.73V7.171a4.719,4.719,0,0,0-2.2-3.73L11.69.471A4.758,4.758,0,0,0,7.54.481Z"
                                        transform="translate(110.39 189.999)" fill="none" stroke="currentColor"
                                        stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                </g>
                            </svg>
                        </a>
                        <a :href="`/admin/bookinger/${transfer.data.uuid}`"
                           class="text-indigo-600 hover:text-indigo-900"
                           title="Gå til booking">
                            <span class="sr-only">Go to booking</span>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                 stroke="currentColor" opacity="0.6"
                                 class="flex-shrink-0 min-w-0 w-5 h-5 text-office-black hover:text-office-black/[0.7]">
                                <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                            </svg>
                        </a>
                    </div>
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
    import axios from "axios";
    import LoadingSpinner from "./Common/LoadingSpinner.vue";
    import UserAvatar from "./UserAvatar.vue";
    import RateUser from "./RateUser.vue";
    import Tooltip from "./Tooltip.vue";
    import UserStoreId from "./UserStoreId.vue";
    import UserConcept from "./UserConcept.vue";

    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import ColumnGroup from "primevue/columngroup";   // optional
    import Row from "primevue/row";
    import { FilterMatchMode, FilterOperator } from "@primevue/core/api";
    import IconField from "primevue/iconfield";
    import InputText from "primevue/inputtext";
    import Button from "primevue/button";
    import InputIcon from "primevue/inputicon";
    import DatePicker from "primevue/datepicker";
    import MultiSelect from "@vueform/multiselect";
    import Select from "primevue/select";
    import Tag from "primevue/tag";
    import Skeleton from "primevue/skeleton";

    import WeekColor from "./Common/WeekColor.vue";
    import UserTask from "./UserTask.vue";
    import AssignUser from "./AssignUser.vue";                   // optional


    export default {
        name: "TransferCentre",
        components: {
            AssignUser,
            UserTask,
            WeekColor,
            LoadingSpinner,
            UserAvatar,
            RateUser,
            Tooltip,
            UserConcept,
            UserStoreId,
            DataTable,
            Column,
            ColumnGroup,
            Row,
            IconField,
            InputIcon,
            InputText,
            Button,
            FilterMatchMode,
            FilterOperator,
            DatePicker,
            PrimeMultiSelect: MultiSelect,
            Select,
            Tag,
            Skeleton
        },
        props: {
            userRole: {},
            locale: {},
            height: {}
        },
        data() {
            return {
                currentTab: "all",
                visibleGroups: {},
                transfersIn: [], // Initialize as empty arrays
                transfersOut: [],
                transfersNew: [],
                transfersNeedsAction: [],
                admins: [],
                isLoading: false,
                selectedTransfers: null,
                selectedStatuses: null,
                selectedAdmins: null,
                filters: null,
                statuses: ["approved", "completed", "pending", "active", "paid", "declined", "refunded", "stalled", "discontinued", "cancelled", "finished"]
            };
        },
        computed: {
            filteredTransfers() {
                let transfersNew = [...this.transfersNew];
                let transfersNeedsAction = [...this.transfersNeedsAction];

                // Sort transfersNew by remainingDays
                transfersNew.sort((a, b) => a.remainingDays - b.remainingDays);

                // Sort transfersNeedsAction by remainingDays
                transfersNeedsAction.sort((a, b) => a.remainingDays - b.remainingDays);

                if (this.currentTab === "all") {
                    // Add all sorted arrays in the order you want, followed by the rest of the arrays
                    transfersNew.push(...transfersNeedsAction, ...this.transfersIn, ...this.transfersOut);
                    return transfersNew;
                } else if (this.currentTab === "in") {
                    transfersNew.push(...this.transfersIn);
                    return transfersNew;
                } else if (this.currentTab === "out") {
                    return this.transfersOut;
                } else if (this.currentTab === "action") {
                    return transfersNeedsAction;
                }

                return [];
            }
        },
        created() {
            this.fetchTransfers();
            this.initFilters();
        },
        methods: {
            handleClick(tab) {
                this.currentTab = tab;
            },
            async fetchTransfers(payload) {
                this.isLoading = true;
                try {
                    // Make a GET request to your new endpoint
                    const response = await axios.get("/admin/bookings/get-bookings-transfer-centre");

                    // The object inside the data property will look like: { startingBookings: [...], endingBookings: [...] }
                    // Assigning the response data to the respective transfersIn and transfersOut.
                    this.transfersIn = response.data.startingBookings.map(this.calculateRemainingDays);
                    this.transfersOut = response.data.endingBookings.map(this.calculateRemainingDays);
                    this.transfersNew = response.data.newBookings.map(this.calculateRemainingDays);
                    this.transfersNeedsAction = response.data.takeActionBookings.map(this.calculateRemainingDays);
                    this.admins = response.data.admins;

                    if (payload) {
                        this.emitter.emit("show-toast", {
                            message: payload.message,
                            type: payload.success ? "success" : "error"
                        });
                    }

                    this.isLoading = false;

                } catch (error) {
                    console.error(error);
                    this.isLoading = false;
                }
            },
            clearFilter() {
                this.initFilters();
            },
            initFilters() {
                this.filters = {
                    ...this.filters,
                    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                    "customer.name": {
                        operator: FilterOperator.AND,
                        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
                    },
                    "admin.name": {
                        operator: FilterOperator.AND,
                        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
                    },
                    start_date: {
                        operator: FilterOperator.AND,
                        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }]
                    },
                    status: {
                        operator: FilterOperator.AND,
                        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }]
                    },
                    products_sold_today: {
                        operator: FilterOperator.AND,
                        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }]
                    },
                    salesAdjustedForCommissionAndDiscount: {
                        operator: FilterOperator.AND,
                        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }]
                    }
                    // and other fields....
                };
            },
            calculateRemainingDays(transfer) {
                const deadlines = {
                    "easy": {
                        "Print labels": 7,
                        "Receive products": 7,
                        "Quality check": 0,
                        "Booking active": 0,
                        "Collect products": 3,
                        "Manage collected products": 7,
                        "Payout profit": 14
                    },
                    "easier": {
                        "Receive products": 21,
                        "Sort products": 14,
                        "Pricing products": 14,
                        "Print labels": 7,
                        "Booking active": 0,
                        "Collect products": 3,
                        "Manage collected products": 7,
                        "Payout profit": 14
                    },
                    "easiest": {
                        "Arrange pickup date": 35,
                        "Pickup products": 21,
                        "Sort products": 14,
                        "Pricing products": 14,
                        "Print labels": 7,
                        "Booking active": 0,
                        "Collect products": 3,
                        "Manage collected products": 7,
                        "Payout profit": 14
                    }
                };
                let deadlineDays = 0;

                if (transfer.concept in deadlines && transfer.tasks[0].name in deadlines[transfer.concept]) {
                    deadlineDays = deadlines[transfer.concept][transfer.tasks[0].name];
                }

                const startDate = new Date(transfer.start_date);
                const deadline = new Date(startDate);
                deadline.setDate(startDate.getDate() - deadlineDays);

                const now = new Date();
                transfer.remainingDays = Math.ceil((deadline - now) / (1000 * 60 * 60 * 24));

                return transfer;
            },
            tagClass(status) {
                switch (status) {
                    case "pending":
                        return "light-blue-tag";
                    case "approved":
                        return "light-orange-tag";
                    case "stalled":
                        return "pink-tag";
                    case "active":
                        return "green-tag";
                    case "completed":
                        return "light-rose-tag";
                    case "paid":
                        return "dark-sky-tag";
                    case "cancelled":
                    case "refunded":
                    case "declined":
                    case "finished":
                    case "discontinued":
                        return "light-skin-tag";
                    default:
                        return "gray-tag";
                }
            },
            getIsoWeek(date) {
                const targetDate = new Date(date);
                const dayNumber = (targetDate.getUTCDay() + 6) % 7;
                targetDate.setDate(targetDate.getDate() - dayNumber + 3);
                const firstThursday = targetDate.valueOf();
                targetDate.setMonth(0, 1);
                if (targetDate.getDay() !== 4) {
                    targetDate.setMonth(0, 1 + ((4 - targetDate.getDay()) + 7) % 7);
                }
                return 1 + Math.ceil((firstThursday - targetDate) / 604800000);
            },
            formatDateLL(date) {
                const options = { year: "numeric", month: "long", day: "numeric" };
                return new Date(date).toLocaleDateString(this.locale, options);
            },
            getGradientColor(count) {
                if (count <= 1) {
                    return "fill-green-200"; // light green
                } else if (count <= 3) {
                    return "fill-teal-400"; // medium-light green
                } else if (count > 3) {
                    return "fill-blue-400"; // dark green
                } else {
                    return "fill-sky-200";
                }
            },
            getGradientExtendColor(count) {
                if (count <= 1) {
                    return "fill-rose-300"; // light green
                } else if (count <= 2) {
                    return "fill-red-600"; // medium-light green
                } else if (count > 3) {
                    return "fill-red-800"; // dark green
                }
            }
        }
    };
</script>

<style>
    .p-icon.p-datatable-sort-icon {
        width: 0.75rem;
        height: 0.75rem;
        margin-bottom: 0.25rem;
    }

    .p-icon.p-button-icon {
        width: 0.75rem;
        height: 0.75rem;
    }
</style>