<template>
    <div class="mini-tag italic font-semibold px-3 py-1 border"
         :style="{ 'background-color': computedBgColor, 'color': computedTextColor, 'border-color': computedBorderColor }"
         :class="{ 'text-xs' : size === 'mini', 'text-sm' : size === 'small', 'text-lg' : size === 'medium'}">
        {{ name }}
    </div>
</template>
<script>
    export default {
        name: "ColorBadge",
        props: {
            model: {},
            name: {},
            size: {
                type: String,
                default: "small"
            },
            bgColor: {},
            textColor: {},
            borderColor: {}
        },
        computed: {
            computedBgColor() {
                if (this.bgColor) {
                    return "#" + this.bgColor;
                } else {
                    return "transparent";
                }
            },
            computedTextColor() {
                return "#" + this.textColor;
            },
            computedBorderColor() {
                if (this.borderColor) {
                    return "#" + this.borderColor;
                } else {
                    return "transparent";
                }

            }
        }
    };
</script>