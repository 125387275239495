<template>
    <span v-if="model === 'color'" class="inline-flex relative">
        <span v-if="hex" :style="{ 'background-color': '#' + hex }"
              :class="{ 'small-color-badge' : size === 'small', 'medium-color-badge' : size === 'medium' }"></span>

        <span v-if="name === 'Gennemsigtig'"
              :class="{ 'small-color-badge small-see-through' : size === 'small', 'medium-color-badge see-through' : size === 'medium' }"></span>

        <!--Gulv, Sølv, Metallic-->
        <span v-if="name === 'Guld'"
              :class="{ 'small-color-badge' : size === 'small', 'medium-color-badge' : size === 'medium' }"
              class="gold-gradient"></span>
        <span v-if="name === 'Sølv'"
              :class="{ 'small-color-badge' : size === 'small', 'medium-color-badge' : size === 'medium' }"
              class="silver-gradient"></span>
        <span v-if="name === 'Kobber'"
              :class="{ 'small-color-badge' : size === 'small', 'medium-color-badge' : size === 'medium' }"
              class="copper-gradient"></span>
        <span v-if="name === 'Træfarvet'"
              :class="{ 'small-color-badge' : size === 'small', 'medium-color-badge' : size === 'medium' }"
              class="wood-gradient"></span>

        <!--Multi, MISC-->
        <span v-if="name === 'Multi' || name === 'Mange farver' || name === 'Forskellige farver'"
              :class="{ 'small-color-badge' : size === 'small', 'medium-color-badge' : size === 'medium' }"
              class="rainbow-gradient"></span>

        <span v-if="showName" class="ml-0.5"
              :class="{ 'text-sm' : size === 'small', 'text-base' : size === 'medium', 'font-bold' : boldText }">{{ name
            }}</span>
    </span>
</template>
<script>
    export default {
        name: "ColorBadge",
        props: {
            model: {},
            name: {},
            showName: {
                type: Boolean,
                required: false,
                default: false
            },
            size: {
                type: String,
                default: "small"
            },
            hex: {},
            boldText: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        mounted() {
            this.$nextTick(() => {
                setTimeout(() => {
                    window.dispatchEvent(new Event("componentLoaded"));
                }, 300);
            });
        }
    };
</script>

<style>
    .small-color-badge {
        @apply h-4 w-4 rounded-full border border-gray-200;
    }

    .medium-color-badge {
        @apply h-6 w-6 rounded-full border border-gray-200;
    }
</style>
