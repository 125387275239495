<template>
    <div class="relative w-full">
        <div class="mx-auto text-left w-full relative">
            <form action="#" method="POST" class="flex flex-col gap-2 w-full"
                  @submit.prevent="unsubscribe">
                <div class="flex flex-col xs:flex-row items-center w-full">
                    <div class="min-w-0 flex-1 w-full max-w-[650px]">
                        <label for="unsubscribe_email" class="sr-only">Din email addresse</label>
                        <input id="unsubscribe_email" v-model="form.email" name="unsubscribe_email" autocomplete="email"
                               type="email" class="newsletter-input form-large-input"
                               placeholder="Skriv din email her" required />
                    </div>
                    <div class="mt-2 xs:mt-0 xs:ml-2 w-full xs:w-fit">
                        <loading-button class="button button-purple"
                                        :loading="loading"
                                        :disabled="loading" type="submit" @click.native="unsubscribe">
                            <span>Afmeld</span>
                        </loading-button>
                    </div>
                </div>
                <div class="xs:ml-4 text-xs font-medium text-gray-700 text-center xs:text-left max-w-2xl">
                    Du kan læse vores
                    <a href="https://minitopolis.dk/persondatapolitik#/" class="font-black text-gray-700"
                       target="_blank">persondata
                        politik</a>
                    for yderligere information om beskyttelse af personlige oplysninger.
                </div>
            </form>
            <div v-if="message" class="flex items-center mt-4 w-full space-x-1">
                <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-1 min-w-6 w-6 text-green-700"
                     viewBox="0 0 24 24">
                    <g transform="translate(-748 -188)">
                        <path d="M10,20A10,10,0,1,0,0,10,10.029,10.029,0,0,0,10,20Z" transform="translate(750 190)"
                              fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                              stroke-width="1.5" />
                        <path d="M0,2.83,2.83,5.66,8.5,0" transform="translate(755.75 197.17)" fill="none"
                              stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                    </g>
                </svg>
                <p class="font-medium text-sm text-gray-700 w-fit hyphens-auto">{{ $translate(message) }}</p>
            </div>
            <div v-if="error && error.unsubscribe_email"
                 class="flex items-center justify-center xs:justify-start mt-4 w-full space-x-1">
                <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 min-w-0 w-6 h-6 text-red-700"
                     viewBox="0 0 24 24">
                    <g transform="translate(-428 -252)">
                        <path d="M0,0V5" transform="translate(440 261)" fill="none" stroke="currentColor"
                              stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                        <path
                            d="M10,18.817H3.939c-3.47,0-4.92-2.48-3.24-5.51l3.12-5.62,2.94-5.28c1.78-3.21,4.7-3.21,6.48,0l2.94,5.29,3.12,5.62c1.68,3.03.22,5.51-3.24,5.51H10Z"
                            transform="translate(430.001 254.592)"
                            fill="none"
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                        />
                        <path d="M0,0H.009" transform="translate(439.995 269)" fill="none" stroke="currentColor"
                              stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                    </g>
                </svg>
                <p class="font-medium text-sm text-red-700 w-fit hyphens-auto">{{ $translate(error.unsubscribe_email[0])
                    }}</p>
            </div>
        </div>
    </div>
</template>

<script>
    import LoadingButton from "./LoadingButton.vue";

    export default {
        name: "UnsubscribeNewsletter",
        components: { LoadingButton },
        data() {
            return {
                form: {
                    email: null
                },
                message: null,
                error: null,
                loading: false
            };
        },
        methods: {
            unsubscribe() {
                this.loading = true;
                this.message = null;
                this.error = null;
                axios
                    .post("/unsubscribe", { unsubscribe_email: this.form.email })

                    .then((response) => {
                        this.loading = false;
                        this.message = response.data.message;
                    })
                    .catch((error) => {
                        this.loading = false;
                        this.error = error.response.data.errors;
                    });
            }
        }
    };
</script>
