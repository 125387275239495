<template>
    <div class="flex flex-col items-start gap-4">
        <div v-if="avatar_color && avatar_color.toLowerCase() !== ('#' + color).toLowerCase()">
            <div class="flex items-center gap-2 mt-1">
                <color-badge model="color" size="medium"
                             :hex="color"></color-badge>
                <p class="leading-3 font-bold">#{{ color }}</p>
            </div>
        </div>
        <div>
            <p class="font-black" v-if="avatar_color && avatar_color.toLowerCase() !== ('#' + color).toLowerCase()">Ny
                farve:</p>
            <div class="flex items-center gap-2 mt-1">
                <Verte id="color_picker" v-model="avatar_color" :showHistory="false" picker="wheel" model="hex"
                       menuPosition="center" :rgbSliders="true"
                       class="bg-gray-200 rounded-full p-0.5 hover:bg-gray-600"></Verte>
                <input type="hidden" :id="idName" :name="idName" :value="avatar_color_no_hash">
                <p class="leading-3 font-bold">{{ avatar_color }}</p>
            </div>
        </div>

    </div>

</template>

<script>
    import Verte from "verte-vue3";
    import "verte-vue3/dist/verte.css";
    import ColorBadge from "./ColorBadge.vue";

    export default {
        name: "ColorPicker",
        components: { Verte, ColorBadge },
        props: {
            color: String,
            idName: String

        },
        data() {
            return {
                internalColor: null, // Internal color storage for the computed property
                avatar_color_no_hash: null  // holds color value without the hash
            };
        },
        mounted() {
            this.internalColor = "#" + this.color; // Set initial color during mounting
        },
        computed: {
            avatar_color: {
                // Define getter for computed property
                get() {
                    return this.internalColor;
                },
                // Define setter for computed property
                set(newValue) {
                    this.internalColor = newValue;
                }
            }
        },
        watch: {
            avatar_color(newValue) {
                this.avatar_color_no_hash = newValue ? newValue.replace("#", "") : "";
            }
        }
    };
</script>