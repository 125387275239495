<template>
    <div class="w-full">
        <div class="w-full">
            <div>
                <!-- Existing variants -->
                <table class="w-full table-auto">
                    <thead>
                    <tr>
                        <th class="px-4 py-2">Size</th>
                        <th class="px-4 py-2">Colors</th>
                        <th class="px-4 py-2">Patterns</th>
                        <th class="px-4 py-2">Price</th>
                        <th class="px-4 py-2"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <!-- Existing variants -->
                    <tr v-for="(variant, index) in product.variants" :key="'existing-' + index">
                        <td class="border px-4 py-2">
                            <div v-if="variant.size">
                                {{ variant.size.name }}
                            </div>
                            <div v-else>
                                Ingen str.
                            </div>
                        </td>
                        <td class="border px-4 py-2">
                            <div v-if="variant.colors" class="flex items-center">
                                <div v-for="(color, index) in variant.colors">
                                    <color-badge model="color" :name="color.name"
                                                 :hex="color.hex_code" :show-name="true"
                                                 class="mr-2" />
                                </div>
                            </div>
                            <div v-else>Ingen farve(r)</div>
                        </td>
                        <td class="border px-4 py-2">
                            <div v-if="variant.patterns">
                                <div v-for="(pattern, index) in variant.patterns">
                                    <div class="mr-2">{{ pattern.name }}</div>
                                </div>
                            </div>
                            <div v-else>Ingen mønstre(r)</div>
                        </td>
                        <td class="border px-4 py-2">{{ variant.price.value.amount / 100 }}</td>
                        <td class="border px-4 py-2"></td>
                    </tr>
                    <!-- New variants -->
                    <tr v-for="(variant, index) in newVariants" :key="'new-' + index">
                        <td class="border px-4 py-2">
                            <div class="flex gap-1">
                                <!--SIZES-->
                                <minitopolis-autocomplete mode="tags" model="size"
                                                          :show-guides="false"
                                                          :placeholder="$translate('Search sizes')"
                                                          :items="attributes.sizes" @set-color="setFilteredColor"
                                                          @remove-color="deleteFilteredColor"
                                                          @clear-value="clearAllFilteredColors">
                                </minitopolis-autocomplete>
                            </div>
                        </td>
                        <td class="border px-4 py-2">
                            <div class="flex gap-1">
                                <!--COLORS-->
                                <minitopolis-autocomplete mode="tags" model="colors"
                                                          :show-guides="false"
                                                          :placeholder="$translate('Search colors')"
                                                          :items="attributes.colors" @set-color="setFilteredColor"
                                                          @remove-color="deleteFilteredColor"
                                                          @clear-value="clearAllFilteredColors">
                                </minitopolis-autocomplete>
                            </div>

                        </td>
                        <td class="border p-2">
                            <div class="flex gap-1 w-full">
                                <!--PATTERNS-->
                                <minitopolis-autocomplete mode="tags" model="patterns"
                                                          :show-guides="false"
                                                          :placeholder="$translate('Search patterns')"
                                                          :items="attributes.patterns" @set-color="setFilteredColor"
                                                          @remove-color="deleteFilteredColor"
                                                          @clear-value="clearAllFilteredColors">
                                </minitopolis-autocomplete>
                            </div>
                        </td>
                        <td class="border p-2">
                            <div class="flex flex-col w-full xs:w-1/2">
                                <label class="sr-only" :for="'price-' + variant.id">
                                    Pris
                                </label>
                                <div class="flex h-[40px]">
                                    <input :id="'price-' + variant.id" :value="variant.price"
                                           class="form-input-defined" type="number" placeholder="0"
                                           autocomplete="off" />
                                    <span class="form-input-defined-box">kr.</span>
                                </div>
                            </div>
                        </td>
                        <td class="border px-4 py-2">
                            <button @click="removeVariant(index)"
                                    class="bg-red-500 hover:bg-red-600 text-white rounded p-2 ml-2">
                                Remove
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <div class="flex items-center justify-between w-full">
                    <button class="admin-border-button mt-5" disabled
                            @click="addNewVariantRow">Add Variant
                    </button>

                    <button v-if="newVariants.length > 0"
                            class="bg-green-500 hover:bg-green-600 text-white rounded p-2 mt-5" disabled
                            @click="createVariants">Create New Variants
                    </button>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import ColorBadge from "./Common/ColorBadge.vue";
    import axios from "axios";

    export default {
        name: "CreateVariant",
        components: {
            ColorBadge
        },
        props: {
            product: {
                type: Object,
                default: () => {
                }
            },
            existingVariant: {},
            attributes: {}
        },
        data() {
            return {
                newVariants: [],
                showNewVariantsMode: false
            };
        },
        computed: {
            sizes() {
                return this.product.variants.map(variant => variant.size).filter((value, index, self) => self.indexOf(value) === index);
            }
            // Add a similar computed property for colors, patterns, and prices.
        },
        methods: {
            addNewVariantRow() {
                this.newVariants.push({ type: "Colors", value: "" });
            },
            addVariant() {
                this.showNewVariantsMode = true;
                this.newVariants.push({ type: "Colors", value: "" });
            },
            removeVariant(index) {
                this.newVariants.splice(index, 1);
            },
            createVariants() {
                // Add your logic here

                console.log(this.newVariants);
            },
            saveProduct(product, fromDuplicate = false) {
                return new Promise((resolve, reject) => {
                    axios
                        .post(this.endpoint, {
                            category_id: product.category.value,
                            brand: product.brand,
                            price: product.price,
                            booking_id: this.booking.id,
                            parent_id: this.currentParentCategory.value,
                            size: product.size,
                            new_colors: product.new_colors,
                            patterns: product.patterns,
                            tags: product.tags,
                            title: product.title,
                            description: product.description,
                            name: product.name,
                            pretty_name: product.pretty_name,
                            pretty_description: product.pretty_description,
                            product_note: product.product_note,
                            condition: product.condition,
                            retail_price: product.retail_price ? product.retail_price : 0,
                            min_price: product.min_price ? product.min_price : 0,
                            marked_for_webshop: product.marked_for_webshop,
                            is_shippable: product.is_shippable
                        })
                        .then((response) => {
                            product.id = response.data.product.id;
                            product.uuid = response.data.product.uuid;
                            product.name = response.data.product.name;

                            // Clear the price buttons and total when saving a product
                            this.averagePrice = {
                                value: null,
                                pretty: null
                            };
                            this.maxPrice = {
                                value: null,
                                pretty: null
                            };
                            this.total = null;

                            if (!fromDuplicate) {
                                this.emitter.emit("show-toast", {
                                    message: "Varen er blevet oprettet",
                                    type: "success"
                                });
                                if (!this.adminPage && !this.notReachedProductLimit()) {
                                    setTimeout(() => {
                                        window.location.href = "/mit-minitopolis/" + this.booking.uuid + "/dine-produkter";
                                    }, 500);
                                } else {
                                    setTimeout(() => {
                                        window.location.reload();
                                    }, 500);
                                }
                            }
                            resolve();
                        })
                        .catch((error) => {
                            console.log(error);

                            this.emitter.emit("show-toast", {
                                message: "Varen blev ikke oprettet",
                                type: "error"
                            });

                            reject();
                        });
                });
            }
        }
    };
</script>