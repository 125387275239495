<template>
    <div class="flex flex-col text-sm gap-2 w-full">
        <div class="flex items-center justify-between gap-2">
            <div>Opgaven:
                <span v-if="item.status === 'pending'" class="font-bold">{{ $translate("Godkend booking") }}</span>
            </div>
            <button type="button" class="admin-border-icon-button admin-gray-icon-small-button"
                    @click="closeTaskDropdown">
                <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 min-w-0 w-4 h-4"
                     viewBox="0 0 32 32">
                    <g transform="translate(-236 -252)">
                        <path d="M0,14.935,14.935,0" transform="translate(244.532 260.532)" fill="none"
                              stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                              stroke-width="2" />
                        <path d="M14.935,14.935,0,0" transform="translate(244.532 260.532)" fill="none"
                              stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                              stroke-width="2" />
                    </g>
                </svg>
            </button>
        </div>
        <div class="flex flex-col">
            <div class="text-xs break-words">
                {{ $translate("Hejsa") }}
            </div>
            <div v-if="item.status === 'pending'" class="mt-4">
                <div class="flex items-center justify-between gap-2 w-full">
                    <div class="font-bold">Hej</div>
                    <button>
                        <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 min-w-0 w-6 h-6"
                             viewBox="0 0 24 24">
                            <g transform="translate(-620 -252)">
                                <path d="M9,0H7C2,0,0,2,0,7v6c0,5,2,7,7,7h6c5,0,7-2,7-7V11"
                                      transform="translate(622 254)" fill="none" stroke="currentColor"
                                      stroke-linecap="round"
                                      stroke-linejoin="round" stroke-width="1.5" />
                                <path
                                    d="M8.991,1.271l-7.88,7.88a2.712,2.712,0,0,0-.66,1.32l-.43,3.01a1.424,1.424,0,0,0,1.7,1.7l3.01-.43a2.8,2.8,0,0,0,1.32-.66l7.88-7.88c1.36-1.36,2-2.94,0-4.94S10.351-.089,8.991,1.271Z"
                                    transform="translate(627.049 253.749)" fill="none" stroke="currentColor"
                                    stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                <path d="M0,0A7.144,7.144,0,0,0,4.94,4.94"
                                      transform="translate(634.91 256.15)" fill="none" stroke="currentColor"
                                      stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                            </g>
                        </svg>
                    </button>
                </div>

                <!--                <form @submit.prevent="arrangePickupDate" class="flex flex-col gap-2 my-2">-->
                <!--                    <div>-->
                <!--                        <label for="suggested_date" class="form-label">{{ $translate("Afhentnings dato") }}</label>-->
                <!--                        <input-->
                <!--                            class="form-input"-->
                <!--                            v-model.lazy="pickupDate"-->
                <!--                            id="suggested_date"-->
                <!--                            type="datetime-local"-->
                <!--                            name="suggested_date">-->
                <!--                    </div>-->


                <!--                    <div class="flex items-center gap-2 mt-2">-->
                <!--                        <loading-button :disabled="!pickupDate" type="submit" :loading="isLoading[task] || false"-->
                <!--                                        button-type="admin-border-button">-->
                <!--                            <span v-if="pickupDate">Gem tidspunkt</span>-->
                <!--                            <span v-else>Angiv tidspunkt</span>-->
                <!--                        </loading-button>-->
                <!--                    </div>-->
                <!--                </form>-->
            </div>
        </div>
    </div>
</template>

<script>
    import LoadingButton from "../Shop/LoadingButton.vue";
    import { reactive } from "vue";

    export default {
        name: "BookingStatusItem",
        components: { LoadingButton },
        emits: ["mark-task-as-done", "close-task-dropdown"], // declare the event
        props: {
            item: { type: Object, required: true },
            type: { type: String, required: true },
            locale: {}
        },
        data() {
            return {
                isLoading: reactive({})

            };
        },
        methods: {
            closeTaskDropdown() {
                this.$emit("close-task-dropdown");
            }
        }
    };
</script>