<template>
    <div class="flex z-10">
        <form
            class="md:flex items-center w-full min-w-[300px] xxs:min-w-[350px] xs:min-w-[450px] sm:min-w-[550px] md:min-w-[650px]">
            <site-wide-search></site-wide-search>
        </form>
    </div>
</template>

<script>
    import SiteWideSearch from "./SiteWideSearch.vue";

    export default {
        name: "SearchInput",
        components: {
            SiteWideSearch
        }
    };
</script>

<style scoped>

</style>
