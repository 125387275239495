<template>
    <div>
        <div v-if="booking.concept === 'easy'">
            <div class="rounded-full h-3 w-3 concept-easy"></div>
        </div>
        <div v-else-if="booking.concept === 'easier'">
            <div class="rounded-full h-3 w-3 concept-easier"></div>
        </div>
        <div v-else-if="booking.concept === 'easiest'">
            <div class="rounded-full h-3 w-3 concept-easiest"></div>
        </div>
        <div v-else-if="booking.concept === 'business'">
            <div class="rounded-full h-3 w-3 concept-business"></div>
        </div>
        <div v-else>
            <div class="rounded-full h-3 w-3 bg-gray-800"></div>
        </div>
        <div v-if="showName">{{ $translate(booking.concept) + " koncept" }}</div>
    </div>

</template>

<script>
    export default {
        props: {
            booking: { type: Object, required: true },
            showName: { type: Boolean, default: false }
        }
    };
</script>