<template>
    <section class="relative pt-8 md:pt-12 pb-4 md:pb-8">
        <div class="w-full topolis-section space-y-4 mx-auto">
            <h2 v-if="title" class="sm:pb-4 flex flex-col items-center mx-auto gap-4">
                {{ title }}
                <span class="shop-line"></span>
            </h2>
            <loading-spinner v-if="loading" :loading="loading" />
            <Carousel v-if="!loading" :value="products" :numVisible="6" :numScroll="3"
                      :responsiveOptions="responsiveOptions"
                      :showIndicators="false" class="topolis-product-carousel-container">
                <template #item="slotProps">
                    <product-card :variant="slotProps.data" class="px-2"
                                  :new-products="newProducts" :current-segment="currentSegment" />
                </template>
            </Carousel>
        </div>
    </section>

</template>

<script>
    import ProductCard from "./ProductCard.vue";
    import LoadingSpinner from "../Pages/Common/LoadingSpinner.vue";
    import Carousel from "primevue/carousel";

    export default {
        components: {
            ProductCard, LoadingSpinner, Carousel
        },
        props: {
            newProducts: {
                type: Boolean,
                default: false,
                required: false
            },
            route: {
                type: String,
                required: true
            },
            currentSegment: {
                type: String,
                required: true,
                default: "boern"
            },
            title: {
                type: String,
                required: false
            }
        },
        data() {
            return {
                products: [],
                targetSegment: null,
                loading: false,
                responsiveOptions: [
                    {
                        breakpoint: "1400px",
                        numVisible: 5,
                        numScroll: 2
                    },
                    {
                        breakpoint: "1200px",
                        numVisible: 4,
                        numScroll: 2
                    },
                    {
                        breakpoint: "1024px",
                        numVisible: 3,
                        numScroll: 1
                    },
                    {
                        breakpoint: "767px",
                        numVisible: 2,
                        numScroll: 1
                    },

                    {
                        breakpoint: "525px",
                        numVisible: 1,
                        numScroll: 1
                    }
                ]
            };
        },
        methods: {
            loadMore() {
                this.loading = true;
                this.loadingMore = true;
                axios.get(this.route)
                    .then(response => {
                        this.products = this.products.concat(response.data.products);
                        this.moreProducts = response.data.has_more; // Change this line
                        this.targetSegment = response.data.targetSegment;
                        this.loading = false;
                    });
            }
        },
        mounted() {
            this.loadMore();
        }
    };

</script>

<style>
    .topolis-product-carousel-container .p-button-icon-only {
        margin-bottom: 4rem;
    }

    .topolis-product-carousel-container .p-icon {
        width: 2rem;
        height: 2rem;
    }

    .topolis-product-carousel-container .p-button:disabled {
        opacity: 0.00;
    }
</style>
