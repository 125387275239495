<template>
    <div id="topolis-shop-login" class="px-4 md:px-8 pt-6 md:pt-8">
        <div class="flex flex-col text-center space-y-4 mb-8">
            <span class="app-h1 -mt-4">Log ind</span>
            <h5 class="app-h5">Indtast dine brugeroplysninger</h5>
        </div>
        <form class="w-full max-w-sm mx-auto text-left space-y-8" method="POST" @submit.prevent="login">
            <div v-if="generalError" class="text-red-500">
                {{ generalError }}
            </div>
            <div class="space-y-4">
                <div>
                    <label for="email" class="block text-sm font-bold text-topolis-blue-700">Email</label>
                    <div class="mt-1">
                        <input id="email" v-model="form.email" name="email" type="email" placeholder="fx. hejsa@minitopolis.dk" autocomplete="email" data-cy="login-form-email-input" required class="form-input" />
                    </div>
                    <div v-if="errors && errors.email" class="mt-1 text-xs text-red-500">
                        <p class="text-red-500">{{ errors.email[0] }}</p>
                    </div>
                </div>
                <div>
                    <label for="password" class="block text-sm font-bold text-topolis-blue-700"> Password </label>
                    <div class="mt-1">
                        <input id="password" v-model="form.password" data-cy="login-form-password-input" name="password" type="password" placeholder="******" autocomplete="current-password" required class="form-input" />
                    </div>
                    <div v-if="errors && errors.password" class="mt-1 text-xs text-red-500">
                        <p class="text-red-500">{{ errors.password[0] }}</p>
                    </div>
                </div>
                <div class="flex items-center justify-end">
                    <div class="text-xs -mt-1">
                        <a href="/forgot-password" class="a-link">Glemt dit password?</a>
                    </div>
                </div>
            </div>

            <div class="flex flex-col space-y-8 pb-48 sm:pb-28">
                <div class="flex items-center justify-center">
                    <button data-cy="login-form-submit-button" class="button button-blue w-full" type="submit" :disabled="invalidInputs">Log ind</button>
                </div>
                <div class="relative flex justify-center text-sm">
                    <span class="px-1 text-gray-500"> Har du ikke en bruger? </span>
                    <a href="" class="a-link">Opret bruger</a>
<!--                    <router-link to="/registrer" class="a-link"> Opret bruger </router-link>-->
                </div>
                <hr />
                <div class="text-center">
                    <p>
                        Læs venligst
                        <a href="https://minitopolis.dk/persondatapolitik#/" class="a-link" target="_blank" rel="noreferrer">persondata politik</a>
                        for yderligere information om beskyttelse af personlige oplysninger
                    </p>
                </div>
            </div>

        </form>
    </div>
</template>

<script>
export default {
    name: 'LoginPage',
    data() {
        return {
            outsideFlow: true,
            errors: null,
            generalError: null,
            form: {
                email: null,
                password: null,
                device_name: 'webshop'
            }
        };
    },
    computed: {
        invalidInputs() {
            return false;
        },
    },
    methods: {
        isValidEmail() {
            const emailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

            return emailRegex.test(this.form.email);
        },
        login() {
            if (this.isValidEmail(this.form.email)) {
                this.loading = true;
                axios
                    .post('/api/authenticate', this.form)
                    .then((response) => {
                        this.loading = false;
                        console.log(response.data);
                        window.localStorage.setItem('access_token', response.data.token);

                        window.location.href = '/oversigt';
                    })
                    .catch((error) => {
                        this.loading = false;
                        if (error.response.status === 423) {
                            this.generalError = error.response.data.message;
                        } else {
                            this.errors = error.response.data.errors;
                        }
                    });
            }
        },
    },
};
</script>
