<template>
    <FloatLabel class="basis-full sm:basis-1/6 min-w-[150px]">
        <PrimeMultiSelect id="ms-items"
                          v-model="selectedItems"
                          :options="items"
                          optionLabel="name"
                          filter
                          :maxSelectedLabels="3"
                          class="w-full">
        </PrimeMultiSelect>
        <label for="ms-items">{{ placeholder }}</label>
    </FloatLabel>
</template>

<script>
    import MultiSelect from "@vueform/multiselect";
    import FloatLabel from "primevue/floatlabel";

    export default {
        name: "ProductMultiSelect",
        components: { PrimeMultiSelect: MultiSelect, FloatLabel },
        props: {
            items: {},
            placeholder: {}
        },
        data() {
            return {
                selectedItems: null
            };
        }
    };
</script>


<style scoped>

</style>