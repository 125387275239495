<template>
    <div class="w-full">
        <button @keydown.esc="hideDropdown" @keydown.enter.prevent="submit" @keydown.down="down" @keydown.up="up" class="shop-form-select">
            <span>{{ title }}</span>
        </button>
        <div class="results-box" v-if="showDropdown">
            <ul>
                <li @click="submit(result)" class="search-list-item" v-for="(result, index) in searchResults" :key="result.uuid" :class="{ 'is-active': index === arrowCounter }">{{ result.pretty_name }}</li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            required: true,
            type: String
        },
    },
    data() {
        return {
            msg: "hola",
            value: 'list',
            options: ['list', 'of', 'options', 'fasdf', 'asdasd', 'asdasdas']
        }
    }
}
</script>
