<template>
    <div class="relative" ref="trigger">
        <!-- Trigger Element -->
        <button
            class="block text-left text-zinc-700 underline decoration-dotted decoration-zinc-300 underline-offset-4 cursor-help"
            @mouseenter="openTooltip"
            @mouseleave="closeTooltip">
            <slot name="triggerText"></slot> <!-- Triggering Text Slot -->
        </button>

        <!-- Tooltip Element -->
        <div ref="tooltip" class="z-10 absolute bottom-full left-0" v-if="open" style="visible: hidden">
            <div
                class="w-[12.5rem] h-full text-xs font-medium bg-white text-zinc-900 border border-zinc-200 px-3 py-2 rounded whitespace-normal shadow-lg mb-1.5 transition-all duration-200">
                <slot></slot> <!-- Content Slot -->
            </div>
        </div>
    </div>
</template>

<script>
    import { createPopper } from "@popperjs/core";

    export default {
        name: "Tooltip",

        data() {
            return {
                open: false,
                popperInstance: null
            };
        },

        mounted() {
            this.popperInstance = createPopper(this.$refs.trigger, this.$refs.tooltip, {
                placement: "top",
                modifiers: [{ name: "offset", options: { offset: [0, 10] } }]
            });
        },

        beforeUnmount() {
            if (this.popperInstance) {
                this.popperInstance.destroy();
                this.popperInstance = null;
            }
        },

        methods: {
            openTooltip() {
                this.open = true;
                if (this.popperInstance) {
                    this.popperInstance.update();
                }
            },

            closeTooltip() {
                this.open = false;
            }
        }
    };
</script>