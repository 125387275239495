<template>
    <div class="w-full border border-surface rounded-2xl py-5 px-7 flex flex-col justify-between relative">
        <div class="flex flex-col gap-4 mb-4">
            <div class="flex-1 text-color font-semibold leading-6">{{ title }}</div>
            <div class="flex items-center justify-between gap-4">
                <div class="flex flex-wrap gap-6 items-center justify-between">
                    <SelectButton v-model="selectedTime" :options="timeOptions" aria-labelledby="basic"
                                  :allowEmpty="false" @change="changeSelect" />
                </div>
                <div class="flex items-center gap-2">
                    <div v-for="(item, index) in chartData.datasets" :key="index" class="flex items-center gap-2">
                        <div class="w-3 h-3 rounded-full" :style="{ backgroundColor: item.backgroundColor }"></div>
                        <span class="font-medium text-color leading-6">{{ $translate(item.label) }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div id="chartContainer" class="h-80">
            <loading-spinner :loading="loading" class="absolute top-20" />
            <Chart v-show="!loading" type="bar" :data="chartData" :options="chartOptions" class="h-full" />
        </div>
    </div>
</template>

<script>
    import Chart from "primevue/chart";
    import SelectButton from "primevue/selectbutton";
    import axios from "axios";
    import LoadingSpinner from "../Pages/Common/LoadingSpinner.vue";

    export default {
        name: "ColumnGraph",
        components: { LoadingSpinner, Chart, SelectButton },
        props: {
            chartDataEndpoint: String,
            timeOptions: {
                type: Array,
                required: true
            },
            title: String
        },
        mounted() {
            this.fetchChartData();
            window.addEventListener("resize", this.handleResize);

            this.$nextTick(() => {
                setTimeout(() => {
                    window.dispatchEvent(new Event("componentLoaded"));
                }, 300);
            });
        },
        beforeUnmount() {
            window.removeEventListener("resize", this.handleResize);
        },
        data() {
            return {
                chartData: {
                    labels: [],
                    datasets: []
                },
                chartOptions: {
                    responsive: true,
                    maintainAspectRatio: false
                },
                selectedTime: this.timeOptions.length > 0 ? this.timeOptions[0] : "Hourly",
                barThickness: 32, // Default bar thickness
                loading: false
            };
        },
        methods: {
            async fetchChartData() {
                this.loading = true;
                try {
                    const response = await axios.get(this.chartDataEndpoint, {
                        params: {
                            timeFrame: this.selectedTime
                        }
                    });
                    const data = response.data;

                    // Extract labels and dataset from the response
                    const labels = data.labels;
                    const datasets = data.datasets;

                    // Assuming sales and bookings are stored in respective dataset arrays
                    const formattedDatasets = datasets.map(dataset => ({
                        ...dataset,
                        data: dataset.data.map(value => value / 100) // Convert values as needed
                    }));

                    // Prepare the datasets for the chart
                    this.chartData = {
                        labels,
                        datasets: formattedDatasets
                    };

                    console.log(this.chartData);

                    this.setupChartOptions();
                } catch (error) {
                    console.error("Error fetching chart data:", error);
                } finally {
                    // Hide loading indicator
                    this.loading = false;
                }
            },
            formatCurrency(value) {
                return new Intl.NumberFormat("da-DK", {
                    style: "currency",
                    currency: "DKK",
                    minimumFractionDigits: 2
                }).format(value);
            },
            changeSelect() {
                this.updateBarThickness();
                this.fetchChartData();
            },
            updateBarThickness() {
                let baseThickness;

                // Determine base thickness based on selected time
                switch (this.selectedTime) {
                    case "Hourly":
                        baseThickness = 12;
                        break;
                    case "Daily":
                        baseThickness = 50;
                        break;
                    case "Weekly":
                        baseThickness = 20;
                        break;
                    case "Monthly":
                        baseThickness = 40;
                        break;
                    case "Yearly":
                        baseThickness = 60;
                        break;
                    default:
                        baseThickness = 32;
                }

                // Adjust based on screen size
                if (window.matchMedia("(max-width: 640px)").matches) {
                    this.barThickness = baseThickness / 2;
                } else {
                    const containerWidth = document.getElementById("chartContainer").offsetWidth;
                    this.barThickness = Math.max(baseThickness, containerWidth / 50);
                }
            },
            setupChartOptions() {
                const documentStyle = getComputedStyle(document.documentElement);
                const surface100 = documentStyle.getPropertyValue("--p-surface-100");
                const surface400 = documentStyle.getPropertyValue("--p-surface-400");

                this.chartOptions = {
                    maintainAspectRatio: false,
                    aspectRatio: 0.8,
                    plugins: {
                        tooltip: {
                            enabled: false,
                            external: (context) => this.customTooltipHandler(context),
                            mode: "index", // Show tooltip for all bars at the hovered index
                            intersect: false // Tooltip will appear when hovering between items as well
                        },
                        legend: {
                            display: false // Adjust based on your needs
                        }
                    },
                    scales: {
                        x: {
                            stacked: true,
                            ticks: {
                                color: surface400
                            },
                            grid: {
                                display: false,
                                borderColor: "transparent"
                            },
                            border: {
                                display: false
                            }
                        },
                        y: {
                            beginAtZero: true,
                            stacked: true,
                            ticks: {
                                color: surface400
                            },
                            grid: {
                                display: true,
                                color: surface100,
                                borderColor: "transparent"
                            },
                            border: {
                                display: false
                            }
                        }
                    }
                };
            },
            customTooltipHandler(context) {
                const { chart, tooltip } = context;
                let tooltipEl = chart.canvas.parentNode.querySelector("div.chartjs-tooltip");

                if (!tooltipEl) {
                    tooltipEl = document.createElement("div");
                    tooltipEl.classList.add(
                        "chartjs-tooltip",
                        "bg-white",
                        "p-3",
                        "rounded-[8px]",
                        "overflow-hidden",
                        "opacity-100",
                        "absolute",
                        "transition-all",
                        "duration-[0.1s]",
                        "pointer-events-none",
                        "shadow-[0px_25px_20px_-5px_rgba(0,0,0,0.10),0px_10px_8px_-6px_rgba(0,0,0,0.10)]"
                    );
                    chart.canvas.parentNode.appendChild(tooltipEl);
                }

                if (tooltip.opacity === 0) {
                    tooltipEl.style.opacity = 0;
                    return;
                }

                const datasetPointsX = tooltip.dataPoints.map((dp) => dp.element.x);
                const avgX = datasetPointsX.reduce((a, b) => a + b, 0) / datasetPointsX.length;
                const avgY = tooltip.dataPoints[0]?.element.y ?? 0; // Ensure there's a data point at index 2

                if (tooltip.body) {
                    tooltipEl.innerHTML = "";
                    const tooltipBody = document.createElement("div");

                    tooltipBody.classList.add("flex", "flex-col", "gap-4", "px-3", "py-3", "min-w-[18rem]");
                    tooltip.dataPoints.reverse().forEach((body) => {
                        const row = document.createElement("div");

                        row.classList.add("flex", "items-center", "gap-2", "w-full");
                        const point = document.createElement("div");

                        point.classList.add("w-2.5", "h-2.5", "rounded-full");
                        point.style.backgroundColor = body.dataset.backgroundColor;
                        row.appendChild(point);
                        const label = document.createElement("span");

                        label.appendChild(document.createTextNode(body.dataset.label));
                        label.classList.add("text-base", "font-medium", "text-color", "flex-1", "text-left", "capitalize");
                        row.appendChild(label);
                        const value = document.createElement("span");

                        // Include currency format here
                        value.appendChild(document.createTextNode(this.formatCurrency(body.raw)));
                        value.classList.add("text-base", "font-medium", "text-color", "text-right");
                        row.appendChild(value);
                        tooltipBody.appendChild(row);
                    });
                    tooltipEl.appendChild(tooltipBody);
                }

                const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

                tooltipEl.style.opacity = 1;
                tooltipEl.style.font = tooltip.options.bodyFont.string;
                tooltipEl.style.padding = 0;
                const chartWidth = chart.width;
                const tooltipWidth = tooltipEl.offsetWidth;
                const chartHeight = chart.height;
                const tooltipHeight = tooltipEl.offsetHeight;

                let tooltipX = positionX + avgX + 24;
                let tooltipY = avgY;

                if (tooltipX + tooltipWidth > chartWidth) {
                    tooltipX = positionX + avgX - tooltipWidth - 20;
                }

                if (tooltipY < 0) {
                    tooltipY = 0;
                } else if (tooltipY + tooltipHeight > chartHeight) {
                    tooltipY = chartHeight - tooltipHeight;
                }

                tooltipEl.style.left = tooltipX + "px";
                tooltipEl.style.top = tooltipY + "px";
            },
            handleResize() {
                this.updateBarThickness();
                this.setupChartOptions();
            }
        }
    };
</script>