<template>
    <div>
        <div class="flex items-center justify-between mb-2">
            <div>
                {{ label }}
            </div>
            <div>
                <label class="sr-only">Sortér efter</label>
                <select class="form-select-admin text-sm" v-model="sortType" @change="sortBy">
                    <option value="created">{{ $translate("Created products") }}</option>
                    <option value="sold">{{ $translate("Sold products") }}</option>
                </select>
            </div>
        </div>

        <div
            class="h-full w-full flex items-center justify-center border border-gray-300 min-h-[650px] rounded-lg py-1 sm:py-2">
            <div v-if="loading">
                <loading-spinner :loading="loading"></loading-spinner>
            </div>
            <div v-if="!loading" class="h-full w-full relative">

                <apexchart v-if="!loading" :width="width" :height="chartHeight" type="bar" :options="chartOptions"
                           :series="series" />
                <div class="flex items-center justify-center w-full mx-auto mb-2">
                    <button class="admin-gray-button" type="button" @click="loadMore">
                        {{ $translate("Load more") }}
                    </button>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import LoadingSpinner from "../Pages/Common/LoadingSpinner.vue";

    export default {
        components: {
            LoadingSpinner
        },
        name: "BarChart",
        props: {
            label: String,
            width: Number,
            height: Number,
            endpoint: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                createdVariantStats: [], // stores data for 'created_variants_count'
                soldVariantStats: [], // stores data for 'sold_variants_count'
                isSortedByCreatedVariants: true,
                chartData: [],
                loading: false,
                chartHeight: this.height, // initializing with the height prop
                page: 1,
                perPage: 20,
                sortType: "created",
                chartOptions: {
                    chart: {
                        type: "bar",
                        stacked: false,
                        toolbar: {
                            show: false
                        },
                        zoom: {
                            enabled: false
                        }
                    },
                    responsive: [
                        {
                            breakpoint: 480,
                            options: {
                                legend: {
                                    position: "bottom",
                                    offsetX: -10,
                                    offsetY: 0
                                }
                            }
                        }
                    ],
                    plotOptions: {
                        bar: {
                            horizontal: true,
                            borderRadius: 2
                        }
                    },
                    tooltip: {
                        shared: true,
                        intersect: false,
                        y: {
                            formatter: (value, { series, seriesIndex, dataPointIndex, w }) => {
                                return `${w.config.series[seriesIndex].name}: ${value}`;
                            }
                        }
                    },
                    xaxis: {
                        type: "category"
                    },
                    legend: {
                        position: "top",
                        offsetY: 0,
                        markers: {
                            width: 12,
                            height: 12,
                            radius: 12
                        }
                    },
                    fill: {
                        opacity: 1
                    }
                }
            };
        },
        computed: {
            categories() {
                return this.chartData.map((userData, index) => `${index + 1}. ${userData.user.name}`);
            },
            series() {
                if (this.chartData) {
                    const createdVariantsSeries = {
                        name: this.$translate("Created products"),
                        data: this.chartData.map(userData => userData.created_variants_count)
                    };
                    const soldVariantsSeries = {
                        name: this.$translate("Sold products"),
                        data: this.chartData.map(userData => userData.sold_variants_count)
                    };

                    if (this.isSortedByCreatedVariants) {
                        createdVariantsSeries.color = "#008FFB";
                        soldVariantsSeries.color = "#FF4560";
                        return [createdVariantsSeries, soldVariantsSeries];
                    } else {
                        createdVariantsSeries.color = "#008FFB";
                        soldVariantsSeries.color = "#FF4560";
                        return [soldVariantsSeries, createdVariantsSeries];
                    }
                } else {
                    return [];
                }
            }
        },
        methods: {
            sortBy() {
                this.isSortedByCreatedVariants = this.sortType === "created";

                const itemsCount = this.chartData.length;
                this.chartData = [];
                this.fetchData(1, itemsCount);
            },
            fetchData(page = 1, perPage = 40) {
                this.loading = true;
                axios.get(this.endpoint, {
                    params: {
                        sortBy: this.isSortedByCreatedVariants ? "created_variants_count" : "sold_variants_count",
                        page: page,
                        perPage: perPage
                    }
                })
                    .then(response => {
                        console.log("fetchData response", response.data);
                        this.chartData = [...this.chartData, ...response.data]; // Extend the current chartData with new data.
                        if (this.isSortedByCreatedVariants) {
                            this.createdVariantStats = [...this.createdVariantStats, ...this.chartData];
                        } else {
                            this.soldVariantStats = [...this.soldVariantStats, ...this.chartData];
                        }

                        this.page++;  // Increment page number to fetch the next set of data
                        this.$forceUpdate(); // Force a re-render
                        this.loading = false;
                    })
                    .catch(error => {
                        console.error(`Error fetching data: ${error}`);
                        this.loading = false;
                    });
            },
            updateChart() {
                this.chartOptions = {
                    ...this.chartOptions,
                    xaxis: {
                        ...this.chartOptions.xaxis,
                        categories: this.categories
                    }
                };
            },
            loadMore() {
                this.fetchData(this.page, 40);
                // After loading more data, adjust the chart height
                this.chartHeight += this.perPage * 50;
            }
        },
        mounted() {
            this.fetchData();
        },
        watch: {
            chartData: {
                handler() {
                    this.updateChart();
                },
                deep: true
            }
        }
    };
</script>