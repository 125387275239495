<template>
    <div class="flex flex-col w-full mx-auto space-y-8">
        <profile v-if="authenticatedUser" class="mt-2" />
        <div class="flex flex-col">
            <div class="flex flex-col space-y-8">
                <span class="text-xl xs:text-2xl sm:text-3xl font-black">Nemt, Nemmere & Nemmest konceptet</span>
                <div class="flex flex-col xs:flex-row items-center justify-between gap-4">
                    <h5 class="app-h5">Vælg det koncept, der passer dig.</h5>
                    <bouncing-button
                        data-cy="btn-booking-continue"
                        type="button"
                        :bouncing="shouldBounce"
                        :disabled="disableBouncingButton"
                        @click.native="gotoNext">
                        Fortsæt
                    </bouncing-button>
                </div>
            </div>
        </div>
        <div class="flex flex-col xs:flex-row xs:items-center gap-4 mx-auto w-full h-full">
            <div class="radio-card concept-card concept-card-easy w-full"
                 :class="{ checked: chosenConcept === 'easy', checkedSub: chosenSubConcept === 'easy25' || chosenSubConcept === 'easy50' || chosenSubConcept === 'easy75' || chosenSubConcept === 'easy100' }"
                 @click="setConcept('easy')">
                <!--Discount styling-->
                <!--                <div class="corner-ribbon">-->
                <!--                    <span class="cr-inner">-->
                <!--                        <span class="cr-text">Tilbud <strong>20%</strong></span>-->
                <!--                    </span>-->
                <!--                </div>-->
                <div class="radio-card-wrapper">
                    <div class="absolute top-3 right-3 xs:top-0 xs:right-0 lg:top-3 lg:right-3">
                        <span class="radio-card-check-icon"
                              :class="{ 'radio-card-check-icon-checked': chosenSubConcept }"></span>
                    </div>
                    <div class="flex flex-col items-center relative mt-3 xxs:mt-5 xs:mt-0 space-y-4 sm:space-y-8">
                        <!--Illustration-->
                        <div
                            class="flex flex-col justify-center items-center relative space-y-2 xs:space-y-4 transition-all"
                            :class="{ 'pb-40 xs:pb-52 sm:pb-64 lg:pb-32': chosenConcept === 'easy' && chosenSubConcept === null, 'pb-32 xs:pb-44 sm:pb-56 md:pb-60 lg:pb-20': chosenConcept === 'easy' && chosenSubConcept !== null }"
                        >
                            <img class="w-32 lg:w-40" src="/assets/illustrations/hanger-yellow-text.svg"
                                 alt="" />
                            <!--Text-->
                            <div class="flex flex-col lg:space-y-1 relative">
                                <h5 class="text-sm xs:text-xs md:text-sm">Kommission: <span
                                    class="font-bold text-base xs:text-sm md:text-base">17%</span></h5>
                                <!--Discount styling-->
                                <!--                                <h5 class="text-base xs:text-sm sm:text-base flex xs:flex-col lg:flex-row items-center">-->
                                <!--                                    <span class="text-sm xs:text-xs md:text-sm">Periode leje: <span class="text-base xs:text-sm md:text-base font-bold">fra 79 kr.</span></span>-->
                                <!--                                    <span class="text-xs xs:text-xxs md:text-xs text-gray-500 line-through ml-1">99 kr.</span>-->
                                <!--                                </h5>-->
                                <h5 class="text-base xs:text-sm sm:text-base">Periode leje: <span class="font-bold">fra 199 kr.</span>
                                </h5>
                            </div>
                        </div>
                    </div>

                    <div v-if="chosenConcept === 'easy'"
                         class="absolute bottom-0 xs:-bottom-5 lg:bottom-0 transition-all flex items-center justify-between gap-2 flex-wrap w-full">
                        <div v-if="!chosenSubConcept" class="flex justify-center mx-auto w-full mb-0 md:-mb-2 lg:mb-0">
                            <span class="flex h-2 w-2 mr-1 xs:mr-0.5 sm:mr-1 relative">
                                <span
                                    class="animate-longPing absolute inline-flex h-full w-full rounded-full bg-topolis-yellow-500 opacity-75"></span>
                                <span
                                    class="relative inline-flex items-center justify-center rounded-full h-2 w-2 bg-topolis-yellow-500 text-sm font-medium"></span>
                            </span>
                            <span class="text-xs xs:text-[10px] lg:text-xs font-bold text-topolis-yellow-900">Vælg en prisklasse:</span>
                        </div>
                        <div class="radio-card-small concept-card concept-card-easy w-[48%] xs:w-full lg:w-[48%]"
                             :class="{ checked: chosenSubConcept === 'easy25' }"
                             @click="$store.commit('setSubConcept', 'easy25')">
                            <div class="radio-card-wrapper">
                                <div class="flex flex-col justify-center items-center relative transition-all">
                                    <!--Text-->
                                    <div class="flex flex-col lg:space-y-1 relative">
                                        <!--When campaign - add text-left-->
                                        <h5 class="text-sm xs:text-xs sm:text-sm"><span class="font-bold">25</span>
                                            varer</h5>
                                        <!--Discount styling-->
                                        <!--                                        <div class="relative">-->
                                        <!--                                            <h5 class="text-sm xs:text-xs sm:text-sm">Leje: <span class="font-bold">79 kr.</span></h5>-->
                                        <!--                                            <span class="absolute -top-2 -right-3 xs:-right-4 lg:-right-1.5 text-[10px] text-gray-500 line-through rotate-12">99 kr.</span>-->
                                        <!--                                        </div>-->
                                        <h5 class="text-sm xs:text-xs sm:text-sm">Leje: <span
                                            class="font-bold">199 kr.</span></h5>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="radio-card-small concept-card concept-card-easy w-[48%] xs:w-full lg:w-[48%]"
                             :class="{ checked: chosenSubConcept === 'easy50' }"
                             @click="$store.commit('setSubConcept', 'easy50')">
                            <div class="radio-card-wrapper">
                                <div class="flex flex-col justify-center items-center relative transition-all">
                                    <!--Text-->
                                    <div class="flex flex-col lg:space-y-1 relative">
                                        <!--When campaign - add text-left-->
                                        <h5 class="text-sm xs:text-xs sm:text-sm"><span class="font-bold">50</span>
                                            varer</h5>
                                        <!--Discount styling-->
                                        <!--                                        <div class="relative">-->
                                        <!--                                            <h5 class="text-sm xs:text-xs sm:text-sm">Leje: <span class="font-bold">239 kr.</span></h5>-->
                                        <!--                                            <span class="absolute -top-2 -right-3 xs:-right-4 lg:-right-1.5 text-[10px] text-gray-500 line-through rotate-12">299 kr.</span>-->
                                        <!--                                        </div>-->
                                        <h5 class="text-sm xs:text-xs sm:text-sm">Leje: <span
                                            class="font-bold">399 kr.</span></h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="radio-card-small concept-card concept-card-easy w-[48%] xs:w-full lg:w-[48%]"
                             :class="{ checked: chosenSubConcept === 'easy75' }"
                             @click="$store.commit('setSubConcept', 'easy75')">
                            <div class="radio-card-wrapper">
                                <div class="flex flex-col justify-center items-center relative transition-all">
                                    <!--Text-->
                                    <div class="flex flex-col space-y-1 relative">
                                        <!--When campaign - add text-left-->
                                        <h5 class="text-sm xs:text-xs sm:text-sm"><span class="font-bold">75</span>
                                            varer</h5>
                                        <!--Discount styling-->
                                        <!--                                        <div class="relative">-->
                                        <!--                                            <h5 class="text-sm xs:text-xs sm:text-sm">Leje: <span class="font-bold">399 kr.</span></h5>-->
                                        <!--                                            <span class="absolute -top-2 -right-3 xs:-right-4 lg:-right-1.5 text-[10px] text-gray-500 line-through rotate-12">499 kr.</span>-->
                                        <!--                                        </div>-->
                                        <h5 class="text-sm xs:text-xs sm:text-sm">Leje: <span
                                            class="font-bold">599 kr.</span></h5>
                                    </div>
                                </div>
                                <div v-if="chosenConcept === 'easy'" class="absolute bottom-0 transition"></div>
                            </div>
                        </div>
                        <div class="radio-card-small concept-card concept-card-easy w-[48%] xs:w-full lg:w-[48%]"
                             :class="{ checked: chosenSubConcept === 'easy100' }"
                             @click="$store.commit('setSubConcept', 'easy100')">
                            <div class="radio-card-wrapper">
                                <div class="flex flex-col justify-center items-center relative transition-all">
                                    <!--Text-->
                                    <div class="flex flex-col lg:space-y-1 relative">
                                        <!--When campaign - add text-left-->
                                        <h5 class="text-sm xs:text-xs sm:text-sm"><span class="font-bold">100</span>
                                            varer</h5>
                                        <!--Discount styling-->
                                        <!--                                        <div class="relative">-->
                                        <!--                                            <h5 class="text-sm xs:text-xs sm:text-sm">Leje: <span class="font-bold">639 kr.</span></h5>-->
                                        <!--                                            <span class="absolute -top-2 -right-3 xs:-right-4 lg:-right-1.5 text-[10px] text-gray-500 line-through rotate-12">799 kr.</span>-->
                                        <!--                                        </div>-->
                                        <h5 class="text-sm xs:text-xs sm:text-sm">Leje: <span
                                            class="font-bold">799 kr.</span></h5>
                                    </div>
                                </div>
                                <div v-if="chosenConcept === 'easy'" class="absolute bottom-0 transition"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="radio-card concept-card concept-card-easier w-full"
                 :class="{ checked: chosenConcept === 'easier', checkedSub: chosenSubConcept === 'easier50' || chosenSubConcept === 'easier100' }"
                 @click="setConcept('easier')">
                <div class="radio-card-wrapper">
                    <div class="absolute top-3 right-3 xs:top-0 xs:right-0 lg:top-3 lg:right-3">
                        <span class="radio-card-check-icon"
                              :class="{ 'radio-card-check-icon-checked': chosenSubConcept }"></span>
                    </div>
                    <div class="flex flex-col items-center relative mt-3 xxs:mt-5 xs:mt-0 space-y-4 sm:space-y-8">
                        <!--Illustration-->
                        <div
                            class="flex flex-col justify-center items-center relative space-y-2 xs:space-y-4 transition-all"
                            :class="{ 'pb-24 xs:pb-32 sm:pb-36 lg:pb-24': chosenConcept === 'easier' && chosenSubConcept === null, 'pb-24 xs:pb-32 md:pb-36 lg:pb-20': chosenConcept === 'easier' && chosenSubConcept !== null }"
                        >
                            <img class="w-32 lg:w-40" src="/assets/illustrations/hanger-purple-text.svg" alt="" />
                            <!--Text-->
                            <div class="flex flex-col lg:space-y-1 relative">
                                <h5 class="text-sm xs:text-xs md:text-sm">Kommission: <span
                                    class="font-bold text-base xs:text-sm md:text-base">50%</span></h5>
                                <!--Discount styling-->
                                <!--                                <h5 class="text-base xs:text-sm sm:text-base flex xs:flex-col lg:flex-row items-center">-->
                                <!--                                    <span class="text-sm xs:text-xs md:text-sm">Periode leje: <span class="text-base xs:text-sm md:text-base font-bold">fra 79 kr.</span></span>-->
                                <!--                                    <span class="text-xs xs:text-xxs md:text-xs text-gray-500 line-through ml-1">99 kr.</span>-->
                                <!--                                </h5>-->
                                <h5 class="text-base xs:text-sm sm:text-base">Periode leje: <span class="font-bold">fra 249 kr.</span>
                                </h5>
                            </div>
                        </div>
                    </div>
                    <div v-if="chosenConcept === 'easier'"
                         class="absolute bottom-2 xxs:bottom-4 xs:bottom-10 transition-all flex items-center justify-between gap-2 flex-wrap w-full">
                        <div v-if="!chosenSubConcept" class="flex justify-center mx-auto w-full mb-0 md:-mb-2 lg:mb-0">
                            <span class="flex h-2 w-2 mr-1 xs:mr-0.5 sm:mr-1 relative">
                                <span
                                    class="animate-longPing absolute inline-flex h-full w-full rounded-full bg-topolis-bright-purple-300 opacity-75"></span>
                                <span
                                    class="relative inline-flex items-center justify-center rounded-full h-2 w-2 bg-topolis-bright-purple-300 text-sm font-medium"></span>
                            </span>
                            <span class="text-xs xs:text-[10px] lg:text-xs font-bold text-topolis-yellow-900">Vælg en prisklasse:</span>
                        </div>
                        <div class="radio-card-small concept-card concept-card-easier w-[48%] xs:w-full lg:w-[48%]"
                             :class="{ checked: chosenSubConcept === 'easier50' }"
                             @click="$store.commit('setSubConcept', 'easier50')">
                            <div class="radio-card-wrapper">
                                <div class="flex flex-col justify-center items-center relative transition-all">
                                    <!--Text-->
                                    <div class="flex flex-col lg:space-y-1 relative">
                                        <!--When campaign - add text-left-->
                                        <h5 class="text-sm xs:text-xs sm:text-sm"><span class="font-bold">50</span>
                                            varer</h5>
                                        <!--Discount styling-->
                                        <!--                                        <div class="relative">-->
                                        <!--                                            <h5 class="text-sm xs:text-xs sm:text-sm">Leje: <span class="font-bold">239 kr.</span></h5>-->
                                        <!--                                            <span class="absolute -top-2 -right-3 xs:-right-4 lg:-right-1.5 text-[10px] text-gray-500 line-through rotate-12">299 kr.</span>-->
                                        <!--                                        </div>-->
                                        <h5 class="text-sm xs:text-xs sm:text-sm">Leje: <span
                                            class="font-bold">249 kr.</span></h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="radio-card-small concept-card concept-card-easier w-[48%] xs:w-full lg:w-[48%]"
                             :class="{ checked: chosenSubConcept === 'easier100' }"
                             @click="$store.commit('setSubConcept', 'easier100')">
                            <div class="radio-card-wrapper">
                                <div class="flex flex-col justify-center items-center relative transition-all">
                                    <!--Text-->
                                    <div class="flex flex-col lg:space-y-1 relative">
                                        <!--When campaign - add text-left-->
                                        <h5 class="text-sm xs:text-xs sm:text-sm"><span class="font-bold">100</span>
                                            varer</h5>
                                        <!--Discount styling-->
                                        <!--                                        <div class="relative">-->
                                        <!--                                            <h5 class="text-sm xs:text-xs sm:text-sm">Leje: <span class="font-bold">639 kr.</span></h5>-->
                                        <!--                                            <span class="absolute -top-2 -right-3 xs:-right-4 lg:-right-1.5 text-[10px] text-gray-500 line-through rotate-12">799 kr.</span>-->
                                        <!--                                        </div>-->
                                        <h5 class="text-sm xs:text-xs sm:text-sm">Leje: <span
                                            class="font-bold">399 kr.</span></h5>
                                    </div>
                                </div>
                                <div v-if="chosenConcept === 'easier'" class="absolute bottom-0 transition"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="radio-card concept-card concept-card-easiest w-full"
                 :class="{ checked: chosenConcept === 'easiest' }"
                 @click="$store.commit('setConcept', 'easiest')">
                <div class="radio-card-wrapper">
                    <div class="absolute top-3 right-3 xs:top-0 xs:right-0 lg:top-3 lg:right-3">
                        <span class="radio-card-check-icon"></span>
                    </div>
                    <div class="flex flex-col items-center relative mt-3 xxs:mt-5 xs:mt-0 space-y-4 sm:space-y-8">
                        <!--Illustration-->
                        <div
                            class="flex flex-col justify-center items-center relative space-y-2 xs:space-y-4 transition-all"
                            :class="{ 'pb-24 xs:pb-12 sm:pb-20 lg:pb-24': chosenConcept === 'easiest' }"
                        >
                            <img class="w-32 lg:w-40" src="/assets/illustrations/hanger-blue-text.svg" alt="" />
                            <!--Text-->
                            <div class="flex flex-col lg:space-y-1 relative">
                                <h5 class="text-sm xs:text-xs md:text-sm">Kommission: <span
                                    class="font-bold text-base xs:text-sm md:text-base">50%</span></h5>
                                <!--Discount styling-->
                                <!--                                <h5 class="text-base xs:text-sm sm:text-base flex xs:flex-col lg:flex-row items-center">-->
                                <!--                                    <span class="text-sm xs:text-xs md:text-sm">Periode leje: <span class="text-base xs:text-sm md:text-base font-bold">fra 79 kr.</span></span>-->
                                <!--                                    <span class="text-xs xs:text-xxs md:text-xs text-gray-500 line-through ml-1">99 kr.</span>-->
                                <!--                                </h5>-->
                                <h5 class="text-base xs:text-sm sm:text-base">Periode leje: <span class="font-bold">399 kr.</span>
                                </h5>
                                <!--                                <h5 class="text-base xs:text-sm sm:text-base">Uge pris: <span-->
                                <!--                                    class="font-black text-base">75 kr./uge</span>-->
                                <!--                                </h5>-->
                            </div>
                        </div>
                    </div>
                    <div v-if="chosenConcept === 'easiest'"
                         class="absolute bottom-2 xxs:bottom-4 xs:bottom-10 transition-all flex items-center justify-between gap-2 flex-wrap w-full">
                        <div class="radio-card-small concept-card concept-card-easiest w-full"
                             :class="{ checked: chosenConcept === 'easiest' }">
                            <div class="radio-card-wrapper">
                                <div class="flex flex-col justify-center items-center relative transition-all">
                                    <!--Text-->
                                    <div class="flex flex-col lg:space-y-1 relative">
                                        <!--When campaign - add text-left-->
                                        <h5 class="text-sm xs:text-xs sm:text-sm"><span class="font-bold">100</span>
                                            varer</h5>
                                        <!--Discount styling-->
                                        <!--                                        <div class="relative">-->
                                        <!--                                            <h5 class="text-sm xs:text-xs sm:text-sm">Leje: <span class="font-bold">239 kr.</span></h5>-->
                                        <!--                                            <span class="absolute -top-2 -right-3 xs:-right-4 lg:-right-1.5 text-[10px] text-gray-500 line-through rotate-12">299 kr.</span>-->
                                        <!--                                        </div>-->
                                        <h5 class="text-sm xs:text-xs sm:text-sm">Leje: <span
                                            class="font-bold">399 kr.</span></h5>
                                        <h5 class="text-xxs leading-3 font-medium">
                                            Afhentning
                                            fra: <span class="font-bold">79 kr.</span></h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-12 mb-8 space-y-8 pb-48 sm:pb-28">
            <div class="bg-topolis-gray-400 w-full p-4 shadow rounded">
                <span>Hvis du har få gode ting, så kan du skrive til vores mail:</span>
                <span>
                    <a href="mailto:kontakt@minitopolis.dk"> kontakt@minitopolis.dk </a>
                </span>
            </div>
            <h5>Du kan klikke her, hvis du vil vide mere om
                <span>
                    <a class="a-link" href="https://minitopolis.dk/koncepterne" target="_blank">vores koncepter</a>
                </span> og
                <span>
                    <a class="a-link" href="https://minitopolis.dk/priser" target="_blank">priser</a>
                </span></h5>
            <div class="flex flex-col space-y-8">
                <hr />
                <div class="flex items-center justify-center pb-48 sm:pb-28">
                    <bouncing-button
                        data-cy="btn-booking-continue"
                        type="button"
                        :bouncing="shouldBounce"
                        :disabled="disableBouncingButton"
                        @click.native="gotoNext">
                        Fortsæt
                    </bouncing-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapGetters } from "vuex";
    import BouncingButton from "../Common/BouncingButton.vue";
    import Profile from "./Common/Profile.vue";

    export default {
        name: "ConceptStep",
        components: { BouncingButton, Profile },
        computed: {
            ...mapGetters(["authenticatedUser", "chosenConcept", "conceptPrices"]),
            chosenConcept: {
                get() {
                    return this.$store.state.chosenConcept;
                },
                set(concept) {
                    this.$store.commit("setConcept", concept);
                }
            },
            chosenSubConcept: {
                get() {
                    return this.$store.state.chosenSubConcept;
                },
                set(concept) {
                    this.$store.commit("setSubConcept", concept);
                }
            },
            formIsValid() {
                if (this.chosenConcept === "easy" && (this.chosenSubConcept === "easy25" || this.chosenSubConcept === "easy50" || this.chosenSubConcept === "easy75" || this.chosenSubConcept === "easy100")) {
                    return true;
                } else if (this.chosenConcept === "easier" && (this.chosenSubConcept === "easier50" || this.chosenSubConcept === "easier100")) {
                    return true;
                } else if (this.chosenConcept === "easiest") {
                    return true;
                }

                return false;
            },
            shouldBounce() {
                return this.formIsValid;
            },
            disableBouncingButton() {
                return !this.formIsValid;
            }
        },
        mounted() {
            // this.$router.push({ name: "success-step" });

            return;
            const url = new URL(window.location.href);
            const urlParams = new URLSearchParams(url.search);
            if (urlParams.has("chosen-concept")) {
                this.chosenConcept = urlParams.get("chosen-concept");
            }
            axios
                .get("authenticated-user")
                .then((response) => {
                    this.$store.commit("setAuthenticatedUser", response.data.user);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        methods: {
            setConcept(concept, type = "concept") {
                if (type === "concept") {
                    this.$store.commit("setConcept", concept);
                } else {
                    this.$store.commit("setSubConcept", concept);
                }
            },
            gotoNext() {
                if (this.chosenConcept === "easy") {
                    if (this.chosenSubConcept === null) return;
                    this.$store.commit("setPeriod", {
                        commission: 17,
                        concept: this.chosenSubConcept,
                        discount_percentage: null,
                        id: this.chosenConcept,
                        original_price: this.conceptPrices[this.chosenSubConcept],
                        period_length: 8,
                        price: this.conceptPrices[this.chosenSubConcept]
                    });
                }
                if (this.chosenConcept === "easier") {
                    if (this.chosenSubConcept === null) return;
                    this.$store.commit("setPeriod", {
                        commission: 50,
                        concept: this.chosenSubConcept,
                        discount_percentage: null,
                        id: this.chosenConcept,
                        original_price: this.conceptPrices[this.chosenSubConcept],
                        period_length: 8,
                        price: this.conceptPrices[this.chosenSubConcept]
                    });
                }
                if (this.chosenConcept === "easiest") {
                    this.$store.commit("setPeriod", {
                        commission: 50,
                        concept: this.chosenConcept,
                        discount_percentage: null,
                        id: this.chosenConcept,
                        original_price: this.conceptPrices[this.chosenConcept],
                        period_length: 8,
                        price: this.conceptPrices[this.chosenConcept]
                    });
                }
                this.$store.commit("setSelectedWeeks", []);
                this.$router.push({ name: "calendar-step" });
            }
        }
    };
</script>
