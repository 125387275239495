<template>
    <apexchart ref="donut" type="donut" :options="chartOptions" :series="series"></apexchart>
</template>

<script>
    export default {
        name: "DonutExample",
        props: {
            chartData: Object, // Define a prop to receive the data
            colorTheme: String
        },
        data() {
            return {
                series: [],
                chartOptions: {
                    labels: [],
                    colors: [], // Define custom colors for each series
                    legend: {
                        show: true,
                        position: "left",
                        horizontalAlign: "left"   // makes sure the legend is aligned left within its space
                    },
                    plotOptions: {
                        pie: {
                            size: 200,
                            donut: {
                                labels: {
                                    show: true,
                                    name: {
                                        show: true,
                                        fontSize: "20px",
                                        fontWeight: 600,
                                        offsetY: -10,
                                        formatter: function(val) {
                                            return val;
                                        }
                                    },
                                    value: {
                                        show: true,
                                        fontSize: "20px",
                                        fontWeight: 400,
                                        offsetY: -4,
                                        formatter: function(val) {
                                            return val;
                                        }
                                    },
                                    total: {
                                        show: true,
                                        label: "Total",
                                        fontSize: "20px",
                                        fontWeight: 600,
                                        color: "#373d3f"
                                    }
                                }
                            }
                        }
                    },
                    tooltip: {
                        y: {
                            formatter: (val, opts) => {
                                return `${this.chartOptions.labels[opts.dataPointIndex]}: ${val}`;
                            }
                        }
                    }
                }
            };
        },
        computed: {
            colors() {
                return this.colorTheme === "brand" ?
                    ["#D0AF4A", "#917EA8", "#5C6194", "#212020", "#FFD700", "#d0caca"] :
                    undefined;
                // return undefined to let ApexCharts use the default colors
            }
        },
        methods: {
            formatSeriesdata() {
                this.series = Object.values(this.chartData).map(item => item.count);
                this.chartOptions.labels = Object.keys(this.chartData).map(key => this.$translate(`${key}`));
                console.log("Color theme:", this.colorTheme);
                this.chartOptions.colors = this.colors;
            }
        },
        mounted() {
            this.$nextTick(() => {
                setTimeout(() => {
                    window.dispatchEvent(new Event("componentLoaded"));
                }, 300);
            });
            // Format the series data in the mounted hook
            this.formatSeriesdata();
        }
    };
</script>