<template>
    <div>
        <div v-if="booking.status === 'active'" class="flex flex-col gap-4">
            <div class="flex items-center space-x-4">
                <p class="text-center sm:text-left">Vi tilbyder en forlængelse med følgende
                    muligheder:</p>
            </div>
            <ol class="flex flex-col space-y-1">
                <li class="flex items-start text-left relative w-full">
                    <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 min-w-0 w-6"
                         viewBox="0 0 24 24">
                        <g transform="translate(-748 -316)">
                            <path
                                d="M0,3.724v5.34c0,3.32,2.35,4.67,5.22,3.02l1.28-.74a1,1,0,0,0,.5-.87V2.314a1,1,0,0,0-.5-.87L5.22.7C2.35-.946,0,.4,0,3.724Z"
                                transform="translate(754 321.606)" fill="currentColor" />
                            <path
                                d="M0,.506v6.43a.5.5,0,0,0,.75.43l1.1-.64c2.87-1.65,2.87-4.37,0-6.02L.75.066A.5.5,0,0,0,0,.506Z"
                                transform="translate(762 324.284)" fill="currentColor" opacity="0.4" />
                        </g>
                    </svg>
                    <span class="text-sm mt-0.5"> 10 ugers forlængelse </span>
                </li>
                <li v-if="user.rating > 3" class="flex items-start text-left relative w-full">
                    <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 min-w-0 w-6"
                         viewBox="0 0 24 24">
                        <g transform="translate(-748 -316)">
                            <path
                                d="M0,3.724v5.34c0,3.32,2.35,4.67,5.22,3.02l1.28-.74a1,1,0,0,0,.5-.87V2.314a1,1,0,0,0-.5-.87L5.22.7C2.35-.946,0,.4,0,3.724Z"
                                transform="translate(754 321.606)" fill="currentColor" />
                            <path
                                d="M0,.506v6.43a.5.5,0,0,0,.75.43l1.1-.64c2.87-1.65,2.87-4.37,0-6.02L.75.066A.5.5,0,0,0,0,.506Z"
                                transform="translate(762 324.284)" fill="currentColor" opacity="0.4" />
                        </g>
                    </svg>
                    <span class="text-sm mt-0.5">Valgfrit: Fyld op med nye varer</span>
                </li>
                <li class="flex items-start text-left relative w-full">
                    <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 min-w-0 w-6"
                         viewBox="0 0 24 24">
                        <g transform="translate(-748 -316)">
                            <path
                                d="M0,3.724v5.34c0,3.32,2.35,4.67,5.22,3.02l1.28-.74a1,1,0,0,0,.5-.87V2.314a1,1,0,0,0-.5-.87L5.22.7C2.35-.946,0,.4,0,3.724Z"
                                transform="translate(754 321.606)" fill="currentColor" />
                            <path
                                d="M0,.506v6.43a.5.5,0,0,0,.75.43l1.1-.64c2.87-1.65,2.87-4.37,0-6.02L.75.066A.5.5,0,0,0,0,.506Z"
                                transform="translate(762 324.284)" fill="currentColor" opacity="0.4" />
                        </g>
                    </svg>
                    <span
                        class="text-sm mt-0.5"> 25 % discount på resterende varer i hele forlængelsen (alle ti uger) </span>
                </li>
            </ol>
            <div class="flex flex-col border-t border-gray-300 pt-4">
                <p class="text-center sm:text-left mb-4">
                    Du har lige nu <span class="font-black">{{ productCount }} varer</span> tilbage i
                    butikken. Vi tilbyder kun perioder som enten matcher eller har flere varer end det
                    antal varer du har tilbage. Klik på en kasse for at
                    kunne gå til køb.
                </p>
                <div v-if="user.rating > 3" class="flex items-center gap-2 flex-wrap w-full mb-6">
                    <div v-if="productCount <= 15"
                         class="radio-card-small concept-card concept-card-easy w-[22%] xs:w-full lg:w-[22%]"
                         :class="{ checked: extend.concept === 'easy15' }"
                         @click="setExtension('easy15')">
                        <div class="radio-card-wrapper">
                            <div
                                class="flex flex-col justify-center items-center relative transition-all">
                                <!--Text-->
                                <div class="flex flex-col lg:space-y-1 relative">
                                    <!--When campaign - add text-left-->
                                    <h5 class="text-sm xs:text-xs sm:text-sm"><span
                                        class="font-bold">15</span> varer</h5>
                                    <!--Discount styling-->
                                    <!--                                        <div class="relative">-->
                                    <!--                                            <h5 class="text-sm xs:text-xs sm:text-sm">Leje: <span class="font-bold">79 kr.</span></h5>-->
                                    <!--                                            <span class="absolute -top-2 -right-3 xs:-right-4 lg:-right-1.5 text-[10px] text-gray-500 line-through rotate-12">99 kr.</span>-->
                                    <!--                                        </div>-->
                                    <h5 class="text-sm xs:text-xs sm:text-sm">Leje: <span
                                        class="font-bold">49 kr.</span></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="productCount <= 50" class="radio-card-small w-[22%] xs:w-full lg:w-[22%]"
                         :class="{ checked: extend.concept === 'easy50' }"
                         @click="setExtension('easy50')">
                        <div class="radio-card-wrapper">
                            <div
                                class="flex flex-col justify-center items-center relative transition-all">
                                <!--Text-->
                                <div class="flex flex-col lg:space-y-1 relative">
                                    <!--When campaign - add text-left-->
                                    <h5 class="text-sm xs:text-xs sm:text-sm"><span
                                        class="font-bold">50</span> varer</h5>
                                    <!--Discount styling-->
                                    <!--                                        <div class="relative">-->
                                    <!--                                            <h5 class="text-sm xs:text-xs sm:text-sm">Leje: <span class="font-bold">79 kr.</span></h5>-->
                                    <!--                                            <span class="absolute -top-2 -right-3 xs:-right-4 lg:-right-1.5 text-[10px] text-gray-500 line-through rotate-12">99 kr.</span>-->
                                    <!--                                        </div>-->
                                    <h5 class="text-sm xs:text-xs sm:text-sm">Leje: <span
                                        class="font-bold">149 kr.</span></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="productCount <= 90"
                         class="radio-card-small concept-card concept-card-easy w-[22%] xs:w-full lg:w-[22%]"
                         :class="{ checked: extend.concept === 'easy90' }"
                         @click="setExtension('easy90')">
                        <div class="radio-card-wrapper">
                            <div
                                class="flex flex-col justify-center items-center relative transition-all">
                                <!--Text-->
                                <div class="flex flex-col lg:space-y-1 relative">
                                    <!--When campaign - add text-left-->
                                    <h5 class="text-sm xs:text-xs sm:text-sm"><span
                                        class="font-bold">90</span> varer</h5>
                                    <!--Discount styling-->
                                    <!--                                        <div class="relative">-->
                                    <!--                                            <h5 class="text-sm xs:text-xs sm:text-sm">Leje: <span class="font-bold">79 kr.</span></h5>-->
                                    <!--                                            <span class="absolute -top-2 -right-3 xs:-right-4 lg:-right-1.5 text-[10px] text-gray-500 line-through rotate-12">99 kr.</span>-->
                                    <!--                                        </div>-->
                                    <h5 class="text-sm xs:text-xs sm:text-sm">Leje: <span
                                        class="font-bold">249 kr.</span></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="productCount <= 150"
                         class="radio-card-small concept-card concept-card-easy w-[22%] xs:w-full lg:w-[22%]"
                         :class="{ checked: extend.concept === 'easy150' }"
                         @click="setExtension('easy150')">
                        <div class="radio-card-wrapper">
                            <div
                                class="flex flex-col justify-center items-center relative transition-all">
                                <!--Text-->
                                <div class="flex flex-col lg:space-y-1 relative">
                                    <!--When campaign - add text-left-->
                                    <h5 class="text-sm xs:text-xs sm:text-sm"><span class="font-bold">150</span>
                                        varer</h5>
                                    <!--Discount styling-->
                                    <!--                                        <div class="relative">-->
                                    <!--                                            <h5 class="text-sm xs:text-xs sm:text-sm">Leje: <span class="font-bold">79 kr.</span></h5>-->
                                    <!--                                            <span class="absolute -top-2 -right-3 xs:-right-4 lg:-right-1.5 text-[10px] text-gray-500 line-through rotate-12">99 kr.</span>-->
                                    <!--                                        </div>-->
                                    <h5 class="text-sm xs:text-xs sm:text-sm">Leje: <span
                                        class="font-bold">399 kr.</span></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex flex-col">
                    <p class="text-center sm:text-left">Valgfrit:</p>
                    <div v-if="user.rating > 3" class="flex items-center justify-between w-full mt-2">
                        <div class="flex items-center space-x-1 w-full">
                            <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 min-w-0 w-6"
                                 viewBox="0 0 24 24">
                                <g transform="translate(-428 -316)">
                                    <path
                                        d="M7.276,0V16.66l-.8.91c-1.11,1.26-2.02.92-2.02-.76V9.61H1.366c-1.4,0-1.79-.86-.86-1.91Z"
                                        transform="translate(432.724 319.67)" fill="#B32D76" />
                                    <path
                                        d="M6.77,10.631,0,18.331V1.671L.8.761c1.11-1.26,2.02-.92,2.02.76v7.2H5.91C7.31,8.721,7.7,9.581,6.77,10.631Z"
                                        transform="translate(440 317.999)" fill="#B32D76"
                                        opacity="0.4" />
                                </g>
                            </svg>
                            <p class="font-medium">Fyld op med nye varer</p>
                        </div>
                        <div class="relative w-full">
                            <span class="sr-only">Refill</span>
                            <div class="flex justify-between w-full gap-2">
                                <label class="placement-radio-card"
                                       :class="{ active: extend.refill === false || extend.refill === 0 }">
                                    <input id="radio-card-1" v-model="extend.refill" type="radio" name="radio-card"
                                           :value="false" class="hidden" />
                                    <span id="radio-card-1-label" class="text-center">Nej tak</span>
                                </label>

                                <label class="placement-radio-card"
                                       :class="{ active: extend.refill === true || extend.refill === 1 }">
                                    <input id="radio-card-2" v-model="extend.refill" type="radio" name="radio-card"
                                           :value="true" class="hidden" />
                                    <span id="radio-card-2-label"
                                          class="text-center">Tilføj <span>(+199 kr.)</span></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="flex items-center justify-between w-full mt-2">
                        <div class="flex items-center space-x-1 w-full">
                            <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 min-w-0 w-6"
                                 viewBox="0 0 43.2 43.2">
                                <path
                                    d="M3.573,22.743.837,20.007a2.89,2.89,0,0,1,0-4.068L3.573,13.2a3.321,3.321,0,0,0,.846-2.034V7.3A2.889,2.889,0,0,1,7.3,4.419h3.87A3.321,3.321,0,0,0,13.2,3.573L15.939.837a2.89,2.89,0,0,1,4.068,0l2.736,2.736a3.321,3.321,0,0,0,2.034.846h3.87a2.888,2.888,0,0,1,2.88,2.88v3.87a3.321,3.321,0,0,0,.846,2.034l2.736,2.736a2.89,2.89,0,0,1,0,4.068l-2.736,2.736a3.321,3.321,0,0,0-.846,2.034v3.87a2.888,2.888,0,0,1-2.88,2.88h-3.87a3.321,3.321,0,0,0-2.034.846l-2.736,2.736a2.89,2.89,0,0,1-4.068,0L13.2,32.373a3.321,3.321,0,0,0-2.034-.846H7.3a2.888,2.888,0,0,1-2.88-2.88v-3.87A3.237,3.237,0,0,0,3.573,22.743Z"
                                    transform="translate(3.609 3.645)"
                                    fill="#383fee"
                                    opacity="0.4"
                                />
                                <g transform="translate(25.182 25.2)">
                                    <path
                                        d="M1.818,3.6A1.8,1.8,0,1,1,3.6,1.8,1.79,1.79,0,0,1,1.818,3.6Z"
                                        fill="#292d32" />
                                </g>
                                <g transform="translate(14.4 14.4)">
                                    <path
                                        d="M1.818,3.6A1.8,1.8,0,1,1,3.6,1.8,1.79,1.79,0,0,1,1.818,3.6Z"
                                        fill="#292d32" />
                                </g>
                                <g transform="translate(14.854 14.854)">
                                    <path
                                        d="M1.345,13.5a1.335,1.335,0,0,1-.954-.4,1.358,1.358,0,0,1,0-1.908l10.8-10.8A1.349,1.349,0,0,1,13.1,2.3L2.3,13.1A1.335,1.335,0,0,1,1.345,13.5Z"
                                        transform="translate(0 0)" fill="#292d32" />
                                </g>
                            </svg>
                            <p class="font-medium">25 % på resterende varer</p>
                        </div>
                        <div class="relative w-full">
                            <span class="sr-only">25 % discount</span>
                            <div class="flex justify-between w-full gap-2">
                                <label class="placement-radio-card"
                                       :class="{ active: extend.discount === false || extend.discount === 0 }">
                                    <input id="radio-card-1" v-model="extend.discount" type="radio" name="radio-card"
                                           :value="false" class="hidden" />
                                    <span id="radio-card-1-label" class="text-center">Nej tak</span>
                                </label>

                                <label class="placement-radio-card"
                                       :class="{ active: extend.discount === true || extend.discount === 1 }">
                                    <input id="radio-card-2" v-model="extend.discount" type="radio" name="radio-card"
                                           :value="true" class="hidden" />
                                    <span id="radio-card-2-label"
                                          class="text-center">Tilføj <span>(+199 kr.)</span></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="space-y-2 mt-8">
                    <div class="flex items-center justify-between">
                        <h4 class="app-h4">Samlet pris inkl. moms</h4>
                        <span class="app-h2">{{ extend.price }}</span>
                    </div>
                </div>
            </div>
            <button class="button button-purple" @click="openDrawer('purchase')">Gå til betaling
            </button>
        </div>
        <div v-else>
            <p class="text-center sm:text-left">
                Din valgte booking er ikke aktiv, så derfor tilbyder vi ikke en forlængelse.
            </p>
        </div>
    </div>

    <drawer name="purchase" deactivate-outside-click to-purchase drawer-header="Kurv"></drawer>
</template>

<script>
    import Drawer from "./Drawer.vue";

    // Define a map that associates each concept with its base price
    const basePriceMap = {
        easy15: 4900,
        easy50: 14900,
        easy90: 24900,
        easy150: 39900
    };

    export default {
        name: "BookingAddonPage",
        components: { Drawer },
        props: {
            booking: {},
            user: {},
            productCount: {}
        },
        data() {
            return {
                loading: true,
                dataProperty: null,
                extend: {
                    concept: null,
                    price: null,
                    refill: false,
                    discount: false
                }
            };
        },
        mounted() {
            this.$nextTick(() => {
                window.dispatchEvent(new Event("componentLoaded"));
            });
            this.setExtensionFromMaxProducts();
        },
        watch: {
            "extend.refill": {
                handler(newVal, oldVal) {
                    this.updateExtendRefillPrice(newVal);
                },
                deep: true
            },
            "extend.discount": {
                handler(newVal, oldVal) {
                    this.updateExtendDiscountPrice(newVal);
                },
                deep: true
            }
        },
        methods: {
            setExtensionFromMaxProducts() {
                if (this.booking.max_products === 15 && this.productCount <= 15) {
                    this.setExtension("easy15");
                } else if (this.booking.max_products === 50 && this.productCount <= 50) {
                    this.setExtension("easy50");
                } else if (this.booking.max_products === 90 && this.productCount <= 90) {
                    this.setExtension("easy90");
                } else {
                    this.setExtension("easy150");
                }
            },
            setExtension(concept) {
                if (Object.keys(basePriceMap).includes(concept)) {
                    this.extend.concept = concept;
                    let basePrice = basePriceMap[concept];
                    if (this.extend.refill) {
                        basePrice += 19900;
                    }
                    if (this.extend.discount) {
                        basePrice += 19900;
                    }
                    this.extend.price = this.formatPrice(basePrice);
                }
            },
            updateExtendRefillPrice(isRefill) {
                const basePrice = 19900;
                let currentPrice = parseFloat(this.extend.price) * 100;
                if (isRefill) {
                    const newPrice = currentPrice + basePrice;
                    this.extend.price = this.formatPrice(newPrice);
                } else {
                    const newPrice = currentPrice - basePrice;
                    this.extend.price = this.formatPrice(newPrice);
                }
            },
            updateExtendDiscountPrice(isDiscount) {
                const basePrice = 19900;
                let currentPrice = parseFloat(this.extend.price) * 100;
                if (isDiscount) {
                    const newPrice = currentPrice + basePrice;
                    this.extend.price = this.formatPrice(newPrice);
                } else {
                    const newPrice = currentPrice - basePrice;
                    this.extend.price = this.formatPrice(newPrice);
                }
            },
            formatPrice(price) {
                return new Intl.NumberFormat("da-DK", {
                    style: "currency",
                    currency: "DKK",
                    maximumSignificantDigits: 3
                }).format(price / 100);
            },
            openDrawer(name) {
                this.$store.dispatch("addItemToDrawer", { name: "Booking forlængelse", price: this.extend.price });
                this.emitter.emit("open-drawer", name);
            }
        }
    };
</script>

<style scoped></style>
