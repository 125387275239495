<template>
    <li class="px-4 py-4 sm:px-6">
        <div class="flex flex-col xs:flex-row items-start xs:items-center justify-between flex-wrap gap-2">
            <div class="flex items-center flex-wrap">
                <div class="flex justify-center" v-if="!isCollectingItems">
                    <label class="flex items-center h-5" for="accepts_marketing_emails">
                        <input id="accepts_marketing_emails" v-model="markProductDiscountPreparation"
                               name="accepts_marketing_emails" type="checkbox" checked
                               class="form-input-checkbox hover:cursor-pointer"
                               value="" @change="setDiscountPreparationProduct(product)" />
                    </label>
                </div>
                <p class="text-sm font-medium text-gray-800 text-left mr-2">
                    {{ product.name }}
                </p>
                <div class="border border-black px-1 leading-none w-fit flex items-center">
                    <span class="text-xxs text-gray-800">nr.:</span>
                    <span class="text-sm font-medium text-black">
                        <span>{{ product.store_id }}</span>
                    </span>
                </div>
                <p v-if="product.size" class="status-badge turquoise-badge ml-1">
                    {{ product.size.name }}
                </p>
            </div>
            <div class="flex items-center gap-2">
                <span class="topolis-badge topolis-table-badge" :class="product.pearl_color">
                    {{ product.end_date }}
                </span>
            </div>
        </div>
        <div class="mt-2 xxs:flex items-center xxs:justify-between flex-wrap gap-2">
            <div class="flex items-center flex-wrap gap-2 mb-2">
                <p v-if="product.brand" class="flex items-center text-sm text-gray-800 capitalize">
                    <span class="product-scanner-name">{{ product.brand.name }}</span>
                </p>
                <p v-if="product.category" class="flex items-center text-sm text-gray-800">
                    {{ product.category.name }}
                </p>
                <p v-if="product.colors && product.colors.length" class="flex items-center text-sm text-gray-800">
                    <span v-for="color in product.colors">
                        <color-badge model="color" :show-name="true" :name="color.name" :hex="color.hex_code"
                                     class="mr-1" />
                    </span>
                </p>
                <p v-if="product.patterns && product.patterns.length"
                   class="flex items-center text-sm text-gray-800">
                    <span v-for="pattern in product.patterns">
                        {{ pattern.name }}
                    </span>
                </p>
                <div class="flex items-center gap-2">
                    <p v-if="product.description" class="flex items-center text-sm text-gray-800">
                        {{ product.description }}
                    </p>
                </div>
            </div>
            <div class="flex items-center space-x-2">
                <div class="text-gray-800 font-medium">
                    <span v-if="product.status === 'store'" class="status-badge topolis-small-badge purple-badge">I butikken</span>
                    <span v-if="product.status === 'storage'" class="status-badge topolis-small-badge yellow-badge">På lager</span>
                    <span v-if="product.status === 'review'" class="status-badge topolis-small-badge amber-badge">Gennemse</span>
                    <span v-if="product.status === 'collected'"
                          class="status-badge topolis-small-badge neon-blue-badge">Indsamlet</span>
                    <span v-if="product.status === 'rejected'" class="status-badge topolis-small-badge orange-badge">Returvare</span>
                    <span v-if="product.status === 'unknown'"
                          class="status-badge topolis-small-badge gray-badge">Ukendt</span>
                    <span v-if="product.status === 'unregistered'" class="status-badge topolis-small-badge gray-badge">Ikke registreret</span>
                    <span v-if="product.status === 'released'" class="status-badge topolis-small-badge gray-badge">Afleveret</span>
                    <span v-if="product.status === 'donated'" class="status-badge topolis-small-badge gray-badge">Doneret</span>
                    <span v-if="product.status === 'none' || !product.status"
                          class="status-badge topolis-small-badge pink-badge">Ikke sat</span>
                </div>
                <div>
                    <select v-if="isCollectingItems" v-model="selectProductStatus"
                            class="form-select-admin text-sm w-full"
                            @change="setStatus(product)">
                        <option value="-- Vælg status --" disabled>Vælg status</option>
                        <option value="store">I butikken</option>
                        <option value="storage">På lager</option>
                        <option value="review">Gennemse</option>
                        <option value="rejected">Returvare</option>
                        <option value="collected">Indsamlet</option>
                        <option value="unknown">Ukendt</option>
                        <option value="unregistered">Ikke registreret</option>
                        <option value="released">Afleveret</option>
                        <option value="donated">Doneret</option>
                    </select>
                </div>
            </div>
        </div>
    </li>
</template>
<script>
    import ColorBadge from "./ColorBadge.vue";

    export default {
        name: "ScanProductMobile",
        components: { ColorBadge },
        props: {
            propProduct: {},
            isCollectingItems: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                selectProductStatus: this.propProduct.status,
                product: this.propProduct,
                markProductDiscountPreparation: !!this.propProduct.discount_prepared_at
            };
        },
        methods: {
            setStatus(product) {
                axios.post("/admin/set-product-status/" + product.id, { status: this.selectProductStatus }).then((response) => {
                    console.log(response);
                    this.product = response.data.product;
                    emitter.emit("product-changed");  // emit event after change
                });
            },
            setDiscountPreparationProduct(product) {
                axios.post("/admin/set-product-discount-preparation/" + product.id, { discount_prepared_at: this.markProductDiscountPreparation }).then((response) => {
                    console.log(response);
                    this.product = response.data.product;
                    this.markProductDiscountPreparation = !!this.product.discount_prepared_at;
                    emitter.emit("product-changed");  // emit event after change
                });
            }
        }
    };
</script>
