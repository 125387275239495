<template>
    <div class="flex items-center justify-between w-full">
        <div class="flex flex-col xs:items-start text-left w-full">
            <div class="space-x-1">
                <span class="inline-flex font-medium">Hej</span>
                <span class="font-black">{{ authenticatedUser.name }}</span>
            </div>
            <div class="text-xs space-x-0.5">
                <span class="inline-flex">Medlem siden</span>
                <span>{{ authenticatedUser.created_at }}</span>
            </div>
        </div>
        <button class="button transparent-button nav-button smaller-button flex space-x-2 items-center" @click="logout">
            <span>Log ud</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="20.414" height="18" viewBox="0 0 20.414 18">
                <path
                    d="M17,16l4-4m0,0L17,8m4,4H7m6,4v1a3,3,0,0,1-3,3H6a3,3,0,0,1-3-3V7A3,3,0,0,1,6,4h4a3,3,0,0,1,3,3V8"
                    transform="translate(-2 -3)" fill="none" stroke="#5C6194" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="2" />
            </svg>
        </button>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";

    export default {
        name: "Profile",
        data() {
            return {
                showProfileDropdown: false
            };
        },
        computed: {
            ...mapGetters(["authenticatedUser", "bookingUuid"])
        },
        methods: {
            logout() {
                if (confirm("Er du sikker på du vil logge ud? Hvis du gør, så starter din booking forfra."))
                    axios
                        .post("logout-user")
                        .then((response) => {
                            this.$store.commit("setAuthenticatedUser", null);
                            window.localStorage.removeItem("vuex");
                            window.location.href = "https://minitopolis.dk";
                            // if (this.bookingUuid) {
                            //     this.$router.push({ name: 'login-step' });
                            // } else {
                            //     this.$router.push({ name: 'concept-step' });
                            // }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
            }
        }
    };
</script>
