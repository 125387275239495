<template>
    <div class="flex flex-col" :class="{ 'min-w-72 md:min-w-96 max-2-xl': checkoutFlow}">
        <div class="w-full mx-auto text-left space-y-8"
             :class=" { 'max-w-lg' : !checkoutFlow, 'md:max-w-lg' : checkoutFlow } ">
            <div v-if="generalError" class="text-red-500">
                {{ generalError }}
            </div>
            <div class="space-y-4">
                <!--Full name-->
                <div>
                    <label for="name" class="block text-sm font-bold text-topolis-blue-700">Fulde navn</label>
                    <div class="mt-1">
                        <input id="name" v-model="form.name" name="name" type="text" placeholder="Helle Hansen"
                               autocomplete="name" data-cy="login-form-name-input" required class="form-input" />
                    </div>
                    <div v-if="errors && errors.name" class="mt-1 text-xs text-red-500">
                        <p>{{ errors.name[0] }}</p>
                    </div>
                </div>
                <!--Email-->
                <div>
                    <label for="email" class="block text-sm font-bold text-topolis-blue-700">Email</label>
                    <div class="mt-1">
                        <input
                            id="email"
                            v-model="form.email"
                            :class="{ valid: validateEmail, invalid: !validateEmail }"
                            name="email"
                            type="email"
                            placeholder="dinemail@gmail.com"
                            autocomplete="email"
                            data-cy="login-form-email-input"
                            required
                            class="form-input"
                        />
                    </div>
                    <div v-if="!validateEmail" class="mt-1 text-xs text-red-500">Fejl i email</div>

                    <div v-if="errors && errors.email" class="mt-1 text-xs text-red-500">
                        <p>{{ errors.email[0] }}</p>
                    </div>
                </div>
                <!--Phone-->
                <div>
                    <label for="phone" class="block text-sm font-bold text-topolis-blue-700">Telefonnummer</label>
                    <div class="mt-1 relative flex">
                        <span class="form-input-defined-phone">
                            <img src="https://flagcdn.com/24x18/dk.png"
                                 srcset="https://flagcdn.com/48x36/dk.png 2x, https://flagcdn.com/72x54/dk.png 3x"
                                 width="24" height="18" alt="Denmark" />
                        </span>
                        <div class="relative w-full">
                            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                <span class="text-sm text-topolis-blue-300">+45</span>
                            </div>
                            <input
                                id="phone"
                                v-model="form.phone"
                                required
                                :class="{ valid: validatePhoneNumber, invalid: !validatePhoneNumber }"
                                name="phone"
                                type="tel"
                                placeholder="30292827"
                                autocomplete="tel"
                                data-cy="login-form-phone-input"
                                class="pl-10 block w-full rounded-r-lg border border-gray-300 placeholder-gray-300 focus:outline-none focus:ring-topolis-purple-400 focus:border-topolis-purple-400 text-sm min-w-[140px]"
                                @input="isValidPhone"
                            />
                        </div>
                    </div>

                    <div v-if="!validatePhoneNumber" class="mt-1 text-xs text-red-500">Ugyldigt telefonnr.</div>

                    <div v-if="errors && errors.phone" class="mt-1 text-xs text-red-500">
                        <p>{{ errors.phone[0] }}</p>
                    </div>
                </div>

                <div v-if="!registrationStep && !fullRegistration" class="relative w-full">
                    <span class="block text-sm font-bold text-topolis-blue-700">Opret en bruger</span>
                    <div class="flex justify-between w-full gap-2">
                        <label class="placement-radio-card"
                               :class="{ active: form.fullUser === false || form.fullUser === 0 }">
                            <input id="radio-card-1" v-model="form.fullUser"
                                   type="radio" name="radio-card" :value="false" class="hidden" />
                            <span id="radio-card-1-label" class="text-center">Forbliv som gæst</span>
                        </label>

                        <label class="placement-radio-card"
                               :class="{ active: form.fullUser === true || form.fullUser === 1 }">
                            <input id="radio-card-2" v-model="form.fullUser"
                                   type="radio" name="radio-card" :value="true" class="hidden" />
                            <span id="radio-card-2-label" class="text-center">Opret bruger</span>
                        </label>
                    </div>
                </div>

                <div v-if="fullRegistration || form.fullUser" class="flex flex-col gap-4">
                    <!--Address-->
                    <div v-if="fullRegistration">
                        <label for="address" class="block text-sm font-bold text-topolis-blue-700">Adresse</label>
                        <div class="mt-1">
                            <input id="address" v-model="form.address" name="address" type="text"
                                   placeholder="Hans Hansensgade 1, 1. mf" autocomplete="address"
                                   data-cy="login-form-address-input" required class="form-input" />
                        </div>
                        <div v-if="errors && errors.address" class="mt-1 text-xs text-red-500">
                            <p>{{ errors.address[0] }}</p>
                        </div>
                    </div>
                    <!--Zip code and city-->
                    <div class="flex w-full gap-4">
                        <div class="w-full">
                            <label for="zip" class="block text-sm font-bold text-topolis-blue-700">Postnummer</label>
                            <div class="mt-1">
                                <input id="zip" v-model="form.zip" name="zip" type="text" placeholder="8000"
                                       autocomplete="postal-code" data-cy="login-form-zip-input" required
                                       class="form-input" />
                            </div>
                            <div v-if="errors && errors.zip" class="mt-1 text-xs text-red-500">
                                <p>{{ errors.zip[0] }}</p>
                            </div>
                        </div>
                        <div class="w-full">
                            <label for="city" class="block text-sm font-bold text-topolis-blue-700">By</label>
                            <div class="mt-1">
                                <input id="city" v-model="form.city" name="city" type="text" placeholder="Aarhus"
                                       autocomplete="address-level2" data-cy="login-form-city-input" required
                                       class="form-input" />
                            </div>
                            <div v-if="errors && errors.city" class="mt-1 text-xs text-red-500">
                                <p>{{ errors.city[0] }}</p>
                            </div>
                        </div>
                    </div>
                    <!--Password-->
                    <div v-if="showPassword || registrationStep || !isEditMode">
                        <div class="flex flex-col w-full gap-4">
                            <div class="w-full">
                                <label for="password-register"
                                       class="block text-sm font-bold text-topolis-blue-700">Password <span
                                    class="font-medium text-xs">(minimum 6
                                    bogstaver/tegn)<span
                                        v-if="passwordLengthRequirementMet"> &#10004;</span></span></label>
                                <div class="mt-1">
                                    <input id="password-register" v-model="form.password"
                                           data-cy="login-form-password-input" minlength="4"
                                           name="password" type="password" placeholder="************"
                                           autocomplete="current-password" required class="form-input" />
                                </div>
                                <div v-if="errors && errors.password" class="mt-1 text-xs text-red-500">
                                    <p>{{ errors.password[0] }}</p>
                                </div>
                            </div>
                            <div class="w-full">
                                <label for="new-password" class="block text-sm font-bold text-topolis-blue-700">Gentag
                                    Password</label>
                                <div class="mt-1">
                                    <input id="new-password" v-model="confirmPassword" type="password"
                                           placeholder="************" autocomplete="new-password"
                                           required class="form-input" />
                                </div>
                                <div v-if="passwordMismatch" class="mt-1 text-xs text-red-500">Passwords matcher ikke.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--Terms-->
            <div class="space-y-8">
                <!--Data policies-->
                <div class="text-center">
                    <p>
                        Læs venligst
                        <a href="https://minitopolis.dk/persondatapolitik#/" class="a-link" target="_blank">persondata
                            politik</a>
                        for yderligere information om beskyttelse af personlige oplysninger
                    </p>
                </div>
                <div v-if="showTerms && !isEditMode" class="space-y-4">
                    <!--Newsletter-->
                    <div class="flex">
                        <div class="flex items-center h-5">
                            <input id="accepts_marketing_emails" v-model="form.accepts_marketing_emails"
                                   name="accepts_marketing_emails" type="checkbox" checked class="form-input-checkbox"
                                   value="" />
                        </div>
                        <div class="ml-3 text-sm">
                            <label for="accepts_marketing_emails" class="font-medium text-gray-700">Jeg vil gerne
                                modtage Minitopolis' nyhedsbrev pr. email </label>
                        </div>
                    </div>

                    <!--Terms-->
                    <div class="flex">
                        <div class="flex items-center h-5">
                            <input id="accepts_trade_terms" v-model="form.accepts_trade_terms"
                                   name="accepts_trade_terms" type="checkbox" class="form-input-checkbox" value="" />
                        </div>
                        <div class="ml-3 text-sm">
                            <label for="accepts_trade_terms" class="font-medium text-gray-700"
                            >Jeg accepterer
                                <a href="https://minitopolis.dk/handelsbetingelser#/" class="a-link" target="_blank">handelsbetingelserne</a>
                            </label>
                        </div>
                    </div>

                    <!--Quality Terms-->
                    <div class="flex">
                        <div class="flex items-center h-5">
                            <input id="accepts_quality_terms" v-model="form.accepts_quality_terms"
                                   name="accepts_quality_terms" type="checkbox" class="form-input-checkbox" value="" />
                        </div>
                        <div class="ml-3 text-sm">
                            <label for="accepts_quality_terms" class="font-medium text-gray-700"
                            >Jeg accepterer
                                <a href="https://minitopolis.dk/krav-kvalitetsbetingelser#/" class="a-link"
                                   target="_blank">krav & kvalitetsbetingelserne</a>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import BouncingButton from "../../Common/BouncingButton.vue";

    export default {
        components: { BouncingButton },
        watch: {
            "form.email"() {
                const isValid = this.validateRegex(this.form.email, this.form.phone);
                this.$emit("validation-changed", isValid);
            },
            "form.phone"() {
                const isValid = this.validateRegex(this.form.email, this.form.phone);
                this.$emit("validation-changed", isValid);
            },
            "form.password"() {
                this.checkPasswordsMatch();
                this.checkPasswordLength();
            },
            confirmPassword() {
                this.checkPasswordsMatch();
            },
            form: {
                handler: function(newValue) {
                    this.$emit("form-updated", newValue);
                },
                deep: true
            }
        },
        computed: {
            ...mapGetters(["authenticatedUser"]),
            invalidInputs() {
                return false;
                // @TODO - make disabled work
                // return !this.form.name || !this.form.email || this.form.password;
            },
            validatePhoneNumber() {
                if (!this.form.phone) return true;

                return this.form.phone.match(this.phoneRegex);
            },
            validateEmail() {
                if (!this.form.email) return true;

                return this.form.email.match(this.emailRegex);
            }
        },
        props: {
            showTerms: Boolean,
            registrationStep: Boolean,
            outsideFlow: Boolean,
            errors: Object,
            generalError: Object,
            fullRegistration: Boolean,
            showPassword: Boolean,
            isEditMode: Boolean,
            checkoutFlow: Boolean
        },
        data() {
            return {
                form: {
                    name: null,
                    address: null,
                    zip: null,
                    city: null,
                    email: null,
                    phone: null,
                    password: null,
                    accepts_marketing_emails: false,
                    accepts_trade_terms: false,
                    accepts_quality_terms: false,
                    fullUser: this.fullRegistration
                },
                confirmPassword: "",
                passwordMismatch: false,
                passwordLengthRequirementMet: false,
                // Regex only for 8 numbers, with no symbols or spaces allowed
                phoneRegex: /^[1-9][0-9]{7}$/,
                emailRegex: /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/
            };
        },
        mounted() {
            if (this.authenticatedUser) {
                this.form.name = this.authenticatedUser.name;
                this.form.address = this.authenticatedUser.address;
                this.form.zip = this.authenticatedUser.zip;
                this.form.city = this.authenticatedUser.city;
                this.form.email = this.authenticatedUser.email;
                this.form.phone = this.authenticatedUser.phone;
                this.form.accepts_marketing_emails = this.authenticatedUser.accepts_marketing_emails;
                this.form.accepts_trade_terms = this.authenticatedUser.accepts_trade_terms;
                this.form.accepts_quality_terms = this.authenticatedUser.accepts_quality_terms;
            }
        },
        methods: {
            checkPasswordsMatch() {
                if (this.form.password.length >= 2 && this.confirmPassword.length >= 2) {
                    this.passwordMismatch = this.form.password !== this.confirmPassword;
                } else {
                    this.passwordMismatch = false;
                }
            },
            checkPasswordLength() {
                this.passwordLengthRequirementMet = this.form.password.length >= 6;
            },
            isValidEmail() {
                if (!this.form.email) return;

                return this.emailRegex.test(this.form.email);
            },
            isValidPhone() {
                if (!this.form.phone) return;

                return this.phoneRegex.test(this.form.phone);
            },
            validateRegex(form, phone) {
                return this.isValidEmail(form) && this.isValidPhone(phone);
            },
            submit() {
                this.$emit("submit", this.form);
            }
        }
    };
</script>
