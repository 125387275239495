<template>
    <main class="flex flex-col w-full flex-1 max-w-3xl mx-auto">
        <div class="flex items-center justify-center mb-4">
            <div class="text-2xl text-topolis-blue-700 font-black">
                <img class="w-44" :src="'/assets/logos/minitopolis-gul.svg'" alt="" width="auto" height="auto" />
            </div>
        </div>

        <router-view v-slot="{ Component, route }">
            <transition :name="transitionName" mode="out-in">
                <keep-alive>
                    <component :is="Component" :key="$route.fullPath" />
                </keep-alive>
            </transition>
        </router-view>
    </main>
</template>

<script>
    import { mapGetters } from "vuex";
    import Profile from "./Common/Profile.vue";

    export default {
        components: {
            Profile
        },
        data() {
            return {
                transitionName: "slide-left",
                bouncing: false
            };
        },
        computed: {
            backButtonRules() {
                if (this.$route.name === "concept-step") {
                    return false;
                }
            },
            ...mapGetters(["chosenConcept", "chosenPeriod", "authenticatedUser"])
        },
        watch: {
            $route(to, from) {
                const toDepth = to.meta.depth;
                const fromDepth = from.meta.depth;

                this.transitionName = toDepth < fromDepth ? "slide-right" : "slide-left";
            }
        },
        mounted() {
            const anHour = 3600000;
            window.localStorage.setItem("expiry", JSON.stringify(new Date().getTime() + anHour));

            const expiry = window.localStorage.getItem("expiry");
            const vuexStore = window.localStorage.getItem("vuex");

            if (expiry && vuexStore) {
                if (new Date().getTime() > expiry) {
                    console.log("Deleting the Vuex store.");

                    window.localStorage.removeItem("vuex");
                    window.localStorage.removeItem("expiry");

                    window.location.reload();
                }
            }

            // this.$router.push({ name: 'concept-step' });
            // console.log(this.$route.name);
            // if (
            //     (this.$store.state.authenticatedUser && this.$route.name === 'login-step') ||
            //     (this.$store.state.authenticatedUser && this.$route.name === 'register-step') ||
            //     (this.$store.state.authenticatedUser && this.$route.name === 'start-step')
            // ) {
            //     this.$router.push({ name: 'concept-step' });
            // }
            // if (!this.$store.state.bookingUuid && this.$store.state.authenticatedUser) {
            // }
        },
        methods: {
            gotoPrev() {
                this.$router.back();
            },
            gotoNext() {
                this.$router.push({ name: "period-step" });
            }
        }
    };
</script>

<style>
    /*Transitions*/
    .slide-left-enter-active,
    .slide-left-leave-active,
    .slide-right-enter-active,
    .slide-right-leave-active {
        transition: opacity 0.4s,
        transform 0.3s;
    }

    .slide-left-enter,
    .slide-right-leave-to {
        opacity: 0;
        transform: translateX(10%);
    }

    .slide-right-enter,
    .slide-left-leave-to {
        opacity: 0;
        transform: translateX(-10%);
    }
</style>
