<template>
    <div class="shop-product-list-card" :class="{ 'shop-product-sold-card' : variant.sold_at }">
        <div class="shop-product-image-list-container">
            <div v-if="productHasImages">
                <img :srcset="srcSetString"
                     sizes="(max-width: 600px) 600px, (max-width: 800px) 800px, 1200px"
                     :src="defaultImage.real_image_path"
                     :alt="defaultImage.alt"
                     class="shop-top-image"
                >
                <img :srcset="srcSetString"
                     sizes="(max-width: 600px) 600px, (max-width: 800px) 800px, 1200px"
                     :src="defaultImage.real_image_path"
                     :alt="defaultImage.alt"
                     class="shop-second-image"
                >
            </div>
            <div v-else>
                <div v-if="variant.product.category.parent_id === 351">
                    <img :src="clothesImage"
                         :alt="clothesAlt"
                         class="shop-top-image">
                </div>
                <div v-if="variant.product.category.parent_id === 213">
                    <img src="/assets/shop/minitopolis-toy-placeholder-light-gray.jpg"
                         alt="minitopolis-no-image-toy-placeholder"
                         class="shop-top-image">
                </div>
                <div v-if="variant.product.category.parent_id === 221">
                    <img src="/assets/shop/minitopolis-warm-clothing-placeholder-light-gray.jpg"
                         alt="minitopolis-no-image-warm-clothing-placeholder"
                         class="shop-top-image">
                </div>
                <div v-if="variant.product.category.parent_id === 621">
                    <img src="/assets/shop/minitopolis-shoes-placeholder-light-gray.jpg"
                         alt="minitopolis-no-image-shoes-placeholder"
                         class="shop-top-image">
                </div>
                <div v-if="variant.product.category.parent_id === 376">
                    <img :src="accessoriesImage"
                         :alt="accessoriesAlt"
                         class="shop-top-image">
                </div>
                <div v-if="variant.product.category.parent_id === 377">
                    <img src="/assets/shop/minitopolis-books-games-placeholder-light-gray.jpg"
                         alt="minitopolis-no-image-books-games-placeholder"
                         class="shop-top-image">
                </div>
                <div v-if="variant.product.category.parent_id === 144">
                    <img :src="equipmentImage"
                         :alt="equipmentAlt"
                         class="shop-top-image">
                </div>
                <div v-if="variant.product.category.parent_id === 620">
                    <img :src="interiorImage"
                         :alt="interiorAlt"
                         class="shop-top-image">
                </div>
            </div>
        </div>
        <!-- Product details and variants -->
        <div class="flex flex-col gap-2 w-full py-2">
            <div class="flex items-center justify-between gap-2">
                <div class="flex items-center gap-1">
                    <!-- 'a' tag href will use the router-link or nuxt-link instead with the correct route to the product -->
                    <a :href="productLink" class="line-clamp-1 inline-flex">
                        <span class="font-medium text-sm" v-if="!adminPage">{{ variant.product.pretty_name }}</span>
                        <span class="max-w-[200px] truncate text-sm">{{ cleanProductName(variant.product.name) }}</span>
                    </a>
                    <div class="created-at-badge flex items-center !text-xxs">
                        {{ formatDate(variant.created_at) }}
                    </div>
                </div>
                <div v-if="variant.discount" class="flex flex-col">
                    <div class="flex">
                        <p class="text-sm font-medium text-red-500">
                            <price :price="variant.discounted_price"></price>
                        </p>
                    </div>
                    <div class="flex items-center -mt-0.5">
                        <p class="text-gray-500 line-through text-xs mr-1">
                            <price :price="variant.price"></price>
                        </p>
                        <p class="text-xxs xs:text-xs text-red-500 font-medium">-{{ variant.discount }}%</p>
                    </div>
                </div>
                <div v-else class="flex text-sm font-medium text-topolis-blue-700 mr-2 xs:mr-0">
                    <price :price="variant.price"></price>
                </div>
            </div>
            <div class="flex items-center gap-2">
                <a v-if="variant.product.brand" :href="brandLink" class="line-clamp-1 inline-flex">
                    <span class="font-bold text-xs">{{ variant.product.brand.name }}</span>
                </a>
                <!-- Product variants, use v-for to loop through your variants -->
                <div class="flex gap-2">
                    <!-- The SVG will remain the same since it's static -->
                    <div v-if="variant.size" class="flex items-center gap-1">
                        <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 min-w-0 w-5 -rotate-45"
                             viewBox="0 0 24 24">
                            <g transform="translate(-620 -188)">
                                <path d="M0,10H20V0H0S0,8.612,0,10Z" transform="translate(622 195)" fill="#292d32"
                                      opacity="0.2" />
                                <path d="M1.5,0V5a.755.755,0,0,1-.75.75A.755.755,0,0,1,0,5V0Z"
                                      transform="translate(637.25 195)" fill="#292d32" />
                                <path d="M1.5,0V3.8a.755.755,0,0,1-.75.75A.755.755,0,0,1,0,3.8V0Z"
                                      transform="translate(625.25 195)" fill="#292d32" />
                                <path d="M1.54,0,1.5,5.01a.747.747,0,0,1-.75.74H.74A.749.749,0,0,1,0,4.99L.04,0Z"
                                      transform="translate(629.25 195)" fill="#292d32" />
                                <path d="M1.5,0V2.6a.755.755,0,0,1-.75.75A.755.755,0,0,1,0,2.6V0Z"
                                      transform="translate(633.25 195)" fill="#292d32" />
                            </g>
                        </svg>
                        <p class="text-sm leading-3">{{ "Str. " + variant.size.name }}</p>
                    </div>
                </div>
            </div>
            <div class="flex items-center justify-end gap-1">
                <div v-if="!adminPage" class="flex gap-2">
                    <add-product-button :product="variant.product" :variant="variant"
                                        button-type="small"></add-product-button>
                    <!--                Add to wishlist button-->
                    <add-product-to-list-button :product="variant.product" :variant="variant" :authenticated="true"
                                                :favorited="true" button-type="small"></add-product-to-list-button>
                </div>
                <div v-else class="flex items-center gap-1">
                    <Button @click="openUploadImageDialogBox(variant)" icon="pi pi-images" text rounded size="normal"
                            severity="secondary" title="Upload billeder dialog" aria-label="Upload billeder dialog"
                            style="width: 2rem; height: 2rem"></Button>
                    <Dialog v-model:visible="showUploadDialog" maximizable modal header="Upload billeder"
                            :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
                        <FileUpload name="demo[]" url="/api/upload" @upload="onUpload($event)" :multiple="true"
                                    accept="image/*" :maxFileSize="1000000" :auto="true">
                            <template #empty>
                                <span>Drag and drop files to here to upload.</span>
                            </template>
                        </FileUpload>
                    </Dialog>
                    <Button v-if="adminPage" @click="syncProductToPOS(variant)" icon="pi pi-sync" text rounded
                            size="normal" :loading="isSyncing"
                            severity="secondary" title="Sync produkt til POS" aria-label="Sync Product to POS"
                            style="width: 2rem; height: 2rem"></Button>
                    <Button @click="goToEditProductPage(variant)" icon="pi pi-pencil" text rounded size="normal"
                            severity="secondary" title="Rediger produkt" aria-label="Edit Product"
                            style="width: 2rem; height: 2rem"></Button>
                    <Button @click="goToProductPage(variant)" icon="pi pi-angle-right" text rounded size="large"
                            severity="secondary" title="Gå til produkt" aria-label="Go To Product"
                            style="width: 2rem; height: 2rem"></Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Price from "./Price.vue";
    import TagBadge from "../Pages/Common/TagBadge.vue";
    import AddProductButton from "./AddProductButton.vue";
    import AddProductToListButton from "./AddProductToListButton.vue";
    import { isGecko } from "@fingerprintjs/fingerprintjs";
    import Button from "primevue/button";
    import FileUpload from "primevue/fileupload";
    import Dialog from "primevue/dialog";

    export default {
        components: { FileUpload, Button, Price, TagBadge, AddProductButton, AddProductToListButton, Dialog },
        props: {
            variant: {},
            newProducts: {
                type: Boolean,
                required: false,
                default: false
            },
            currentSegment: {
                type: String,
                required: false,
                default: "boern"
            },
            adminPage: {
                type: Boolean,
                required: false,
                default: false
            },
            isOnWebshop: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                defaultImage: null,
                mediumImage: null,
                mobileImage: null,
                isSyncing: false,
                showUploadDialog: false
            };
        },
        computed: {
            productLink() {
                return `/shop/${this.currentSegment}/${this.variant.product.category.slug}/${this.variant.product.slug}/${this.variant.barcode}`;
            },
            brandLink() {
                let url;
                // if (this.adminPage) {
                //     url = `/admin/produkter/mærker/${this.variant.product.brand.uuid}/edit`;
                // } else {
                //     url = `/shop/${this.currentSegment}/brands/${this.variant.product.brand.slug}`;
                // }
                // // Or redirect to the URL
                // window.location.href = url;
            },
            productHasImages() {
                return !!this.defaultImage && !!this.mediumImage && !!this.mobileImage;
            },
            srcSetString() {
                return `${this.defaultImage.real_image_path} 1200w, ${this.mediumImage.real_image_path} 800w, ${this.mobileImage.real_image_path} 600w`;
            },
            clothesImage() {
                if (this.variant.product.target_segment) {
                    if (this.newProducts) {
                        return this.variant.product.target_segment === "mama"
                            ? "/assets/shop/minitopolis-clothing-mama-placeholder.jpg"
                            : "/assets/shop/minitopolis-clothing-placeholder.jpg"; // Default to 'children' or fallback
                    } else {
                        return this.variant.product.target_segment === "mama"
                            ? "/assets/shop/minitopolis-clothing-mama-placeholder-light-gray.jpg"
                            : "/assets/shop/minitopolis-clothing-placeholder-light-gray.jpg"; // Default to 'children' or fallback
                    }

                }
                return "/assets/shop/minitopolis-clothing-placeholder.jpg"; // Default fallback
            },
            clothesAlt() {
                if (this.variant.product.target_segment) {
                    return this.variant.product.target_segment === "mama"
                        ? "MAMA tøj"
                        : "Børnetøj"; // Default to 'children' or fallback
                }
                return "MAMA tøj"; // Default fallback
            },
            equipmentImage() {
                if (this.variant.product.target_segment) {
                    if (this.newProducts) {
                        return this.variant.product.target_segment === "mama"
                            ? "/assets/shop/minitopolis-equipment-mama-placeholder.jpg"
                            : "/assets/shop/minitopolis-equipment-placeholder.jpg"; // Default to 'children' or fallback
                    } else {
                        return this.variant.product.target_segment === "mama"
                            ? "/assets/shop/minitopolis-equipment-mama-placeholder-light-gray.jpg"
                            : "/assets/shop/minitopolis-equipment-placeholder-light-gray.jpg"; // Default to 'children' or fallback
                    }
                }
                return "/assets/shop/minitopolis-equipment-placeholder.jpg"; // Default fallback
            },
            equipmentAlt() {
                if (this.variant.product.target_segment) {
                    return this.variant.product.target_segment === "mama"
                        ? "Udstyr til MAMA"
                        : "Udstyr til børn"; // Default to 'children' or fallback
                }
                return "Udstyr til børn"; // Default fallback
            },
            accessoriesImage() {
                if (this.variant.product.target_segment) {
                    if (this.newProducts) {
                        return this.variant.product.target_segment === "mama"
                            ? "/assets/shop/minitopolis-accessories-mama-placeholder.jpg"
                            : "/assets/shop/minitopolis-accessories-placeholder.jpg"; // Default to 'children' or fallback
                    } else {
                        return this.variant.product.target_segment === "mama"
                            ? "/assets/shop/minitopolis-accessories-mama-placeholder-light-gray.jpg"
                            : "/assets/shop/minitopolis-accessories-placeholder-light-gray.jpg"; // Default to 'children' or fallback
                    }
                }
                return "/assets/shop/minitopolis-accessories-placeholder.jpg"; // Default fallback
            },
            accessoriesAlt() {
                if (this.variant.product.target_segment) {
                    return this.variant.product.target_segment === "mama"
                        ? "Accessories til MAMA"
                        : "Accessories til børn"; // Default to 'children' or fallback
                }
                return "Accessories til børn"; // Default fallback
            },
            interiorImage() {
                if (this.variant.product.target_segment) {
                    if (this.newProducts) {
                        return this.variant.product.target_segment === "mama"
                            ? "/assets/shop/minitopolis-interior-mama-placeholder.jpg"
                            : "/assets/shop/minitopolis-interior-placeholder.jpg"; // Default to 'children' or fallback
                    } else {
                        return this.variant.product.target_segment === "mama"
                            ? "/assets/shop/minitopolis-interior-mama-placeholder-light-gray.jpg"
                            : "/assets/shop/minitopolis-interior-placeholder-light-gray.jpg"; // Default to 'children' or fallback
                    }
                }
                return "/assets/shop/minitopolis-interior-placeholder.jpg"; // Default fallback
            },
            interiorAlt() {
                if (this.variant.product.target_segment) {
                    return this.variant.product.target_segment === "mama"
                        ? "Interiør til MAMA"
                        : "Interiør til børn"; // Default to 'children' or fallback
                }
                return "Interiør til børn"; // Default fallback
            }
        },
        created() {
            this.variant.images.forEach(image => {
                switch (image.responsive_size) {
                    case "default":
                        this.defaultImage = image;
                        break;
                    case "medium":
                        this.mediumImage = image;
                        break;
                    case "mobile":
                        this.mobileImage = image;
                        break;
                }
            });
        },
        methods: {
            openUploadImageDialogBox(variant) {
                console.log("hej", variant);
                this.showUploadDialog = !this.showUploadDialog;
            },
            cleanProductName(productName) {
                return productName.replace(/\[\d+% RABAT\]/, "").trim();
            },
            formatDate(dateString) {
                let date = new Date(dateString);
                let day = date.getDate();
                let monthIndex = date.getMonth();
                let year = date.getFullYear();
                let hours = date.getHours();
                let minutes = date.getMinutes();

                // Format the date
                day = day < 10 ? "0" + day : day;
                // Danish month names
                const months = ["jan", "feb", "mar", "apr", "maj", "jun", "jul", "aug", "sep", "okt", "nov", "dec"];
                monthIndex = months[monthIndex];
                hours = hours < 10 ? "0" + hours : hours;
                minutes = minutes < 10 ? "0" + minutes : minutes;

                return day + ". " + monthIndex + " " + hours + ":" + minutes;
            },
            goToProductPage(variant) {
                let url;
                if (this.adminPage) {
                    url = `/admin/produkter/${variant.product.booking.uuid}/${variant.product.uuid}/variant/${variant.uuid}`;
                } else {
                    url = `/mit-minitopolis/${variant.product.booking.uuid}/dine-produkter/${variant.product.uuid}/${variant.uuid}`;
                }
                // Or redirect to the URL
                window.location.href = url;
            },
            goToEditProductPage(variant) {
                let url;
                if (this.adminPage) {
                    url = `/admin/produkter/${variant.product.booking.uuid}/${variant.product.uuid}/variant/${variant.uuid}/rediger`;
                } else {
                    url = `/mit-minitopolis/${variant.product.booking.uuid}/dine-produkter/${variant.product.uuid}/${variant.uuid}/rediger`;
                }
                // Or redirect to the URL
                window.location.href = url;
            },
            async syncProductToPOS(variant) {
                let endpoint = `/admin/produkter/${variant.product.booking.uuid}/${variant.product.uuid}/variant/${variant.uuid}/sync-to-zettle`;

                try {
                    this.isSyncing = true;
                    let response = await fetch(endpoint, {
                        headers: {
                            "Accept": "application/json"
                        }
                    });

                    // Ensure 'payload' contains the actual JSON response
                    let payload = await response.json();
                    this.showToast(payload);
                } catch (error) {
                    console.error("Error syncing product:", error);
                    this.showToast({ message: "An unexpected error occurred.", success: false });
                } finally {
                    this.isSyncing = false;
                }
            },
            showToast(payload) {
                if (payload) {
                    this.emitter.emit("show-toast", {
                        message: payload.message,
                        type: payload.success ? "success" : "error"
                    });
                }
            },
            onUpload() {
            }
        }
    };

</script>
