<template>
    <div>
        <form class="flex flex-col md:flex-row justify-center w-full mx-auto gap-8" method="POST"
              @submit.prevent="register">
            <div class="flex flex-col">
                <h4 class="app-h4 mb-4">Kontaktoplysniger</h4>
                <p class="mb-4 md:max-w-lg">Har du tidligere oprettet dig i vores system, skal du booke dig via “Forbliv
                    som
                    gæst”. Har du ikke en profil herinde i forvejen (har du ikke haft en periode eller meldt dig til et
                    event før), kan du vælge at booke dig som; “Forbliv som gæst” eller som; “Opret bruger”. </p>
                <profile-form-fields :general-error="generalError" :errors="errors" :outside-flow="false"
                                     :registration-step="registrationStep" :show-terms="showTerms"
                                     :full-registration="fullRegistration"
                                     :show-password="showPassword"
                                     :is-edit-mode="isEditMode"
                                     @validation-changed="handleValidationChange"
                                     @form-updated="updateForm" :checkoutFlow="true"></profile-form-fields>
            </div>
            <div class="flex flex-col w-full md:w-1/2 lg:w-1/3 space-y-8">
                <event-checkout-fields :event="event" :totalAttendees="totalAttendees"
                                       @event-form-updated="updateEventForm" :loading="loading"
                                       :isFormValid="isFormValid"></event-checkout-fields>
            </div>
        </form>
    </div>
</template>

<script>
    import ProfileFormFields from "../Tenants/Bookings/Common/ProfileFormFields.vue";
    import EventCheckoutFields from "./EventCheckoutFields.vue";

    export default {
        components: { EventCheckoutFields, ProfileFormFields },
        props: ["event", "totalAttendees"],
        data() {
            return {
                errors: null,
                generalError: null,
                isFormValid: false,
                form: {},
                eventForm: {},
                registrationStep: false,
                fullRegistration: false,
                showPassword: false,
                isEditMode: false,
                showTerms: false,
                loading: false
            };
        },
        methods: {
            handleValidationChange(isValid) {
                // The `isValid` value receives the current validation status from the child component
                this.isFormValid = isValid;
            },
            updateForm(newFormData) {
                this.form = newFormData;
            },
            updateEventForm(newFormData) {
                this.eventForm = newFormData;
            },
            register() {
                this.loading = true;
                let mergedForm = { ...this.form, ...this.eventForm };   // merge two objects together

                axios.post(`/events/${this.event.slug}/tilmeld`, mergedForm)
                    .then((response) => {
                        this.loading = false;
                        window.location.href = response.data.url;
                    })
                    .catch((error) => {
                        if (error.response.status === 400) {
                            // show error notification
                            this.generalError = error.response.data.error;
                        }

                        this.loading = false;

                        this.errors = error.response.data.errors;
                    });
            }
        }

    };
</script>
