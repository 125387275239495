<template>
    <button @click="openCart" class="menu-item relative flex items-center gap-2"
            :class="{ 'menu-item-frontpage' : frontPage }">
        <span class="inline-flex items-center relative">
            <cart-counter :count="$store.state.cart.items_count"></cart-counter>
            <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 min-w-0"
                 :class="{ 'w-7 lg:w-6 h-7 lg:h-6': size === 'small', 'w-7 h-7': size === 'medium', 'mb-0.5': withTitle }"
                 viewBox="0 0 32 32">
                <path
                    d="M6.559,0h9.6c4.533,0,4.987,2.12,5.293,4.707l1.2,10c.387,3.28-.627,5.96-5.293,5.96H5.373c-4.68,0-5.693-2.68-5.293-5.96l1.2-10C1.573,2.12,2.026,0,6.559,0Z"
                    transform="translate(4.641 8.667)" fill="fillColor" stroke="currentColor" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="1.5" />
                <path d="M0,8V3.333A3.149,3.149,0,0,1,3.333,0h4a3.149,3.149,0,0,1,3.333,3.333V8"
                      transform="translate(10.667 2.667)" fill="none" stroke="currentColor" stroke-linecap="round"
                      stroke-linejoin="round" stroke-width="1.5" />
                <path d="M16.547,0H0" transform="translate(10.667 22.707)" fill="none" stroke="currentColor"
                      stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
            </svg>
        </span>
        <span v-if="withTitle" class="hidden lg:inline-flex">Kurv</span>
    </button>
</template>

<script>
    import CartCounter from "./CartCounter.vue";

    export default {
        components: {
            CartCounter
        },
        props: {
            frontPage: {
                required: false,
                type: Boolean,
                default: false
            },
            size: {
                type: String,
                required: false,
                default: "medium"
            },
            withTitle: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        // created() {
        //     this.getCartItems();
        // },
        methods: {
            // getCartItems() {
            //     this.$store.dispatch('getCartItems');
            // },
            openCart() {
                this.$store.commit("showCart");
                // this.lockBodyInApp();
                // this.activateOverlay();
            }
            // lockBodyInApp() {
            //     const body = document.body;
            // body.classList.add('body-overflow-hidden');
            // },
            // activateOverlay() {
            // TODO: not working as overlay is null atm
            // console.log(this.$parent.$refs.slideOverlay)
            // const overlay = document.querySelector('.drawer-shop-overlay')
            // console.log('overlay', overlay)
            // overlay.classList.toggle('active');
            // }
        }
    };
</script>
