<template>
    <FloatLabel class="basis-full sm:basis-1/6 min-w-[150px]">
        <PrimeSelect id="ms-items"
                     v-model="selectedItems"
                     :options="items"
                     optionLabel="name"
                     filter
                     :maxSelectedLabels="3"
                     class="w-full">
        </PrimeSelect>
        <label for="ms-items">{{ placeholder }}</label>
    </FloatLabel>
</template>

<script>
    import Select from "primevue/select";
    import FloatLabel from "primevue/floatlabel";

    export default {
        name: "ProductSelect",
        components: { PrimeSelect: Select, FloatLabel },
        props: {
            items: {},
            placeholder: {}
        },
        data() {
            return {
                selectedItems: null
            };
        }
    };
</script>


<style scoped>

</style>