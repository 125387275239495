<template>
    <div class="relative mt-2">
        <div class="flex flex-col w-full gap-4 mb-4">
            <div>Klik start scanning for at få generel info på produktet.</div>
            <div class="flex flex-col items-center justify-center my-8">
                <div class="flex flex-col items-center justify-center">
                    <div class="flex items-center space-x-2">
                        <button class="admin-gray-button" @click="openModal">Start scanning</button>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="modalOpen" class="bg-gray-100 shadow-lg p-2 top-0 left-0 right-0 z-[999999] h-full fixed">
            <div class="flex flex-col w-full">
                <div class="flex flex-col w-full mb-2 gap-2">
                    <div class="flex items-center justify-between w-full">
                        <div class="flex items-center gap-2" v-if="loaded">
                            <div class="scanner-switch-input" :class="{ disabled: videoDevices?.devices?.length < 2 }"
                                 @click="switchInputDevice">
                                <CameraIcon class="flex-shrink-0 min-w-0 w-6" aria-hidden="true" />
                            </div>
                            <div class="scanner-switch-input"
                                 :class="{ disabled: !hasAutofocus, activated: !autofocus && hasFocusDistance }"
                                 @click="autofocus = !autofocus">
                                <ViewfinderCircleIcon class="flex-shrink-0 min-w-0 w-6" aria-hidden="true" />
                            </div>
                            <div class="scanner-switch-input"
                                 :class="{ disabled: !hasTorch, activated: hasTorch && torch }" @click="torch = !torch">
                                <LightBulbIcon class="flex-shrink-0 min-w-0 w-6" aria-hidden="true" />
                            </div>
                        </div>
                        <button class="admin-border-icon-button" @click="closeModal">
                            <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 min-w-0 w-6"
                                 viewBox="0 0 32 32">
                                <g transform="translate(-236 -252)">
                                    <path d="M0,14.935,14.935,0" transform="translate(244.532 260.532)" fill="none"
                                          stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                          stroke-width="2" />
                                    <path d="M14.935,14.935,0,0" transform="translate(244.532 260.532)" fill="none"
                                          stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                          stroke-width="2" />
                                </g>
                            </svg>
                        </button>
                    </div>
                </div>
                <div class="relative">
                    <div
                        v-if="message"
                        :class="messageType"
                        class="scanner-message absolute top-0 left-0 right-0 text-left flex items-center p-2 w-full h-fit min-h-[50px] bg-office-black text-office-white font-bold text-base sm:text-lg z-[999999999]"
                    >
                        <div class="flex w-full mr-2">{{ message }}</div>
                        <div class="flex flex-col sm:flex-row sm:space-x-4 space-y-4 sm:space-y-0">
                            <div v-if="endDate" class="flex items-center space-x-2">
                                <week-color :end-date="endDate" />
                            </div>
                        </div>
                    </div>
                    <div class="barcode-container">
                        <StreamBarcodeReader
                            ref="scanner"
                            v-model:videoDevices="videoDevices"
                            v-model:hasFocusDistance="hasFocusDistance"
                            v-model:hasAutofocus="hasAutofocus"
                            v-model:hasTorch="hasTorch"
                            v-model:hasZoom="hasZoom"
                            :torch="torch"
                            :zoom="Number(zoom)"
                            :autofocus="autofocus"
                            :focus-distance="Number(focusDistance)"
                            :device-index="deviceIndex"
                            @decode="scanSuccessful"
                            @loaded="onLoaded"
                        />
                        <div v-if="!autofocus && hasFocusDistance && loaded" class="focus-container">
                            <div>Focus</div>
                            <input v-model="focusDistance" type="range" :min="hasFocusDistance.min || 0"
                                   :max="Math.min(hasFocusDistance.max, 1) || 1" :step="hasFocusDistance.step || 0.1" />
                        </div>

                        <div v-if="hasZoom && loaded" class="zoom-container">
                            <div>Zoom</div>
                            <input v-model="zoom" type="range" :min="hasZoom.min || 1" :max="hasZoom.max || 2"
                                   :step="hasZoom.step || 0.1" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import StreamBarcodeReader from "../../Components/StreamBarcodeReader.vue";
    import WeekColor from "./WeekColor.vue";
    import { CameraIcon, ViewfinderCircleIcon, LightBulbIcon } from "@heroicons/vue/20/solid";

    const initialState = {
        loaded: false,
        modalState: false,
        torch: false,
        zoom: 1,
        autofocus: true,
        focusDistance: 0,
        hasTorch: false,
        hasZoom: false,
        hasAutofocus: false,
        hasFocusDistance: false,
        videoDevices: {},
        deviceIndex: null,
        debounce: false,
        debounceTimeout: null,
        isMobile: navigator?.userAgentData?.mobile || navigator?.platform === "iPad" || navigator?.platform === "iPhone",
        isChrome: navigator?.userAgentData?.brands.findIndex((brand) => brand.brand === "Google Chrome" || brand.brand === "Chromium") !== -1
    };

    export default {
        components: {
            StreamBarcodeReader,
            CameraIcon,
            ViewfinderCircleIcon,
            LightBulbIcon,
            WeekColor
        },
        data() {
            return {
                barcode: null,
                message: null,
                modalOpen: false,
                messageType: null,
                endDate: null,
                ...initialState
            };
        },
        beforeUnmount() {
            this.closeModal();
        },
        computed: {
            isAndroidChrome() {
                return this.isMobile && this.isAndroid && this.isChrome;
            }
        },
        watch: {
            hasAutofocus() {
                this.autofocus = this.hasAutofocus;
            },
            focusDistance() {
                this.sliderMovement();
            },
            zoom() {
                this.sliderMovement();
            },
            "videoDevices.selectedIndex": {
                handler() {
                    if (this.loaded) {
                        this.deviceIndex = this.videoDevices?.selectedIndex;
                    }
                }
            }
        },
        methods: {
            switchInputDevice() {
                const length = this.videoDevices?.devices?.length;
                if (this.deviceIndex >= 0 && length > 1) {
                    this.loaded = false;
                    this.deviceIndex = this.deviceIndex + 1 >= length ? 0 : this.deviceIndex + 1;
                }
            },
            sliderMovement() {
                if (!this.debounce) {
                    this.debounce = true;
                    window.navigator?.vibrate?.(10);
                    clearTimeout(this.debounceTimeout);
                    this.debounceTimeout = setTimeout(() => {
                        this.debounce = false;
                    }, 10);
                }
            },
            onLoaded() {
                this.loaded = true;
                if (!this.hasAutofocus) {
                    this.autofocus = false;
                }
                console.log("loaded");
            },
            // stopScanner() {
            //     const tracks = this.$refs.scanner.srcObject.getTracks();
            //     tracks.forEach((track) => {
            //         track.stop();
            //     });
            // },
            scanSuccessful(barcode) {
                const vm = this;
                axios
                    .post("/admin/produkter-scan", {
                        barcode: barcode
                    })
                    .then((response) => {
                        if (response.data.message) {
                            this.message = response.data.message;
                            this.messageType = "success";
                            this.endDate = response.data.endDate;

                            console.log(this.endDate);

                            const audio = new Audio("/assets/sounds/ding.wav");
                            audio.play();
                            // audio.play();
                            setTimeout(() => {
                                vm.message = null;
                                vm.messageType = null;
                            }, 8000);
                        }
                    })
                    .catch((error) => {
                        this.message = error.response.data.message;
                        this.messageType = "error";

                        // console.log(error.response);

                        const audio = new Audio("/assets/sounds/negative-ding.wav");
                        audio.play();
                        // audio.play();

                        setTimeout(() => {
                            vm.message = null;
                            vm.messageType = null;
                        }, 8000);
                    });
            },
            openModal() {
                this.modalOpen = true;
            },
            closeModal() {
                Object.assign(this.$data, initialState);
                this.modalOpen = false;

                // this.stopScanner();
            }
        }
    };
</script>
