<template>
    <div>
        <label v-if="label" class="form-label">{{ label }}</label>
        <Combobox v-model="selectedItem" as="div" class="relative w-full">
            <div>
                <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-office-gray-600" viewBox="0 0 16 16">
                        <path d="M12.667,6.333A6.333,6.333,0,1,1,6.333,0,6.333,6.333,0,0,1,12.667,6.333Z" transform="translate(1.333 1.333)" fill="none" stroke="#b4b4b4" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                        <path d="M1.333,1.333,0,0" transform="translate(13.333 13.333)" fill="none" stroke="#b4b4b4" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                    </svg>
                </div>
                <ComboboxInput class="search-small-input" @change="query = $event.target.value" :display-value="(item) => item.name" :placeholder="placeholder" />
            </div>
            <div class="absolute w-full h-full z-[1]">
                <ComboboxOptions v-if="query && filteredItems.length" class="mt-1 bg-white rounded-xl shadow-lg divide-y divide-gray-100">
                    <ComboboxOption
                        v-for="item in filteredItems"
                        :key="item.id"
                        :value="item"
                        :disabled="item.value === null"
                        class="relative py-2 pl-10 pr-4 hover:bg-gray-50 cursor-pointer"
                    >
                        {{ item.name }}
                    </ComboboxOption>
                </ComboboxOptions>
            </div>
            <button class="absolute right-3 top-3 text-gray-500 hover:text-gray-900" type="button" v-if="selectedItem" @click="selectedItem = ''">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="flex-shrink-0 min-w-0 w-4">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </button>
        </Combobox>

        <input v-if="selectedItem" :name="name" type="hidden" :value="selectedItem.value" />
    </div>
</template>

<script>
import {
    Combobox,
    ComboboxInput,
    ComboboxOptions,
    ComboboxOption,
} from '@headlessui/vue'
export default {
    props: {
        items: {
            type: Array,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: false,
        },
        placeholder: {
            type: String,
            required: false,
        },
        preselectedItem: {
            required: false,
        }
    },
    components: {
        Combobox,
        ComboboxInput,
        ComboboxOptions,
        ComboboxOption,
    },
    data() {
        return {
            query: '',
            selectedItem: '',
        }
    },
    watch: {
        query() {
            if(this.query.length === 0) {
                this.showDropdown = false;
            }
            if(this.query.length > 1) {
                this.search();
            }
        }
    },
    mounted() {
        if(this.preselectedItem) {
            this.selectedItem = this.items.find((item) => {
                return item.value === this.preselectedItem;
            });
        }
    },
    computed: {
        filteredItems() {
            return this.query.value === ''
                ? this.items
                : this.items.filter((item) => {
                    return item.name.toLowerCase().includes(this.query.toLowerCase())
                });
        }
    }
}
</script>
