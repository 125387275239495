<template>
    <div class="office-calendar-wrapper">
        <span v-if="label" class="mb-1 text-sm font-bold">{{ label }}</span>
        <VDatePicker v-model="selectedDate" locale="da" expanded :disabled-dates="disabledDates" show-iso-weeknumbers />
        <input v-model="selectedDate" type="hidden" :name="name" />
    </div>
</template>

<script>
    export default {
        props: {
            name: String,
            passedDate: String,
            disabledDatesProp: Array,
            label: String
        },
        data() {
            return {
                selectedDate: new Date(this.passedDate),
                disabledDates: this.disabledDatesProp
            };
        }
    };
</script>

<style>
    /*Weekdays header*/
    .office-calendar-wrapper .vc-weekday {
        background-color: #161616;
        padding-top: 8px !important;
        color: #f1f0f0 !important;
    }

    .office-calendar-wrapper .vc-weeknumber-content {
        color: #ecae9e !important;
    }

    .office-calendar-wrapper .vc-weeks {
        padding: 0 !important;
    }

    .office-calendar-wrapper .vc-header {
        margin-bottom: 8px;
    }

    .office-calendar-wrapper .vc-title {
        font-size: 14px;
        text-transform: capitalize;
    }

    .office-calendar-wrapper .vc-day {
        padding-top: 8px !important;
        padding-bottom: 8px !important;
    }

    /*is Today*/
    .office-calendar-wrapper .vc-container .vc-day.is-today .vc-day-content.vc-focusable {
        background-color: #b25028;
        color: #fff;
        border-radius: 0.25rem;
    }

    /*Available dates*/
    .office-calendar-wrapper .vc-container .vc-day .vc-day-content.vc-focusable {
        background-color: #94d789;
        border-radius: 0.25rem;
    }

    /*Disabled dates*/
    .office-calendar-wrapper .vc-container .vc-day .vc-day-content.vc-focusable.is-disabled {
        background-color: transparent;
    }

    /*Highligted date*/
    .office-calendar-wrapper .vc-container .vc-day .vc-highlights.vc-day-layer + .vc-day-content.vc-focusable {
        background-color: #1592b6;
        color: white;
    }
</style>
