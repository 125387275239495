<template>
    <div>
        <label v-if="label" class="form-label">{{ label }}</label>
        <div class="editor-container">
            <div v-if="editor" class="editor">
                <div class="editor-menubar bg-gray-50 rounded-t-lg">
                    <button class="editor-menu-button" :class="{ 'is-active': editor.isActive('bold') }" type="button"
                            @click="editor.chain().focus().toggleBold().run()">
                        <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 min-w-0 w-5" viewBox="0 0 24 24">
                            <g transform="translate(-428 -252)">
                                <path d="M0,2A2.006,2.006,0,0,1,2,0H7.12a4.75,4.75,0,0,1,0,9.5H0Z"
                                      transform="translate(432.88 254.5)" fill="none" stroke="currentColor"
                                      stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                <path d="M0,0H9.5a4.75,4.75,0,0,1,0,9.5H2a2.006,2.006,0,0,1-2-2V0Z"
                                      transform="translate(432.88 264)" fill="none" stroke="currentColor"
                                      stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                            </g>
                        </svg>
                    </button>
                    <button class="editor-menu-button" :class="{ 'is-active': editor.isActive('italic') }" type="button"
                            @click="editor.chain().focus().toggleItalic().run()">
                        <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 min-w-0 w-5" viewBox="0 0 24 24">
                            <g transform="translate(-236 -252)">
                                <path d="M0,0H9.25" transform="translate(245.62 255)" fill="none" stroke="currentColor"
                                      stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                <path d="M0,0H9.25" transform="translate(241.12 273)" fill="none" stroke="currentColor"
                                      stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                <path d="M4.5,0,0,18" transform="translate(245.75 255)" fill="none"
                                      stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                      stroke-width="1.5" />
                            </g>
                        </svg>
                    </button>
                    <button class="editor-menu-button" type="button"
                            :class="{ 'is-active': editor.isActive('highlight') }"
                            @click="editor.chain().focus().toggleHighlight().run()">
                        <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 min-w-0 w-5" viewBox="0 0 24 24">
                            <g transform="translate(-234 -190)">
                                <path
                                    d="M15.54,1.93A33.727,33.727,0,0,1,6.91,13.58L4.94,15.16a3.588,3.588,0,0,1-.78.45,3.728,3.728,0,0,0-.04-.57,3.916,3.916,0,0,0-1.16-2.29A4.084,4.084,0,0,0,.6,11.56a4.007,4.007,0,0,0-.6-.01,2.614,2.614,0,0,1,.49-.84L2.05,8.74A33.629,33.629,0,0,1,13.7.1a1.4,1.4,0,0,1,1.52.31A1.358,1.358,0,0,1,15.54,1.93Z"
                                    transform="translate(240.27 192.01)"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="1.5"
                                />
                                <path
                                    d="M8.445,4.064a4.17,4.17,0,0,1-1.21,2.95A4.156,4.156,0,0,1,4.8,8.174l-2.46.27a2.114,2.114,0,0,1-2.33-2.35l.27-2.46A4.077,4.077,0,0,1,4.295,0a5.956,5.956,0,0,1,.6.01A4.025,4.025,0,0,1,7.255,1.2a3.916,3.916,0,0,1,1.16,2.29C8.425,3.684,8.445,3.874,8.445,4.064Z"
                                    transform="translate(235.985 203.556)"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="1.5"
                                />
                                <path d="M4.73,4.73A4.733,4.733,0,0,0,0,0" transform="translate(243.51 199.74)"
                                      fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                      stroke-width="1.5" />
                            </g>
                        </svg>
                    </button>
                    <div class="divider"></div>
                    <button
                        class="editor-menu-button flex items-center justify-center max-w-[36px] min-h-[36px] max-h-[36px]"
                        type="button"
                        :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
                        @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
                    >
                        <span class="text-lg">h3</span>
                    </button>
                    <button
                        class="editor-menu-button flex items-center justify-center max-w-[36px] min-h-[36px] max-h-[36px]"
                        type="button"
                        :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }"
                        @click="editor.chain().focus().toggleHeading({ level: 4 }).run()"
                    >
                        <span class="text-lg">h4</span>
                    </button>
                    <button class="editor-menu-button" :class="{ 'is-active': editor.isActive('bulletList') }"
                            type="button" @click="editor.chain().focus().toggleBulletList().run()">
                        <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 min-w-0 w-5" viewBox="0 0 24 24">
                            <g transform="translate(-364 -188)">
                                <path d="M0,0H12.213" transform="translate(372.787 192.5)" fill="none"
                                      stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                      stroke-width="1.5" />
                                <path d="M0,0H12.213" transform="translate(372.787 207.5)" fill="none"
                                      stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                      stroke-width="1.5" />
                                <path d="M0,0H12.213" transform="translate(372.787 200)" fill="none"
                                      stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                      stroke-width="1.5" />
                                <path d="M0,0H2.365" transform="translate(367 192.5)" fill="none" stroke="currentColor"
                                      stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                <path d="M0,0H2.365" transform="translate(367 207.5)" fill="none" stroke="currentColor"
                                      stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                <path d="M0,0H2.365" transform="translate(367 200)" fill="none" stroke="currentColor"
                                      stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                            </g>
                        </svg>
                    </button>
                    <div class="divider"></div>
                    <button class="editor-menu-button" type="button"
                            @click="editor.chain().focus().setHorizontalRule().run()">
                        <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 min-w-0 w-5" viewBox="0 0 24 24">
                            <g transform="translate(-364 -188)">
                                <path d="M0,0H10" transform="translate(371 200)" fill="none" stroke="currentColor"
                                      stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                <path d="M0,0H1" transform="translate(367 200)" fill="none" stroke="currentColor"
                                      stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                <path d="M0,0H1" transform="translate(384 200)" fill="none" stroke="currentColor"
                                      stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                            </g>
                        </svg>
                    </button>
                    <div class="divider"></div>
                    <button class="editor-menu-button" type="button" @click="editor.chain().focus().undo().run()">
                        <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 min-w-0 w-5" viewBox="0 0 24 24">
                            <g transform="translate(-108 -316)">
                                <path d="M3,10h8A5,5,0,0,0,11,0H0" transform="translate(112.13 324.31)" fill="none"
                                      stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                      stroke-width="1.5" />
                                <path d="M2.56,5.12,0,2.56,2.56,0" transform="translate(111.87 321.69)" fill="none"
                                      stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                      stroke-width="1.5" />
                            </g>
                        </svg>
                    </button>
                    <button class="editor-menu-button" type="button" @click="editor.chain().focus().redo().run()">
                        <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 min-w-0 w-5" viewBox="0 0 24 24">
                            <g transform="translate(-172 -316)">
                                <path d="M13,10H5A5,5,0,1,1,5,0H16" transform="translate(175.87 324.31)" fill="none"
                                      stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                      stroke-width="1.5" />
                                <path d="M0,5.12,2.56,2.56,0,0" transform="translate(189.57 321.69)" fill="none"
                                      stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                      stroke-width="1.5" />
                            </g>
                        </svg>
                    </button>
                </div>
                <editor-content class="editor-content bg-white rounded-b-lg" :editor="editor" />
                <input id="content" type="hidden" name="content" />
            </div>
        </div>

        <div v-if="errors" class="error">
            {{ errors }}
        </div>
    </div>
</template>

<script>
    import { Editor, EditorContent } from "@tiptap/vue-3";
    import StarterKit from "@tiptap/starter-kit";
    import { Highlight } from "@tiptap/extension-highlight";
    import { Placeholder } from "@tiptap/extension-placeholder";

    export default {
        components: {
            EditorContent
        },

        props: {
            value: {
                type: String,
                default: ""
            },
            label: {
                type: String,
                required: false
            },
            placeholder: {
                type: String,
                required: false
            }
        },

        data() {
            return {
                editor: null,
                errors: null
            };
        },
        mounted() {
            this.editor = new Editor({
                content: this.value,
                extensions: [
                    StarterKit,
                    Highlight.configure({ multicolor: true }),
                    Placeholder.configure({
                        placeholder: this.placeholder
                    })
                ],
                onUpdate: () => {
                    // HTML
                    document.querySelector("#content").setAttribute("value", this.editor.getHTML());
                    // JSON
                    // this.emitter.emit('input', this.editor.getJSON())
                }
            });
        },

        beforeDestroy() {
            this.editor.destroy();
        }
    };
</script>
