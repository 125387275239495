<template>
    <div class="flex items-center justify-end gap-2">
        <vue-date-picker v-model="date" range :multi-calendars="{ solo: true }" style="width: auto"
                         :preset-dates="presetDates" position="left"
                         :enable-time-picker="false" :format="format" :locale="locale"
                         :max-date="new Date()" :min-date="minDate" week-numbers="iso" week-num-name=""
                         :select-text="$translate('Apply')"
                         :cancel-text="$translate('Cancel')">
            <template v-slot:preset-date-range-button="{ label, value, presetDate }">
              <span role="button"
                    :tabindex="0"
                    @click="presetDate(label, value)"
                    @keyup.enter.prevent="presetDate(label, value)"
                    @keyup.space.prevent="presetDate(label, value)">
                {{ label }}
              </span>
            </template>
            <template v-slot:clear-icon="{ clear }">
                <svg xmlns="http://www.w3.org/2000/svg" class="mr-4 w-2.5 h-2.5" viewBox="0 0 19.998 15.001">
                    <path d="M11.178,19.569a1,1,0,0,0,1.644,0l9-13A1,1,0,0,0,21,5H3a1,1,0,0,0-.822,1.569l9,13Z"
                          transform="translate(-2.002 -5)" />
                </svg>
            </template>
        </vue-date-picker>
    </div>
</template>

<script>
    import VueDatePicker from "@vuepic/vue-datepicker";
    import "@vuepic/vue-datepicker/dist/main.css";

    export default {
        name: "AdvancedDatePicker",
        components: { VueDatePicker },
        props: {
            locale: {},
            startDate: String,
            endDate: String
        },
        data() {
            const minDate = new Date(2021, 9, 29);
            const today = new Date();
            const yesterday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
            const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
            const startOfThisMonth = new Date(today.getFullYear(), today.getMonth(), 1);
            const endOfThisMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
            const endOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
            const startOfThisYear = new Date(today.getFullYear(), 0, 1);
            const endOfThisYear = new Date(today.getFullYear(), 11, 31);
            const endOfTodayYear = today < endOfThisYear ? today : endOfThisYear;
            const thisWeekMonday = new Date(today.setDate(today.getDate() - today.getDay() + (today.getDay() === 0 ? -6 : 1)));
            const todayThisWeek = new Date(); // Reset 'Today' as it was changed in previous statement
            const lastWeekMonday = new Date(thisWeekMonday);
            lastWeekMonday.setDate(lastWeekMonday.getDate() - 7);
            const lastWeekSunday = new Date(thisWeekMonday);
            lastWeekSunday.setDate(thisWeekMonday.getDate() - 1);

            let startDate = this.startDate ? new Date(this.startDate) : new Date();
            let endDate = this.endDate ? new Date(this.endDate) : new Date();

            return {
                date: [startDate, endDate],
                activePreset: null,
                minDate,
                presetDates: [
                    { label: "Yesterday", value: [yesterday, new Date(yesterday.getTime())] },
                    { label: "Today", value: [todayThisWeek, new Date(todayThisWeek.getTime())] },
                    { label: "This week", value: [thisWeekMonday, todayThisWeek] },
                    { label: "Last week", value: [lastWeekMonday, lastWeekSunday] },
                    { label: "This month", value: [startOfThisMonth, endOfThisMonth] },
                    { label: "Last month", value: [lastMonth, endOfLastMonth] },
                    { label: "This year", value: [startOfThisYear, todayThisWeek] },
                    { label: "All Time", value: [minDate, todayThisWeek] }
                ]
            };
        },
        watch: {
            date: {
                handler(newDateRange) {
                    axios.post("/admin/finance/store-selected-dates", {
                        start_date: newDateRange[0].toISOString().split("T")[0],
                        end_date: newDateRange[1].toISOString().split("T")[0]
                    }).then(response => {
                        console.log(response.data); // handle success
                        this.$root.emitter.emit("dateChanged");
                    }).catch(error => {
                        console.error(error); // handle error
                    });
                },
                deep: true
            }
        },
        methods: {
            format(dates) {
                if (!Array.isArray(dates)) {
                    dates = [dates];
                }

                let startDate = dates[0];
                let endDate = dates[1] || startDate;

                const options = { year: "numeric", month: "short", day: "numeric" };
                let startStr = new Intl.DateTimeFormat("en-US", options).format(startDate);
                let endStr = new Intl.DateTimeFormat("en-US", options).format(endDate);

                // Split parts of the dates
                let [startMonthDay, startYear] = startStr.split(", ");
                let [endMonthDay, endYear] = endStr.split(", ");

                // Translate month names
                let [startMonth, startDay] = this.translateMonth(startMonthDay);
                let [endMonth, endDay] = this.translateMonth(endMonthDay);

                // Check if start and end dates are the same
                if (startDate.toDateString() === endDate.toDateString()) {
                    return `${endDay} ${endMonth}, ${endYear}`;
                } else if (startDate.getFullYear() === endDate.getFullYear()) {
                    if (startDate.getMonth() === endDate.getMonth()) {
                        return `${startDay} ${startMonth} - ${endDay}, ${endYear}`;
                    } else {
                        return `${startDay} ${startMonth} - ${endDay} ${endMonth}, ${endYear}`;
                    }
                } else {
                    return `${startDay} ${startMonth} ${startYear} - ${endDay} ${endMonth} ${endYear}`;
                }
            },
            translateMonth(monthDayStr) {
                const enShortMonths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                let monthDayParts = monthDayStr.split(" ");

                // Get locale equivalent of month
                let monthIndex = enShortMonths.indexOf(monthDayParts[0]);
                let localeMonth = new Date(2000, monthIndex).toLocaleDateString(this.locale, { month: "short" });

                // Return parts with translated month
                return [localeMonth, monthDayParts[1]];
            }
        }
    };
</script>